import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useLocationState } from '../../../Common/Util/CustomHooks';
import * as request from '../../../Common/Util/Request';
import * as excel from '../../../Common/Util/Excel';
import * as Button from '../../../Common/Component/Button';
import DatePicker from '../../../Common/Component/DatePicker';
import Table from '../../../Common/Component/Table';

function UserList(props) {
  const intl = useIntl();
  const location = useLocation();

  const isForeign = useMemo(() => location.pathname === '/user/foreign-list', [location.pathname]);

  const [locationState, setLocationState] = useLocationState({
    start_date: null,
    end_date: null,
    searchOption: 'name',
    searchText: '',
    filters: [],
  });

  const { start_date, end_date, filters } = locationState;
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onChangeSearchOption = useCallback((e) => {
    setSearchText('');
    setSearchOption(e.target.value);
  }, []);
  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchOption, searchText]);

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: '닉네임', accessor: 'nickname' },
      { Header: '이름', accessor: 'name' },
      {
        Header: '구분',
        accessor: 'role',
        Cell: ({ value }) => <FormattedMessage id={'ID_ROLE_' + value.toUpperCase()} />,
      },
      { Header: '총 구매 강좌수', accessor: 'countAllPaidClass' },
      {
        Header: '총 결제 금액',
        accessor: 'amountAllPaidClass',
        Cell: ({ value }) => value.toPrice(),
      },
      { Header: '총 방문수', accessor: 'count_visit' },
      {
        Header: '가입일',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => (createdAt ? moment.utc(createdAt).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '최종방문일',
        accessor: 'lastLoginAt',
        Cell: ({ value: lastLoginAt }) => (lastLoginAt ? moment.utc(lastLoginAt).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '가입 유형',
        accessor: 'login_type',
      },
      {
        Header: '탈퇴',
        accessor: 'deletedAt',
        Cell: ({ value: deletedAt }) => (deletedAt ? '탈퇴' : '가입'),
      },
      {
        Header: '정보수정',
        accessor: (row) => (
          <Link
            to={{
              pathname: `${location.pathname}/${row.id}`,
              state: row,
            }}
          >
            수정
          </Link>
        ),
      },
    ],
    [location.pathname],
  );

  const loadUsers = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        korean: !isForeign,
        filtered: JSON.stringify(filters),
      };

      if (start_date) {
        params.start_date = moment(start_date).format('YYYY-MM-DD');
      }
      if (end_date) {
        params.end_date = moment(end_date).format('YYYY-MM-DD');
      }

      return request.users(params);
    },
    [isForeign, filters, start_date, end_date],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadUsers({
        page: pageIndex + 1,
        limit: pageSize,
      }).then(({ users }) => {
        setUsers(users.rows);
        setTotal(users.count);
      });
    },
    [loadUsers],
  );

  const onClickCSV = useCallback(() => {
    loadUsers({
      page: 1,
      limit: 99999, // temp
    }).then(({ users }) => {
      const data = users.rows.map((user) => ({
        ID: user.id,
        닉네임: user.nickname,
        이름: user.name,
        이메일: user.email,
        핸드폰번호: user.phone ? user.phone.toPhone() : '-',
        국가정보: user.FK_country?.name_kor || '-',
        '혜택 수신 동의': user.is_marketing_on ? '동의' : '비동의',
        구분: intl.formatMessage({ id: 'ID_ROLE_' + user.role.toUpperCase() }),
        '총 구매 강좌수': user.countAllPaidClass,
        '총 결제 금액': user.amountAllPaidClass,
        '총 방문수': user.count_visit,
        가입일: user.createdAt ? moment.utc(user.createdAt).format('YYYY-MM-DD') : '-',
        최종방문일: user.lastLoginAt ? moment.utc(user.lastLoginAt).format('YYYY-MM-DD') : '-',
        탈퇴일: user.deletedAt ? moment.utc(user.deletedAt).format('YYYY-MM-DD') : '-',
      }));
      excel.downloadXLSX(data);
    });
  }, [intl, loadUsers]);

  return (
    <div className="user-list">
      <div className="user-list__filter">
        <div className="user-list__filter__date">
          기간
          <DatePicker
            selected={start_date}
            maxDate={end_date}
            onChange={(date) => setLocationState({ start_date: date })}
          />
          ~
          <DatePicker
            selected={end_date}
            minDate={start_date}
            onChange={(date) => setLocationState({ end_date: date })}
          />
        </div>
        <div className="user-list__filter__select">
          <select value={searchOption} onChange={onChangeSearchOption}>
            <option value="name">이름</option>
            <option value="nickname">닉네임</option>
            <option value="email">이메일</option>
            <option value="phone">핸드폰 번호</option>
            <option value="id">회원ID</option>
          </select>
          <input value={searchText} onChange={onChangeSearchText} />
          <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
        </div>
      </div>
      <div className="user-list__total">
        총 회원수 {total}
        <Button.Excel onClick={onClickCSV}>엑셀 다운로드</Button.Excel>
      </div>
      <Table data={users} columns={columns} total={total} pageSize={20} fetchData={fetchData} />
    </div>
  );
}

export default UserList;
