import { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../Common/Util/Request';
import Table from '../../../Common/Component/Table';
import { useLocationState } from '../../../Common/Util/CustomHooks';
import moment from 'moment';
import 'moment/locale/ko';
import { useHistory } from 'react-router-dom';
import { Button, Input, MenuItem, OutlinedInput, Select } from '@mui/material'; // @mui/material
import SearchIcon from '@mui/icons-material/Search';
import './List.scss';
import swal from 'sweetalert2';

const OfflineClassesByScheduleList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    offset: 0,
  });
  const [locationState, setLocationState] = useLocationState({
    page: 1,
    className: '',
    scheduleName: '',
    classDate: '',
  });

  const fetchData = useCallback(async ({ pageIndex, searchType, searchText }) => {
    setLoading(true);
    await request.updateAttendanceBoard();
    const params = {
      page: pageIndex + 1,
    };
    if (searchType && searchText) {
      params[searchType] = searchText;
    }
    request
      .getOfflineClassesBySchedule(params)
      .then((response) => {
        const { success, classes, pagination } = response;
        console.log(response);
        if (success) {
          setList(classes);
          setPagination(pagination);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 250);
      });
  }, []);
  const HeaderNames = {
    id: 'ID',
    classId: '강의ID',
    className: '강의명',
    startDate: '시작일',
    endDate: '종료일',
    classDays: '수업요일',
    startTime: '시작시간',
    endTime: '종료시간',
    classroom: '강의실',
    studentCount: '수강생 수',
  };
  const HeaderWidths = {
    id: 80,
    classId: 80,
    className: 520,
    startDate: 130,
    endDate: 130,
    classDays: 120,
    startTime: 90,
    endTime: 90,
    classroom: 160,
    studentCount: 80,
  };
  const ignoreColumns = ['scheduleIds', 'studentIds'];
  const columns = useMemo(() => {
    if (list.length === 0) return [];
    const keys = Object.keys(list[0]);
    const result = keys
      .filter((key) => !ignoreColumns.includes(key))
      .map((key) => {
        return {
          Header: key in HeaderNames ? HeaderNames[key] : key,
          className: key,
          accessor: (row) => {
            switch (key) {
              case 'classDays':
                const days = row[key];
                return days
                  .split('')
                  .map((day, index) => {
                    if (day === '1')
                      return moment()
                        .day(index + 1 > 6 ? 0 : index + 1)
                        .format('ddd');
                  })
                  .filter((day) => day)
                  .join('');
                break;
              default:
                return row[key];
            }
            return row[key];
          },
          Cell: (cell) => {
            const {
              column: { className },
            } = cell;
            return <div>{cell.value}</div>;
          },
        };
      });
    // console.log(result);
    return result;
  }, [list]);

  const goToPage = useCallback((row) => {
    const { classId, id } = row.original;
    window.open(`/lecture/${classId}/attendance/${id}`, '_blank');
  }, []);

  const [searchType, setSearchType] = useState(locationState.searchType || 'className');
  const [searchText, setSearchText] = useState(locationState.searchText || '');

  const onClickSearch = useCallback(() => {
    fetchData({ pageIndex: 0, searchType, searchText });
  }, [searchType, searchText]);

  const updateAttendanceBoard = useCallback(() => {
    swal
      .fire({
        title: '출석부를 갱신하시겠습니까?',
        text: '종료일이 도래하지 않은 모든 강의의 출석부가 갱신됩니다.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '갱신',
        cancelButtonText: '취소',
      })
      .then((result) => {
        if (result.isConfirmed) {
          request
            .updateAttendanceBoard()
            .then((response) => {
              // console.log(response);
              const { success } = response;
              if (success) {
                swal.fire({
                  icon: 'success',
                  title: '출석부 갱신이 완료되었습니다.',
                  timer: 2500,
                  showConfirmButton: false,
                });
              } else {
                swal.fire({
                  icon: 'error',
                  title: '출석부 갱신에 실패했습니다.',
                });
              }
            })
            .finally(() => {
              fetchData({ pageIndex: 0, searchType, searchText });
            });
        }
      });
  }, []);

  return (
    <div className="container">
      <div className="header-wrap" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div
          className="filters--search--wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            size="small"
            sx={{
              fontSize: '0.875rem',
              marginRight: '0.5rem',
            }}
          >
            <MenuItem value="className" sx={{ fontSize: '0.875rem' }}>
              강의 제목
            </MenuItem>
            {/* <MenuItem value="classId" sx={{ fontSize: '0.875rem' }}>
              강의 ID
            </MenuItem> */}
          </Select>

          <OutlinedInput
            value={searchText}
            size="small"
            sx={{
              fontSize: '0.875rem',
              marginRight: '0.5rem',
              width: '20rem',
            }}
            placeholder="검색어를 입력하세요."
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClickSearch();
              }
            }}
          />

          <Button
            variant="outlined"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              onClickSearch();
            }}
            sx={{
              fontSize: '0.875rem',
              height: '2.25rem',
              minWidth: '4rem',
              color: '#4f4f4f',
              borderColor: '#bebebe',
              '&:hover': {
                borderColor: '#000000',
                backgroundColor: '#f5f5f5',
              },
            }}
            disableRipple
            endIcon={
              <SearchIcon
                fontSize="small"
                sx={{
                  marginLeft: '-0.25rem',
                }}
              />
            }
          >
            검색
          </Button>
        </div>
        {/*<Button variant="contained" size="small" onClick={updateAttendanceBoard} sx={{ fontSize: '0.875rem' }}>*/}
        {/*  출석부 갱신*/}
        {/*</Button>*/}
      </div>
      <div className="content-wrap">
        <div className="content-table">
          <Table data={list} columns={columns} total={pagination.total} fetchData={fetchData} onClickRow={goToPage} />
          {list.length === 0 && !loading && (
            <div
              className="empty-data"
              style={{
                textAlign: 'center',
                padding: '2rem',
                fontSize: '1.25rem',
                color: '#9c9c9c',
                height: '360px',
                lineHeight: '360px',
              }}
            >
              데이터가 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OfflineClassesByScheduleList;
