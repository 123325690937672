import React from 'react';
import { FormattedMessage } from 'react-intl';
import Breadcrumb from './Breadcrumb';
import Menus from './Menus';
import './index.scss';

function Navigator(props) {
  const path = window.location.pathname;
  const pathElement = path.split('/');
  const pathElementWithoutBlank = pathElement.filter((_) => _);
  const ids = pathElementWithoutBlank.filter((_) => !isNaN(_));
  const paths = pathElementWithoutBlank.filter((_) => isNaN(_));
  const id = pathElement[pathElement.length - 1] !== 'list' ? pathElement[pathElement.length - 1] : '';
  const exception = [
    `/lecture/single/new`,
    `/lecture/single/new/${id}`,
    `/lecture/package/new`,
    `/lecture/package/new/${id}`,
    `/lecture/tutor/new`,
    `/lecture/tutor/new/${id}`,
    `/lecture/given/new`,
    `/lecture/given/new/${id}`,
    `/survey/list/new`,
    `/survey/list/new/${id}`,
    `/survey/question-list/new`,
    `/survey/question-list/new/${id}`,
    `/promotion/coupon/new`,
    `/promotion/coupon/new/${id}`,
    `/contents/records/new`,
    `/contents/records/edit/${id}`,
    `/lecture/review/new`,
    `/lecture/review/new/${id}`,
    `/organization/${id}`,
    `/organization/create`,
    `/notice/new`,
    `/notice/new/${id}`,
    `/event/new`,
    `/event/new/${id}`,
    `/blog/new`,
    `/blog/new/${id}`,
    `/lecture/${id}/schedule`,
    `/survey/${id}/statistics`,
  ];
  const isSchedule = /\/lecture\/\d+\/attendance/.test(path);

  const newTabEdit =
    exception.includes(path) ||
    paths.includes('new') ||
    paths.includes('edit') ||
    paths.includes('statistics') ||
    isSchedule;

  return (
    <div className="nav">
      <div className="header">
        <div className="header__title">
          <FormattedMessage id="ID_TITLE" />
        </div>
      </div>
      <div className="body">
        {!Boolean(newTabEdit) && <Menus />}
        <div className="content">
          <Breadcrumb />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default Navigator;
