import { useEffect } from 'react';
import { useHistory } from 'react-router';
import './index.scss';

function Unauth() {
  const history = useHistory();
  useEffect(() => {
    alert('권한이 없습니다');
    history.goBack();
  }, [history]);
  return null;
}

export default Unauth;
