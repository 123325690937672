import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import * as Button from '../../../../Common/Component/Button';
import * as Field from '../../../../Common/Component/Field';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FormControlLabel, Switch } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import * as excel from '../../../../Common/Util/Excel';

function ThirdPartyUserList(props) {
  const intl = useIntl();
  const { id } = useParams();

  const [locationState, setLocationState] = useLocationState({
    searchOption: 'username',
    searchText: '',
    filters: [{ id: 'lectureId', value: id }],
  });

  const { filters } = locationState;
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText: searchOption === 'phone' ? searchText.replace(/^0/, '82') : searchText,
      filters: searchText
        ? [
            {
              id: searchOption,
              value: searchOption === 'phone' ? searchText.replace(/^0/, '82') : searchText,
            },
            { id: 'lectureId', value: id },
          ]
        : [{ id: 'lectureId', value: id }],
    });
  }, [setLocationState, searchOption, searchText, id]);

  const [userClasses, setUserClasses] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [isDownloading, setIsDownloading] = useState(null);

  const loadUsers = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        all_people: true,
        filtered: JSON.stringify(filters),
      };

      return request.lectureUsers(params).then(({ userClasses }) => userClasses);
    },
    [filters],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      setPageIndex(pageIndex);
      setPageSize(pageSize);
      loadUsers({ page: pageIndex + 1, limit: pageSize }).then((userClasses) => {
        setUserClasses(userClasses.rows);
        setTotal(userClasses.count);
      });
    },
    [loadUsers],
  );

  const onClickCertificateFile = useCallback((fileInput, code) => {
    if (code) {
      Swal.fire({
        title: '파일로 대체하시겠습니까?',
        text: '사이트에서 자체발급된 수료증이 존재하며, 업로드 시 즉시 반영됩니다.',
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
        preConfirm: () => fileInput.current.click(),
      });
    } else
      Swal.fire({
        title: `수료증을 업로드 하시겠습니까?`,
        text: '업로드 시 즉시 반영됩니다.',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
        showLoaderOnConfirm: true,
        preConfirm: () => fileInput.current.click(),
      });
  }, []);

  const onChangeCertificateFile = useCallback(
    (certificateFile, userClassId, certificate) => {
      if (certificateFile) {
        let params = {
          file: certificateFile,
        };
        if (certificate?.id)
          params = {
            ...params,
            certId: Number(certificate.id),
          };
        request
          .createCertificate(params, userClassId)
          .then((data) => {
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 업로드되었습니다.</div>`,
              position: 'center',
              icon: 'success',
            });
            fetchData({ pageIndex, pageSize });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">업로드에 실패했습니다.</div>`,
              toast: true,
              position: 'center',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'error',
              type: 'error',
            });
          });
      }
    },
    [fetchData, pageIndex, pageSize],
  );

  const onClickDeleteCertificateFile = useCallback(
    (fileInput, userClassId, certificateId, certCode) => {
      Swal.fire({
        title: `수료증을 제거하시겠습니까?`,
        text: '제거 시 즉시 반영됩니다.',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: '삭제',
        preConfirm: async () => {
          fileInput.current.value = null;
          request
            .deleteCertificate(certificateId)
            .then(() => {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 삭제되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              });
              const newUserClasses = userClasses.map((userClass) => {
                if (userClass.id === userClassId) {
                  certCode
                    ? (userClass.User_Certificates = [
                        {
                          id: userClass.User_Certificates[0].id,
                          code: userClass.User_Certificates[0].code,
                        },
                      ])
                    : (userClass.User_Certificates = []);
                }
                return userClass;
              });
              setUserClasses(newUserClasses);
            })
            .catch(() => {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'error',
                type: 'error',
              });
            });
        },
      });
    },
    [userClasses],
  );

  const onChangeSurveyDone = useCallback(
    (e, userClassId, type) => {
      // 프론트 뷰 변경
      // sol1. userClasses를 매핑하여 변경된 userClass만 수정, 적용
      // sol2. 다시 fetchData 요청(단점: API Call 횟수 증가)
      const newUserClasses = userClasses.map((userClass) => {
        if (userClass.id === userClassId) {
          if (type === 'pre') {
            e.target.checked ? (userClass.survey_pre_done = 1) : (userClass.survey_pre_done = -1);
          } else e.target.checked ? (userClass.survey_post_done = 1) : (userClass.survey_post_done = -1);
        }
        return userClass;
      });
      setUserClasses(newUserClasses);

      // 서버 요청
      const params = {
        value: e.target.checked,
        target: type,
      };
      request.updateSurveyDone(params, userClassId).catch(() => {
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">상태 변경에 실패했습니다.</div>`,
          toast: true,
          position: 'center',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          icon: 'error',
          type: 'error',
        });
        //실패 시 프론트 뷰 상태 원복
        const newUserClasses = userClasses.map((userClass) => {
          if (userClass.id === userClassId) {
            if (type === 'pre') {
              e.target.checked ? (userClass.survey_pre_done = -1) : (userClass.survey_pre_done = 1);
            } else e.target.checked ? (userClass.survey_post_done = -1) : (userClass.survey_post_done = 1);
          }
          return userClass;
        });
        setUserClasses(newUserClasses);
      });
    },
    [userClasses],
  );

  const updateEndDate = useCallback(
    (endDate, beforeEndDate, userClassId) => {
      const hasModified = moment(endDate).format('YYYY-MM-DD') !== moment(beforeEndDate).format('YYYY-MM-DD');
      if (hasModified) {
        Swal.fire({
          title: `수강종료일을 ${moment(endDate).format('YYYY-MM-DD')}로 수정하시겠습니까?`,
          text: '확인 시 즉시 반영됩니다.',
          showCancelButton: true,
          cancelButtonText: '취소',
          confirmButtonText: '확인',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const result = await request.updateEndDate(userClassId, endDate);
            return new Promise((resolve) => {
              setTimeout(() => {
                if (result.ok) {
                  Swal.fire({
                    text: '수강종료일이 수정되었습니다.',
                    type: 'success',
                    icon: 'success',
                    confirmButtonText: '확인',
                    toast: true,
                    timer: 2000,
                  });
                  fetchData({ pageIndex, pageSize });
                }
                resolve();
              }, 1000);
            });
          },
        });
      } else {
        Swal.fire({
          text: '수강종료일이 수정되지 않았습니다.',
          type: 'error',
          icon: 'error',
          confirmButtonText: '확인',
          toast: true,
        });
      }
    },
    [fetchData, pageIndex, pageSize],
  );

  const onClickCSV = useCallback(() => {
    setIsDownloading(true);
    loadUsers({ page: 1, limit: 9999999, all_people: true, filtered: JSON.stringify(filters) }).then((users) => {
      const data = users.rows.map((userClass) => {
        const classModel = userClass.FK_class;
        const registerInfo = userClass.ThirdPartyClassRegistrations[0] ?? {};
        const sectionProgresses = {};
        let number = 1;
        switch (classModel.class_type) {
          case 'single':
            for (let chapter of classModel.ClassChapters) {
              for (let section of chapter.ClassSections) {
                sectionProgresses[number + '차시'] = section.section_completion_rate
                  ? section.section_completion_rate + '%'
                  : '-';
                number++;
              }
            }
            break;
          case 'package':
            const classSinglePackages = classModel.ClassSingle_ClassPackages;
            for (const classSinglePackage of classSinglePackages) {
              const classModelInPackage = classSinglePackage.FK_class_single;

              for (const chapter of classModelInPackage.ClassChapters) {
                const sections = chapter.ClassSections;
                for (const section of sections) {
                  sectionProgresses[`${number}차시`] = section.section_completion_rate
                    ? section.section_completion_rate + '%'
                    : '-';
                  number++;
                }
              }
            }
            break;

          default:
            break;
        }

        const txStatus = {
          ready: '결제대기',
          paid: '결제',
          partial_cancelled: '부분환불',
          cancelled: '환불',
        };
        const getBeforeSurveyStatus = (userClass) => {
          if (userClass.survey_pre_done !== 0) {
            // 기존 설문조사 여부
            if (userClass.survey_pre_done === -1) return '미완료';
            else if (userClass.survey_pre_done === 1) return '완료';
          } else {
            // 신규 설문조사 여부
            const beforeSurvey = userClass.survey_chapters.find((chapter) => chapter.timing === 'before');
            if (!beforeSurvey) return '설문 없음';
            if (!!beforeSurvey?.isCompleted) return '완료';
            else return '미완료';
          }
        };
        const getAfterSurveyStatus = (userClass) => {
          if (userClass.survey_post_done !== 0) {
            // 기존 설문조사 여부
            if (userClass.survey_post_done === -1) return '미완료';
            else if (userClass.survey_post_done === 1) return '완료';
          } else {
            // 신규 설문조사 여부
            const afterSurvey = userClass.survey_chapters.find((chapter) => chapter.timing === 'after');
            if (!afterSurvey) return '설문 없음';
            if (!!afterSurvey?.isCompleted) return '완료';
            else return '미완료';
          }
        };

        return {
          회원ID: userClass.FK_user.id,
          닉네임: userClass.FK_user.nickname,
          이름: userClass.FK_user.name,
          국가정보: userClass.FK_user.FK_country?.name_kor || '-',
          이메일: userClass.FK_user.email,
          핸드폰번호: userClass.FK_user.phone ? userClass.FK_user.phone.toPhone() : '-',
          '혜택 수신 동의': userClass.FK_user.is_marketing_on ? '동의' : '비동의',
          '이름(신청서)': registerInfo.user_name ?? '-',
          '생년월일(신청서)': registerInfo.user_birth ? moment.utc(registerInfo.user_birth).format('YYYY-MM-DD') : '-',
          '핸드폰(신청서)': registerInfo.user_phone ? registerInfo.user_phone.toPhone() : '-',
          '이메일(신청서)': registerInfo.user_email ?? '-',
          '수강자 유형(신청서)': registerInfo.user_type
            ? registerInfo.user_type === '기타'
              ? `${registerInfo.user_type}(${registerInfo.user_type_name})`
              : registerInfo.user_type
            : '-',
          '성별(신청서)': registerInfo.user_gender ? (registerInfo.user_gender === 'M' ? '남자' : '여자') : '-',
          '최종 학력(신청서)': registerInfo.user_education ?? '-',
          '졸업학교명(신청서)': registerInfo.user_school_name ?? '-',
          '졸업학과명(신청서)': registerInfo.user_school_department ?? '-',
          '신청동기(신청서)': registerInfo.user_apply_reason ?? '-',
          '추천인(신청서)': registerInfo.user_recommender ?? '-',
          제공사: classModel.host === 'ours' ? '자사' : classModel.host === 'gov' ? '정부' : '타사',
          강의ID: userClass.FK_class.id,
          강의명: userClass.FK_class.title,
          수강옵션: userClass.FK_class_offline_schedule_id
            ? `${
                userClass.FK_class_offline_schedule.class_round &&
                `${userClass.FK_class_offline_schedule.class_round}기`
              } | ${
                !!userClass.FK_class_offline_schedule.title
                  ? `${userClass.FK_class_offline_schedule.title} |`
                  : '옵션명 없음 | '
              } ${userClass.FK_class_offline_schedule.class_dates
                .split('')
                .map((classDate, index) => {
                  return +classDate !== 0
                    ? intl.formatMessage({
                        id: 'ID_WEEKDAY_' + (index + 1),
                      })
                    : '';
                })
                .join('')} | ${userClass.FK_class_offline_schedule.class_start_time} | ${
                userClass.FK_class_offline_schedule.start_date
              }~${userClass.FK_class_offline_schedule.end_date}
              `
            : userClass.FK_class_online_schedule_id
            ? `${userClass.FK_class_online_schedule.class_round}기 | ${
                !!userClass.FK_class_online_schedule.title
                  ? `${userClass.FK_class_online_schedule.title} |`
                  : '옵션명 없음 | '
              } ${userClass.FK_class_online_schedule.start_date}~${userClass.FK_class_online_schedule.end_date}${
                userClass.FK_class_online_schedule.class_start_time
                  ? ` | ${userClass.FK_class_online_schedule.class_start_time}~${userClass.FK_class_online_schedule.class_end_time}`
                  : ` | 강의 시간 없음`
              }`
            : ``,
          '사전 설문조사 여부': getBeforeSurveyStatus(userClass),
          '사후 설문조사 여부': getAfterSurveyStatus(userClass),
          강사명: userClass.FK_class.Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(','),
          구분: intl.formatMessage({
            id: 'ID_LISTEN_TYPE_' + userClass.FK_class.listen_type.toUpperCase(),
          }),
          결제상태: txStatus[userClass.FK_tx.status],
          결제일:
            userClass.FK_tx.deletedAt || !userClass.FK_tx.date_paid_at
              ? '-'
              : moment.utc(userClass.FK_tx.date_paid_at).format('YYYY-MM-DD'),
          콘텐츠지급일: userClass.FK_tx.deletedAt ? moment.utc(userClass.FK_tx.createdAt).format('YYYY-MM-DD') : '-',
          수강시작일: userClass.start_date ? moment.utc(userClass.start_date).format('YYYY-MM-DD') : '-',
          수강종료일: userClass.end_date ? moment.utc(userClass.end_date).format('YYYY-MM-DD') : '-',
          남은일수: userClass.count_left_day ? userClass.count_left_day + '일' : '-',
          정가: userClass.FK_class.price_original,
          결제금액: userClass.FK_tx.paid_amount - userClass.FK_tx.canceled_amount,
          '강의자료 다운로드': userClass.is_download_attached_file ? '다운로드 후' : '다운로드 전',
          진도율: userClass.completion_rate ? userClass.completion_rate + '%' : '-',
          '수료증 발급': userClass?.User_Certificates[0]?.url_certificate_file ? '발급' : '미발급',
          ...sectionProgresses,
        };
      });

      excel.downloadXLSX(
        data,
        `정부지원수강자관리(${userClasses[0]?.FK_class.title})_${moment.utc().format('YYYYMMDD')}`,
      );
      setIsDownloading(false);
    });
  }, [loadUsers, userClasses, filters]);

  const columns = useMemo(
    () => [
      { Header: '회원ID', accessor: 'FK_user.id' },
      { Header: '이름', accessor: 'ThirdPartyClassRegistrations[0].user_name' },
      {
        Header: '생년월일',
        accessor: 'ThirdPartyClassRegistrations[0].user_birth',
        Cell: ({ value: user_birth }) => (user_birth ? moment.utc(user_birth).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '핸드폰',
        accessor: 'ThirdPartyClassRegistrations[0].user_phone',
        Cell: ({ value: user_phone }) => user_phone?.replace(/^82/, '0') ?? '',
      },
      {
        Header: '이메일',
        accessor: 'ThirdPartyClassRegistrations[0].user_email',
      },
      {
        Header: '수강자 유형',
        accessor: (user) =>
          user.ThirdPartyClassRegistrations[0]?.user_type === '기타'
            ? `${user.ThirdPartyClassRegistrations[0]?.user_type}(${user.ThirdPartyClassRegistrations[0]?.user_type_name})`
            : user.ThirdPartyClassRegistrations[0]?.user_type,
      },
      { Header: '강의ID', accessor: 'FK_class.id' },
      { Header: '강의명', accessor: 'FK_class.title' },
      {
        Header: '구분',
        accessor: 'FK_class.listen_type',
        Cell: ({ value: listen_type }) => <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />,
      },
      {
        Header: '제공사',
        accessor: 'FK_class.host',
        Cell: ({ value: host }) => (host === 'ours' ? '자사' : host === 'gov' ? '정부' : '타사'),
      },
      {
        Header: '수강시작일',
        accessor: 'start_date',
        Cell: ({ value: start_date }) => (start_date ? moment.utc(start_date).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '수강종료일',
        accessor: (user) => {
          const end_date = user.manual_end_date || user.end_date;
          return end_date ? moment.utc(end_date).format('YYYY-MM-DD') : '-';
        },
      },
      {
        Header: '수강종료일 변경',
        accessor: (user) => {
          const end_date = user.manual_end_date || user.end_date;

          return (
            end_date &&
            end_date !== '-' && (
              <ReactDatePicker
                selected={new Date(end_date)}
                minDate={new Date(user.start_date)}
                dateFormat="yyyy-MM-dd"
                onChange={(e) => updateEndDate(e, new Date(end_date), user.id)}
              />
            )
          );
        },
      },
      {
        Header: '남은일수',
        accessor: 'count_left_day',
        Cell: ({ value: count_left_day }) => (count_left_day ? count_left_day + '일' : '-'),
      },
      {
        Header: '진도율',
        accessor: 'completion_rate',
        Cell: ({ value: completion_rate }) => (completion_rate ? completion_rate + '%' : '-'),
      },
      {
        Header: '사전 설문조사 여부',
        accessor: (row) => {
          // 기존 설문조사 여부
          if (!!row.FK_class.survey_pre) {
            return row.survey_pre_done === 0 ? (
              <Switch checked={false} disabled />
            ) : row.survey_pre_done === -1 ? (
              <FormControlLabel
                control={<Switch checked={false} onChange={(e) => onChangeSurveyDone(e, row.id, 'pre')} />}
                label="미완료"
                labelPlacement="bottom"
              />
            ) : (
              <FormControlLabel
                control={<Switch checked={true} onChange={(e) => onChangeSurveyDone(e, row.id, 'pre')} />}
                label="완료"
                labelPlacement="bottom"
              />
            );
          } else {
            // 신규 설문조사 여부
            const beforeSurvey = row.survey_chapters.find((chapter) => chapter.timing === 'before');
            if (!beforeSurvey) return '설문 없음';
            if (!!beforeSurvey.isCompleted) return <span style={{ textDecoration: 'underline' }}>완료</span>;
            else return <span style={{ color: '#ffad33' }}>미완료</span>;
          }
        },
      },
      {
        Header: '사후 설문조사 여부',
        accessor: (row) => {
          // 기존 설문조사 여부
          if (!!row.FK_class.survey_post) {
            return row.survey_post_done === 0 ? (
              <Switch checked={false} disabled />
            ) : row.survey_post_done === -1 ? (
              <FormControlLabel
                control={<Switch checked={false} onChange={(e) => onChangeSurveyDone(e, row.id, 'post')} />}
                label="미완료"
                labelPlacement="bottom"
              />
            ) : (
              <FormControlLabel
                control={<Switch checked={true} onChange={(e) => onChangeSurveyDone(e, row.id, 'post')} />}
                label="완료"
                labelPlacement="bottom"
              />
            );
          } else {
            // 신규 설문조사 여부
            const afterSurvey = row.survey_chapters.find((chapter) => chapter.timing === 'after');
            if (!afterSurvey) return '설문 없음';
            if (!!afterSurvey.isCompleted) return <span style={{ textDecoration: 'underline' }}>완료</span>;
            else return <span style={{ color: '#ffad33' }}>미완료</span>;
          }
        },
      },
      {
        Header: '이수증',
        accessor: (row) => (
          <Field.File
            value={row.User_Certificates[0]?.url_certificate_file}
            onClick={(fileInput) => onClickCertificateFile(fileInput, row.User_Certificates[0]?.code)}
            onChange={(e) => onChangeCertificateFile(e, row.id, row.User_Certificates[0])}
            onClickDelete={(fileInput) =>
              onClickDeleteCertificateFile(
                fileInput,
                row.id,
                row.User_Certificates[0]?.id,
                row.User_Certificates[0]?.code,
              )
            }
            hideUrl
          />
        ),
      },
    ],
    [onClickCertificateFile, onChangeCertificateFile, onChangeSurveyDone, onClickDeleteCertificateFile, updateEndDate],
  );

  return (
    <div className="third-party-list">
      <div className="third-party-list__search">
        <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
          <option value="username">회원 이름</option>
          <option value="nickname">닉네임</option>
          <option value="email">이메일</option>
          <option value="phone">핸드폰번호</option>
          <option value="id">회원ID</option>
        </select>
        <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
      </div>
      <div className="third-party-list__total">
        총 수강자수 {total}
        <Button.Excel
          style={{
            cursor: isDownloading ? 'not-allowed' : 'pointer',
          }}
          onClick={!isDownloading && userClasses.length > 0 ? onClickCSV : undefined}
        >
          {isDownloading ? 'Loading...' : '액셀 다운로드'}
        </Button.Excel>
      </div>
      <Table data={userClasses} total={total} pageSize={20} columns={columns} fetchData={fetchData} />
    </div>
  );
}

export default ThirdPartyUserList;
