import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { useParams } from 'react-router-dom';
import * as Button from '../../../Common/Component/Button';
import * as Field from '../../../Common/Component/Field';
import * as request from '../../../Common/Util/Request';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

function SurveyNew() {
  const intl = useIntl();
  const { id } = useParams();

  const [title, setTitle] = useState(null);
  const [logo, setLogo] = useState(null);
  const [description, setDescription] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [sections, setSections] = useState([{ title: '섹션 1', questions: [] }]);
  const [registeredQuestions, setRegisteredQuestions] = useState([]);

  const [surveyOrigin, setSurveyOrigin] = useState(null);
  const editing = useMemo(() => !!id && !!surveyOrigin?.id, [id, surveyOrigin]);

  const [surveyList, setSurveyList] = useState([]);

  const loadSurveyList = useCallback(() => {
    let params = {
      page: 1,
      limit: 999_999_999,
      sort: 'id',
      order: 'desc',
    };
    return request.getSurveys(params).then(({ surveys }) => surveys);
  }, []);

  // 최초 설문 정보 조회
  useEffect(() => {
    if (id) {
      request.getSurveyDetail(id).then(({ survey }) => {
        setSurveyOrigin(survey);
      });
    } else {
      loadSurveyList().then((data) => {
        setSurveyList(data.list);
      });
    }
  }, [id, loadSurveyList]);

  // 기존 설문 정보 있을 경우(수정인 경우) 데이터 반영
  useEffect(() => {
    if (surveyOrigin) {
      setTitle(surveyOrigin.title);
      setLogo(surveyOrigin.logo);
      setDescription(surveyOrigin.description);
      surveyOrigin.startDatetime && setStartDate(dayjs(surveyOrigin.startDatetime).format('YYYY-MM-DD'));
      surveyOrigin.startDatetime && setStartTime(dayjs(surveyOrigin.startDatetime).format('HH:mm:ss'));
      surveyOrigin.endDatetime && setEndDate(dayjs(surveyOrigin.endDatetime).format('YYYY-MM-DD'));
      surveyOrigin.endDatetime && setEndTime(dayjs(surveyOrigin.endDatetime).format('HH:mm:ss'));
      if (surveyOrigin.sections) {
        setSections(surveyOrigin.sections);
        setRegisteredQuestions(surveyOrigin.sections.map((section) => section.questions).flat());
      }
    }
  }, [surveyOrigin]);

  const handleSurveySelect = (event) => {
    const selectedSurveyId = event.target.value;
    const selectedSurvey = surveyList.find((survey) => survey.id === selectedSurveyId);

    if (selectedSurvey) {
      request.getSurveyDetail(selectedSurvey.id).then(({ survey }) => {
        setSurveyOrigin(survey);
      });
    }
  };

  // 날짜 변경 핸들러
  const onChangeStartDate = useCallback(
    (value) => {
      setStartDate(value);
      !startTime && setStartTime(dayjs().set('hour', 0).set('minute', 0).set('second', 0).format('HH:mm:ss'));
    },
    [startDate, startTime],
  );
  const onChangeEndDate = useCallback(
    (value) => {
      setEndDate(value);
      !endTime && setEndTime(dayjs().set('hour', 23).set('minute', 59).set('second', 59).format('HH:mm:ss'));
    },
    [endDate, endTime],
  );

  // 저장 핸들러
  const onClickSave = useCallback(() => {
    // 설문 정보 객체 생성
    const survey = {
      title,
      description,
      startDatetime: startDate && endTime && `${startDate}T${startTime}`,
      endDatetime: endDate && endTime && `${endDate}T${endTime}`,
      sections: JSON.stringify(
        sections.map((section, index) => ({
          id: section.id,
          title: section.title,
          sort_order: index + 1,
          questions: section.questions.map((question, index) => ({
            id: editing ? question.id : question.FK_question_id,
            FK_question_id: editing ? question.FK_question_id : null,
            sort_order: index + 1,
            is_required: question.is_required,
          })),
        })),
      ),
      logo,
    };

    // 필수 입력값 체크
    const requiredFields = ['title', 'description', 'logo'];
    const emptyFields = requiredFields.filter((field) => !survey[field]);
    if (emptyFields.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: '입력 오류',
        text: `${emptyFields
          .map((field) => intl.formatMessage({ id: 'ID_SURVEY_FIELD_' + field.toUpperCase() }))
          .join(', ')} 값을 입력해주세요.`,
      });
      return;
    }

    // 수정, 생성 분기
    if (editing) {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">수정 사항을 반영하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          request.updateSurvey(surveyOrigin.id, survey).then((res) => {
            window.opener.location.reload();

            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">수정 사항이 반영되었습니다.</div>`,
              position: 'center',
              icon: 'success',
            }).then(() => {
              window.exitFlag = true;
              window.self.close();
            });
          });
        }
      });
    } else {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">설문을 생성하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          request.createSurvey(survey).then((res) => {
            window.opener.location.reload();

            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">설문이 생성되었습니다.</div>`,
              position: 'center',
              icon: 'success',
            }).then(() => {
              window.exitFlag = true;
              window.self.close();
            });
          });
        }
      });
    }
  }, [title, logo, description, sections, startDate, startTime, endDate, endTime, intl, editing, surveyOrigin]);

  // 취소 핸들러
  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">설문을 저장하지 않고 나가시겠습니까?</div>`,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  }, []);

  // 삭제 핸들러
  const onClickDelete = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteSurvey(surveyOrigin.id)
          .then(({ success }) => {
            if (success) {
              window.opener.location.reload();
              window.exitFlag = true;
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">설문이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            }
          })
          .catch((err) => {
            console.error(err);
            Swal.fire({
              title: `삭제에 실패했습니다.`,
              text: err.message,
              position: 'center',
              showConfirmButton: true,
              icon: 'error',
            });
          }),
    });
  }, [surveyOrigin]);

  // 페이지 벗어날 때 경고
  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  return (
    <div className="survey">
      <h2>설문 {editing ? '수정' : '생성'}</h2>
      {!editing && (
        <Field.Base title="기존 설문 복사">
          <FormControl style={{ width: '40rem' }}>
            <InputLabel id="survey-select-label">설문 선택</InputLabel>
            <Select
              labelId="survey-select-label"
              id="survey-select"
              value={surveyOrigin?.id || ''}
              onChange={handleSurveySelect}
            >
              <MenuItem value="">
                <em>-- Select a Survey --</em>
              </MenuItem>
              {surveyList.map((survey) => (
                <MenuItem key={survey.id} value={survey.id}>
                  {survey.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Field.Base>
      )}
      <Field.Input title="제목" value={title} onChange={setTitle} required />
      <Field.Image title="로고" value={logo} onChange={setLogo} required />
      <Field.Textarea title="설명" value={description} onChange={setDescription} required />
      <Field.Datetime
        title="시작일시"
        date={startDate}
        time={startTime}
        onDateChange={onChangeStartDate}
        onTimeChange={setStartTime}
        maxDate={endDate}
      />
      <Field.Datetime
        title="종료일시"
        date={endDate}
        time={endTime}
        onDateChange={onChangeEndDate}
        onTimeChange={setEndTime}
        minDate={startDate}
      />

      <Field.SurveySections
        title="질문/섹션 추가"
        sections={sections}
        setSections={setSections}
        registeredQuestions={registeredQuestions}
        setRegisteredQuestions={setRegisteredQuestions}
      />

      <div className="survey__buttons">
        <Button.Negative onClick={onClickDelete} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default SurveyNew;
