import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

function SubMenu(props) {
  const location = useLocation();

  const hasMultiplePath = Array.isArray(props.path);
  const path = hasMultiplePath ? props.path[0] : props.path;
  const selected = hasMultiplePath ? props.path.includes(location.pathname) : location.pathname.startsWith(path);

  return (
    <div className={'sub-menu' + (selected ? ' sub-menu--selected' : '')}>
      <Link className="sub-menu__name" to={path}>
        <FormattedMessage id={'ID_SUBMENU' + path.replaceAll('/', '_').toUpperCase()} />
      </Link>
    </div>
  );
}

export default SubMenu;
