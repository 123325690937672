import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../../Common/Util/Request';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import Table from '../../../../Common/Component/Table';
import * as Button from '../../../../Common/Component/Button';
import './index.scss';
import { FormControl, MenuItem, Select } from '@mui/material';
import SortHeader from '../../../../Common/Component/Table/SortIcons';

function QuestionList() {
  const [locationState, setLocationState] = useLocationState({
    searchType: 'search',
    searchText: '',
    sortInfo: { name: 'id', order: 'desc' },
    questionTypeCode: null,
    choiceLayoutCode: null,
  });
  const {
    sortInfo: { name: sort, order },
    questionTypeCode,
    choiceLayoutCode,
  } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [total, setTotal] = useState(0);
  const [questions, setQuestions] = useState([]);

  const [questionTypes, setQuestionTypes] = useState([]);
  const [choiceLayouts, setChoiceLayouts] = useState([]);

  useEffect(() => {
    request.getCodes().then((codes) => {
      const questionTypes = [...codes].filter((code) => {
        if (code.category === 'question_type' && code.code !== 'imageChoice') {
          return true;
        }
      });
      const choiceLayouts = [...codes].filter((code) => {
        if (code.category === 'choice_layout' && code.code !== 'multiRow' && code.code !== 'slider') {
          return true;
        }
      });
      setQuestionTypes(questionTypes);
      setChoiceLayouts(choiceLayouts);
    });
  }, []);

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
    });
  }, [setLocationState, searchType, searchText]);

  const onClickResetFilters = useCallback(() => {
    setLocationState({
      questionTypeCode: null,
      choiceLayoutCode: null,
    });
  }, [setLocationState]);

  /** 테이블 설정 */
  const loadQustions = useCallback(
    ({ page, limit }) => {
      let params = {
        page,
        limit,
        sort,
        order,
        questionType: questionTypes.find((type) => type.code === questionTypeCode)?.id,
        choiceLayout: choiceLayouts.find((layout) => layout.code === choiceLayoutCode)?.id,
      };
      if (searchText) {
        params = {
          ...params,
          [searchType]: searchText,
        };
      }

      return request.getQuestions(params).then(({ questions }) => questions);
    },
    [locationState, questionTypes, choiceLayouts],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadQustions({ page: pageIndex + 1, limit: pageSize }).then((data) => {
        setTotal(data.count);
        setQuestions(data.list);
      });
    },
    [loadQustions],
  );

  const clickHeader = useCallback((columnId) => {
    const { name: prevSort, order: prevOrder } = locationState.sortInfo;
    const order = prevSort === columnId && prevOrder === 'desc' ? 'asc' : 'desc';

    setLocationState({
      sortInfo: {
        name: columnId,
        order,
      },
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="id">
            질문 ID
          </SortHeader>
        ),
        accessor: 'id',
        onClickHeader: () => clickHeader('id'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="FK_question_type_id">
            질문 유형
          </SortHeader>
        ),
        id: 'FK_question_type_id',
        accessor: (row) => row.FK_question_type?.name,
        onClickHeader: () => clickHeader('FK_question_type_id'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="FK_choice_layout_id">
            선택 유형
          </SortHeader>
        ),
        id: 'FK_choice_layout_id',
        accessor: (row) => row.FK_choice_layout?.name ?? '-',
        onClickHeader: () => clickHeader('FK_choice_layout_id'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="content">
            질문 내용
          </SortHeader>
        ),
        accessor: 'content',
        onClickHeader: () => clickHeader('content'),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/survey/question-list/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [],
  );

  return (
    <div className="questions">
      <section>
        <div className="questions__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="search">질문 내용</option>
          </select>
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
        </div>

        <div className="making-btn">
          <Button.Positive onClick={() => window.open(`/survey/question-list/new`)}>질문 생성</Button.Positive>
        </div>
      </section>

      <div className="questions__filter">
        <div className="questions__filter__detail">
          <span>질문 유형 :</span>
          <FormControl sx={{ minWidth: 100 }}>
            <Select
              onChange={(e) => {
                setLocationState({
                  questionTypeCode: e.target.value,
                });
              }}
              value={questionTypeCode || ''}
              displayEmpty={true}
              defaultValue=""
              sx={{ fontSize: '0.875rem', height: '1.7rem' }}
            >
              <MenuItem value="" disabled>
                선택
              </MenuItem>
              {questionTypes.map((questionType) => (
                <MenuItem key={questionType.id} value={questionType.code}>
                  {questionType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="questions__filter__detail">
          <span>선택 유형 :</span>
          <FormControl sx={{ minWidth: 100 }}>
            <Select
              onChange={(e) => {
                setLocationState({
                  choiceLayoutCode: e.target.value,
                });
              }}
              value={choiceLayoutCode || ''}
              displayEmpty={true}
              defaultValue=""
              disabled={questionTypeCode !== 'singleChoice' && questionTypeCode !== 'multipleChoice'}
              sx={{ fontSize: '0.875rem', height: '1.7rem' }}
            >
              <MenuItem value="" disabled>
                선택
              </MenuItem>
              {choiceLayouts.map((choiceLayout) => (
                <MenuItem key={choiceLayout.id} value={choiceLayout.code}>
                  {choiceLayout.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button.Positive onClick={onClickResetFilters}>초기화</Button.Positive>
      </div>

      <div className="questions-container">
        <div className="questions__total">총 설문수 {total}</div>
        <Table
          data={questions}
          columns={columns}
          total={total}
          pageSize={10}
          sortedId={sort}
          isSortedDesc={order === 'desc'}
          fetchData={fetchData}
        />
      </div>
    </div>
  );
}

export default QuestionList;
