import { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  TablePagination,
  Checkbox,
  Box,
  Switch,
  Typography,
} from '@mui/material';
import * as Button from '../../../../Common/Component/Button';
import Swal from 'sweetalert2';
import EnhancedTableHead from '../../../../Common/Component/EnhancedTableHead';
import * as request from '../../../../Common/Util/Request';
import './index.scss';
import DatePicker from '../../../../Common/Component/DatePicker';
import moment from 'moment';

function ManageWaitings(props) {
  const { data: lecture } = props;

  const [waitingUsers, setWaitingUsers] = useState(lecture.waitingUsers ? lecture.waitingUsers : []);
  const [searchType, setSearchType] = useState('name');
  const [searchedType, setSearchedType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [messageFormat, setMessageFormat] = useState('open');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(-1);

  const handleChangeFormat = (event) => {
    setMessageFormat(event.target.checked ? 'close' : 'open');
  };

  const handleSelectAllClick = () => {
    if (selected.length <= 0) {
      const offset = page * rowsPerPage;
      const newSelected = waitingUsers
        .map((n, idx) => {
          if (rowsPerPage === -1) return n.id;
          if (offset <= idx && idx < offset + rowsPerPage) return n.id;
        })
        .filter((n) => n !== undefined);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // setWaitingUsers((prev) => prev.slice().sort(getComparator(order, orderBy)));
  };

  const onClickSearch = useCallback(() => {
    setSearchedType(searchType);
    setSearchedText(searchType === 'phone' ? searchText.replace(/^0/, '82') : searchText);
  }, [searchType, searchText]);

  useEffect(() => {
    // 날짜 필터링 반영
    const waitingUsersWithDateFilters = lecture.waitingUsers.filter((waitingUser) => {
      if (startDate && endDate) {
        return (
          moment(moment(waitingUser.createdAt).format('YYYY-MM-DD')).isSameOrAfter(startDate) &&
          moment(moment(waitingUser.createdAt).format('YYYY-MM-DD')).isSameOrBefore(endDate)
        );
      } else if (startDate) {
        return moment(moment(waitingUser.createdAt).format('YYYY-MM-DD')).isSameOrAfter(startDate);
      } else if (endDate) {
        return moment(moment(waitingUser.createdAt).format('YYYY-MM-DD')).isSameOrBefore(endDate);
      } else {
        return true;
      }
    });

    // 검색 필터링 반영
    let searchResults = [];
    for (const waitingUser of waitingUsersWithDateFilters) {
      // 각 속성을 순회하며 일치하는 값 확인
      for (let key in waitingUser) {
        if (
          key === searchedType &&
          waitingUser.hasOwnProperty(key) &&
          waitingUser[key].toString().includes(searchedText)
        ) {
          searchResults.push(waitingUser);
          break;
        }
      }
    }

    // sort 반영
    setWaitingUsers(searchResults.slice().sort(getComparator(order, orderBy)));
  }, [startDate, endDate, searchedType, searchedText, order, orderBy]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sendMessages = () => {
    if (selected.length <= 0) {
      Swal.fire({
        html: `<div style="margin-top:20px;">
      <p style="font-weight: bold;">
        수강 대기 신청자를 선택해주세요.
      </p>`,
        icon: 'info',
      });

      return;
    }

    if (!lecture.hasActiveSchedule) {
      Swal.fire({
        html: `<div style="margin-top:20px;">
      <p style="font-weight: bold;">
        결제 가능한 기수 옵션이 없습니다! 기수를 추가해주세요.
      </p>`,
        icon: 'info',
      });

      return;
    }

    Swal.fire({
      html: `<div style="margin-top:20px;">
    <p style="font-weight: bold;">
      <span style='color: red'>${selected.length}명</span>의 수강 대기 신청자${
        selected.length > 1 ? '(들)' : ''
      }에게 <span style='color: red'>${messageFormat === 'open' ? '오픈' : '마감 임박'}</span> 알림을 전송하시겠습니까?
    </p>
  </div>`,
      position: 'center',
      showConfirmButton: true,
      confirmButtonText: '알림 전송',
      cancelButtonText: '취소',
      showCancelButton: true,
      icon: 'info',
      allowOutsideClick: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const transmitInfos = [];
        const buttons = [
          {
            name: '수강 신청 하러가기',
            linkMobile: `https://enterjobedu.co.kr/lecture/${lecture.id}`,
            linkPc: `https://enterjobedu.co.kr/lecture/${lecture.id}`,
            type: 'WL',
          },
          {
            name: '문의하기',
            linkMobile: 'https://enterjobedu.channel.io/',
            linkPc: 'https://enterjobedu.channel.io/',
            type: 'WL',
          },
        ];

        for (const waitingUser of waitingUsers) {
          if (!selected.includes(waitingUser.id)) continue;

          switch (messageFormat) {
            case 'open':
              if (waitingUser.isKo && waitingUser.phone) {
                transmitInfos.push({
                  template_code: 'EJE0009',
                  text: `안녕하세요 ${waitingUser.name}님\n엔터잡에듀입니다.\n\n대기 신청하신 [${lecture.title}]이 오픈되었습니다.\n\n아래 링크를 통해 수강 신청이 가능하오니 일정 확인 후 신청해주시기 바랍니다.\n\n기타 문의사항은 아래 링크를 통해 연락주시면, 성심성의껏 답변드리겠습니다.\n\n감사합니다.`,
                  phone: waitingUser.phone,
                  purpose: 'waiting',
                  lectureId: lecture.id,
                });
              } else if (waitingUser.email) {
                transmitInfos.push({
                  template_sid: 8588,
                  email: waitingUser.email,
                  purpose: 'waiting',
                  parameters: {
                    lectureTitle: lecture.title,
                    userName: waitingUser.name,
                    lectureAddress: `https://enterjobedu.co.kr/lecture/${lecture.id}`,
                  },
                  lectureId: lecture.id,
                });
              }
              break;

            case 'close':
              if (waitingUser.isKo) {
                transmitInfos.push({
                  template_code: 'EJE0010',
                  text: `안녕하세요 ${waitingUser.name}님\n엔터잡에듀입니다.\n\n대기 신청하신 [${lecture.title}]가 곧 마감될 예정이니, 수강 의향이 있으신 분들은 아래 링크를 통해 빠르게 신청해주시기 바랍니다.\n\n기타 문의사항은 아래 링크를 통해 연락주시면, 성심성의껏 답변드리겠습니다.\n\n감사합니다.`,
                  phone: waitingUser.phone,
                  purpose: 'waiting',
                  lectureId: lecture.id,
                });
              } else {
                transmitInfos.push({
                  template_sid: 8589,
                  email: waitingUser.email,
                  purpose: 'waiting',
                  parameters: {
                    lectureTitle: lecture.title,
                    userName: waitingUser.name,
                    lectureAddress: `https://enterjobedu.co.kr/lecture/${lecture.id}`,
                  },
                  lectureId: lecture.id,
                });
              }
              break;

            default:
              break;
          }
        }

        // 안내 메시지 전송
        request
          .sendPersonalMessages({
            transmitInfos,
            buttons,
          })
          .then((res) => {
            if (res.success) {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;"><span style='color: red'>${
                  res.successList.length
                }명</span>의 수강 대기 신청자${selected.length > 1 ? '(들)' : ''}에게 <span style='color: red'>${
                  messageFormat === 'open' ? '오픈' : '마감 임박'
                }</span> 알림이 전송되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              }).then(() => {
                window.location.reload();
              });
            } else {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">${res.failList.length}명의 수강 대기 신청자${
                  selected.length > 1 ? '(들)' : ''
                }에게 알림 전송 요청이 실패하였습니다. <br>아래는 해당 회원들의 주소로, 잘못된 주소일 수 있습니다.
                  <br><br>
                  <ul>
                    ${res.failList.map((item) => `<li>${item}</li>`).join('')}
                  </ul></div>`,
                position: 'center',
                icon: 'error',
              }).then(() => {
                window.location.reload();
              });
            }
          });
      }
    });
  };

  const headCells = [
    { id: 'checkbox', label: '', align: 'center', minWidth: 30 },
    { id: 'name', label: '이름', align: 'center', minWidth: 40 },
    { id: 'isKo', label: '국적', align: 'center', minWidth: 40 },
    { id: 'phone', label: '핸드폰', align: 'center', minWidth: 80 },
    { id: 'email', label: '이메일', align: 'center', minWidth: 200 },
    { id: 'createdAt', label: '신청 일자', align: 'center', minWidth: 70 },
    { id: 'recentSentAt', label: '최근 안내 일자', align: 'center', minWidth: 70 },
    { id: 'sentCount', label: '안내 횟수', align: 'center', minWidth: 30 },
  ];

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - waitingUsers.length) : 0;

  return (
    <div className="waitings-container">
      <div className="table-top-container">
        <div className="table-top-first-container">
          <div className="waitings__search">
            <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
              <option value="name">이름</option>
              <option value="phone">휴대폰</option>
              <option value="email">이메일</option>
            </select>
            <input
              value={searchText}
              placeholder={searchType === 'phone' ? '하이픈(-) 제외하고 입력' : ''}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
          </div>
          <div className="waiting-users-message__date">
            <DatePicker selected={startDate} maxDate={endDate} onChange={(date) => setStartDate(date)} />
            ~
            <DatePicker selected={endDate} minDate={startDate} onChange={(date) => setEndDate(date)} />
          </div>
        </div>
        <div className="waitings-switch">
          <Typography>
            <span
              style={{
                fontWeight: messageFormat === 'open' ? 'bold' : '',
                opacity: messageFormat === 'open' ? 1 : 0.6,
              }}
            >
              {messageFormat === 'open' && '*'} 오픈 알림
            </span>
          </Typography>
          <Switch
            checked={messageFormat === 'close'}
            onChange={handleChangeFormat}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>
            <span
              style={{
                color: 'blue',
                fontWeight: messageFormat === 'close' ? 'bold' : '',
                opacity: messageFormat === 'close' ? 1 : 0.6,
              }}
            >
              {messageFormat === 'close' && '*'} 마감 임박 알림
            </span>
          </Typography>
        </div>
      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                headCells={headCells}
                rowCount={waitingUsers.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />

              <TableBody>
                {(rowsPerPage > 0
                  ? waitingUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : waitingUsers
                ).map((item, idx) => {
                  const isItemSelected = isSelected(item.id);
                  const labelId = `enhanced-table-checkbox-${item.id}`;

                  return (
                    <TableRow
                      key={item.id}
                      hover
                      onClick={(event) => handleClick(event, item.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">{item.isKo ? '내국인' : '외국인'}</TableCell>
                      <TableCell align="center">
                        {item.phone?.replace(/^82/, '0').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3') ?? '-'}
                      </TableCell>
                      <TableCell align="center">{item.email}</TableCell>
                      <TableCell align="center">{moment(item.createdAt).format('YYYY-MM-DD')}</TableCell>
                      <TableCell align="center">
                        {item.recentSentAt === 'pending'
                          ? '전송 확인 중...'
                          : item.recentSentAt
                          ? moment(item.recentSentAt).format('YYYY-MM-DD')
                          : '-'}
                      </TableCell>
                      <TableCell align="center">
                        {item.recentSentAt === 'pending' ? item.sentCount - 1 : item.sentCount}회
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 73 * emptyRows }}>
                    <TableCell colSpan={headCells.length + 1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, { value: -1, label: '전체' }]}
              component="div"
              count={waitingUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={'페이지당 표시 수 :'}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}-${to} / ${count !== -1 ? count : `more than ${to}`}`;
              }}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableContainer>
        </Paper>
      </Box>
      <div className="buttons">
        <Button.Positive onClick={sendMessages}>일괄 발송</Button.Positive>
        <Button.Negative onClick={() => Swal.clickCancel()}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default ManageWaitings;
