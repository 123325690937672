import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../Common/Util/Request';
import Swal from 'sweetalert2';
import * as Field from '../../../Common/Component/Field';
import * as Button from '../../../Common/Component/Button';
import Table from '../../../Common/Component/Table';
import './index.scss';

function ReviewBestEdit(props) {
  const params = useParams();
  const { id: lectureId } = params;

  const [lecture, setLecture] = useState(null);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [reviewCnt, setReviewCnt] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => await request.reviews('best_reviews', lectureId);
    // lectureId -> lecture 정보 획득
    fetchReviews().then((data) => {
      const { lecture, targetReviews, reviewCnt } = data;
      setLecture(lecture);
      setSelectedReviews((prev) => {
        if (!prev.length) {
          return [...targetReviews.map((review) => review.FK_review_id)];
        } else {
          return prev;
        }
      });
      setReviewCnt(reviewCnt);
    });
  }, [lectureId]);

  const onClickSave = () => {
    Swal.fire({
      title: '저장하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        request
          .saveBestReview(lectureId, selectedReviews)
          .then((response) => {
            if (response) {
              Swal.fire({
                title: '저장되었습니다.',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: '창 닫기',
                cancelButtonText: '취소',
              }).then(({ isConfirmed }) => {
                isConfirmed && window.close();
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };
  const onClickCancel = () => {
    Swal.fire({
      title: '취소하시겠습니까?',
      text: '취소하면 작성 중인 내용이 사라집니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) window.close();
    });
  };

  const updateSelectedReviews = useCallback(
    (review) => {
      setSelectedReviews((prev) => {
        if (prev.filter((item) => item === review.id).length > 0) {
          return prev.filter((item) => item !== review.id);
        }
        if (selectedReviews.length >= 3) {
          Swal.fire({
            title: '베스트 리뷰는 최대 3개까지 등록 가능합니다.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          });
          return [...prev];
        }
        return [...prev, review.id];
      });
    },
    [selectedReviews],
  );

  return (
    <div className="best-review-detail">
      <div className="review-detail-form-fields">
        <Field.Input title="강의 ID" placeholder="자동생성" value={lectureId} className="readonly" readOnly />

        <Field.Input title="강의 제목" placeholder="자동생성" value={lecture?.title} className="readonly" readOnly />

        <Field.Input title="리뷰 개수" placeholder="자동생성" value={reviewCnt} className="readonly" readOnly />

        <ReviewField
          title="현재 "
          value={selectedReviews}
          onChange={updateSelectedReviews}
          lectureId={lectureId}
          required
        />
      </div>
      <div className="bestReviewAdd_buttons">
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

function ReviewField(props) {
  const { value: selectedReviews, onChange, disabled, lectureId, reviewCnt, ...otherProps } = props;

  const [reviews, setReviews] = useState([]);
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .lectureReviews(lectureId, {
          page: pageIndex + 1,
          limit: pageSize,
          filtered: [],
        })
        .then(({ reviews }) => {
          setReviews(reviews);
        });
    },
    [lectureId],
  );

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return '비공개';
      case 1:
        return '대기';
      case 2:
        return '공개';
      default:
        return '-';
    }
  };
  const columns = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: (review) => {
          const checked = selectedReviews.filter((item) => item === review.id).length > 0;
          return (
            <input
              type="checkbox"
              checked={checked}
              disabled={disabled}
              onChange={() => {
                onChange(review);
              }}
            />
          );
        },
      },
      { Header: '리뷰ID', accessor: 'id' },
      { Header: '공개 여부', accessor: (review) => getStatus(review.status) },
      { Header: '작성자', accessor: 'old_nickname' },
      { Header: '평점', accessor: 'rate' },
      {
        Header: '리뷰 수정',
        accessor: (review) => (
          <button className="btn-detail-view" onClick={() => window.open(`/review/lecture/new/${review.id}`)}>
            수정
          </button>
        ),
      },
      {
        Header: '리뷰 내용',
        accessor: (review) => <p className="review-content">{review.content}</p>,
      },
    ];
  }, [selectedReviews, disabled, onChange]);

  return (
    <Field.Base type="reviews" {...otherProps}>
      <div className="table-wrapper">
        <Table pageSize={99999} total={reviewCnt} data={reviews} columns={columns} fetchData={fetchData} />
      </div>
      {selectedReviews.length > 0 && (
        <div className="field--reviews__selected">
          해당 강의 베스트 리뷰 ID: {selectedReviews.map((id) => id).join(', ')}
        </div>
      )}
    </Field.Base>
  );
}

export default ReviewBestEdit;
