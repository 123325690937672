import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import ko from './Common/Locale/ko';
import store from './Common/Store';
import App from './App';
import './Common/Extension';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="ko" messages={ko}>
      <Provider store={store}>
        <App />
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
