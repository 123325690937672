import { Button, Checkbox, Paper } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { addStudent } from '../functions';
import AddStudentModal from './AddStudentModal';
import { useEffect, useRef, useState } from 'react';
import * as request from '../../../../../Common/Util/Request';
import HandleNoteToOnCall from './HandleNoteToOnCall';
import './Info.scss';

const InfoWrap = styled.div`
  //margin-bottom: 3rem;
  @media screen and (min-width: 960px) {
    //margin-bottom: 1rem;
  }
`;
const FloatingInfo = styled.div(
  ({ inView }) => `
  background-color: #fff;
  width: calc(100% - 2rem);
  height: 3.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #ddd;
  opacity: ${inView ? 0 : 1};
  pointer-events: ${inView ? 'none' : 'auto'};
  transition: opacity 0.2s ease-in-out;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.4);
  
  @media screen and (min-width: 960px) {
    width: calc(100% - 4rem);
    padding: 1rem 2rem;
  }
`,
);
const FloatingTitleWrap = styled.div.attrs({ className: 'floating-title-wrap' })`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
`;
const FloatingTitle = styled.div.attrs({ className: 'floating-title' })`
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  position: relative;
  left: 0;

  /* 애니메이션 관련 스타일은 animate prop에 따라 조건부로 적용 */
  ${({ animate }) =>
    animate &&
    `
    animation: marquee 5s linear infinite;

    &:before,
    &:after {
      content: attr(data-text);
      position: absolute;
      white-space: nowrap;
      padding: 0 1rem; /* 양쪽에 1rem의 여백을 줘서 텍스트 사이에 공간을 만듭니다. */
    }

    &:before {
      right: 100%; /* 원본 텍스트의 왼쪽에 복제본 배치 */
    }

    &:after {
      left: 100%; /* 원본 텍스트의 오른쪽에 복제본 배치 */
    }

    @keyframes marquee {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-100% - 1rem)); /* 복제본의 너비만큼 이동합니다. */
      }
    }
  `}
`;

const FloatingInfoActions = styled.div.attrs({ className: 'floating-info-actions' })`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  aling-items: center;
`;
const BoardActions = styled.div.attrs({ className: 'board-actions' })``;
const StudentActions = styled.div.attrs({ className: 'student-actions' })``;

const ActionsWrap = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  position: relative;
`;
const NoteToOnCallWrap = styled.div`
  @keyframes rainbow {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
`;
const NoteToOnCallLink = styled.a`
  color: #5ca8dd;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    animation: rainbow 2s linear infinite;
    background-image: linear-gradient(160deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff);
    background-size: 200%;
  }
`;
const Info = ({ classInfo, attendanceBoardInfo, setAttendanceBoardInfo, expandAll, setExpandAll }) => {
  const { ref, inView, entry } = useInView({
    fallbackInView: true,
  });

  // console.log('classInfo', classInfo);
  const [userList, setUserList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  useEffect(() => {
    // console.log('fetching user list...');

    if (
      !classInfo ||
      !attendanceBoardInfo ||
      Object.keys(classInfo).length === 0 ||
      Object.keys(attendanceBoardInfo).length === 0
    )
      return;
    // console.log('fetching user list...');
    // console.log('classInfo', classInfo);
    // console.log('attendanceBoardInfo', attendanceBoardInfo);
    (async () => {
      // get user list
      const { users } = await request.allUsers();
      const { lecture, schedules } = await request.getOfflineSchedules({
        classId: classInfo.id,
        startDate: attendanceBoardInfo.startDate,
        endDate: attendanceBoardInfo.endDate,
        startTime: attendanceBoardInfo.startTime,
        endTime: attendanceBoardInfo.endTime,
        dates: attendanceBoardInfo.dates,
      });
      if (!users || !schedules) return;
      const userList = users.map((user) => {
        return {
          title: `${user.name || user.nickname} (${user.email}, ${user.id})`,
          id: user.id,
          email: user.email,
          phone: user.phone,
        };
      });
      setUserList(userList);
      setScheduleList(schedules);
    })();
  }, [classInfo, attendanceBoardInfo]);

  const datesToString = (dates) => {
    // 1111000 -> 일, 월, 화, 수
    const days = ['월', '화', '수', '목', '금', '토', '일'];
    const daysArr = dates.toString().split('');
    const daysStr = daysArr.map((day, index) => {
      if (day === '1') {
        return days[index];
      }
    });
    return daysStr.filter((day) => day).join(', ');
  };

  const [animate, setAnimate] = useState(false);
  const animationWrapRef = useRef(null);
  const animationTitleRef = useRef(null);

  useEffect(() => {
    const wrapWidth = animationWrapRef.current.offsetWidth;
    const titleWidth = animationTitleRef.current.offsetWidth;
    if (titleWidth > wrapWidth) {
      setAnimate(true);
    }
  }, [classInfo.className, inView]);

  const handleNoteToOnCall = () => {
    // console.log('note to on call');
    // notion link를 입력한다.
  };

  return (
    <>
      <InfoWrap ref={ref} className="lecture-info-wrap">
        <div className="lecture-thumbnail">
          <img src={classInfo.classThumbnail} className="lecture-info-item_value"></img>
        </div>
        <div className="lecture-info">
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">강의명</div>
            <div className="lecture-info-item_value">{classInfo.className}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">시작일</div>
            <div className="lecture-info-item_value">{attendanceBoardInfo.startDate}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">종료일</div>
            <div className="lecture-info-item_value">{attendanceBoardInfo.endDate}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">수업요일</div>
            <div className="lecture-info-item_value">{datesToString(attendanceBoardInfo.dates || '0000000')}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">시작시간</div>
            <div className="lecture-info-item_value">{attendanceBoardInfo.startTime}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">종료시간</div>
            <div className="lecture-info-item_value">{attendanceBoardInfo.endTime}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">강의실</div>
            <div className="lecture-info-item_value">{attendanceBoardInfo.location}</div>
          </div>
          <div className="lecture-info-item">
            <div className="lecture-info-item_title">참고사항</div>
            <div className="lecture-info-item_value" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              {attendanceBoardInfo.noteToOnCallLink ? (
                <NoteToOnCallWrap>
                  <NoteToOnCallLink href={attendanceBoardInfo.noteToOnCallLink} target="_blank">
                    {attendanceBoardInfo.noteToOnCallLink}
                  </NoteToOnCallLink>
                </NoteToOnCallWrap>
              ) : null}
              <HandleNoteToOnCall
                attendanceBoardInfo={attendanceBoardInfo}
                options={userList}
                setAttendanceBoardInfo={setAttendanceBoardInfo}
              />
            </div>
          </div>
        </div>
      </InfoWrap>
      <ActionsWrap>
        <div
          style={{
            position: 'absolute',
            left: '0',
            display: 'flex',
            alignItems: 'center',
            marginTop: '-0.25rem',
          }}
        >
          <Checkbox
            color="primary"
            onChange={(e) => {
              // console.log('checked', e.target.checked);
              if (e.target.checked) {
                setExpandAll('true');
              } else {
                setExpandAll('false');
              }
            }}
            size="small"
            value={expandAll}
            disableRipple
          />
          <span style={{ color: 'inherit !important', fontSize: '0.875rem' }}>
            수강생 참고 사항 전체 {expandAll === 'true' ? '접기' : '펼치기'}
          </span>
        </div>
        <AddStudentModal
          classInfo={classInfo}
          classId={classInfo.classId}
          scheduleId={classInfo.scheduleId}
          options={userList}
          schedules={scheduleList}
        />
      </ActionsWrap>
      <FloatingInfo inView={inView}>
        <FloatingTitleWrap ref={animationWrapRef}>
          <FloatingTitle ref={animationTitleRef} animate={animate} data-text={classInfo.className}>
            {classInfo.className}
          </FloatingTitle>
        </FloatingTitleWrap>
        <FloatingInfoActions>
          <BoardActions>&nbsp;</BoardActions>\
          <StudentActions>
            {classInfo && (
              <AddStudentModal
                classInfo={classInfo}
                classId={classInfo.classId}
                scheduleId={classInfo.scheduleId}
                options={userList}
                schedules={scheduleList}
              />
            )}
          </StudentActions>
        </FloatingInfoActions>
      </FloatingInfo>
    </>
  );
};

export default Info;
