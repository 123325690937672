import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as request from '../../../../Common/Util/Request';
import * as Field from '../../../../Common/Component/Field';
import './index.scss';
import * as Button from '../../../../Common/Component/Button';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';

function QuestionNew(props) {
  const intl = useIntl();
  const { id } = useParams();

  const [questionOrigin, setQuestionOrigin] = useState(null);
  const [choicesOrigin, setChoicesOrigin] = useState([]);
  const [choices, setChoices] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [choiceLayouts, setChoiceLayouts] = useState([]);

  const [content, setContent] = useState(null);
  const [description, setDescription] = useState(null);
  const [type, setType] = useState(null);
  const [layout, setLayout] = useState(null);

  const editing = useMemo(() => !!questionOrigin?.id, [questionOrigin]);

  // 최초 질문 정보, 코드 목록 조회
  useEffect(() => {
    id &&
      request.getQuestionDetail(id).then(({ question, choices }) => {
        setQuestionOrigin(question);
        setChoicesOrigin(choices);
      });

    request.getCodes().then((codes) => {
      const questionTypes = [...codes].filter((code) => {
        if (code.category === 'question_type' && code.code !== 'imageChoice') {
          return true;
        }
      });
      const choiceLayouts = [...codes].filter((code) => {
        if (code.category === 'choice_layout' && code.code !== 'multiRow' && code.code !== 'slider') {
          return true;
        }
      });
      setQuestionTypes(questionTypes);
      setChoiceLayouts(choiceLayouts);
    });
  }, []);

  // 수정인 경우 기존 데이터 반영
  useEffect(() => {
    if (editing && questionOrigin) {
      setContent(questionOrigin.content);
      questionOrigin.description && setDescription(questionOrigin.description);
      setType(questionOrigin.typeCode);
      setLayout(questionOrigin.layoutCode);
      setChoices(choicesOrigin);
    }
  }, [editing, choicesOrigin, questionOrigin]);

  const onClickSave = useCallback(
    (event) => {
      event.preventDefault();
      // editing: true => 수정, false => 생성
      const requiredLayoutTypes = ['singleChoice', 'multipleChoice'];
      const isRequiredLayout = requiredLayoutTypes.includes(type);
      if (editing) {
        // 수정
        const question = {
          id: questionOrigin.id,
          content,
          description,
          choiceLayoutId: isRequiredLayout
            ? choiceLayouts.find((choiceLayout) => choiceLayout.code === layout)?.id
            : null,
          choices: isRequiredLayout
            ? choices.map((choice, index) => ({
                id: choice.id,
                content: choice.content,
                sortOrder: index + 1,
              }))
            : null,
        };

        // 질문 수정
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">질문을 수정하시겠습니까?</div>`,
          position: 'center',
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'info',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            request.updateQuestion(question).then((res) => {
              window.opener.location.reload();

              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">질문이 수정되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              }).then(() => {
                window.exitFlag = true;
                window.self.close();
              });
            });
          }
        });
      } else {
        // 생성
        const newChoices = choices.map((choice, idx) => ({
          content: choice.content,
          sort_order: idx + 1,
          is_etc: choice.isEtc,
        }));
        const question = {
          content,
          typeId: questionTypes.find((questionType) => questionType.code === type)?.id,
          choiceLayoutId: isRequiredLayout
            ? choiceLayouts.find((choiceLayout) => choiceLayout.code === layout)?.id
            : null,
          description,
          choices: isRequiredLayout ? JSON.stringify(newChoices) : null,
        };

        // 필수 입력값 체크
        const requiredFields = ['content', 'typeId'];
        const emptyFields = requiredFields.filter((field) => !question[field]);
        if (emptyFields.length > 0) {
          Swal.fire({
            icon: 'warning',
            title: '입력 오류',
            text: `${emptyFields
              .map((field) => intl.formatMessage({ id: 'ID_QUESTION_FIELD_' + field.toUpperCase() }))
              .join(', ')} 값을 입력해주세요.`,
          });
          return;
        }
        if (isRequiredLayout) {
          if (!question.choiceLayoutId) {
            Swal.fire({
              icon: 'warning',
              title: '입력 오류',
              text: '선택형태를 선택해주세요.',
            });
            return;
          }
          if (newChoices.length < 1) {
            Swal.fire({
              icon: 'warning',
              title: '입력 오류',
              text: '질문 선택지를 입력해주세요.',
            });
            return;
          }
          if (newChoices.map((choice) => choice.content).includes('')) {
            Swal.fire({
              icon: 'warning',
              title: '입력 오류',
              text: '질문 선택지의 내용을 입력해주세요.',
            });
            return;
          }
        }

        // 질문 생성
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">질문을 생성하시겠습니까?</div>`,
          position: 'center',
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'info',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            request.createQuestion(question).then((res) => {
              window.opener.location.reload();

              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">질문이 생성되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              }).then(() => {
                window.exitFlag = true;
                window.self.close();
              });
            });
          }
        });
      }
    },
    [content, description, type, layout, choices, questionTypes, choiceLayouts, intl, editing],
  );

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">질문을 저장하지 않고 나가시겠습니까?</div>`,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  }, []);

  const onClickDelete = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteQuestion(questionOrigin.id)
          .then(({ success }) => {
            if (success) {
              window.opener.location.reload();
              window.exitFlag = true;
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">질문이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            }
          })
          .catch((err) => {
            console.error(err);
            Swal.fire({
              title: `삭제에 실패했습니다.`,
              text: err.response?.data.message,
              position: 'center',
              showConfirmButton: true,
              icon: 'error',
            });
          }),
    });
  }, [questionOrigin]);

  // 페이지 벗어날 때 경고
  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  return (
    <div className="question">
      <h2>질문 {editing ? '수정' : '생성'}</h2>
      <Field.Textarea title="내용" value={content} onChange={setContent} required />
      <Field.Textarea title="설명" value={description} onChange={setDescription} />
      <Field.Radio
        title="질문 유형"
        name="questionTypes"
        value={type}
        onChange={setType}
        options={questionTypes.map((code) => ({ value: code.code, label: code.name }))}
        disabled={editing}
        required
      />
      {(type === 'singleChoice' || type === 'multipleChoice') && (
        <Field.Radio
          title="선택 유형"
          name="choiceLayouts"
          value={layout}
          onChange={setLayout}
          options={choiceLayouts.map((code) => ({ value: code.code, label: code.name }))}
          disabled={type !== 'singleChoice' && type !== 'multipleChoice'}
        />
      )}
      {(type === 'singleChoice' || type === 'multipleChoice') && (
        <Field.QuestionChoices
          type="choice"
          title="질문 선택지"
          required
          choices={choices}
          setChoices={setChoices}
          disabledEtc={layout === 'row'}
          disabled={editing}
        />
      )}

      <div className="question__buttons">
        <Button.Negative onClick={onClickDelete} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default QuestionNew;
