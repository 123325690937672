import * as request from '../../../../../Common/Util/Request';
export const postTakeAttendance = async (body) => {
  const response = await request.postTakeAttendance(body);
  return response;
};

export const deleteCancelAttendance = async ({ id }) => {
  const response = await request.deleteCancelAttendance({ id });
  return response;
};

export const addStudent = async ({ classId, scheduleId }) => {
  const body = {
    classId,
    scheduleId,
  };

  // modal을 띄우고, 학생 정보를 입력받는다.
  // 학생 정보는 회원 목록에서 검색하여 가져온다.

  // const response = await request.postAddStudent(body);
  return body;
  // return response;
};
