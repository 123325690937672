import { useEffect, useMemo, useState } from 'react';
import { ArrowForward } from '@mui/icons-material';
import swal from 'sweetalert2';

const NoteToStudent = ({ student, attendanceBoardInfo, expandAll, setExpandAll, noteToStudent }) => {
  const [isExpand, setIsExpand] = useState(false);
  const [currentExpand, setCurrentExpand] = useState(false);

  useEffect(() => {
    setCurrentExpand(expandAll === 'true' || isExpand);
  }, [isExpand, expandAll]);
  return (
    <div className="note-to-student" style={{ marginBottom: '0.5rem' }}>
      <div
        className="note-to-student-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          cursor: noteToStudent ? 'pointer' : 'default',
          color: noteToStudent ? '#393939' : '#DEDEDE',
          pointerEvents: noteToStudent ? 'auto' : 'none',
        }}
        onClick={() => {
          if (expandAll === 'true') {
            return;
          } else {
            setIsExpand(!isExpand);
          }
        }}
      >
        <ArrowForward
          sx={{
            fontSize: '0.875rem',
            transform: currentExpand ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s',
          }}
        />
        참고사항
      </div>
      {noteToStudent && (
        <div
          className="note-to-student-content"
          style={{
            maxHeight: currentExpand ? '10rem' : '0',
            overflow: currentExpand ? 'auto' : 'hidden',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          <div style={{ borderBottom: '1px solid #DEDEDE', width: '100%' }} />
          <div
            style={{ padding: '0.375rem 1rem 0' }}
            dangerouslySetInnerHTML={{ __html: noteToStudent.replace(/\n/g, '<br />') }}
          />
        </div>
      )}
    </div>
  );
};

export default NoteToStudent;
