import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import Issues from './Issues';
import Usages from './Usages';
import './index.scss';

function CouponList(props) {
  const history = useHistory();
  const location = useLocation();

  const selectedTab = location.pathname === '/promotion/coupon-usages' ? 'usages' : 'issues';

  const onClickTab = useCallback((tab) => history.replace(`./coupon-${tab}`), [history]);

  return (
    <div className="coupons">
      <div className="coupons__tabs">
        <div
          className={'coupons__tab' + (selectedTab === 'issues' ? ' coupons__tab--selected' : '')}
          onClick={() => onClickTab('issues')}
        >
          쿠폰 발행 관리
        </div>
        <div
          className={'coupons__tab' + (selectedTab === 'usages' ? ' coupons__tab--selected' : '')}
          onClick={() => onClickTab('usages')}
        >
          쿠폰 발급 내역
        </div>
      </div>
      <div className="coupons__content">
        {selectedTab === 'issues' && <Issues />}
        {selectedTab === 'usages' && <Usages />}
      </div>
    </div>
  );
}

export default CouponList;
