import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import dayjs from 'dayjs';
import './index.scss';
import {
  Button,
  createTheme,
  MenuItem,
  Select,
  Input,
  ThemeProvider,
  Stack,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  TablePagination,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';
import BooleanIcon from '../../../Review/Request/BooleanIcon';
import Index from './CouponSend';
import withReactContent from 'sweetalert2-react-content';
import SearchIcon from '@mui/icons-material/Search';
import EnhancedTableHead from '../../../../Common/Component/EnhancedTableHead';
import { EJE_THEME } from '../../../../Common/Constant';

function LectureLikeList() {
  const [likeList, setLikeList] = useState([]);
  const [total, setTotal] = useState(0);

  const ALL_ROWS_PER_PAGE = 9_999_999_999;

  /** filters */
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchType: 'lectureTitle',
    searchText: '',
    sortInfo: {
      name: 'id',
      order: 'desc',
    },
    marketingAgreement: '',
    hasMessageLogs: '',
    hasPaid: '',
    rowsPerPage: 20,
  });
  const {
    startDate,
    endDate,
    sortInfo: { name: sort, order },
    rowsPerPage,
    marketingAgreement,
    hasMessageLogs,
    hasPaid,
  } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [selectedLikes, setSelectedLikes] = useState([]);
  const selectedLikeUserIds = useMemo(() => selectedLikes.map((like) => like.userId), [selectedLikes]);
  const [page, setPage] = useState(0);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
    });
  }, [setLocationState, searchType, searchText]);

  const loadLikes = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        searchType: searchText ? searchType : undefined,
        searchText: searchText || undefined,
        sort,
        order,
        marketingAgreement: marketingAgreement === '' ? undefined : marketingAgreement,
        hasMessageLogs: hasMessageLogs === '' ? undefined : hasMessageLogs,
        hasPaid: hasPaid === '' ? undefined : hasPaid,
      };
      if (startDate) {
        params.startDate = dayjs(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.endDate = dayjs(endDate).format('YYYY-MM-DD');
      }

      return request.getLectureLikes(params).then(({ data }) => data);
    },
    [locationState],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadLikes({ page: pageIndex + 1, limit: pageSize }).then(({ count, rows }) => {
        setLikeList(rows);
        setTotal(count);
      });
    },
    [loadLikes],
  );

  useEffect(() => {
    fetchData({ pageIndex: page, pageSize: rowsPerPage });
  }, [page, rowsPerPage, locationState.filters]);

  const SwalWithReactContent = withReactContent(Swal);
  const showSendMessagesPopup = (lecture) => {
    const selectedLikeUserIds = selectedLikes.map((selectedLike) => selectedLike.userId);

    const showPopup = () => {
      SwalWithReactContent.fire({
        title: '쿠폰 선택 후 안내 발송',
        html: <Index targetInfos={selectedLikes} />,
        width: '1400px',
        showCloseButton: true,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    };

    const duplicateUserIds = [
      ...new Set(selectedLikeUserIds.filter((userId, index) => selectedLikeUserIds.indexOf(userId) !== index)),
    ];

    if (duplicateUserIds.length > 0) {
      const duplicateUserInfo = duplicateUserIds.map((userId) => {
        const duplicateUser = selectedLikes.find((like) => like.userId === userId);
        return `${duplicateUser.userId}(${duplicateUser.userName})`;
      });

      Swal.fire({
        icon: 'warning',
        title: '동일한 유저가 두 번 이상 선택되었습니다.',
        html: `중복된 유저를 제외해주세요.
        <br />
        <br />
        * 중복 회원 ID(이름): ${duplicateUserInfo.join(', ')}`,
        confirmButtonColor: '#F24462',
        confirmButtonText: '확인',
      });
      return;
    }

    showPopup();
  };

  const handleSelectAllClick = () => {
    if (selectedLikes.length < likeList.length) {
      setSelectedLikes(likeList);
      return;
    }
    setSelectedLikes([]);
  };

  const isSelected = (targetId) => selectedLikes.map((like) => like.id).indexOf(targetId) !== -1;

  const handleClickDisabledRow = () => {
    Swal.fire({
      icon: 'warning',
      text: '이미 선택한 회원입니다.',
      toast: true,
      timerProgressBar: true,
      timer: 1500,
      showConfirmButton: false,
    });
  };

  const handleClick = useCallback(
    (event, targetLike) => {
      const selectedIndex = selectedLikes.map((like) => like.id).indexOf(targetLike.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedLikes, targetLike);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedLikes.slice(1));
      } else if (selectedIndex === selectedLikes.length - 1) {
        newSelected = newSelected.concat(selectedLikes.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedLikes.slice(0, selectedIndex), selectedLikes.slice(selectedIndex + 1));
      }
      setSelectedLikes(newSelected);
    },
    [selectedLikes],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLocationState({
      rowsPerPage: +event.target.value,
    });
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = sort === property && order === 'desc';
    setLocationState({
      sortInfo: {
        name: property,
        order: isDesc ? 'asc' : 'desc',
      },
    });
  };

  const headCells = [
    { id: 'id', label: 'ID', align: 'center', minWidth: 10 },
    { id: 'userId', label: '회원 ID', align: 'center', minWidth: 10 },
    { id: 'userName', label: '회원 이름', align: 'center', minWidth: 20 },
    { id: 'lectureId', label: '강의 ID', align: 'center', minWidth: 10 },
    { id: 'lectureTitle', label: '강의 제목', align: 'center', minWidth: 400 },
    { id: 'lectureListenType', label: '강의 구분', align: 'center', minWidth: 20 },
    { id: 'lectureIsSelling', label: '강의 판매 상태', align: 'center', minWidth: 20 },
    { id: 'issueCouponCount', label: '쿠폰 발급 횟수', align: 'center', minWidth: 10 },
    { id: 'sendMessageCount', label: '안내 발송 횟수', align: 'center', minWidth: 10 },
    { id: 'isMarketingOn', label: '마케팅 동의', align: 'center', minWidth: 10 },
    { id: 'isPaid', label: '구매 이력', align: 'center', minWidth: 10 },
    { id: 'createdAt', label: '찜한 날짜', align: 'center', minWidth: 40 },
  ];

  return (
    <ThemeProvider theme={EJE_THEME}>
      <div className="lecture--likes">
        <section className="lecture--likes--filters">
          <div className="filters--date--wrap">
            기간 :
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={locationState.startDate && dayjs(locationState.startDate)}
                maxDate={dayjs(locationState.endDate)}
                onChange={(value) => {
                  setLocationState({
                    startDate: dayjs(value).format('YYYY-MM-DD'),
                  });
                }}
                sx={{ margin: '0 0.5rem' }}
              />
              ~
              <DatePicker
                value={locationState.endDate && dayjs(locationState.endDate)}
                minDate={dayjs(locationState.startDate)}
                onChange={(value) => {
                  setLocationState({
                    endDate: dayjs(value).format('YYYY-MM-DD'),
                  });
                }}
                sx={{ margin: '0 0.5rem' }}
              />
            </LocalizationProvider>
          </div>

          <div className="filters--bottom">
            <div className="filters--select--wrap">
              <Stack spacing={2} direction="row" alignItems="center">
                <span>쿠폰 발급 안내 이력</span>
                <Select
                  value={hasMessageLogs}
                  onChange={(e) =>
                    setLocationState({
                      hasMessageLogs: e.target.value,
                    })
                  }
                  displayEmpty
                  color="pink"
                  size="small"
                >
                  <MenuItem value={''} sx={{ fontSize: '0.875rem' }}>
                    전체
                  </MenuItem>
                  <MenuItem value={true} sx={{ fontSize: '0.875rem' }}>
                    존재
                  </MenuItem>
                  <MenuItem value={false} sx={{ fontSize: '0.875rem' }}>
                    미존재
                  </MenuItem>
                </Select>

                <span>마케팅 수신 활용 동의</span>
                <Select
                  value={marketingAgreement}
                  onChange={(e) =>
                    setLocationState({
                      marketingAgreement: e.target.value,
                    })
                  }
                  displayEmpty
                  color="pink"
                  size="small"
                >
                  <MenuItem value={''} sx={{ fontSize: '0.875rem' }}>
                    전체
                  </MenuItem>
                  <MenuItem value={true} sx={{ fontSize: '0.875rem' }}>
                    동의
                  </MenuItem>
                  <MenuItem value={false} sx={{ fontSize: '0.875rem' }}>
                    비동의
                  </MenuItem>
                </Select>

                <span>구매 이력</span>
                <Select
                  value={hasPaid}
                  onChange={(e) =>
                    setLocationState({
                      hasPaid: e.target.value,
                    })
                  }
                  displayEmpty
                  color="pink"
                  size="small"
                >
                  <MenuItem value={''} sx={{ fontSize: '0.875rem' }}>
                    전체
                  </MenuItem>
                  <MenuItem value={true} sx={{ fontSize: '0.875rem' }}>
                    존재
                  </MenuItem>
                  <MenuItem value={false} sx={{ fontSize: '0.875rem' }}>
                    미존재
                  </MenuItem>
                </Select>
              </Stack>
            </div>

            <div className="filters--search--wrap">
              <Select value={searchType} onChange={(e) => setSearchType(e.target.value)} size="small" color="pink">
                <MenuItem value="lectureId" sx={{ fontSize: '0.875rem' }}>
                  강의 ID
                </MenuItem>
                <MenuItem value="lectureTitle" sx={{ fontSize: '0.875rem' }}>
                  강의 제목
                </MenuItem>
                <MenuItem value="userId" sx={{ fontSize: '0.875rem' }}>
                  회원 ID
                </MenuItem>
                <MenuItem value="userName" sx={{ fontSize: '0.875rem' }}>
                  회원 이름
                </MenuItem>
              </Select>

              <Input
                value={searchText}
                placeholder="검색어를 입력하세요."
                color="pink"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickSearch();
                  }
                }}
              />

              <Button
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  onClickSearch();
                }}
                color="pink"
                sx={{
                  height: '2rem',
                }}
                endIcon={<SearchIcon fontSize="small" />}
              >
                검색
              </Button>
            </div>
          </div>
        </section>

        <div className="lecture--likes--data">
          <div className="lecture--likes--data--top">
            전체: {total} / 선택: {selectedLikes.length}
          </div>

          <Box sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" color="pink">
                <EnhancedTableHead
                  useCheckbox
                  headCells={headCells}
                  rowCount={total}
                  numSelected={selectedLikes.length}
                  onSelectAllClick={handleSelectAllClick}
                  color="pink"
                  order={order}
                  orderBy={sort}
                  onRequestSort={handleRequestSort}
                  disableSortHeaders={['issueCouponCount', 'sendMessageCount', 'isMarketingOn', 'isPaid']}
                />

                <TableBody>
                  {likeList.map((like) => {
                    const isItemSelected = isSelected(like.id);
                    const labelId = `enhanced-table-checkbox-${like.id}`;
                    const isDisabled = !isItemSelected && selectedLikeUserIds.includes(like.userId);

                    return (
                      <TableRow
                        key={like.id}
                        hover
                        onClick={(event) => (isDisabled ? handleClickDisabledRow() : handleClick(event, like))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={ALL_ROWS_PER_PAGE}
                        selected={isItemSelected}
                        sx={{
                          backgroundColor: isDisabled ? '#e5e5e5' : 'white',
                          '&:hover': {
                            backgroundColor: isDisabled ? '#e5e5e5 !important' : 'inherit',
                          },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="pink"
                            checked={isItemSelected}
                            disabled={selectedLikeUserIds.includes(like.userId)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">{like.id}</TableCell>
                        <TableCell align="center">{like.userId}</TableCell>
                        <TableCell align="center">{like.userName}</TableCell>
                        <TableCell align="center">{like.lectureId}</TableCell>
                        <TableCell align="center">{like.lectureTitle}</TableCell>
                        <TableCell align="center">
                          {like.lectureListenType === 'online'
                            ? '온라인'
                            : like.lectureListenType === 'roundOnline'
                            ? '온라인(기수제)'
                            : like.lectureListenType === 'offline'
                            ? '오프라인'
                            : '컨설팅'}
                        </TableCell>
                        <TableCell align="center">{like.lectureIsSelling ? '판매중' : '판매중지'}</TableCell>
                        <TableCell align="center">{like.sendMessageCount}</TableCell>
                        <TableCell align="center">
                          {like.recentSentAt === 'pending' ? '발송 결과 확인중...' : like.sendMessageCount}
                        </TableCell>
                        <TableCell align="center">
                          <BooleanIcon value={like.isMarketingOn} />
                        </TableCell>
                        <TableCell align="center">
                          <BooleanIcon value={like.isPaid} />
                        </TableCell>
                        <TableCell align="center">{dayjs(like.createdAt).format('YYYY년 MM월 DD일')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <TablePagination
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20, { value: ALL_ROWS_PER_PAGE, label: '전체' }]}
            showFirstButton={true}
            showLastButton={true}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} / ${count !== -1 ? count : `more than ${to}`}`;
            }}
            component="div"
            labelRowsPerPage={'페이지당 표시 수 :'}
          />

          <div className="lecture--likes--data--bottom">
            <Button
              variant="contained"
              color="pink"
              endIcon={<SendIcon />}
              disabled={selectedLikes.length === 0}
              onClick={showSendMessagesPopup}
              sx={{
                height: '2.5rem',
                fontWeight: 'bold',
              }}
            >
              쿠폰 선택 후 안내 발송
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default LectureLikeList;
