let subscribers = [];

export function subscribe(callback) {
  subscribers.push(callback);
}

export function unsubscribe(callback) {
  const index = subscribers.indexOf(callback);
  if (index >= 0) {
    return subscribers.splice(index, 1);
  }
}

export function event(progress) {
  subscribers.forEach((callback) => callback(progress));
}

export function disposeAll() {
  subscribers = [];
}
