import {
  Badge,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  LinearProgress,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import Buttons from './Buttons';
import { useEffect, useState } from 'react';
import swal from 'sweetalert2';
import styled from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ArrowForward } from '@mui/icons-material';
import NoteToStudent from './NoteToStudent';
import withReactContent from 'sweetalert2-react-content';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  padding: 0, // 여백(padding) 없애기
  margin: 0, // 여백(margin) 없애기
  border: `none`,
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForward sx={{ fontSize: '0.875rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  padding: '0',
  fontSize: '0.875rem',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '0.5rem',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0, // 여백(padding) 없애기
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const StudentCard = ({ student, classInfo, attendanceBoardInfo, expandAll, setExpandAll }) => {
  const [attendanceRate, setAttendanceRate] = useState(0);
  const [attendanceLength, setAttendanceLength] = useState(0);
  const [classDatesLength, setClassDatesLength] = useState(0);
  const [noteToStudent, setNoteToStudent] = useState('');

  // Update attendance length when student data changes
  useEffect(() => {
    if (student?.AttendanceLogs) {
      setAttendanceLength(student.AttendanceLogs.length);
    }
  }, [student]);

  // Update class dates length when attendance board info changes
  useEffect(() => {
    if (attendanceBoardInfo?.classDays) {
      setClassDatesLength(attendanceBoardInfo.classDays.length);
    }
  }, [attendanceBoardInfo]);

  // Calculate attendance rate when attendance length or class dates length changes
  useEffect(() => {
    setAttendanceRate(Math.round((attendanceLength / classDatesLength) * 100));
  }, [attendanceLength, classDatesLength]);

  // Display review in a modal
  const showReview = () => {
    if (student.Reviews?.length > 0) {
      swal.fire({
        title: `${student.name}(${student.nickname})님의 강의 후기`,
        html: student.Reviews[0]?.content.replace(/\n/g, '<br />'),
        showCancelButton: false,
        confirmButtonText: '확인',
        confirmButtonColor: '#4da600',
      });
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const modalNoteToStudent = () => {
    setOpen(true);
  };

  useEffect(() => {
    const noteKeys = attendanceBoardInfo?.noteToStudent ? Object.keys(attendanceBoardInfo?.noteToStudent) : [];
    noteKeys.forEach((key) => {
      if (+key === +student.id) {
        // console.log(attendanceBoardInfo?.noteToStudent[student.id]);
        setNoteToStudent(attendanceBoardInfo?.noteToStudent[student.id].replace(/\n/g, '<br />'));
      }
    });
  }, [attendanceBoardInfo, student]);

  return (
    <Grid item xs={12} md={6} lg={4} xl={3} gap={2}>
      <Card elevation={5}>
        <LinearProgress
          variant="determinate"
          value={attendanceRate}
          sx={{
            backgroundColor: '#ddd',
            '& .MuiLinearProgress-bar': { backgroundColor: '#FF7F50' },
            height: '0.375rem',
          }}
        />
        <CardContent sx={{ fontSize: '0.875rem', display: 'block', position: 'relative' }}>
          <div style={{ marginBottom: '0.5rem' }}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem',
              }}
            >
              {student.name}
              {/*{student.id}*/}
              <Badge
                sx={{
                  fontSize: '0.75rem',
                  cursor: student.Reviews?.length > 0 ? 'pointer' : 'default',
                  backgroundColor: student.Reviews?.length > 0 ? '#4DA600' : '#DEDEDE',
                  color: '#FFFFFF',
                  padding: '0.125rem 0.5rem',
                  borderRadius: '0.75rem',
                }}
                onClick={showReview}
              >
                리뷰 {student.Reviews?.length > 0 ? '작성' : '미작성'}
              </Badge>
              <Badge
                sx={{
                  fontSize: '0.75rem',
                  cursor: 'pointer',
                  backgroundColor: '#FFA500',
                  color: '#FFFFFF',
                  padding: '0.125rem 0.5rem',
                  borderRadius: '0.75rem',
                  position: 'absolute',
                  right: '1rem',
                }}
                onClick={modalNoteToStudent}
              >
                Note
              </Badge>
            </Typography>
            <Typography
              sx={{ fontSize: 'inherit', color: 'text.secondary', minHeight: '1.375rem', cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard.writeText(student.email);
                swal.fire({
                  title: '이메일 주소 복사 완료',
                  text: `${student.email}이 복사되었습니다.`,
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }}
            >
              {student.email}
            </Typography>
            <a
              href={`tel:${student.phone?.startsWith('8210') ? `010${student.phone.slice(4)}` : student.phone}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography sx={{ fontSize: 'inherit', color: 'text.secondary', minHeight: '1.375rem' }}>
                {(student.phone?.toString().startsWith('8210')
                  ? `010${student.phone.slice(4)}`
                  : student.phone
                )?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
              </Typography>
            </a>
          </div>
          <NoteToStudent
            student={student}
            attendanceBoardInfo={attendanceBoardInfo}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
            noteToStudent={noteToStudent}
          />
          <Buttons
            attendanceBoardInfo={attendanceBoardInfo}
            student={student}
            setAttendanceLength={setAttendanceLength}
          />
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '30rem',
            borderRadius: '0.5rem',
          },
        }}
      >
        <DialogTitle>
          {student.name}({student.nickname})님의 참고사항
        </DialogTitle>
        <DialogContent>
          <textarea
            style={{
              padding: '1rem',
              width: '100%',
              border: '1px solid #ddd',
              borderRadius: '0.25rem',
              boxSizing: 'border-box',
              resize: 'none',
            }}
            rows={10}
            value={noteToStudent}
            onChange={(e) => setNoteToStudent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setNoteToStudent(attendanceBoardInfo?.noteToStudent?.find((note) => note.userId === student.id)?.content);
            }}
            size="small"
            sx={{
              color: '#fff',
              backgroundColor: '#9c9c9c',
              '&:hover': {
                color: '#fff',
                backgroundColor: '#7a7a7a',
              },
            }}
            disableRipple
          >
            취소
          </Button>
          <Button
            onClick={() => {
              // console.log('noteToStudent', noteToStudent);
              setOpen(false);
            }}
            size="small"
            sx={{
              color: '#fff',
              backgroundColor: '#7ec0ee',
              '&:hover': {
                color: '#fff',
                backgroundColor: '#5ca0cc',
              },
            }}
            disableRipple
          >
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default StudentCard;
