import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOfflineClassBySchedule } from '../../../../Common/Util/Request';

import '../Detail.scss';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import Info from './partials/Info';
import ListContainer from './partials/ListContainer';

const Index = () => {
  // width 960 이하는 모바일
  const isMobile = useMediaQuery('(max-width:960px)');
  const [isLoaded, setIsLoaded] = useState(false);
  const { classId, attendanceBoardId } = useParams();
  const [studentList, setStudentList] = useState([]);
  const [classInfo, setClassInfo] = useState({});
  const [attendanceBoardInfo, setAttendanceBoardInfo] = useState({});
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('이름순');

  useEffect(() => {
    if (classId && attendanceBoardId) {
      // TODO: fetch data
      getOfflineClassBySchedule({ classId, attendanceBoardId }).then((response) => {
        const { success, students, attendanceBoardInfo, classInfo } = response;
        if (success) {
          // console.log('students---', students);
          setStudentList(students);
          setFilteredStudents(students);
          setClassInfo(classInfo);
          setAttendanceBoardInfo(attendanceBoardInfo);
        }
      });
    }
  }, [classId, attendanceBoardId]);

  useEffect(() => {
    if (
      classInfo &&
      Object.keys(classInfo).length > 0 &&
      attendanceBoardInfo &&
      Object.keys(attendanceBoardInfo).length > 0
    ) {
      setIsLoaded(true);
      // console.log('----', studentList);
    }
  }, [classInfo, attendanceBoardInfo]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, sortOrder, studentList]);

  const handleSearch = () => {
    let tempStudents = studentList.filter((student) => student.name.toLowerCase().includes(searchTerm.toLowerCase()));
    if (sortOrder === '이름순') {
      tempStudents.sort((a, b) => a.name.localeCompare(b.name));
    }
    setFilteredStudents(tempStudents);
  };

  console.log('filteredStudents: ', filteredStudents);

  const [expandAll, setExpandAll] = useState('false');
  return (
    <div className={`container ${isLoaded ? '' : 'blind'}`}>
      <Info
        classInfo={classInfo}
        attendanceBoardInfo={attendanceBoardInfo}
        setAttendanceBoardInfo={setAttendanceBoardInfo}
        expandAll={expandAll}
        setExpandAll={setExpandAll}
      />
      <Box sx={{ my: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <TextField
          label="수강생 검색"
          variant="outlined"
          size="small"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="sort-label">정렬 기준</InputLabel>
          <Select
            labelId="sort-label"
            value={sortOrder}
            label="정렬 기준"
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <MenuItem value="이름순">이름순</MenuItem>
            <MenuItem value="등록순">등록순</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {filteredStudents.length > 0 && (
        <ListContainer
          students={filteredStudents}
          classInfo={classInfo}
          attendanceBoardInfo={attendanceBoardInfo}
          expandAll={expandAll}
          setExpandAll={setExpandAll}
        />
      )}
    </div>
  );
};

export default Index;
