import React, { useCallback, useState } from 'react';
import { useLocationState } from '../../../Common/Util/CustomHooks';
import * as request from '../../../Common/Util/Request';
import * as Button from '../../../Common/Component/Button';
import Table from '../../../Common/Component/Table';
import './index.scss';
import Swal from 'sweetalert2';

function Records() {
  const [locationState, setLocationState] = useLocationState({
    searchType: 'title',
    searchText: '',
    filters: [],
  });
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
      pageIndex: 0,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        ...locationState,
        page: pageIndex + 1,
        limit: pageSize,
      };
      request.records(params).then(({ rows, count }) => {
        setRecords(rows);
        setTotal(count);
      });
    },
    [locationState],
  );

  const [deleteTargets, setDeleteTargets] = useState([]);

  const checkboxHandler = ({ target: { checked, value } }) => {
    const id = +value;
    if (checked) setDeleteTargets([...deleteTargets, id]);
    else setDeleteTargets(deleteTargets.filter((target) => target !== id));
  };

  const deleteHandler = () => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      text: '삭제한 데이터는 복구할 수 없습니다.',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteRecords(deleteTargets)
          .then(({ success, deletedRecordCount }) => {
            if (success) {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">${deletedRecordCount}개 앨범이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'success',
                type: 'success',
              });
            } else {
              throw new Error('삭제에 실패하였습니다.');
            }
          })
          .then(() => fetchData({ pageIndex: 0, pageSize: 20 }))
          .then(() => document.getElementsByName('deleteTargets').forEach((item) => (item.checked = false)))
          .then(() => setDeleteTargets([]))
          .catch(() => {
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
              toast: true,
              position: 'center',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'error',
              type: 'error',
            });
          }),
    });
  };
  return (
    <div className="records">
      <section>
        <div className="records__search">
          <select
            value={searchType}
            onChange={(e) =>
              setSearchType((prev) => {
                setSearchText('');
                return e.target.value;
              })
            }
          >
            <option value="title">앨범명</option>
            <option value="artistName">아티스트명</option>
            <option value="agencyName">기획사명</option>
          </select>
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyUp={(e) => (e.keyCode === 13 || e.code === 'Enter') && onClickSearch()}
          />
          <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
        </div>
        <div className="records-action-buttons-wrapper">
          <Button.Negative onClick={deleteHandler} disabled={!deleteTargets.length}>
            삭제
          </Button.Negative>
          <Button.Positive onClick={() => window.open(`/contents/records/new`)}>앨범등록</Button.Positive>
        </div>
      </section>
      <div className="records__total">총 앨범수 {total}</div>
      <Table
        data={records}
        total={total}
        pageSize={20}
        columns={[
          {
            Header: '선택',
            accessor: ({ id }) => (
              <input type="checkbox" name="deleteTargets" id={id} value={id} onChange={checkboxHandler} />
            ),
          },
          { Header: '앨범ID', accessor: 'id' },
          {
            Header: '아티스트명',
            accessor: (row) => {
              const artists = row.Record_Artists.map(({ FK_Artist }) => FK_Artist).sort((a, b) =>
                a.status > b.status ? -1 : a.status < b.status ? 1 : 0,
              );
              return artists.map(({ name }) => name).join(', ');
            },
          },
          { Header: '앨범명', accessor: 'title' },
          // {
          //   Header: '대표이미지',
          //   accessor: ({ thumbnail }) =>
          //     thumbnail ? (
          //       <img src={thumbnail} className="list-thumbnail" />
          //     ) : (
          //       '-'
          //     )
          // },
          {
            Header: '앨범상태',
            accessor: ({ status }) => {
              return status === 1 ? '대기' : status === 2 ? '공개' : '비공개';
            },
          },
          {
            Header: '정보수정',
            accessor: (row) => (
              <button onClick={() => window.open(`/contents/records/edit/${row.id}`)}>수정</button>
              // <Link
              //   to = { window.location }
              //   onClick = { () => window.open(`/contents/records/edit/${row.id}`) }
              // to={{
              //   pathname: `/contents/records/edit/${row.id}`,
              //   state: { record: row }
              // }}
              // >
              //   수정
              // </Link>
            ),
          },
        ]}
        fetchData={fetchData}
      />
    </div>
  );
}

export default Records;
