import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import * as request from '../../../../Common/Util/Request';
import Table from '../../../../Common/Component/Table';
import Chip from '../../../../Common/Component/Chip';
import * as Field from '../../../../Common/Component/Field';
import * as Button from '../../../../Common/Component/Button';
import './index.scss';
import { useParams } from 'react-router-dom';

function Coupon(props) {
  const intl = useIntl();
  const { id } = useParams();
  const [couponOrigin, setCouponOrigin] = useState(null);
  const editing = useMemo(() => !!couponOrigin?.id, [couponOrigin]);
  useEffect(() => {
    id &&
      request.couponDetail(id).then((data) => {
        setCouponOrigin(data);
      });
  }, []);

  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState('rate');
  const [amount, setAmount] = useState(null);
  const [countIssue, setCountIssue] = useState(null);
  const [selectedLectures, setSelectedLectures] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [userIdsToDelete, setUserIdsToDelete] = useState([]);
  const [userIdsStringToAdd, setUserIdsStringToAdd] = useState('');
  const [validDateType, setValidDateType] = useState('manual');
  const [days, setDays] = useState(null);
  useEffect(() => {
    if (couponOrigin?.id) {
      setName(couponOrigin.name);
      setCode(couponOrigin.code);
      setStartDate(new Date(couponOrigin.start_date));
      setEndDate(new Date(couponOrigin.end_date));
      setType(couponOrigin.type);
      setAmount(couponOrigin.amount);
      setValidDateType(couponOrigin.valid_date_type);
      setDays(couponOrigin.valid_days);
      setCountIssue(couponOrigin.count_issue);
      setSelectedLectures(couponOrigin.Coupon_Classes.map((Coupon_Class) => Coupon_Class.FK_class));
      setUserIds(couponOrigin.User_Coupons.map((User_Coupon) => User_Coupon.FK_user?.id));
    }
  }, [couponOrigin]);

  const [searchOption, setSearchOption] = useState('title');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([]);
  const onClickSearch = useCallback(() => {
    setFilters(searchText ? [{ id: searchOption, value: searchText }] : []);
  }, [searchText, searchOption]);

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .lectures('coupon', {
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(filters),
        })
        .then(({ classes }) => {
          setLectures(classes.rows);
          setTotal(classes.count);
        });
    },
    [filters],
  );
  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: (lecture) => {
          const checked = !!selectedLectures.find((selectedLecture) => selectedLecture.id === lecture.id);
          return (
            <input
              type="checkbox"
              checked={checked}
              onChange={() => {
                if (checked) {
                  setSelectedLectures(selectedLectures.filter((selectedLecture) => selectedLecture.id !== lecture.id));
                } else {
                  setSelectedLectures([...selectedLectures, lecture]);
                }
              }}
            />
          );
        },
      },
      { Header: '강의ID', accessor: 'id' },
      { Header: '강의명', accessor: 'title' },
      {
        Header: '구분',
        accessor: 'listen_type',
        Cell: ({ value: listen_type }) => <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />,
      },
    ],
    [selectedLectures],
  );

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  const onClickUserId = useCallback((userId) => {
    setUserIds((userIds) => userIds.filter((_userId) => _userId !== userId));
    setUserIdsToDelete((userIds) => [...userIds, userId]);
  }, []);
  const onClickDeleteAllUserIds = useCallback(() => {
    setUserIds([]);
    setUserIdsToDelete((_userIds) => [..._userIds, ...userIds]);
  }, [userIds]);

  const history = useHistory();
  const onClickRemove = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      text: '삭제한 데이터는 복구할 수 없습니다.',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteCoupon(couponOrigin.id)
          .then(({ success }) => {
            if (success) {
              window.exitFlag = true;
              window.opener.location.reload();
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">쿠폰이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            } else {
              throw new Error('삭제에 실패하였습니다.');
            }
          })
          .catch(() => {
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
              toast: true,
              position: 'center',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'error',
              type: 'error',
            });
          }),
    });
  }, [history, couponOrigin]);
  const onClickSave = useCallback(() => {
    let coupon = {};
    const requiredFields = ['name', 'amount', 'valid_date_type'];

    switch (validDateType) {
      case 'manual':
        requiredFields.push('start_date', 'end_date');
        coupon = {
          name,
          code: code && code.length > 0 ? code : null,
          start_date: startDate && moment(startDate).format('YYYY-MM-DD'),
          end_date: endDate && moment(endDate).format('YYYY-MM-DD'),
          type,
          amount,
          valid_date_type: validDateType,
          createdBy: 'manual',
          count_issue: countIssue,
        };
        break;
      case 'days':
        requiredFields.push('valid_days');
        coupon = {
          name,
          code: code && code.length > 0 ? code : null,
          type,
          amount,
          valid_days: days ? +days : null,
          valid_date_type: validDateType,
          createdBy: 'manual',
          count_issue: countIssue,
        };
        break;
      default:
        break;
    }

    if (selectedLectures.length > 0) {
      coupon.class_ids = selectedLectures.map((lecture) => lecture.id).join(',');
    }

    if (userIdsStringToAdd) {
      coupon.user_ids = userIdsStringToAdd;
    }

    if (userIdsToDelete.length > 0) {
      coupon.delete_user_ids = userIdsToDelete.join(',');
    }

    const emptyFields = requiredFields.filter((field) => !coupon[field]);
    if (emptyFields.length > 0) {
      Swal.fire({
        html: `${emptyFields
          .map((field) => intl.formatMessage({ id: 'ID_COUPON_FIELD_' + field.toUpperCase() }))
          .join(', ')} 값을 입력해주세요.`,
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }

    if (editing) {
      request
        .updateCoupon(couponOrigin.id, coupon)
        .then(() => {
          window.opener.location.reload();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 수정되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        })
        .catch(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">쿠폰 수정에 실패했습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            type: 'error',
          });
        });
    } else {
      request
        .createCoupon(coupon)
        .then(() => {
          window.opener.location.reload();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 저장되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        })
        .catch(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">쿠폰 생성에 실패했습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            type: 'error',
          });
        });
    }
  }, [
    intl,
    history,
    editing,
    couponOrigin,
    name,
    code,
    startDate,
    endDate,
    type,
    amount,
    countIssue,
    selectedLectures,
    userIdsStringToAdd,
    userIdsToDelete,
    validDateType,
    days,
  ]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">쿠폰을 저장하지 않고 나가시겠습니까?</div>`,
      toast: true,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  }, []);
  return (
    <div className="coupon">
      <div className="coupon__fields">
        <Field.Input title="쿠폰명" value={name} onChange={setName} required />
        <Field.Input
          title="쿠폰코드"
          value={code}
          onChange={setCode}
          disabled={!!couponOrigin}
          placeholder="미입력 시 자동생성"
        />
        <Field.Base type="discount" title="쿠폰 사용기간 타입" required>
          <Field.Radio
            value={validDateType}
            onChange={setValidDateType}
            options={[
              { label: '기본', value: 'manual' },
              { label: '발급일로부터', value: 'days' },
            ]}
          />
          {validDateType === 'days' ? (
            <Field.Input type="number" className="w-120px" textAfter="일" value={days} onChange={setDays} />
          ) : (
            <Field.Period
              value={{ startDate, endDate }}
              onChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              required
            />
          )}
        </Field.Base>
        <Field.Base type="discount" title="할인 유형" required>
          <Field.Radio
            value={type}
            onChange={setType}
            options={[
              { label: '비율 할인', value: 'rate' },
              { label: '금액 할인', value: 'price' },
            ]}
          />
          <Field.Input type="number" textAfter={type === 'rate' ? '%' : '원'} value={amount} onChange={setAmount} />
        </Field.Base>
        <Field.Input type="number" title="쿠폰 발급수" textAfter="개" value={countIssue} onChange={setCountIssue} />
        <Field.Base type="lectures" title="쿠폰 적용 대상 콘텐츠">
          <div className="field--lectures__message">
            별도로 콘텐츠를 지정하지 않을 시, 전체 상품에 쿠폰을 사용할 수 있습니다
          </div>
          <div className="field--lectures__search">
            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
              <option value="title">강의명</option>
              <option value="id">강의 ID</option>
            </select>
            <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
          </div>
          <Table
            pageSize={10}
            total={total}
            data={lectures}
            columns={columns}
            usesLocation={false}
            fetchData={fetchData}
          />
          {selectedLectures.length > 0 && (
            <div className="field--lectures__lectures">
              쿠폰 적용 대상 강의 ID: {selectedLectures.map((lecture) => lecture.id).join(', ')}
            </div>
          )}
        </Field.Base>
        <Field.Textarea title="쿠폰 지급 대상 회원 ID" value={userIdsStringToAdd} onChange={setUserIdsStringToAdd} />
        {userIds.length > 0 && (
          <Field.Base type="user-ids">
            <div className="user-ids">
              {userIds.map((userId) => (
                <Chip key={userId} name={userId} onClick={() => onClickUserId(userId)} selected />
              ))}
              <Button.Negative onClick={onClickDeleteAllUserIds}>회원ID 일괄삭제</Button.Negative>
            </div>
          </Field.Base>
        )}
      </div>
      <div className="coupon__buttons">
        <Button.Negative onClick={onClickRemove} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default Coupon;
