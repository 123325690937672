import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import * as excel from '../../../../Common/Util/Excel';
import DatePicker from '../../../../Common/Component/DatePicker';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';

function GivenLectures(props) {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchOption: 'id',
    searchText: '',
    filters: [],
  });
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchOption, searchText]);

  const onClickCSV = useCallback(() => {
    request
      .givenLectures({ page: 1, limit: 99999 }) // temp
      .then(({ adminAddClasses }) => {
        const data = adminAddClasses.rows.reduce(
          (acc, cur) => [
            ...acc,
            ...cur.User_AdminAddClasses.map((User_AdminAddClass) => ({
              강의ID: cur.FK_class.id,
              강의명: cur.FK_class.title,
              수강일수: cur.valid_days,
              '콘텐츠 지급일': moment.utc(cur.createdAt).format('YYYY-MM-DD'),
              '콘텐츠 지급 대상 회원ID': User_AdminAddClass.FK_user_id,
            })),
          ],
          [],
        );
        excel.downloadXLSX(data);
      });
  }, []);

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = useMemo(
    () => [
      { Header: '강의ID', accessor: 'FK_class.id' },
      { Header: '강의명', accessor: 'FK_class.title' },
      {
        Header: '콘텐츠 지급일',
        accessor: (row) => moment.utc(row.createdAt).format('YYYY-MM-DD'),
      },
      {
        Header: '정보수정',
        accessor: (row) => <button onClick={() => window.open(`./given/new/${row.id}`)}>수정</button>,
      },
    ],
    [],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        page: pageIndex + 1,
        limit: pageSize,
        filtered: JSON.stringify(locationState.filters),
      };

      if (locationState.startDate) {
        params.start_date = moment(locationState.startDate).format('YYYY-MM-DD');
      }
      if (locationState.endDate) {
        params.end_date = moment(locationState.endDate).format('YYYY-MM-DD');
      }

      request.givenLectures(params).then(({ adminAddClasses }) => {
        setLectures(adminAddClasses.rows);
        setTotal(adminAddClasses.count);
      });
    },
    [locationState.filters, locationState.startDate, locationState.endDate],
  );

  return (
    <div className="given-lectures">
      <div className="toolbar">
        <div className="filters">
          <div className="filters__date">
            기간
            <DatePicker
              selected={locationState.startDate}
              maxDate={locationState.endDate}
              onChange={(date) => setLocationState({ startDate: date })}
            />
            ~
            <DatePicker
              selected={locationState.endDate}
              minDate={locationState.startDate}
              onChange={(date) => setLocationState({ endDate: date })}
            />
          </div>
          <div className="filters__search">
            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
              <option value="id">강의ID</option>
              <option value="title">강의명</option>
              <option value="FK_user_id">회원ID</option>
            </select>
            <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
          </div>
        </div>
        <div className="btns">
          <button className="making-btn" onClick={() => window.open(`./given/new`)}>
            <Button.Positive>+ 임의지급 생성</Button.Positive>
          </button>
          <Button.Excel onClick={onClickCSV}>엑셀 다운로드</Button.Excel>
        </div>
      </div>
      <Table data={lectures} total={total} columns={columns} fetchData={fetchData} pageSize={20} />
    </div>
  );
}

export default GivenLectures;
