import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Field from '../../../Common/Component/Field';
import * as Button from '../../../Common/Component/Button';
import * as request from '../../../Common/Util/Request';
import * as object from '../../../Common/Util/Object';
import './index.scss';
import Swal from 'sweetalert2';

function UserDetail(props) {
  const history = useHistory();
  const { state } = useLocation();
  const [userOrigin, setUserOrigin] = useState(state);

  const id = userOrigin.id;
  const [nickname, set_nickname] = useState(userOrigin.nickname);
  const [name, set_name] = useState(userOrigin.name);
  const [email, set_email] = useState(userOrigin.email);
  const phone = userOrigin.phone ? userOrigin.phone.toPhone() : '-';
  const loginType = userOrigin.login_type;
  const countryName = userOrigin.FK_country?.name_kor || '-';
  const [is_marketing_on, set_is_marketing_on] = useState(userOrigin.is_marketing_on);
  const role = userOrigin.role;
  const count_class = userOrigin.countAllPaidClass;
  const amount_payed = userOrigin.amountAllPaidClass;
  const count_visit = userOrigin.count_visit;
  const createdAt = moment.utc(userOrigin.createdAt).format('YYYY.MM.DD');
  const lastLoginAt = moment.utc(userOrigin.lastLoginAt).format('YYYY.MM.DD');
  const deletedAt = userOrigin.deletedAt ? moment.utc(userOrigin.deletedAt).format('YYYY.MM.DD') : '-';
  const interestedCompanies = userOrigin.interestedCompanies?.join(', ') || '-';
  const otherInterestedCompanies = userOrigin.otherInterestedCompanies?.join(', ') || '-';
  const [permmission_level, setPermmissionLevel] = useState(userOrigin.permmission_level?.toString() || '0');
  const [blocked_cause, setBlockedCause] = useState(userOrigin.blocked_cause || '');

  const isForeign = userOrigin.FK_country?.code !== 'KR';

  const onClickSignOut = useCallback(() => {
    const confirmed = window.confirm('정말로 해당 회원을 탈퇴시키겠습니까?');
    if (confirmed) {
      request.deleteUser(userOrigin.id).then(() => {
        window.alert('탈퇴되었습니다.');
        history.goBack();
      });
    }
  }, [history, userOrigin]);

  const onClickSave = useCallback(() => {
    const user = { nickname, name, email, is_marketing_on, permmission_level };
    permmission_level < 0 && (user.blocked_cause = blocked_cause);
    const changed = object.getChanged(userOrigin, user);
    if (Object.keys(changed).length > 0) {
      request.updateUser(userOrigin.id, changed).then((response) => {
        const { success } = response;
        if (!success) {
          Swal.fire({
            icon: 'error',
            title: '저장 실패',
            text: '저장에 실패했습니다. 다시 시도해주세요.',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: '저장 완료',
            text: '저장이 완료되었습니다.',
            timer: 2000,
            showConfirmButton: false,
          }).then(() => {
            setUserOrigin({ ...userOrigin, ...changed });
          });
        }
      });
    }
  }, [userOrigin, nickname, name, email, is_marketing_on, permmission_level, blocked_cause]);

  return (
    <div className="user-detail">
      <div className="user-detail__fields">
        <Field.Input title="ID" value={id} fixed />
        <Field.Input title="닉네임" value={nickname} onChange={set_nickname} />
        <Field.Input title="이름" value={name} onChange={set_name} />
        <Field.Input title="이메일" value={email} onChange={set_email} fixed={isForeign} />
        {isForeign || <Field.Input title="핸드폰번호" value={phone} fixed />}
        <Field.Input title="가입 유형" value={loginType} fixed />
        <Field.Input title="국가정보" value={countryName} fixed />
        <Field.Radio
          title="혜택 수신 동의"
          name="is_marketing_on"
          value={is_marketing_on}
          options={[
            { label: '동의', value: true },
            { label: '비동의', value: false },
          ]}
          onChange={set_is_marketing_on}
        />
        <Field.Input title="회원그룹 정회원" value={role} fixed />
        <Field.Radio
          title="블랙리스트 여부"
          name="permmission_level"
          value={permmission_level}
          options={[
            { label: '일반', value: '0' },
            { label: '블랙리스트 1단계', value: '-1' },
            { label: '블랙리스트 2단계', value: '-2' },
          ]}
          onChange={setPermmissionLevel}
        />
        {Number(permmission_level) < 0 && (
          <Field.Textarea title="블랙리스트 사유" value={blocked_cause} onChange={setBlockedCause} />
        )}
        <Field.Input title="총 구매 강좌수" textAfter="개" value={count_class} fixed />
        <Field.Input title="총 결제금액" textAfter="원" value={amount_payed} fixed />
        <Field.Input title="총 방문수" textAfter="회" value={count_visit} fixed />
        <Field.Input title="관심 기업" value={interestedCompanies} fixed />
        <Field.Input title="기타 관심 기업" value={otherInterestedCompanies} fixed />
        <Field.Input title="가입일" value={createdAt} fixed />
        <Field.Input title="최종방문일" value={lastLoginAt} fixed />
        <Field.Input title="탈퇴일자" value={deletedAt} fixed />
      </div>
      <div className="user-detail__buttons">
        <Button.Negative onClick={onClickSignOut} disabled={userOrigin.deletedAt}>
          탈퇴
        </Button.Negative>
        <Button.Positive onClick={onClickSave} disabled={userOrigin.deletedAt}>
          저장
        </Button.Positive>
      </div>
    </div>
  );
}

export default UserDetail;
