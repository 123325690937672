import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import * as request from '../../Common/Util/Request';
import * as excel from '../../Common/Util/Excel';
import DatePicker from '../../Common/Component/DatePicker';
import * as Button from '../../Common/Component/Button';
import Table from '../../Common/Component/Table/index';
import './index.scss';

function Sales(props) {
  const intl = useIntl();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);

  const loadPayments = useCallback(
    ({ page, limit }) => {
      const params = { page, limit };

      if (startDate) {
        params.start_date = moment(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = moment(endDate).format('YYYY-MM-DD');
      }

      return request.payments(params).then(({ transactionGroup }) => transactionGroup);
    },
    [startDate, endDate],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadPayments({ page: pageIndex + 1, limit: pageSize }).then((payments) => {
        setPayments(payments.rows);
        setTotal(payments.count);
      });
    },
    [loadPayments],
  );

  const columns = useMemo(
    () => [
      { Header: '일자', accessor: 'date_paid_at' },
      {
        Header: '요일',
        accessor: (row) => <FormattedMessage id={'ID_WEEKDAY_' + moment(row.date_paid_at).day()} />,
      },
      {
        Header: '결제건',
        accessor: 'count',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '정가 합계',
        accessor: 'originalPriceSum',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '할인금액 합계',
        accessor: 'discountPriceSum',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '매출 합계',
        accessor: 'salesPriceSum',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '온라인 매출',
        accessor: 'onlineSale',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '오프라인 매출',
        accessor: 'offlineSale',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '외국인 매출',
        accessor: 'foreignerSale',
        Cell: ({ value }) => value.toPrice(),
      },
      {
        Header: '환불금액',
        accessor: 'refundSum',
        Cell: ({ value }) => value.toPrice(),
      },
    ],
    [],
  );

  const [stat, setStat] = useState(null);

  const statRow = useMemo(
    () =>
      stat ? (
        <tr>
          <td>총합</td>
          <td>-</td>
          <td>{stat.transactionSum.toPrice()}</td>
          <td>{stat.originalPriceSum.toPrice()}</td>
          <td>{stat.discountPriceSum.toPrice()}</td>
          <td>{stat.salesPriceSum.toPrice()}</td>
          <td>{stat.onlineSale.toPrice()}</td>
          <td>{stat.offlineSale.toPrice()}</td>
          <td>{stat.foreignerSale.toPrice()}</td>
          <td>{stat.refundSum.toPrice()}</td>
        </tr>
      ) : null,
    [stat],
  );

  useEffect(() => {
    const params = {};
    if (startDate) {
      params.start_date = moment(startDate).format('YYYY-MM-DD');
    }
    if (endDate) {
      params.end_date = moment(endDate).format('YYYY-MM-DD');
    }

    request.paymentStat(params).then(setStat);
  }, [startDate, endDate]);

  const onClickCSV = useCallback(() => {
    loadPayments({ page: 1, limit: 9999 }).then((payments) => {
      excel.downloadXLSX(
        payments.rows.map((payment) => ({
          일자: payment.date_paid_at,
          요일: intl.formatMessage({
            id: 'ID_WEEKDAY_' + moment(payment.date_paid_at).day(),
          }),
          결제건: payment.count,
          '정가 합계': payment.originalPriceSum,
          '할인금액 합계': payment.discountPriceSum,
          '매출 합계': payment.salesPriceSum,
          '온라인 매출': payment.onlineSale,
          '오프라인 매출': payment.offlineSale,
          '외국인 매출': payment.foreignerSale,
          환불금액: payment.refundSum,
        })),
      );
    });
  }, [intl, loadPayments]);

  return (
    <div className="sales">
      <div className="sales__date">
        기간
        <DatePicker selected={startDate} maxDate={endDate} onChange={setStartDate} />
        ~
        <DatePicker selected={endDate} minDate={startDate} onChange={setEndDate} />
      </div>
      <Button.Excel onClick={onClickCSV}>엑셀 다운로드</Button.Excel>
      <Table data={payments} columns={columns} total={total} pageSize={40} fetchData={fetchData} fixedRow={statRow} />
    </div>
  );
}

export default Sales;
