import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { addStudent } from '../functions';
import * as request from '../../../../../Common/Util/Request';
import { SelectItem } from '../../../../Survey/Statistics/styles';
import swal from 'sweetalert2';
import './Info.scss';

const AddStudentModal = ({ classInfo, classId, scheduleId, options, schedules }) => {
  // console.log(classInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const BtnAddStudent = () => (
    <Button
      variant="contained"
      color="info"
      className="add-student-btn"
      disableRipple
      size="small"
      onClick={handleModalOpen}
      sx={{
        whiteSpace: 'nowrap',
        wordWrap: 'nowrap',
        // media query
        '@media screen and (max-width: 600px)': {
          fontSize: '0.75rem',
        },
      }}
    >
      수강생 추가
    </Button>
  );

  const handleAddStudent = async () => {
    const response = await addStudent({ classId, scheduleId });
    const { success, student } = response;
    if (success) {
      setStudentList([...studentList, student]);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    setIsModalOpen(false);
  };

  const [open, setOpen] = useState(false);
  const loading = useMemo(() => open && options.length === 0, [open, options]);

  const [targetUser, setTargetUser] = useState(null);
  const [targetSchedule, setTargetSchedule] = useState('-1');

  useEffect(() => {
    if (!isModalOpen) setTargetUser(null);
  }, [isModalOpen]);

  const createPayment = useCallback(async () => {
    console.log('createPayment');
    console.log(targetUser);
    console.log(classInfo);
    console.log(targetSchedule);
    const priceOriginal = +classInfo.price_original || 0;
    const discountRate = +classInfo.discount_rate || 0;
    const optionPrice = +schedules.find((schedule) => schedule.id === targetSchedule).option_price || 0;
    const price = Math.floor(priceOriginal - (priceOriginal * discountRate) / 100) + optionPrice;
    const response = await request.addStudentCreatePayment({
      userId: targetUser.id,
      classId: classInfo.id,
      scheduleId: targetSchedule,
      price,
      priceOriginal,
      discountRate,
      optionPrice,
    });
    const { success, data } = response;
    if (!success) {
      // 결제 페이지 생성 실패
      // 에러 메시지 확인 및 표시
      // setIsModalOpen(false);
      // setTargetSchedule('-1');
      // setTargetUser(null);
      swal.fire({
        icon: 'error',
        title: '결제 페이지 생성에 실패했습니다.',
        timer: 2000,
        showConfirmButton: false,
        customClass: {
          container: 'add-student-response-alert',
        },
      });
      return;
    }
    // 결제 페이지 생성 성공
    // 결페 페이지 URL 확인 및 전달
    swal
      .fire({
        customClass: {
          container: 'add-student-response-alert',
        },
        icon: 'success',
        title: '결제 페이지가 생성되었습니다.',
        html: `<p>수강생에게 <a href="${process.env.REACT_APP_HOST}/payment/${data.txResponse.code}" target="_blank">결제 페이지<svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#666666"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg></a>를 전달하시겠습니까?</p>`,
        showCancelButton: true,
        confirmButtonText: '전달',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // 알림톡 전송
          // const message = `${studentName}님, 안녕하세요!\n${classInfo.title} 강의의 결제 페이지가 생성되었습니다. 아래 링크를 통해 결제를 진행해주세요.\nhttps://www.enterjobedu.co.kr/payment/${data.txResponse.code}`;
          const response = await request.sendPaymentUrlToStudent({
            classId: classInfo.id,
            scheduleId: targetSchedule,
            userId: targetUser.id,
            classTitle: classInfo.title,
            code: data.txResponse.code,
          });
          const { success } = response;
          if (!success) {
            // 알림톡 전송 실패
            // 에러 메시지 확인 및 표시
            swal
              .fire({
                icon: 'error',
                title: '알림톡 전송에 실패했습니다.',
                html: '결제 페이지 URL을 복사 후 직접 전달해주세요.<br />해당 창이 닫히면 URL이 복사됩니다.',
                timer: 3000,
                showConfirmButton: false,
                customClass: {
                  container: 'add-student-response-alert',
                },
              })
              .then(() => {
                navigator.clipboard.writeText(`https://www.enterjobedu.co.kr/payment/${data.txResponse.code}`);
              });
          }
          await swal.fire({
            icon: 'success',
            title: '결제 정보를 전달했습니다.',
            timer: 2000,
            showConfirmButton: false,
            customClass: {
              container: 'add-student-response-alert',
            },
          });
        }
      })
      .then(() => {
        setIsModalOpen(false);
        setTargetSchedule('-1');
        setTargetUser(null);
      });
  }, [targetUser, classInfo, classId, targetSchedule]);

  return (
    <>
      <BtnAddStudent />
      <Fragment>
        <Dialog
          open={isModalOpen}
          onClose={handleModalClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              // formJson의 각 key에 대해 value가 Number인지 확인
              // Number라면 parsePositiveInt로 변환
              // 변환된 값을 formJson에 다시 할당
              for (const key in formJson) {
                if (!!+formJson[key]) {
                  formJson[key] = +formJson[key];
                }
              }
              // console.log(formJson);

              setIsModalOpen(false);
            },
          }}
        >
          <DialogTitle>수강생 추가</DialogTitle>
          <DialogContent>
            <DialogContentText>
              추가할 수강생 선택 후 결제 페이지를 전달해주세요.
              <br />
              결제 완료 후 수강생 등록이 완료됩니다.
            </DialogContentText>
            <Select
              name="targetSchedule"
              value={targetSchedule}
              onChange={(event) => {
                setTargetSchedule(event.target.value);
              }}
              size="small"
              fullWidth
              variant="outlined"
              sx={{ marginTop: '1rem' }}
            >
              <MenuItem disabled value="-1">
                <span style={{ color: '#666666' }}>강의 옵션 선택</span>
              </MenuItem>
              {schedules?.map((schedule) => (
                <MenuItem key={schedule.id} value={schedule.id}>
                  {schedule.title}(
                  {(
                    Math.floor(
                      +classInfo.price_original - (+classInfo.price_original * +classInfo.discount_rate) / 100,
                    ) + +schedule.option_price
                  ).toPrice()}
                  원)
                </MenuItem>
              ))}
            </Select>
            <Autocomplete
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option.title}
              options={options}
              loading={loading}
              size="small"
              sx={{ marginTop: '1rem' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="수강생 선택"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
              onChange={(event, value) => {
                setTargetUser(value);
              }}
            />
            <TextField type="hidden" sx={{ display: 'none' }} name="id" value={targetUser ? targetUser.id : ''} />
            <TextField type="hidden" sx={{ display: 'none' }} name="classId" value={classId} />
            <TextField type="hidden" sx={{ display: 'none' }} name="scheduleId" value={scheduleId} />
            {targetUser?.email && (
              <TextField
                label="이메일"
                name="email"
                size="small"
                sx={{ marginTop: '1rem' }}
                fullWidth
                variant="outlined"
                inputProps={{
                  readOnly: true,
                }}
                value={targetUser ? targetUser.email : ''}
                placeholder="수강생 선택 시 자동 입력"
              />
            )}
            {targetUser?.phone && (
              <TextField
                label="전화번호"
                name="phone"
                size="small"
                sx={{ marginTop: '1rem' }}
                fullWidth
                variant="outlined"
                inputProps={{
                  readOnly: true,
                }}
                value={
                  targetUser
                    ? targetUser.phone.replace(/^8210/, '010').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                    : ''
                }
                placeholder="수강생 선택 시 자동 입력"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleModalClose}
              sx={{
                backgroundColor: '#dedede',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#dedede',
                },
              }}
              disableRipple
              disableElevation
            >
              취소
            </Button>
            <Button
              variant="contained"
              disabled={!targetUser || !targetSchedule || targetSchedule === '-1'}
              onClick={createPayment}
            >
              결제페이지 생성
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </>
  );
};

export default AddStudentModal;
