import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { addStudent } from '../functions';
import * as request from '../../../../../Common/Util/Request';
import Edit from '../../../../ReviewBest/Edit';
import { EditOutlined } from '@mui/icons-material';
import swal from 'sweetalert2';

const HandleNoteToOnCall = ({ attendanceBoardInfo, options, setAttendanceBoardInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const BtnEditNoteToOnCall = () => (
    <EditOutlined sx={{ fontSize: '1.25rem', display: 'inline-block', cursor: 'pointer' }} onClick={handleModalOpen} />
  );

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    setIsModalOpen(false);
  };

  const [link, setLink] = useState(attendanceBoardInfo.noteToOnCallLink || null);
  const [attendanceBoardId, setAttendanceBoardId] = useState(attendanceBoardInfo.id);

  // console.log(JSON.stringify(attendanceBoardInfo, null, 2));
  useEffect(() => {
    if (!isModalOpen) setLink(null);
  }, [isModalOpen]);

  useEffect(() => {
    if (attendanceBoardInfo?.id) {
      setAttendanceBoardId(attendanceBoardInfo.id);
      setLink(attendanceBoardInfo.noteToOnCallLink);
    }
  }, [attendanceBoardInfo]);

  const updateNoteToOnCall = useCallback(async () => {
    const response = await request.updateNoteToOnCall({ attendanceBoardId, link });
    const { success, data } = response;
    if (success) {
      setIsModalOpen(false);
      swal
        .fire({
          icon: 'success',
          title: '참고사항 링크가 등록되었습니다.',
          timer: 1500,
          showConfirmButton: false,
        })
        .then(() => {
          setAttendanceBoardInfo((prev) => ({ ...prev, noteToOnCallLink: link }));
        });
    } else {
      console.error('updateNoteToOnCall failed');
      swal.fire({
        icon: 'error',
        title: '참고사항 링크 등록에 실패했습니다.',
      });
    }
  }, [link, attendanceBoardId]);
  return (
    <>
      <BtnEditNoteToOnCall />
      <Fragment>
        <Dialog
          open={isModalOpen}
          onClose={handleModalClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              // formJson의 각 key에 대해 value가 Number인지 확인
              // Number라면 parsePositiveInt로 변환
              // 변환된 값을 formJson에 다시 할당
              for (const key in formJson) {
                if (!!+formJson[key]) {
                  formJson[key] = +formJson[key];
                }
              }
              // console.log(formJson);

              setIsModalOpen(false);
            },
          }}
          sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px' } }}
        >
          <DialogTitle>당직자 참고사항 관리</DialogTitle>
          <DialogContent>
            <DialogContentText>당직자 참고사항을 당직자가 확인할 수 있도록 링크를 입력해주세요.</DialogContentText>
            <TextField type="hidden" sx={{ display: 'none' }} name="attendanceBoardId" value={attendanceBoardId} />
            <TextField
              label="링크 URL"
              name="link"
              size="small"
              sx={{ marginTop: '1rem' }}
              fullWidth
              variant="outlined"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="작성해 둔 문서 링크를 입력해주세요."
              autoComplete={'off'}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleModalClose}
              sx={{
                backgroundColor: '#dedede',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#dedede',
                },
              }}
              disableRipple
              disableElevation
            >
              취소
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!link}
              sx={{
                backgroundColor: '#4da600',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#4da600',
                },
              }}
              onClick={updateNoteToOnCall}
            >
              저장
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </>
  );
};

export default HandleNoteToOnCall;
