export function getChanged(from, to) {
  const changed = {};
  for (let key in to) {
    if (to[key] !== from[key]) {
      changed[key] = to[key];
    }
  }
  return changed;
}

export function filterEmpty(obj) {
  const filtered = {};
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length > 0) {
        filtered[key] = obj[key];
      }
    } else {
      if (obj[key]) {
        filtered[key] = obj[key];
      }
    }
  }
  return filtered;
}
