import { useLocationState } from '../../../Common/Util/CustomHooks';
import React, { useCallback, useState } from 'react';
import * as request from '../../../Common/Util/Request';
import * as Button from '../../../Common/Component/Button';
import Table from '../../../Common/Component/Table';
import { FormattedMessage } from 'react-intl';
import './index.scss';

function ReviewBestList(props) {
  const [locationState, setLocationState] = useLocationState({
    searchType: 'title',
    searchText: '',
    filters: [],
  });
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        page: pageIndex + 1,
        limit: pageSize,
        filtered: JSON.stringify(locationState.filters),
      };
      request.lectures('best_review', params).then(({ classes }) => {
        setLectures(classes.rows);
        setTotal(classes.count);
        setIsLoading(false);
      });
    },
    [locationState.filters],
  );

  return (
    <div className="review-best-list">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <section>
            <div className="review-best-list__search">
              <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                <option value="title">강의명</option>
                <option value="name">강사명</option>
              </select>
              <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
            </div>
          </section>
          <div className="single-list__total">총 강의수 {total}</div>
        </>
      )}
      <Table
        data={lectures}
        total={total}
        pageSize={20}
        columns={
          isLoading
            ? []
            : [
                { Header: '강의ID', accessor: 'id' },
                { Header: '강의명', accessor: 'title' },
                {
                  Header: '강사명',
                  accessor: 'Class_Tutors',
                  Cell: ({ value: Class_Tutors }) =>
                    Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(', ') || '-',
                },
                {
                  Header: '판매가능',
                  accessor: 'is_selling',
                  Cell: ({ value: is_selling }) => (is_selling ? '판매' : '불가'),
                },
                {
                  Header: '구분',
                  accessor: 'listen_type',
                  Cell: ({ value: listen_type }) => (
                    <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />
                  ),
                },
                {
                  Header: '수강자',
                  accessor: (row) => (
                    <span className="user-class-count">
                      {(row.userClassCount || 0) + (row.userClassReadyCount || 0)}명
                    </span>
                  ),
                },
                {
                  Header: '후기 개수',
                  accessor: (row) => <span className="user-review-count">{row.reviewCnt || 0}개</span>,
                },
                {
                  Header: '평점',
                  accessor: (row) => <span className="user-class-rete">{row.rate || 0}</span>,
                },
                {
                  Header: '베스트 후기 수정',
                  accessor: (row) => (
                    <button className="btn-detail-view" onClick={() => window.open(`/review/best/edit/${row.id}`)}>
                      수정
                    </button>
                  ),
                },
              ]
        }
        fetchData={fetchData}
      />
    </div>
  );
}

export default ReviewBestList;
