import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, MenuItem, Select } from '@mui/material';
import Swal from 'sweetalert2';
import SortIcon from './SortIcons';
import { Input } from '../../../Common/Component/Field';
import ReactLoading from 'react-loading';
import { Loading, ReviewRequest } from './styled';
import * as request from '../../../Common/Util/Request';
import BooleanIcon from './BooleanIcon';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ko';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ReviewRequestPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMessageLogs, setHasMessageLogs] = useState('');
  const [wroteReview, setWroteReview] = useState('');
  const [allowedMarketing, setAllowedMarketing] = useState('');
  const [searchType, setSearchType] = useState('classTitle');
  const [searchKeyword, setSearchKeyword] = useState('');
  // const [checkAll, setCheckAll] = useState(false);
  const [requestTargetIds, setRequestTargetIds] = useState([]);
  const [sort, setSort] = useState({
    name: 'messageCount',
    order: 'asc',
  });
  const [showDescription, setShowDescription] = useState(false);

  const [needFetch, setNeedFetch] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(6, 'months').startOf('month'));
  const [toDate, setToDate] = useState(moment());

  const needRequestIds = useMemo(() => {
    return list.filter((item) => !item.reviewCount).map((item) => item.userClassId);
  }, [list]);

  const requestSearch = useCallback(async () => {
    const params = {
      sort: sort.name,
      order: sort.order,
      fromDate: fromDate.format('YYYYMMDD'),
      toDate: toDate.format('YYYYMMDD'),
    };
    if (hasMessageLogs !== '') {
      params.hasMessageLogs = hasMessageLogs;
    }
    if (wroteReview !== '') {
      params.wroteReview = wroteReview;
    }
    if (allowedMarketing !== '') {
      params.allowedMarketing = allowedMarketing;
    }
    if (searchKeyword) {
      params.searchType = searchType;
      params.searchKeyword = searchType === 'userPhone' ? searchKeyword.replaceAll('-', '') : searchKeyword;
    }
    return await request.getTargetUsers(params);
  }, [
    hasMessageLogs,
    wroteReview,
    sort.name,
    sort.order,
    searchType,
    searchKeyword,
    allowedMarketing,
    fromDate,
    toDate,
  ]);

  const toggleDescription = useCallback(() => {
    setShowDescription((prevShowDescription) => !prevShowDescription);
  }, []);

  const handleRequest = useCallback(
    async (e) => {
      e.preventDefault();

      // 리뷰 작성 요청을 보낼 것인지 확인
      const { isConfirmed } = await Swal.fire({
        title: '리뷰 요청 확인',
        text: '리뷰 요청을 보내시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '리뷰 요청 보내기',
        cancelButtonText: '취소',
      });

      if (isConfirmed) {
        const body = {
          userClassIds: requestTargetIds,
        };

        const response = await request.sendReviewRequest(body);

        if (!response.success) {
          Swal.fire({
            title: '리뷰 요청 실패',
            text: response.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            title: '리뷰 요청 성공',
            text: response.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            setRequestTargetIds([]);
            requestSearch();
          });
        }
      }
    },
    [requestTargetIds, requestSearch],
  );

  const handleCheckboxChange = useCallback(
    (event, item) => {
      event.preventDefault();
      if (item.reviewCount > 0) return;
      if (requestTargetIds.includes(item.userClassId)) {
        setRequestTargetIds((prevState) => prevState.filter((id) => id !== item.userClassId));
      } else {
        setRequestTargetIds((prevState) => [...prevState, item.userClassId]);
      }
    },
    [requestTargetIds],
  );

  useEffect(() => {
    // needRequestIds에 없는 값이 requestTargetIds에 있으면 제거
    setRequestTargetIds(needRequestIds.filter((id) => requestTargetIds.includes(id)));
    // 변경된 needRequestIds와 requestTargetIds를 비교하여 checkAll을 수정
    // setCheckAll(
    //   needRequestIds.length === requestTargetIds.length && needRequestIds.length !== 0 && requestTargetIds.length !== 0,
    // );
  }, [needRequestIds]);

  const indeterminate = useMemo(() => {
    return requestTargetIds.length > 0 && requestTargetIds.length < needRequestIds.length;
  }, [requestTargetIds, needRequestIds]);

  const checkAll = useMemo(() => {
    return (
      needRequestIds.every((item) => requestTargetIds.includes(item)) &&
      needRequestIds.length === requestTargetIds.length &&
      needRequestIds.length !== 0 &&
      requestTargetIds.length !== 0
    );
  }, [needRequestIds, requestTargetIds]);

  const search = () => {
    setIsLoaded(false);
    requestSearch()
      .then((response) => {
        if (!response.success) {
          Swal.fire({
            title: '리뷰 요청 페이지 목록 조회 실패',
            text: response.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
          });
          setList([]);
          setTotal(0);
        } else {
          setList(response.list);
          setTotal(response.total);
        }
      })
      .then(() => {
        setIsLoaded(true);
        setNeedFetch(false);
      });
  };

  useEffect(() => {
    needFetch && search();
  }, [needFetch]);

  useEffect(() => {
    // fromDate가 toDate보다 크면 toDate를 fromDate와 같은 값으로 변경함.
    if (fromDate.isAfter(toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate]);

  return (
    <ReviewRequest>
      <div className={`description`}>
        <div className={`description-header`}>
          <strong>사용 설명</strong>
          <button type="button" className={`btn-toggle-description`} onClick={toggleDescription}>
            <span>{showDescription ? '접기' : '펼쳐보기'}</span>
          </button>
        </div>
        <div className={`description-body`} style={{ height: showDescription ? '24rem' : '0' }}>
          <div>
            필터링
            <ul>
              <li>
                수강 신청 기간 : 수강생이 수강 신청한 기간을 기준으로 필터링합니다. (기본값: from-6개월 전의 1일,
                to-오늘)
              </li>
              <li>메시지 전송 이력 : 전송 이력이 확인되는 유저와 확인되지 않는 유저를 필터링합니다. (기본: 전체)</li>
              <li>리뷰 작성 여부 : 리뷰를 작성한 유저와 작성하지 않은 유저를 필터링합니다. (기본: 전체)</li>
              <li>
                마케팅 수신 활용 동의 : 마케팅 수신 활용 동의한 유저와 하지 않은 유저를 필터링합니다. (기본: 전체)
              </li>
              <li>검색: 강의명, 이름, 전화번호를 검색할 수 있습니다.</li>
            </ul>
          </div>
          <div>
            정렬
            <ul>
              <li>각 항목을 클릭하면 정렬됩니다.</li>
              <li>한 번에 하나의 항목으로만 정렬할 수 있습니다.</li>
              <li>첫번째 클릭은 오름차순 정렬, 두번째 클릭부터는 이전 정렬의 반대로 정렬됩니다.</li>
              <li>기본 정렬은 메시지 전송 이력이 없는 회원부터 조회됩니다.</li>
              <li>
                <SortIcon sort={{ name: 'classTitle', order: 'asc' }} setSort={() => {}} name="classTitle" /> : 오름차순
                정렬 (작은 값부터 큰 값 ex: 1, 2, 3, 4...)
              </li>
              <li>
                <SortIcon sort={{ name: 'classTitle', order: 'desc' }} setSort={() => {}} name="classTitle" /> :
                내림차순 정렬 (큰 값부터 작은 값 ex: ...4, 3, 2, 1)
              </li>
            </ul>
          </div>
          <div>
            리뷰 요청
            <ul>
              <li>보내고자 하는 유저를 선택 후, 아래의 "리뷰 요청" 버튼을 클릭하면 됩니다.</li>
              <li>리뷰 요청 이력이 확인되는 유저에게는 다시 보낼 수 없습니다.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`need-request-header`}>
        <div className={`filters`}>
          <div className={`filter-date-wrap`}>
            <label htmlFor={`dateRange`}>수강신청기간</label>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ko">
              <DatePicker
                className={'date-picker'}
                label="From"
                value={fromDate}
                onChange={(date) => setFromDate(date)}
                slotProps={{ textField: { size: 'small' } }}
              />
              <DatePicker
                className={'date-picker'}
                label="To"
                minDate={fromDate}
                value={toDate}
                onChange={(date) => setToDate(date)}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </div>
          <div className={`filter-message-count`}>
            <label htmlFor={`hasMessageLogs`}>메시지 전송 이력</label>
            <Select
              id={`hasMessageLogs`}
              value={hasMessageLogs}
              onChange={(e) => setHasMessageLogs(e.target.value)}
              size="small"
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              <MenuItem value={''} sx={{ fontSize: '0.75rem' }}>
                전체
              </MenuItem>
              <MenuItem value={false} sx={{ fontSize: '0.75rem' }}>
                없음
              </MenuItem>
              <MenuItem value={true} sx={{ fontSize: '0.75rem' }}>
                있음
              </MenuItem>
            </Select>
          </div>
          <div className={`filter-wrote-review`}>
            <label htmlFor={`hasMessageLogs`}>리뷰 작성 여부</label>
            <Select
              id={`wroteReview`}
              value={wroteReview}
              onChange={(e) => setWroteReview(e.target.value)}
              size="small"
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              <MenuItem value={''} sx={{ fontSize: '0.75rem' }}>
                전체
              </MenuItem>
              <MenuItem value={false} sx={{ fontSize: '0.75rem' }}>
                안함
              </MenuItem>
              <MenuItem value={true} sx={{ fontSize: '0.75rem' }}>
                작성됨
              </MenuItem>
            </Select>
          </div>
          <div className={`filter-allowed-marketing`}>
            <label htmlFor={`allowedMarketing`}>마케팅 수신 활용 동의</label>
            <Select
              id={`allowedMarketing`}
              value={allowedMarketing}
              onChange={(e) => setAllowedMarketing(e.target.value)}
              size="small"
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              <MenuItem value={''} sx={{ fontSize: '0.75rem' }}>
                전체
              </MenuItem>
              <MenuItem value={false} sx={{ fontSize: '0.75rem' }}>
                거부
              </MenuItem>
              <MenuItem value={true} sx={{ fontSize: '0.75rem' }}>
                허용
              </MenuItem>
            </Select>
          </div>
        </div>
        <div className={`search-wrap`}>
          <Select
            id={`searchType`}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            size="small"
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="classTitle" sx={{ fontSize: '0.75rem' }}>
              강의명
            </MenuItem>
            <MenuItem value="userName" sx={{ fontSize: '0.75rem' }}>
              이름
            </MenuItem>
            <MenuItem value="userPhone" sx={{ fontSize: '0.75rem' }}>
              전화번호
            </MenuItem>
          </Select>
          <Input
            type="text"
            placeholder="검색어를 입력하세요."
            value={searchKeyword}
            onChange={(value) => setSearchKeyword(value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                search();
              }
            }}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setNeedFetch(true);
            }}
          >
            검색
          </button>
        </div>
      </div>
      <div className={`need-request-content`}>
        <div className={`need-request-list`}>
          {!isLoaded && needFetch ? (
            <Loading>
              <ReactLoading type="cylon" color="gray" />
            </Loading>
          ) : !isLoaded && !needFetch ? (
            <div className={`empty`}>검색 조건을 선택하고 검색 버튼을 눌러주세요.</div>
          ) : list.length === 0 ? (
            <div className={`empty`}>리뷰 요청 대상이 없습니다.</div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: '0.875rem',
                  marginBottom: '-0.5rem',
                }}
              >
                전체: {list.length}
              </div>
              <div className={`table-wrap`}>
                <table>
                  <colgroup>
                    <col width="5%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="35%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <div>
                          <Checkbox
                            checked={checkAll}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (!checkAll) {
                                setRequestTargetIds(needRequestIds);
                              } else {
                                setRequestTargetIds([]);
                              }
                            }}
                            disableRipple
                            indeterminate={indeterminate}
                            sx={{
                              '&.Mui-checked': {
                                color: '#F24462',
                              },
                              '&.MuiCheckbox-indeterminate': {
                                color: '#F24462',
                              },
                            }}
                          />
                        </div>
                      </th>
                      <th>
                        <div>
                          이름
                          <SortIcon name="userName" sort={sort} setSort={setSort} />
                        </div>
                      </th>
                      <th>
                        <div>
                          전화번호
                          <SortIcon name="userPhone" sort={sort} setSort={setSort} />
                        </div>
                      </th>
                      <th>
                        <div>
                          강의명
                          <SortIcon name="classTitle" sort={sort} setSort={setSort} />
                        </div>
                      </th>
                      <th>
                        <div>
                          수강률
                          <SortIcon name="completionRate" sort={sort} setSort={setSort} />
                        </div>
                      </th>
                      <th>
                        <div>
                          메시지 전송 횟수
                          <SortIcon name="messageCount" sort={sort} setSort={setSort} />
                        </div>
                      </th>
                      <th>리뷰작성여부</th>
                      <th>마케팅 수신 활용 동의</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <tr
                        key={item.userClassId}
                        onClick={(event) => handleCheckboxChange(event, item)}
                        className={item.reviewCount > 0 ? 'wrote-review' : ''}
                      >
                        <td>
                          <Checkbox
                            checked={requestTargetIds.includes(item.userClassId)}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleCheckboxChange(event, item);
                            }}
                            disableRipple
                            disabled={item.reviewCount > 0}
                            sx={{
                              padding: '0',
                              '&.Mui-checked': {
                                color: '#F24462',
                              },
                            }}
                          />
                        </td>
                        <td>{item.userName}</td>
                        <td>{item.userPhone.replace(/^(\d{4})(\d{4})(\d{4})$/, '010-$2-$3')}</td>
                        <td>{item.classTitle}</td>
                        <td>{item.completionRate}%</td>
                        <td>{item.messageCount}</td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '0.5rem',
                            }}
                          >
                            <BooleanIcon value={item.reviewCount > 0} />
                            {false && item.reviewCount > 0 && (
                              <span className={`review-data`} style={{ fontSize: '0.75rem', color: 'gray' }}>
                                view
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <BooleanIcon value={item.userAllowedMarketing} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={`need-request-actions`}>
                <button className="request" disabled={!requestTargetIds.length} onClick={handleRequest}>
                  리뷰 요청
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </ReviewRequest>
  );
};

export default ReviewRequestPage;
