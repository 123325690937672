import { useLocationState } from '../../../../Common/Util/CustomHooks';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as request from '../../../../Common/Util/Request';
import moment from 'moment';
import DatePicker from '../../../../Common/Component/DatePicker';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import Swal from 'sweetalert2';
import { DragDropContext } from 'react-beautiful-dnd';
import './index.scss';
import { SaveSharp } from '@mui/icons-material';

function EventList() {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchType: 'title',
    searchText: '',
    filters: [],
  });
  const { startDate, endDate, filters } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [total, setTotal] = useState(0);
  const [topEvents, setTopEvents] = useState([]);
  const [events, setEvents] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingNormal, setIsDraggingNormal] = useState(false);

  const [eventGnbMenu, setEventGnbMenu] = useState({});
  const [data, setData] = useState({});

  const TOP_EVENTS_MAX = 5;

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText: searchType === 'phone' ? searchText.replace(/^0/, '82') : searchText,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  /** GNB 이벤트 메뉴 이름 설정 */
  const handleKeyDownGnbMenu = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onClickSaveGnbMenu(eventGnbMenu);
      }
    },
    [eventGnbMenu],
  );
  const isMenuNameModified = useMemo(() => {
    return data?.eventGnb?.menu_name !== eventGnbMenu?.menu_name;
  }, [data, eventGnbMenu]);
  const onClickSaveGnbMenu = useCallback(
    (eventGnb) => {
      // console.log(data?.eventGnb?.menu_name !== eventGnbMenu.menu_name);
      if (isMenuNameModified) {
        // console.log(1);
        const isMenuNameEmpty = !eventGnb.menu_name || eventGnb.menu_name === '';
        Swal.fire({
          icon: 'warning',
          title: isMenuNameEmpty ? 'GNB 메뉴명 미입력 알림' : 'GNB 메뉴명 변경 알림',
          html: isMenuNameEmpty
            ? '메뉴명 미입력 시 GNB에 해당 메뉴가 표시되지 않습니다.<br>저장하시겠습니까?'
            : '메뉴명 입력 시 GNB에 해당 메뉴가 표시됩니다.<br>저장하시겠습니까?',
          showCancelButton: true,
          cancelButtonText: '취소',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            request
              .updateGnbMenu(eventGnb.id, { name: eventGnb.menu_name })
              .then(({ success }) => {
                if (success) {
                  Swal.fire({
                    icon: 'success',
                    title: '저장되었습니다.',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                  });
                }
              })
              .then(() => {
                setData((prev) => ({
                  ...prev,
                  eventGnb: {
                    ...prev.eventGnb,
                    menu_name: eventGnb.menu_name,
                  },
                }));
              });
          }
        });
      }
    },
    [isMenuNameModified],
  );

  /** 테이블 설정 */
  const loadPosts = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        filtered: JSON.stringify(filters),
        groupCode: 'service',
        boardCode: 'event',
      };

      if (startDate) {
        params.start_date = dayjs(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = dayjs(endDate).format('YYYY-MM-DD');
      }

      return request.getPosts(params).then(({ data }) => data);
    },
    [startDate, endDate, filters],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadPosts({ page: pageIndex + 1, limit: pageSize }).then((data) => {
        // console.log(data);
        setData(data);
        setEventGnbMenu(data.eventGnb);
        setTopEvents(data.topPosts);
        setEvents(data.posts);
        setTotal(data.count);
      });
    },
    [loadPosts],
  );

  const columns = useMemo(
    () => [
      { Header: '게시글 번호', accessor: 'id' },
      { Header: '제목', accessor: 'title' },
      { Header: '공개 여부', accessor: 'isShow', Cell: ({ value: isShow }) => (isShow ? '공개' : '비공개') },
      {
        Header: '공개 범위',
        accessor: 'showTarget',
        Cell: ({ value: showTarget }) =>
          showTarget === 0 ? '전체 공개' : showTarget === 1 ? '회원 공개' : '어드민 공개',
      },
      { Header: '작성자 닉네임', accessor: 'FK_user.nickname' },
      {
        Header: '첨부파일 개수',
        accessor: 'fileCount',
      },
      {
        Header: '조회수',
        accessor: 'viewCount',
      },
      {
        Header: '작성일',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => moment(createdAt).format('YYYY-MM-DD'),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/event/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [],
  );

  const onChangeTopEvents = async (rows, event) => {
    setTopEvents(rows);
    const method = topEvents.length < rows.length ? 'create' : topEvents.length > rows.length ? 'delete' : 'update';
    let order;

    try {
      switch (method) {
        case 'create':
          order = rows.findIndex((row) => row.id === event.id) + 1;
          await request.createTopNotice({ notices: [{ board_code: 'event', FK_post_id: event.id, order }] });
          break;

        case 'delete':
          order = topEvents.findIndex((topPost) => topPost.id === event.id) + 1;
          const params = { board_code: 'event', notice_id: event.id, nextOrder: order + 1 };
          await request.deleteTopNotice(params);
          break;

        case 'update':
          const body = rows.map((row, index) => ({ board_code: 'event', FK_post_id: row.id, order: index + 1 }));
          await request.updateTopNotice({ notices: body });
          break;

        default:
          break;
      }
    } catch (err) {
      const errorMessage = {
        create: '노출 고정에 실패했습니다.',
        delete: '노출 고정 해제에 실패했습니다.',
        update: '노출 고정 순서 변경에 실패했습니다.',
      };

      console.error(err);
      Swal.fire(errorMessage[method], '', 'error').then(() => {
        window.location.reload();
      });
    }
  };

  const onChangeNormalEvents = (rows) => {
    setEvents(rows);
  };

  const handleDragStart = useCallback(
    (start) => {
      setIsDragging(true);

      if (start.source.droppableId === 'normalNotices') {
        setIsDraggingNormal(true);
      }
    },
    [events, topEvents],
  );

  const handleDragEnd = useCallback(
    (result) => {
      setIsDragging(false);
      setIsDraggingNormal(false);

      const { source, destination } = result;

      // 아이템이 리스트 밖으로 드래그된 경우
      if (!destination) return;

      const destId = destination.droppableId;
      const sourceId = source.droppableId;
      const destRows = destId === 'topEvents' ? topEvents : events;
      const sourceRows = destId === 'topEvents' ? events : topEvents;

      // 다른 리스트로 이동한 경우
      const copiedDestRows = [...destRows];
      if (sourceId !== destId) {
        const copiedSourceRows = [...sourceRows];
        const [removed] = copiedSourceRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        if (destId === 'topEvents') {
          onChangeTopEvents(copiedDestRows, removed).then(() => {
            onChangeNormalEvents(copiedSourceRows);
          });
        } else {
          onChangeTopEvents(copiedSourceRows, removed).then(() => {
            onChangeNormalEvents(copiedDestRows);
          });
        }
      } else {
        // 같은 리스트 내에서 이동한 경우
        if (destId !== 'topEvents') return;

        const [removed] = copiedDestRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        onChangeTopEvents(copiedDestRows, removed).then(() => {});
      }
    },
    [topEvents, events],
  );

  return (
    <div className="post-list">
      <section>
        <div className="post-list__date">
          기간
          <DatePicker
            selected={startDate}
            maxDate={endDate}
            onChange={(date) => setLocationState({ startDate: date })}
          />
          ~
          <DatePicker selected={endDate} minDate={startDate} onChange={(date) => setLocationState({ endDate: date })} />
        </div>
        <div className="post-list__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="id">게시글 아이디</option>
            <option value="title">게시글 제목</option>
            <option value="FK_user_id">작성자 아이디</option>
            <option value="username">작성자 이름</option>
            <option value="nickname">작성자 닉네임</option>
            <option value="email">작성자 이메일</option>
            <option value="phone">작성자 핸드폰</option>
          </select>
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
        </div>
        <div className="making-btn">
          <Button.Positive onClick={() => window.open(`/event/new`)}>이벤트 게시글 작성</Button.Positive>
        </div>
      </section>
      <div className="gnb-name-container">
        <label htmlFor="gnb-name">GNB 노출 메뉴명 </label>
        <input
          type="text"
          id="gnb-name"
          value={eventGnbMenu?.menu_name || ''}
          onChange={(e) =>
            setEventGnbMenu((prev) => ({
              ...prev,
              menu_name: e.target.value,
            }))
          }
          onKeyDown={handleKeyDownGnbMenu}
        />
        <button onClick={() => onClickSaveGnbMenu(eventGnbMenu)} disabled={!isMenuNameModified}>
          <span>저장</span> <SaveSharp fontSize="small" />
        </button>
      </div>
      <div className="post-list-top-container">
        <div className="post-list__total">총 이벤트 게시글수 {total}</div>
      </div>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div className={`top-event-list-container ${isDragging ? 'events-blue' : 'not-dragging'}`}>
          <div className="top-event-list-top-container">
            <div className="top-event-list__total">
              🎁 메인 노출 이벤트 게시글 수 (
              <span
                style={{
                  color: topEvents.length >= TOP_EVENTS_MAX ? 'red' : 'black',
                }}
              >
                {topEvents.length}
              </span>{' '}
              / {TOP_EVENTS_MAX}) <span style={{ color: 'red' }}>* 정렬된 순서대로 노출됩니다.</span>
            </div>
            <span className="top-event-list__info">* 게시글을 드래그하여 옮길 수 있습니다.</span>
          </div>
          <Table
            data={topEvents}
            columns={columns}
            total={topEvents.length}
            pageSize={TOP_EVENTS_MAX}
            fetchData={() => {}}
            droppableId="topEvents"
          />
        </div>

        <div
          className={`normal-event-list-container ${
            isDragging ? (isDraggingNormal ? 'events-red' : 'events-blue') : 'not-dragging'
          }`}
        >
          <div className="normal-event-list__total">🌒 이벤트 게시글수 {total - topEvents.length}</div>
          <Table
            data={events}
            columns={columns}
            total={total}
            pageSize={20}
            fetchData={fetchData}
            pauseDragDrop={topEvents.length >= TOP_EVENTS_MAX}
            droppableId="normalEvents"
          />
        </div>
      </DragDropContext>
    </div>
  );
}

export default EventList;
