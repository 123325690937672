import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import './index.scss';

function Breadcrumb(props) {
  const location = useLocation();
  const intl = useIntl();

  const pathes = location.pathname.split('/');
  pathes.shift();

  let breadcrumb = '';
  // 만약 페이지 경로가 /lecture/1111/attendance 과 같은 포맷이라면
  // breadcrumb = '강의 > 강의 일정' 과 같은 형태로 출력
  if (pathes[0] === 'lecture' && !isNaN(pathes[1]) && pathes[2] === 'attendance') {
    breadcrumb = '수강관리 > 대면 강의 출결 관리';
  } else if (pathes.includes('survey') && pathes.includes('statistics')) {
    // 설문 통계
    breadcrumb =
      intl.formatMessage({ id: 'ID_TITLE_SURVEY' }) + ' > ' + intl.formatMessage({ id: 'ID_TITLE_SURVEY_STATISTICS' });
  } else {
    const menuIds =
      pathes.length === 4 && pathes.includes('schedule')
        ? ['ID_TITLE_LECTURE', 'ID_TITLE_LECTURE_OFFLINE']
        : pathes
            .filter((path) => isNaN(path))
            .map(
              (_, index) =>
                'ID_TITLE_' +
                pathes
                  .slice(0, index + 1)
                  .join('_')
                  .toUpperCase(),
            )
            .filter(
              (path) =>
                path !== 'ID_TITLE_MAIN_BANNER' &&
                path !== 'ID_TITLE_LECTURE_GIVEN_NEW' &&
                path !== 'ID_TITLE_USER_FOREIGN',
            );

    if (pathes[0] === 'organization' && !isNaN(pathes[1])) {
      menuIds.push('ID_TITLE_ORGANIZATION_EDIT');
      menuIds.push(+pathes[1]);
    }

    breadcrumb = menuIds.map((menuId) => (!isNaN(menuId) ? menuId : intl.formatMessage({ id: menuId }))).join(' > ');
  }

  return <div className="breadcrumb">{breadcrumb}</div>;
}

export default Breadcrumb;
