import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as adminActions from '../Store/Reducer/admin';
import * as request from './Request';

function Login(props) {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ admin }) => !!admin.id);

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('key');

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      history.replace(location.pathname);
    }
  }, [history, location, token]);

  useEffect(() => {
    request.login().then(({ user }) => {
      if (user.role === 'administrator') {
        dispatch(adminActions.set(user));
      } else {
        alert('관리자 계정으로 로그인해주세요');
      }
    });
  }, [dispatch, token]);

  return loggedIn ? props.children : '';
}

export default Login;
