import React from 'react';

export default function useCheckbox(hooks) {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
      ),
      Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
    },
    ...columns,
  ]);
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={resolvedRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...rest}
    />
  );
});
