import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import dayjs from 'dayjs';
import * as request from '../../../../Common/Util/Request';
import * as Button from '../../../../Common/Component/Button';
import * as Field from '../../../../Common/Component/Field';
import { URL } from '../../../../Common/Constant';

import './index.scss';

import ArtistUpsert from '../../Artists/Upsert';
import AgencyUpsert from '../../Agencies/Upsert';
import { useHistory, useLocation, useParams } from 'react-router-dom';

function Upsert() {
  const history = useHistory();
  const SwalWithReactContent = withReactContent(Swal);
  const { pathname, state } = useLocation();
  const [record, setRecord] = useState(state?.record);
  const localStorageRecord = JSON.parse(localStorage.getItem('RecordLocalData'));

  const action = useMemo(() => {
    if (pathname.includes('new')) return 'new';
    if (pathname.includes('edit')) return 'edit';
    return 'view';
  }, [pathname]);

  //const [stateId, setStateId] = useState(record?.id || '');
  const params = useParams();
  const recordId = useMemo(() => params?.id, [params]);
  const [title, setTitle] = useState(record?.title || '');
  const [image, setImage] = useState(record?.thumbnail || null);

  const [status, setStatus] = useState(record?.status || 1);
  const [details, setDetails] = useState(record?.details || null);
  const [publishedDate, setPubDate] = useState(dayjs(record?.publishedDate).toDate());
  const [agencies, setAgencies] = useState(record?.Record_Agencies?.map((item) => item.FK_Agency.id) || []);
  const [artists, setArtists] = useState(record?.Record_Artists?.map((item) => item.FK_Artist.id) || []);
  const [RecordLocalData, setRecordLocalData] = useState(localStorageRecord || null);
  const [isBlocked, setIsBlocked] = useState(Boolean(recordId));

  const onChangeTitle = (value) => {
    setTitle(value);
    !record && setRecordLocalData((prev) => ({ ...prev, title: value }));
  };
  const onChangeImage = (value) => {
    setImage(value);
  };

  const onChangeStatus = (value) => {
    setStatus(+value);
    !record && setRecordLocalData((prev) => ({ ...prev, status: value }));
  };
  const onChangeDetails = (value) => {
    if (value !== '<p><br></p>') {
      setDetails(value);
      !record && setRecordLocalData((prev) => ({ ...prev, detail: value }));
    }
  };
  const onChangePubDate = (value) => {
    setPubDate(value);
    !record && setRecordLocalData((prev) => ({ ...prev, pubDate: value }));
  };
  const onChangeAgencies = (value) => {
    if (value.includes(-1)) {
      SwalWithReactContent.fire({
        title: '기획사 추가',
        html: <AgencyUpsert modalClose={() => Swal.close()} />,
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    }
    setAgencies(value.filter((item) => item !== -1));
    !record &&
      setRecordLocalData((prev) => ({
        ...prev,
        agencies: value.filter((item) => item !== -1),
      }));
  };
  const onChangeArtists = (value) => {
    if (value.includes(-1)) {
      SwalWithReactContent.fire({
        title: '아티스트 추가',
        html: <ArtistUpsert modalClose={() => Swal.close()} />,
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    }
    setArtists(value.filter((item) => item !== -1));
    !record &&
      setRecordLocalData((prev) => ({
        ...prev,
        artists: value.filter((item) => item !== -1),
      }));
  };

  useEffect(() => {
    !record &&
      !!RecordLocalData &&
      !isBlocked &&
      Swal.fire({
        title: '기존에 작성하던 내용이 있습니다.\n불러오겠습니까?',
        text: '취소하면 저장된 내용이 사라집니다.',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setTitle(RecordLocalData.title || '');
          setImage(RecordLocalData.image || null);
          setStatus(RecordLocalData.status || 1);
          setDetails(RecordLocalData.detail || null);
          setPubDate(RecordLocalData.pubDate || dayjs().toDate());
          setAgencies(RecordLocalData.agencies || []);
          setArtists(RecordLocalData.artists || []);
        } else {
          localStorage.removeItem('RecordLocalData');
        }
      });
    recordId &&
      request.getRecord(recordId).then((data) => {
        setRecord(data);
        setTitle(data?.title || '');
        setImage(data?.thumbnail || null);
        setStatus(data?.status || 1);
        setDetails(data?.details || null);
        setPubDate(dayjs(data?.publishedDate).toDate() || dayjs().toDate());
        setAgencies(data?.Record_Agencies?.map((item) => item.FK_Agency.id) || []);
        setArtists(data?.Record_Artists?.map((item) => item.FK_Artist.id) || []);
      });
  }, [recordId]);

  useEffect(() => {
    if (!record && !!RecordLocalData) {
      localStorage.setItem('RecordLocalData', JSON.stringify(RecordLocalData));
      setIsBlocked(true);
    }
  }, [RecordLocalData, record]);

  if (!recordId && !record) {
    // create
  } else if (action === 'edit') {
    if (!record) {
      // ID는 있는데 state가 없는 경우,
    }
  }

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  const onClickDelete = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      text: '삭제한 데이터는 복구할 수 없습니다.',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteRecords([recordId])
          .then(({ success, deletedRecordCount }) => {
            if (success) {
              window.exitFlag = true;
              window.opener.location.reload();
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">${deletedRecordCount}개 앨범이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            } else {
              throw new Error('삭제에 실패하였습니다.');
            }
          })
          .catch(() => {
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
              toast: true,
              position: 'center',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'error',
              type: 'error',
            });
          }),
    });
  }, [recordId]);

  const onClickSave = useCallback(() => {
    const record = {
      thumbnail: image,
      title,
      id: recordId,
      status,
      details,
      publishedDate,
      agencies,
      artists,
    };
    const valid = ['artists', 'publishedDate', 'status', 'title', 'agencies'].every(
      (field) =>
        record[field] && Boolean(record[field]) && (Array.isArray(record[field]) ? record[field].length > 0 : true),
    );

    if (!valid) {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">필수 항목을 입력해주세요.</div>`,
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'error',
      });
      return;
    }
    const actionType = Boolean(recordId) ? 'update' : 'create';

    const params = {
      ...record,
      title: record.title.trim(),
      details:
        !!record && !!record.details
          ? record.details
              .trim()
              .replace(/\n/g, '<br>')
              .replace(/\r/g, '')
              .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
              .replace(/\s/g, '&nbsp;')
          : ' ',
      id: recordId,
    };
    request
      .upsertRecord(actionType, params)
      .then(() => {
        window.opener.location.reload();
        window.opener.localStorage.removeItem('RecordLocalData');
        if (actionType === 'update') {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 수정되었습니다.</div>`,
            position: 'center',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '메뉴로 돌아가기',
            cancelButtonText: '음반 상세 페이지로 가기',
            icon: 'success',
          }).then(({ isConfirmed }) => {
            window.exitFlag = true;
            if (isConfirmed) {
              window.self.close();
            } else {
              window.location.href = `${URL.HOST}/albumcredit-db/${recordId}`;
            }
          });
        } else {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 등록되었습니다.</div>`,
            position: 'center',
            confirmButtonText: '메뉴로 돌아가기',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.close();
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setTitle(params.title);
        setDetails(params.details);
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">${error.response.data.detail}</div>`,
          toast: true,
          position: 'center',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          icon: 'error',
        });
      });
  }, [recordId, image, title, status, details, publishedDate, agencies, artists]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">앨범을 저장하지 않고 나가시겠습니까?</div>`,
      toast: true,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  }, []);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isBlocked && action === 'POP') {
        const goToBack = window.confirm('앨범을 저장하지 않고 나가시겠습니까?');
        if (goToBack) {
          unblock();
          return true;
        } else {
          window.history.pushState(
            {},
            '',
            Boolean(recordId) ? `/contents/records/edit/${recordId}` : '/contents/records/new',
          );
          return false;
        }
      }
    });
    return () => unblock();
  }, [history, recordId, isBlocked]);

  return (
    <div className="mt-10">
      <div className={`mb-3 ${Boolean(recordId) ? '' : 'hidden'}`}>
        <Field.Input title="앨범 ID" value={recordId} disabled />
      </div>
      <Field.Radio
        title="상태"
        name="status"
        value={status}
        onChange={onChangeStatus}
        options={[
          { value: 1, label: '대기' },
          { value: 2, label: '공개' },
          { value: 3, label: '비공개' },
        ]}
        required
      />
      <Field.Input title="앨범명" value={title} onChange={onChangeTitle} required />
      <Field.Artists title="아티스트명" value={artists} onChange={onChangeArtists} required />
      <Field.Date title="발매일자" value={publishedDate} onChange={onChangePubDate} required />
      <Field.Agencies title="기획사" value={agencies} onChange={onChangeAgencies} required />
      <Field.Image title="대표 썸네일" value={image} onChange={onChangeImage} />
      <Field.RichText title="크레딧 상세 정보" value={details} onChange={onChangeDetails} />
      <div className="buttons">
        {Boolean(recordId) && <Button.Negative onClick={onClickDelete}>삭제</Button.Negative>}
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
  /*
   const [isValid, setValid] = useState(true);

   */
}

export default Upsert;
