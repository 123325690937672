import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as request from '../../../Common/Util/Request';
import * as Button from '../../../Common/Component/Button';
import Table from '../../../Common/Component/Table';
import './index.scss';

function BannerList() {
  const history = useHistory();
  const match = useRouteMatch();
  const { type } = match.params;

  const columns = useMemo(
    () => [
      { Header: '순서', accessor: 'order' },
      { Header: '유형', accessor: 'type_name' },
      { Header: '제목', accessor: 'title' },
    ],
    [],
  );

  const [banners, setBanners] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedBannerIds, setSelectedBannerIds] = useState([]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request.banners(type, { page: pageIndex + 1, limit: pageSize }).then(({ banners }) => {
        setBanners(banners.rows);
        setTotal(banners.count);
      });
    },
    [type],
  );

  const onClickRemove = useCallback(
    (e) => {
      const confirmed = window.confirm('정말 선택된 배너를 삭제하시겠습니까?');
      if (confirmed) {
        request.deleteBanners(type, selectedBannerIds).then(() => {
          window.alert('삭제했습니다.');
          setBanners(banners.filter((banner) => !selectedBannerIds.includes(banner.id)));
        });
      }
    },
    [type, banners, selectedBannerIds],
  );

  const onClickNew = useCallback(
    (e) => {
      history.push(`./${type}/new`);
    },
    [history, type],
  );

  const onClickBanner = useCallback(
    (row, e) => {
      history.push(`./${type}/new/${row.original.id}`);
    },
    [history, type],
  );

  const onChangeSelectedBanners = useCallback((selectedRows) => {
    setSelectedBannerIds(selectedRows.map((row) => row.original.id));
  }, []);

  return (
    <div className="banner-list">
      <div className="banner-list__buttons">
        <Button.Negative onClick={onClickRemove}>선택 삭제</Button.Negative>
        <Button.Positive onClick={onClickNew}>+ 배너 생성</Button.Positive>
      </div>
      <Table
        columns={columns}
        data={banners}
        total={total}
        fetchData={fetchData}
        onClickRow={onClickBanner}
        onChangeSelectedRows={onChangeSelectedBanners}
      />
    </div>
  );
}

export default BannerList;
