import styled from 'styled-components';

export const Layout = styled.div.attrs({ className: 'layout' })`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0 2rem;
  background-color: #f1f3f5;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 480px) {
    padding: 0;
    overflow-x: hidden;
  }
`;

export const Container = styled.div.attrs({ className: 'container' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1040px;
  padding: 2rem 0;
  position: relative;
`;

export const Logo = styled.img.attrs({ className: 'Logo' })`
  width: 100%;
  max-width: 300px;
  aspect-ratio: 5/1;
  display: block;
  margin-bottom: 2rem;
`;

export const Title = styled.h1.attrs({ className: 'title' })`
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.25rem;

  @media (max-width: 480px) {
    font-size: 1.625rem;
  }
`;

export const Description = styled.p.attrs({ className: 'description' })`
  font-size: 0.875rem;
  display: block;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #dee2e6;
  width: 100%;
  border-radius: 0.5rem;
  white-space: pre-wrap;
  box-sizing: border-box;

  @media (max-width: 480px) {
    font-size: 0.75rem;
    padding: 1.25rem;
    border-radius: 0;
  }
`;

export const Rubbish = styled.div.attrs({ className: 'rubbish' })`
  width: 100%;
  text-align: right;
  padding-right: 0.625rem;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;

  @media (max-width: 480px) {
    padding-right: 1.875rem;
  }
`;

export const SectionContainer = styled.div.attrs({ className: 'section-container' })`
  width: 100%;
  overflow: hidden;
  margin-bottom: 1.875rem;
`;

export const Sections = styled.div.attrs({ className: 'sections' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  gap: 3.75rem;

  @media (max-width: 480px) {
    padding: 0 1.25rem;
    gap: 2.5rem;
  }
`;

export const SectionItem = styled.div.attrs({ className: 'section-item' })`
  display: block;
  width: 100%;
  min-width: 100%;
  border-radius: 0.625rem;
  overflow: hidden;
`;

export const SectionHeader = styled.div.attrs({ className: 'section-header' })`
  display: flex;
`;

export const SectionNumber = styled.div.attrs({ className: 'section-number' })`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem 0.75rem;
  background-color: #343a40;
  color: #fff;
  font-size: 1.25rem;

  @media (max-width: 480px) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`;

export const SectionTitle = styled.div.attrs({ className: 'section-title' })`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem 0.75rem;
  background-color: #868e96;
  color: #fff;
  font-size: 1.25rem;
  width: 100%;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const SectionBody = styled.div.attrs({ className: 'section-body' })`
  padding: 1.875rem;
  background-color: #fff;
  color: #000;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const QuestionContainer = styled.div.attrs({ className: 'question-container' })`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const QuestionHeader = styled.div.attrs({ className: 'question-header' })``;

export const QuestionTitleContainer = styled.div.attrs({ className: 'question-title-container' })`
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const QuestionNumber = styled.div.attrs({ className: 'question-number' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1.25rem;
  font-weight: 700;
  font-size: 1rem;
  margin-right: 1.25rem;

  span {
    border-bottom: 0.0625rem solid #000000;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 480px) {
    margin-right: 0;
  }
`;

export const QuestionTitle = styled.div.attrs({ className: 'question-title' })`
  padding-top: 0.625rem;
  font-size: 1rem;
  font-weight: 500;
`;

export const QuestionDescription = styled.div.attrs({ className: 'question-description' })`
  padding-left: calc(1.25rem + 1.25rem);
  color: #808080;
  margin-top: 0.625rem;
  font-size: 0.875rem;

  @media (max-width: 480px) {
    padding-left: 0;
  }
`;

export const QuestionBody = styled.div.attrs({ className: 'question-body' })`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ButtonContainer = styled.div.attrs({ className: 'button-container' })`
  display: flex;
  justify-content: center;
  gap: 0.625rem;
`;

export const Button = styled.button.attrs({ className: 'button' })`
  box-sizing: border-box;
  background-color: #ffffff;
  border: 0.0625rem solid #f24462;
  border-radius: 1.5rem;
  width: 10rem;
  height: 3rem;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  padding: 0.125rem 0 0;
`;

export const PrevButton = styled(Button).attrs({ className: 'prev-button' })`
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('https://assets.enterjobedu.co.kr/survey/icons/arrow-prev.png');
  }
`;

export const NextButton = styled(Button).attrs({ className: 'next-button' })`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('https://assets.enterjobedu.co.kr/survey/icons/arrow-next.png');
  }
`;

export const RadioItem = styled.div.attrs({ className: 'radio-item' })``;
export const CheckboxItem = styled.div.attrs({ className: 'checkbox-item' })``;
export const InputItem = styled.div.attrs({ className: 'input-item' })`
  box-sizing: border-box;
  background-color: #ffffff;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.5rem;
`;

export const SelectItem = styled.div.attrs({ className: 'select-item' })``;

export const ChoiceContainer = styled.div.attrs({ className: 'choice-container' })``;

export const SubmitButton = styled(Button).attrs({ className: 'submit-button' })`
  background-color: #f24462;
  color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url('https://assets.enterjobedu.co.kr/survey/icons/check-circle.png');
  }
`;

export const Download = styled.button.attrs({ className: 'download' })`
  background-color: #ffffff;
  border: 0.0625rem solid #f24462;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  padding: 0.75rem 1.5rem;
  font-weight: 700;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  z-index: 100;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f24462;
    color: #ffffff;
    cursor: pointer;
  }
`;
