import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Quill } from 'react-quill';
import moment from 'moment';
import * as request from '../../Util/Request';
import * as Model from '../../Model';
import * as Button from '../Button';
import SelectSearch from '../SelectSearch';
import ReactDatePicker from '../DatePicker';
import Table from '../Table';
import Chip from '../Chip';
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import ClassSections from '../ClassSections';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AgencyUpsert from '../../../Page/Contents/Agencies/Upsert';
import ArtistUpsert from '../../../Page/Contents/Artists/Upsert';
import CloseIcon from '../../../Assets/Images/icon-cancel-black.png';

import {
  Checkbox,
  createTheme,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  ThemeProvider,
} from '@mui/material';
import MuiInput from '@mui/material/Input';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddQuestion from '../../../Page/Survey/New/AddQuestion';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import QuillCustom from '../ReactQuill';
import EditorToolbar from '../EditorToolbar';
import ImageResize from 'quill-image-resize-module-react';
import { Editor } from '@tinymce/tinymce-react';
import { uploadIntroductionImage } from '../../Util/Request';

Quill.register('modules/imageResize', ImageResize);

export function Base(props) {
  return (
    <div className={'field' + (props.type ? ` field--${props.type}` : '')} style={props.style ? props.style : {}}>
      {props.title && (
        <div className="field__title">
          {props.title}
          {props.required && '*'}
        </div>
      )}
      <div className="field__body">{props.children}</div>
    </div>
  );
}

export function Input(props) {
  const { fixed, textAfter, ...inputProps } = props;

  return (
    <Base {...props} type="input">
      {fixed ? (
        props.value
      ) : (
        <input
          {...inputProps}
          value={props.value || ''}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        />
      )}
      {textAfter && <span>{textAfter}</span>}
    </Base>
  );
}

export function Textarea(props) {
  return (
    <Base {...props} type="textarea">
      {props.fixed ? (
        props.value
      ) : (
        <textarea
          {...props}
          value={props.value || ''}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        />
      )}
    </Base>
  );
}

export function RichText(props) {
  return (
    <Base {...props} type="rich-text">
      <EditorToolbar />
      <QuillCustom {...props} />
    </Base>
  );
}

export function Radio(props) {
  return (
    <Base {...props} type="radio">
      {props.options.map((option, index) => (
        <label key={index}>
          <input
            type="radio"
            name={props.name}
            value={option.value}
            checked={option.value === props.value}
            onChange={(e) => {
              if (typeof option.value === 'boolean') {
                props.onChange(e.target.value === 'true');
              } else {
                props.onChange(e.target.value);
              }
            }}
            disabled={props.disabled}
          />
          {option.label}
        </label>
      ))}
    </Base>
  );
}

export function File(props) {
  const fileInput = useRef();

  const filename = useMemo(() => {
    if (props.fileName) {
      return props.fileName;
    }
    if (props.value) {
      if (props.value instanceof Blob) {
        return props.value.name;
      } else {
        const splits = props.value.split('/');
        return splits[splits.length - 1];
      }
    }
  }, [props.value]);

  const url = props.value instanceof Blob ? URL.createObjectURL(props.value) : props.value;

  const onClickDelete = useCallback(
    (e) => {
      if (props.onDelete) {
        props.onDelete();
        fileInput.current.value = null;
      } else {
        props.onChange(null);
        fileInput.current.value = null;
      }
    },
    [props],
  );

  return (
    <Base {...props} type="file">
      <input
        type="file"
        accept={props.accept}
        ref={fileInput}
        onChange={(e) => props.onChange(e.target.files[0])}
        hidden
      />
      {!props.value && !props.hideButton && !props.onClick && (
        <Button.Negative onClick={() => fileInput.current.click()}>업로드</Button.Negative>
      )}
      {props.value && !props.hideUrl && (
        <a
          href={url?.replace('enterjobedu.s3.ap-northeast-2.amazonaws.com', 'assets.enterjobedu.co.kr')}
          download={filename}
        >
          {filename}
        </a>
      )}
      {props.value && !props.hideButton && !props.onClickDelete && (
        <Button.Neutral onClick={onClickDelete}>파일 제거</Button.Neutral>
      )}

      {!props.value && !props.hideButton && props.onClick && (
        <Button.Negative onClick={() => props.onClick(fileInput)}>업로드</Button.Negative>
      )}
      {props.value && props.hideUrl && (
        <a
          href={url?.replace('enterjobedu.s3.ap-northeast-2.amazonaws.com', 'assets.enterjobedu.co.kr')}
          download={filename}
        >
          <Button.Neutral>다운로드</Button.Neutral>
        </a>
      )}
      {props.value && !props.hideButton && props.onClickDelete && (
        <Button.Neutral onClick={() => props.onClickDelete(fileInput)}>파일 제거</Button.Neutral>
      )}
    </Base>
  );
}

export function Image(props) {
  const { value, onChange, width, height, disabled, shouldUpload } = props;

  const fileInput = useRef();

  const preview = useMemo(() => {
    return value instanceof Blob ? URL.createObjectURL(value) : value;
  }, [value]);

  const onChangeInput = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (shouldUpload) {
        request.uploadIntroductionImage(file).then(({ image_url }) => {
          onChange(image_url);
        });
      } else {
        onChange(file);
      }
    },
    [onChange, shouldUpload],
  );

  const onClickDelete = useCallback(
    (e) => {
      onChange(null);
      fileInput.current.value = null;
    },
    [onChange],
  );

  return (
    <Base {...props} type="image">
      <input type="file" accept=".jpg,.jpef,.png,.gif" ref={fileInput} onChange={onChangeInput} hidden />
      {(disabled || !preview) && (
        <Button.Negative onClick={() => fileInput.current.click()}>
          업로드 {width && height && `(가로 ${width}px * 세로 ${height}px)`}
        </Button.Negative>
      )}
      {preview && (
        <img
          src={preview?.replace('enterjobedu.s3.ap-northeast-2.amazonaws.com', 'assets.enterjobedu.co.kr')}
          alt="preview"
          height={90}
          style={{ maxWidth: '80%' }}
        />
      )}
      {preview && <Button.Neutral onClick={onClickDelete}>이미지 제거</Button.Neutral>}
    </Base>
  );
}

export function Video(props) {
  const fileInput = useRef();

  const filename = useMemo(() => {
    if (props.value) {
      if (props.value instanceof Blob) {
        return props.value.name;
      } else {
        const splits = props.value.split('/');
        return splits[splits.length - 1];
      }
    }
  }, [props.value]);

  const url = props.value instanceof Blob ? URL.createObjectURL(props.value) : props.value;

  const [duration, setDuration] = useState(null);
  useEffect(() => {
    if (!props.value) {
      setDuration(null);
    }

    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      setDuration(Math.round(video.duration));
    };
    video.src = props.value instanceof Blob ? URL.createObjectURL(props.value) : props.value;
  }, [props.value]);

  const { onChangeDuration } = props;
  useEffect(() => {
    if (onChangeDuration) {
      onChangeDuration(duration);
    }
  }, [onChangeDuration, duration]);

  const onClickDelete = useCallback(
    (e) => {
      props.onChange(null);
    },
    [props],
  );

  return (
    <Base {...props} type="video">
      <input
        type="file"
        accept=".mp4,.m3u8"
        ref={fileInput}
        onChange={(e) => props.onChange(e.target.files[0])}
        hidden
      />
      {!props.value && <Button.Negative onClick={() => fileInput.current.click()}>업로드</Button.Negative>}
      {props.value && (
        <a
          href={url?.replace('enterjobedu.s3.ap-northeast-2.amazonaws.com', 'assets.enterjobedu.co.kr')}
          download={filename}
        >
          {filename} {duration && `(${moment.utc(moment.duration(duration, 's').asMilliseconds()).format('H:mm:ss')})`}
        </a>
      )}
      {props.value && <Button.Neutral onClick={onClickDelete}>영상 파일 제거</Button.Neutral>}
    </Base>
  );
}

export function Date(props) {
  return (
    <Base {...props} type="date">
      <ReactDatePicker selected={props.value} onChange={props.onChange} />
    </Base>
  );
}

export function Datetime(props) {
  const timeSteps = { hours: 1, minutes: 1, seconds: 1 };

  return (
    <Base {...props} type="datetime">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={props.date && dayjs(props.date)}
          maxDate={props.maxDate && dayjs(props.maxDate)}
          minDate={props.minDate && dayjs(props.minDate)}
          onChange={(value) => {
            props.onDateChange(dayjs(value).format('YYYY-MM-DD'));
          }}
          disabled={props.disabled}
        />
        <TimePicker
          value={
            props.time &&
            dayjs()
              .set('h', props.time?.split(':')[0] ?? '00')
              .set('m', props.time?.split(':')[1] ?? '00')
              .set('s', props.time?.split(':')[2] ?? '00')
          }
          format="HH:mm:ss"
          ampm={false}
          timeSteps={timeSteps}
          onChange={(value) => {
            props.onTimeChange(dayjs(value).format('HH:mm:ss'));
          }}
          disabled={!props.date || props.disabled}
          sx={{ margin: '0 0.5rem', width: '9.5rem' }}
        />
      </LocalizationProvider>
    </Base>
  );
}

export function Month(props) {
  return (
    <Base {...props}>
      <input
        type="month"
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        max={props.max}
      />
    </Base>
  );
}

export function Period(props) {
  const { startDate, startTime, endDate, endTime } = props.value || {};

  return (
    <Base {...props} type="period" style={{ height: '4rem' }}>
      <div>
        <ReactDatePicker
          selected={startDate}
          maxDate={endDate}
          onChange={(date) => {
            props.onChange({ startDate: date, startTime: startTime ? startTime : '00:00', endDate, endTime });
          }}
        />
        {props.showTime && (
          <Input
            type="time"
            value={startTime}
            onChange={(value) => {
              props.onChange({ startDate, startTime: value, endDate, endTime });
            }}
          />
        )}
      </div>
      <span>~</span>
      <div>
        <ReactDatePicker
          selected={endDate}
          minDate={startDate}
          onChange={(date) => {
            props.onChange({ startDate, startTime, endDate: date, endTime: endTime ? endTime : '23:59' });
          }}
        />
        {props.showTime && (
          <Input
            type="time"
            value={endTime}
            onChange={(value) => {
              props.onChange({ startDate, startTime, endDate, endTime: value });
            }}
          />
        )}
      </div>
    </Base>
  );
}

export function Weekdays(props) {
  return (
    <Base {...props} type="weekdays">
      {[1, 2, 3, 4, 5, 6, 7].map((weekday) => (
        <Chip
          key={weekday}
          name={<FormattedMessage id={'ID_WEEKDAY_' + weekday} />}
          hideCloseButton={true}
          onClick={() => {
            const index = props.value.indexOf(weekday);
            if (index >= 0) {
              const weekdays = [...props.value];
              weekdays.splice(index, 1);
              props.onChange(weekdays);
            } else {
              props.onChange([...props.value, weekday]);
            }
          }}
          selected={props.value.includes(weekday)}
        />
      ))}
    </Base>
  );
}

export function SelectById(props) {
  const { emptyOptionText, ...selectProps } = props;

  return (
    <Base {...props}>
      <select
        {...selectProps}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      >
        <option value="">{emptyOptionText}</option>
        {props.options.map((option, idx) => (
          <option key={idx} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <style jsx="true">{`
        select {
          width: auto;
          min-width: 200px;
          height: 30px;
          padding: 0px;
          margin: 0;
          font-size: 14px;
          color: #333;
        }
      `}</style>
    </Base>
  );
}

export function SelectTutor(properties) {
  return (
    <Base {...properties}>
      <select
        value={properties.value}
        onChange={(e) => {
          properties.onChange(e.target.value);
        }}
        disabled={properties.disabled}
      >
        <option value="">전체</option>
        {properties.options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <style jsx="true">{`
        select {
          width: 100%;
          height: 30px;
          padding: 0;
          margin: 0;
          font-size: 14px;
          color: #333;
        }
      `}</style>
    </Base>
  );
}

export function SelectVersion(properties) {
  return (
    <Base {...properties}>
      <select
        className="version-selector"
        value={properties.value}
        onChange={(e) => {
          properties.onChange(e.target.value);
        }}
        disabled={properties.disabled}
      >
        <option value={0}>새 버전</option>
        {properties.options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <style jsx="true">{`
        select.version-selector {
          width: 100%;
          height: 30px;
          padding: 0 1.5rem 0 0.5rem !important;
          margin: 0;
          font-size: 14px;
          color: #333;
        }
      `}</style>
    </Base>
  );
}

export function Tutors(props) {
  const [tutors, setTutors] = useState([]);
  const [ratesOfTutors, setRatesOfTutors] = useState([]);

  useEffect(() => {
    request.tutors({ page: 1, limit: 9999 }).then(({ tutors }) => setTutors(tutors.rows));
  }, []);

  const {
    value: selectedTutorIds,
    onChange,
    rates: ratesOfSelectedTutors,
    onRateChange,
    onChangeSelectedTutors,
  } = props;

  const onChangeSelect = useCallback(
    (e) => {
      const tutorId = Number(e.target.value);
      if (!selectedTutorIds.includes(tutorId)) {
        onChange([...selectedTutorIds, tutorId]);
        onRateChange && onRateChange([...ratesOfSelectedTutors, 0]);
      }
    },
    [selectedTutorIds, onChange],
  );

  const selectedTutors = useMemo(
    () => selectedTutorIds.map((tutorId) => tutors.find((tutor) => tutor.id === tutorId)),
    [tutors, selectedTutorIds],
  );
  const rates = onRateChange
    ? useMemo(
        () =>
          selectedTutorIds.map((_, index) => {
            const rate = ratesOfSelectedTutors[index];
            return rate === undefined ? 0 : rate;
          }),
        [ratesOfSelectedTutors, selectedTutorIds],
      )
    : null;

  const onClickTutor = useCallback(
    (tutor) => {
      const index = selectedTutorIds.indexOf(tutor.id);
      onChange([...selectedTutorIds].filter((tutorId) => tutorId !== tutor.id));
      onRateChange && onRateChange([...ratesOfSelectedTutors].filter((rate, i) => i !== index));
    },
    [selectedTutorIds, onChange],
  );
  const onChangeRate = useCallback(
    (rate, index) => {
      const touchedRate = rate > 100 ? 100 : rate < 0 ? 0 : rate;
      const newRates = [...ratesOfSelectedTutors].map((r, i) => (i === index ? touchedRate : r));
      const allSum = newRates.reduce((a, b) => +a + +b, 0);
      if (rate > 100 || allSum > 100) {
        Swal.fire({
          icon: 'error',
          title: '수업비율 합이 100%를 초과할 수 없습니다.',
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      onRateChange(newRates);
    },
    [ratesOfSelectedTutors, onRateChange],
  );

  useEffect(() => {
    onChangeSelectedTutors && onChangeSelectedTutors(selectedTutors);
  }, [selectedTutors]);

  return (
    <Base {...props} type="tutor">
      <select value="" onChange={onChangeSelect} disabled={props.disabled}>
        <option value="" disabled>
          선택
        </option>
        {tutors.map((tutor) => (
          <option key={tutor.id} value={tutor.id}>
            {tutor.name}
          </option>
        ))}
      </select>
      <div className="field__tutors">
        {selectedTutors.map((tutor, index) => {
          return tutor ? (
            <div key={tutor.id}>
              <Chip
                name={tutor.name}
                onClick={() => !props.disabled && onClickTutor(tutor)}
                selected
                disabled={props.disabled}
              />
              {rates && (
                <Input
                  type="number"
                  min="0"
                  max="100"
                  value={rates[index]}
                  placeholder="정산비율"
                  onChange={(rate) => onChangeRate(rate, index)}
                />
              )}
              {rates && <span>%</span>}
            </div>
          ) : (
            ''
          );
        })}
      </div>
    </Base>
  );
}

export function Artists(props) {
  const SwalWithReactContent = withReactContent(Swal);
  const [artists, setArtists] = useState([]);
  const [deletedArtists, setDeletedArtists] = useState([]);

  const fetchArtists = () =>
    request.artists({ page: 1, limit: 9999 }).then(({ rows }) => {
      setArtists(rows);
    });

  const fetchDeletedArtists = () =>
    request.artists({ page: 1, limit: 9999, status: 0 }).then(({ rows }) => {
      setDeletedArtists(rows);
    });

  useEffect(() => {
    fetchArtists();
    fetchDeletedArtists();
  }, []);

  const { value: selectedArtistIds, onChange } = props;

  const modalClose = useCallback(() => fetchArtists().then(() => Swal.close()), []);
  const onKeypressSelect = useCallback(
    (e) => {
      const {
        code,
        target: { value: artistName },
      } = e;
      if (code !== 'Enter' || !artistName) return;
      const value = artists.find((artist) => artist.name === artistName)?.id;
      if (!value) return;
      const artistId = Number(value);
      if (!selectedArtistIds.includes(artistId)) {
        onChange([...selectedArtistIds, artistId]);
        e.target.value = '';
      }
    },
    [selectedArtistIds, onChange, artists],
  );
  const onChangeSelect = useCallback(
    (e) => {
      const {
        code,
        target: { value: artistName },
      } = e;
      if (code !== 'Enter' && artistName === '+ 신규 아티스트 추가하기') {
        SwalWithReactContent.fire({
          title: '아티스트 추가',
          html: <ArtistUpsert modalClose={modalClose} />,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        e.target.value = '';
      }
    },
    [SwalWithReactContent, modalClose],
  );

  const selectedArtists = useMemo(() => {
    const result = [];

    selectedArtistIds.map((artistId) =>
      artists.find((artist) => (artist.id === artistId ? result.push(artist) : null)),
    );

    selectedArtistIds.map((artistId) =>
      deletedArtists.find((artist) =>
        artist.id === artistId ? result.push({ ...artist, style: { backgroundColor: '#969696' } }) : null,
      ),
    );
    return result;
  }, [artists, selectedArtistIds, deletedArtists]);

  const onClickArtist = useCallback(
    (artist) => {
      onChange([...selectedArtistIds].filter((artistId) => artistId !== artist.id));
    },
    [selectedArtistIds, onChange],
  );

  return (
    <Base {...props} type="artist">
      <input
        type="text"
        list="artists"
        placeholder="아티스트명"
        onKeyPress={onKeypressSelect}
        onChange={onChangeSelect}
      />
      <datalist id="artists">
        {artists
          .filter((artist) => !selectedArtistIds.includes(artist.id))
          .map((artist) => (
            <option key={artist.id} value={artist.name} />
          ))}
        <option value="+ 신규 아티스트 추가하기"></option>
      </datalist>
      <div className="field__artists">
        {selectedArtists.map(
          (artist) =>
            artist && (
              <Chip
                key={artist.id}
                name={artist.name}
                style={artist.style}
                onClick={() => onClickArtist(artist)}
                selected
              />
            ),
        )}
      </div>
    </Base>
  );
}

export function Agencies(props) {
  const SwalWithReactContent = withReactContent(Swal);
  const [agencies, setAgencies] = useState([]);
  const [deletedAgencies, setDeletedAgencies] = useState([]);

  const fetchAgencies = () =>
    request.agencies({ page: 1, limit: 9999 }).then(({ rows }) => {
      setAgencies(rows);
    });
  const fetchDeletedAgencies = () =>
    request.agencies({ page: 1, limit: 9999, status: 0 }).then(({ rows }) => {
      setDeletedAgencies(rows);
    });
  useEffect(() => {
    fetchAgencies();
    fetchDeletedAgencies();
  }, []);
  const { value: selectedAgencyIds, onChange } = props;
  const modalClose = useCallback(() => fetchAgencies().then(() => Swal.close()), []);

  const onKeypressSelect = useCallback(
    (e) => {
      const {
        code,
        target: { value: agencyName },
      } = e;
      if (code !== 'Enter' || !agencyName) return;
      const value = agencies.find((agency) => agency.name === agencyName)?.id;
      if (!value) return;
      const agencyId = Number(value);
      if (!selectedAgencyIds.includes(agencyId)) {
        onChange([...selectedAgencyIds, agencyId]);
        e.target.value = '';
      }
    },
    [selectedAgencyIds, onChange, agencies],
  );
  const onChangeSelect = useCallback(
    (e) => {
      const {
        target: { value: agencyName },
      } = e;
      if (agencyName === '+ 신규 기획사 추가하기') {
        SwalWithReactContent.fire({
          title: '기획사 추가',
          html: <AgencyUpsert modalClose={modalClose} />,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        e.target.value = '';
      }
    },
    [SwalWithReactContent, modalClose],
  );

  const selectedAgencies = useMemo(() => {
    const result = [];
    [...selectedAgencyIds].map((agencyId) =>
      agencies.find((agency) => (+agency.id === +agencyId ? result.push(agency) : null)),
    );
    [...selectedAgencyIds].map((agencyId) =>
      deletedAgencies.find((agency) =>
        +agency.id === +agencyId ? result.push({ ...agency, style: { backgroundColor: '#969696' } }) : null,
      ),
    );
    return result;
  }, [agencies, selectedAgencyIds, deletedAgencies]);

  const onClickAgency = useCallback(
    (agency) => {
      onChange([...selectedAgencyIds].filter((agencyId) => agencyId !== agency.id));
    },
    [selectedAgencyIds, onChange],
  );
  return (
    <Base {...props} type="agency">
      <input
        type="text"
        list="agencies"
        placeholder="기획사명"
        onKeyPress={onKeypressSelect}
        onChange={onChangeSelect}
      />
      <datalist id="agencies">
        {agencies
          .filter((agency) => !selectedAgencyIds.includes(agency.id))
          .map((agency) => (
            <option key={agency.id} value={agency.name} onClick={() => alert(1)} />
          ))}
        <option value="+ 신규 기획사 추가하기"></option>
      </datalist>
      <div className="field__agencies">
        {selectedAgencies.map(
          (agency) =>
            agency && (
              <Chip
                key={agency.id}
                name={agency.name}
                style={agency.style}
                onClick={() => onClickAgency(agency)}
                selected
              />
            ),
        )}
      </div>
    </Base>
  );
}

export function Categories(props) {
  const categoryIds = {
    company: [1, 2, 3, 4, 5, 6, 7],
    field: [1, 2, 3, 4, 5],
    process: [1, 2, 3, 4, 5, 6],
  };

  const { value: selectedCategoryIds, onChange, ...categoryProps } = props;

  const onClickCategory = useCallback(
    (categoryId, type) => {
      const _selectedCategoryIds = { ...selectedCategoryIds };
      const index = _selectedCategoryIds[type].indexOf(categoryId);
      if (index >= 0) {
        _selectedCategoryIds[type].splice(index, 1);
      } else {
        _selectedCategoryIds[type].push(categoryId);
      }
      onChange(_selectedCategoryIds);
    },
    [selectedCategoryIds, onChange],
  );

  return (
    <Base {...props} type="categories">
      <div className="field__category-groups">
        {Object.keys(categoryIds).map((type) => (
          <div key={type} className="field__category-group">
            <div className="field__category-group__title">
              <FormattedMessage id={'ID_VIDEO_CATEGORY_TYPE_' + type.toUpperCase()} />
            </div>
            <div className="field__category-group__categories">
              {categoryIds[type].map((categoryId) => (
                <Chip
                  {...categoryProps}
                  key={categoryId}
                  name={<FormattedMessage id={'ID_CATEGORY_' + type.toUpperCase() + '_' + categoryId} />}
                  selected={selectedCategoryIds[type].includes(categoryId)}
                  onClick={() => !props.disabled && onClickCategory(categoryId, type)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Base>
  );
}

const changeInput = (e, props) => {
  props.onChange({
    ...props.value,
    student_limit: e.target.value,
  });
};

export function OnlineOption(props) {
  const studentLimitRef = useRef();

  const keyUpStudentLimit = (e) => {
    e.preventDefault();
    let {
      current: { value: student_limit, min, max },
    } = studentLimitRef;
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8 || e.keyCode === 38 || e.keyCode === 40) {
      if (+student_limit < +min) student_limit = min;
      else if (+student_limit > +max) student_limit = max;
      studentLimitRef.current.value = student_limit;
      changeInput(e, props);
    } else {
      studentLimitRef.current.value = props.value.student_limit;
    }
  };

  return (
    <Base {...props} type="online-option">
      <div>
        <Input
          type="number"
          title="수강일수"
          textAfter="일"
          value={props.value.valid_days}
          onChange={(value) => {
            props.onChange((option) => ({
              ...option,
              valid_days: value,
            }));
          }}
        />
        <Date
          title="수강시작일"
          value={props.value.start_date}
          onChange={(value) => {
            props.onChange((option) => ({
              ...option,
              start_date: value,
            }));
          }}
        />
        <span>
          <Base title="수강인원" type="input">
            <input
              type="number"
              title="수강인원"
              min="0"
              max="999"
              ref={studentLimitRef}
              defaultValue={props.value.student_limit}
              onKeyUp={keyUpStudentLimit}
              onClick={(e) => changeInput(e, props)}
              style={{ width: 120, display: 'inline-block' }}
            />
            <span>명</span>
          </Base>
        </span>
      </div>
    </Base>
  );
}

export function RoundOnlineOptions(props) {
  const [options, setOptions] = useState(props.value);
  const [selected, setSelected] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [baughtOption, setBaughtOption] = useState([]);

  //임시 고유 값으로 사용될 id
  //ref를 사용하여 변수 담기
  const nextId = useRef(1);

  const getGeneralOption = useCallback((item) => {
    return {
      title: item.title,
      start_date: item.start_date,
      end_date: item.end_date,
      class_round: item.class_round,
      class_start_time: item.class_start_time,
      class_end_time: item.class_end_time,
      student_limit: item.student_limit,
      option_price: item.option_price,
      show_timer: item.show_timer,
      timer_text: item.timer_text,
      show_start_date: item.show_start_date,
      show_start_time: item.show_start_time,
      show_end_date: item.show_end_date,
      show_end_time: item.show_end_time,
      option_price_is_promotion: item.option_price_is_promotion,
    };
  }, []);

  const handleSave = (data) => {
    const addOption = () => {
      return [
        ...options,
        new Model.RoundOnlineOption({
          temporaryId: nextId.current,
          ...getGeneralOption(data),
        }),
      ];
    };

    const editOption = () => {
      return options.map((row) =>
        data.temporaryId === row.temporaryId
          ? new Model.RoundOnlineOption({
              id: data.id,
              temporaryId: data.temporaryId,
              ...getGeneralOption(data),
            })
          : row,
      );
    };

    if (data.temporaryId) {
      setOptions(editOption);
      props.onChange(editOption);
    } else {
      setOptions(addOption);
      props.onChange(addOption);
      nextId.current += 1;
    }
  };

  const handleRemove = (temporaryId, id) => {
    if (id && baughtOption.includes(id)) {
      return Swal.fire({
        html: '구매자(또는 구매 대기자)가 있는 옵션은 삭제할 수 없습니다.',
        position: 'center',
        showConfirmButton: true,
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: '정말 삭제하시겠습니까?',
        text: '삭제한 데이터는 복구할 수 없습니다.',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: '삭제',
        preConfirm: () => {
          const removeOption = options.filter((item) => item.temporaryId !== temporaryId);
          if (id) deleteList.push(id);

          setDeleteList(deleteList);
          setOptions(removeOption);

          props.onChangeDeleteList(deleteList);
          props.onChange(removeOption);

          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">삭제되었습니다. <p>* 저장 시 반영됩니다.</p></div>`,
            toast: true,
            position: 'center',
            showConfirmButton: true,
            icon: 'success',
            type: 'success',
          });
        },
      });
    }
  };

  const handleEdit = (item) => {
    const selectedData = {
      id: item.id,
      temporaryId: item.temporaryId,
      ...getGeneralOption(item),
    };
    setSelected([...selected, selectedData]);
  };

  const cleanSelected = () => {
    setSelected([]);
  };

  const handleEditSubmit = (item) => {
    handleSave(item);
    setSelected([]);
  };

  useEffect(() => {
    options.forEach((option, idx) => {
      nextId.current = idx + 1;
      option.temporaryId = nextId.current;
    });
    nextId.current += 1;
    //팔린(또는 대기 중인) 옵션 리스트
    setBaughtOption(
      props.value.map((option) => {
        if (option.id && (option.student_count !== 0 || option.student_ready_count !== 0)) return option.id;
        return null;
      }),
    );
  }, [options, props.value]);

  return (
    <Base {...props} type="round-online-options" style={{ position: 'relative' }}>
      <Button.MUI
        style={{ position: 'absolute', left: '2rem', top: '2rem', backgroundColor: '#eeeeee', color: '#000000' }}
        size="small"
        variant="contained"
        disableRipple
        onClick={() => {
          Swal.fire({
            title: '옵션 추가',
            text: '곧 옵션 추가 기능이 업데이트 됩니다.',
            icon: 'info',
            dangerMode: true,
            timer: 4000,
          });
        }}
      >
        옵션 추가
      </Button.MUI>
      <div className="field__option-container">
        <table className="field__option-table">
          <thead>
            <tr>
              <th>옵션명</th>
              <th>수강 기간</th>
              <th>옵션 노출 기간</th>
              <th>기수</th>
              <th>강의시간</th>
              <th>수강인원</th>
              <th>옵션가</th>
              <th>타이머 사용</th>
              <th>타이머 강조문구</th>
              <th>프로모션</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <LectureOptionTableBody
            options={options}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            onSaveData={handleSave}
            selectedData={selected}
            cleanSelected={cleanSelected}
            handleEditSubmit={handleEditSubmit}
          />
        </table>
      </div>
    </Base>
  );
}

export function OfflineOptions(props) {
  const [options, setOptions] = useState(props.value);
  const [selected, setSelected] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [baughtOption, setBaughtOption] = useState([]);
  const [classrooms, setClassrooms] = useState([]);

  const nextId = useRef(1);

  useEffect(() => {
    request.classrooms().then((data) => setClassrooms(data));
  }, []);

  const getGeneralOption = useCallback((item, type) => {
    return {
      title: item.title,
      start_date: item.start_date,
      end_date: item.end_date,
      class_round: item.class_round,
      class_dates:
        type === 'edit'
          ? item.class_dates
              .split('')
              .map((date, idx) => {
                if (date > 0) return idx + 1;
              })
              .filter((item) => item !== undefined)
          : item.class_dates.reduce((acc, cur) => acc.substring(0, cur - 1) + '1' + acc.substring(cur), '0000000'),
      class_start_time: item.class_start_time,
      class_end_time: item.class_end_time,
      student_limit: item.student_limit,
      class_location: item.class_location,
      option_price: item.option_price,
      show_timer: item.show_timer,
      timer_text: item.timer_text,
      show_start_date: item.show_start_date,
      show_start_time: item.show_start_time,
      show_end_date: item.show_end_date,
      show_end_time: item.show_end_time,
      option_price_is_promotion: item.option_price_is_promotion,
      FK_classroom_id: item.FK_classroom_id,
      video_call_link: item.video_call_link,
      student_count: item.student_count,
    };
  }, []);

  const handleSave = (data) => {
    const addOption = () => {
      return [
        ...options,
        new Model.OfflineOption({
          temporaryId: nextId.current,
          ...getGeneralOption(data),
        }),
      ];
    };

    const editOption = () => {
      return options.map((row) =>
        data.temporaryId === row.temporaryId
          ? new Model.OfflineOption({
              id: data.id,
              temporaryId: data.temporaryId,
              ...getGeneralOption(data),
            })
          : row,
      );
    };

    if (data.temporaryId) {
      setOptions(editOption);
      props.onChange(editOption);
    } else {
      setOptions(addOption);
      props.onChange(addOption);
      nextId.current += 1;
    }
  };

  const handleRemove = (temporaryId, id) => {
    if (id && baughtOption.includes(id)) {
      return Swal.fire({
        html: '구매자(또는 구매 대기자)가 있는 옵션은 삭제할 수 없습니다.',
        position: 'center',
        showConfirmButton: true,
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: '정말 삭제하시겠습니까?',
        text: '삭제한 데이터는 복구할 수 없습니다.',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: '삭제',
        preConfirm: () => {
          const removeOption = options.filter((item) => item.temporaryId !== temporaryId);
          if (id) deleteList.push(id);

          setDeleteList(deleteList);
          setOptions(removeOption);

          props.onChangeDeleteList(deleteList);
          props.onChange(removeOption);

          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">삭제되었습니다. <p>* 저장 시 반영됩니다.</p></div>`,
            toast: true,
            position: 'center',
            showConfirmButton: true,
            icon: 'success',
            type: 'success',
          });
        },
      });
    }
  };

  const handleEdit = (item) => {
    const selectedData = {
      id: item.id,
      temporaryId: item.temporaryId,
      ...getGeneralOption(item, 'edit'),
    };
    setSelected([...selected, selectedData]);
  };

  const cleanSelected = () => {
    setSelected([]);
  };

  const handleEditSubmit = (item) => {
    handleSave(item);
    setSelected([]);
  };

  useEffect(() => {
    options.forEach((option, idx) => {
      nextId.current = idx + 1;
      option.temporaryId = nextId.current;
    });
    nextId.current += 1;
    //구매된(또는 대기 중인) 옵션 리스트
    setBaughtOption(
      props.value.map((option) => {
        if (option.id && (option.student_count !== 0 || option.student_ready_count !== 0)) return option.id;
        return null;
      }),
    );
  }, [options, props.value]);

  return (
    <Base {...props} type="offline-options" style={{ position: 'relative' }}>
      <Button.MUI
        style={{ position: 'absolute', left: '1rem', top: '2rem', backgroundColor: '#eeeeee', color: '#000000' }}
        size="small"
        variant="contained"
        disableRipple
        onClick={() => {
          Swal.fire({
            title: '옵션 추가',
            text: '곧 옵션 추가 기능이 업데이트 됩니다.',
            icon: 'info',
            dangerMode: true,
            timer: 4000,
          });
        }}
      >
        옵션 추가
      </Button.MUI>
      <div className="field__option-container">
        <table className="field__option-table">
          <thead>
            <tr>
              <th>옵션명</th>
              <th>수강 기간*</th>
              <th>옵션 노출 기간</th>
              <th>기수*</th>
              <th>강의요일*</th>
              <th>강의시간*</th>
              <th>수강인원</th>
              <th id="class_location__th">장소*</th>
              <th>옵션가</th>
              <th>타이머 사용</th>
              <th>타이머 강조문구</th>
              <th>프로모션</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <LectureOptionTableBody
            options={options}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            onSaveData={handleSave}
            selectedData={selected}
            cleanSelected={cleanSelected}
            handleEditSubmit={handleEditSubmit}
            offlineOption={true}
            classrooms={classrooms}
            lectureType={props.lectureType}
          />
        </table>
      </div>
    </Base>
  );
}

export function LectureOptionTableBody(props) {
  const {
    options,
    handleRemove,
    handleEdit,
    onSaveData,
    selectedData,
    cleanSelected,
    handleEditSubmit,
    offlineOption,
    classrooms,
    lectureType,
  } = props;

  const keyUpStudentLimit = (e, studentLimitRef, setOptions, options, setEdited, edited) => {
    e.preventDefault();
    let {
      current: { value: student_limit, min, max },
    } = studentLimitRef;
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8 || e.keyCode === 38 || e.keyCode === 40) {
      if (+student_limit < +min) student_limit = min;
      else if (+student_limit > +max) student_limit = max;
      studentLimitRef.current.value = student_limit;
      setEdited &&
        setEdited({
          ...edited,
          student_limit: student_limit,
        });
      setOptions &&
        setOptions({
          ...options,
          student_limit: student_limit,
        });
    } else {
      edited && (studentLimitRef.current.value = edited.student_limit);
      options && (studentLimitRef.current.value = options.student_limit);
    }
  };

  return (
    <tbody>
      {options.map((option, idx) => {
        return (
          <LectureOptionTr
            key={option.temporaryId ? option.temporaryId : idx}
            item={option}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            selectedData={selectedData}
            cleanSelected={cleanSelected}
            handleEditSubmit={handleEditSubmit}
            offlineOption={offlineOption}
            keyUpStudentLimit={keyUpStudentLimit}
            onSaveData={onSaveData}
            classrooms={classrooms}
            lectureType={lectureType}
          />
        );
      })}

      <OptionInput
        onSaveData={onSaveData}
        offlineOption={offlineOption}
        keyUpStudentLimit={keyUpStudentLimit}
        classrooms={classrooms}
        lectureType={lectureType}
      />
    </tbody>
  );
}

export function LectureOptionTr(props) {
  const {
    item,
    handleRemove,
    handleEdit,
    selectedData,
    cleanSelected,
    handleEditSubmit,
    offlineOption,
    keyUpStudentLimit,
    onSaveData,
    classrooms,
    lectureType,
  } = props;
  const [editStatus, setEditStatus] = useState(false);
  const [edited, setEdited] = useState('');

  const onRemove = () => {
    handleRemove(item.temporaryId, item?.id);
  };

  const onEdit = () => {
    handleEdit(item);
  };

  const onCancel = () => {
    cleanSelected();
    setEditStatus(false);
  };

  const onSubmitEdit = (checkNickname) => {
    if (!checkNickname)
      return Swal.fire({
        html: '옵션명은 최대 13자까지 입력 가능합니다.',
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
    handleEditSubmit(edited);
    setEditStatus(false);
  };

  const classroomInfo = classrooms?.find((classroom) => classroom.id === item.FK_classroom_id);

  useEffect(() => {
    const slectedOption = selectedData[selectedData.length - 1];
    if (slectedOption) {
      if (slectedOption.temporaryId === item.temporaryId) {
        setEditStatus(true);
        setEdited(slectedOption);
      }
    }
  }, [selectedData, item.temporaryId]);

  if (editStatus) {
    return (
      <OptionInput
        onSaveData={onSaveData}
        offlineOption={offlineOption}
        keyUpStudentLimit={keyUpStudentLimit}
        setEdited={setEdited}
        edited={edited}
        onCancel={onCancel}
        onSubmitEdit={onSubmitEdit}
        classrooms={classrooms}
        lectureType={lectureType}
      />
    );
  } else {
    const days = ['월', '화', '수', '목', '금', '토', '일'];
    return (
      <tr>
        <td>{item.title ? item.title : '-'}</td>
        <td>
          {item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : '-'} ~{' '}
          {item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : '-'}
        </td>
        <td>
          {item.show_start_date
            ? moment
                .utc(`${moment(item.show_start_date).format('YYYY-MM-DD')} ${item.show_start_time}`)
                .format('YYYY-MM-DD HH:mm')
            : '-'}
          <span> ~ </span>
          {item.show_end_date
            ? moment
                .utc(`${moment(item.show_end_date).format('YYYY-MM-DD')} ${item.show_end_time}`)
                .format('YYYY-MM-DD HH:mm')
            : '-'}
        </td>
        <td>{item.class_round ? item.class_round : '-'}</td>
        {offlineOption && (
          <td>
            {item.class_dates === '000000'
              ? '-'
              : item.class_dates.split('').map((date, idx) => {
                  if (date > 0) return days[idx];
                })}
          </td>
        )}
        <td>
          {item.class_start_time} ~ {item.class_end_time}
        </td>
        <td>{+item.student_limit ? item.student_limit : '제한 없음'}</td>
        {offlineOption && (
          <td>
            {classroomInfo
              ? `${classroomInfo.location} | ${classroomInfo.name}`
              : item.class_location
              ? item.class_location
              : '-'}
          </td>
        )}
        <td>{item.option_price ? item.option_price : 0}</td>
        <td>
          <Checkbox checked={item.show_timer} size="small" disableRipple readOnly />
        </td>
        <td>{item.timer_text ? item.timer_text : '-'}</td>
        <td>
          <Checkbox checked={item.option_price_is_promotion} size="small" disableRipple readOnly />
        </td>
        <td>
          <Button.Negative onClick={onEdit}>수정</Button.Negative>
        </td>
        <td>
          <img onClick={onRemove} src={CloseIcon} alt="close_image" className="close-button" />
        </td>
      </tr>
    );
  }
}

const AddVideoCallLinkPopUp = ({ targetOption, setEdited, editLink, lectureType }) => {
  const [link, setLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const onChangeLink = (e) => {
    setLink(e.target.value);

    const urlPattern = /^https:\/\//;

    if (urlPattern.test(e.target.value)) {
      setIsValidLink(true);
    } else if (e.target.value.length === 0) {
      setIsValidLink(true);
    } else {
      setIsValidLink(false);
    }
  };

  const saveLink = () => {
    request
      .updateScheduleVideoCallLink(targetOption.id, { videoCallLink: link, listenType: lectureType })
      .then((res) => {
        if (res.success) {
          setEdited({
            ...targetOption,
            video_call_link: link,
          });

          Swal.fire({
            html: '저장 및 안내 발송이 완료되었습니다.',
            icon: 'success',
            position: 'center',
            showConfirmButton: true,
            confirmButtonText: '확인',
            confirmButtonColor: '#F24462',
          });
        } else {
          Swal.fire({
            title: '저장 및 안내 발송이 실패하였습니다.',
            html: `*실패 목록 <br /> ${res.failList.join('<br />')}`,
            icon: 'error',
            position: 'center',
            showConfirmButton: true,
            confirmButtonText: '확인',
            confirmButtonColor: '#F24462',
          });
        }
      });
  };

  const onClickSave = () => {
    Swal.fire({
      title: `링크 ${editLink ? '수정' : '추가'} 및 안내 발송을 진행하시겠습니까?`,
      html: `${
        editLink ? '수정' : '추가'
      } 시 <span style='color: #FF0000; font-weight: bold'>즉시 반영</span>되며, 수강생에게 <span style='color: #FF0000; font-weight: bold'>안내가 발송</span>됩니다.
      <br />
      <br />
      *링크: <span style='font-weight: bold'>${link}</span>
      <br />
      *링크는 추가/수정만 가능합니다.(삭제 불가)`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${editLink ? '수정' : '추가'} 및 안내 발송`,
      cancelButtonText: '취소',
      confirmButtonColor: '#F24462',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        saveLink();
      }
    });
  };

  useEffect(() => {
    if (targetOption.video_call_link) {
      setLink(targetOption.video_call_link);
    }
  }, []);

  const ejeTheme = createTheme({
    palette: {
      pink: {
        light: '#f46981',
        main: '#F24462',
        dark: '#a92f44',
        contrastText: '#fff',
      },
      pinkHover: {
        light: '#ff93ab',
        main: '#FF7896',
        dark: '#b25469',
        contrastText: '#000',
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={ejeTheme}>
        <div>
          <span>*비대면 강의를 위한 화상 통화 링크를 {editLink ? '수정' : '추가'}합니다.</span>
          <br />*{editLink ? '수정' : '추가'} 시{' '}
          <span
            style={{
              color: '#FF0000',
              fontWeight: 'bold',
            }}
          >
            즉시 반영
          </span>
          되며, 수강생에게 링크 정보에 대한{' '}
          <span
            style={{
              color: '#FF0000',
              fontWeight: 'bold',
            }}
          >
            안내가 발송
          </span>
          됩니다.
          <br />
          *수강생 수: {targetOption.student_count}명
        </div>
        <div
          style={{
            margin: '2rem 0',
          }}
        >
          <div
            style={{
              marginBottom: '1rem',
            }}
          >
            <label htmlFor="link">링크: </label>
            <MuiInput value={link} onChange={onChangeLink} style={{ width: '25rem' }} id="link" color="pink" />
          </div>
          {!isValidLink && (
            <span style={{ color: '#F24462' }}>
              * 유효하지 않은 형식의 링크입니다. https://로 시작하는 링크를 입력해주세요.
            </span>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '0.5rem',
          }}
        >
          <Button.MUI
            variant="contained"
            color="pink"
            disabled={!link.length > 0 || link.length === 0 || !isValidLink || link === targetOption.video_call_link}
            onClick={onClickSave}
          >
            링크 {editLink ? '수정' : '추가'} 및 안내 발송
          </Button.MUI>
          <Button.MUI variant="outlined" color="pink" onClick={() => Swal.clickCancel()}>
            취소
          </Button.MUI>
        </div>
      </ThemeProvider>
    </div>
  );
};

export function OptionInput(props) {
  const {
    onSaveData,
    offlineOption,
    keyUpStudentLimit,
    setEdited,
    edited,
    onCancel,
    onSubmitEdit,
    classrooms,
    lectureType,
  } = props;

  const initialOfflineOption = {
    title: null,
    class_dates: [],
    student_limit: 0,
    option_price: 0,
    show_timer: false,
    timer_text: null,
    show_start_date: null,
    show_start_time: null,
    show_end_date: null,
    show_end_time: null,
    option_price_is_promotion: false,
    FK_classroom_id: null,
  };

  const initialRoundOnlineOption = {
    title: null,
    start_date: null,
    end_date: null,
    class_round: null,
    class_start_time: null,
    class_end_time: null,
    student_limit: 0,
    option_price: 0,
    show_timer: false,
    timer_text: null,
    show_start_date: null,
    show_start_time: null,
    show_end_date: null,
    show_end_time: null,
    option_price_is_promotion: false,
  };

  const [checkNickname, setCheckNickname] = useState(true);
  const [options, setOptions] = useState(offlineOption ? initialOfflineOption : initialRoundOnlineOption);
  const studentLimitRef = useRef();

  const targetOption = setEdited ? edited : options;

  const handleSubmit = () => {
    if (!checkNickname)
      return Swal.fire({
        html: '옵션명은 최대 13자까지 입력 가능합니다.',
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
    onSaveData(options);
    studentLimitRef.current.value = null;
    setOptions(offlineOption ? initialOfflineOption : initialRoundOnlineOption);
  };

  const onChangeTitle = (value) => {
    !setEdited && setOptions({ ...options, title: value });
    setEdited && setEdited({ ...edited, title: value });
    let reg = /^.{0,13}$/;

    if (reg.test(value)) {
      !checkNickname && setCheckNickname(true);
    } else {
      checkNickname && setCheckNickname(false);
    }
  };

  const getButtonDisabled = () => {
    const isNotDoneShowOption =
      (targetOption.show_start_date ||
        targetOption.show_start_time ||
        targetOption.show_end_date ||
        targetOption.show_end_time) &&
      !(
        targetOption.show_start_date &&
        targetOption.show_start_time &&
        targetOption.show_end_date &&
        targetOption.show_end_time
      );

    if (offlineOption) {
      return (
        !targetOption.title ||
        !targetOption.class_dates.length ||
        !targetOption.class_start_time ||
        !targetOption.class_end_time ||
        !targetOption.FK_classroom_id ||
        isNotDoneShowOption
      );
    } else {
      return isNotDoneShowOption;
    }
  };

  const SwalWithReactContent = withReactContent(Swal);

  const onClickAddVideoCallLink = (targetOption, isEditVideoLink) => {
    SwalWithReactContent.fire({
      title: `비대면 강의 - 화상 통화 링크 ${isEditVideoLink ? '수정' : '추가'}`,
      html: (
        <AddVideoCallLinkPopUp
          targetOption={targetOption}
          setEdited={setEdited}
          editLink={isEditVideoLink}
          lectureType={lectureType}
        />
      ),
      showCloseButton: true,
      allowOutsideClick: false,
      showConfirmButton: false,
      width: '600px',
    });
  };

  const classroomInfo = classrooms?.find((classroom) => classroom.id === targetOption.FK_classroom_id);
  const isEditVideoLink = targetOption.video_call_link && targetOption.video_call_link.length > 0;

  return (
    <>
      <tr>
        <td>
          <Input value={targetOption.title} placeholder="옵션명" onChange={(value) => onChangeTitle(value)} />
        </td>
        <td>
          <Period
            value={{
              startDate: targetOption.start_date,
              endDate: targetOption.end_date,
            }}
            onChange={({ startDate, endDate }) => {
              !setEdited
                ? setOptions({
                    ...options,
                    start_date: startDate,
                    end_date: endDate,
                  })
                : setEdited({
                    ...edited,
                    start_date: startDate,
                    end_date: endDate,
                  });
            }}
            required
          />
        </td>
        <td>
          <Period
            value={{
              startDate: targetOption.show_start_date,
              startTime: targetOption.show_start_time,
              endDate: targetOption.show_end_date,
              endTime: targetOption.show_end_time,
            }}
            onChange={({ startDate, startTime, endDate, endTime }) => {
              !setEdited
                ? setOptions({
                    ...options,
                    show_start_date: startDate,
                    show_start_time: startTime,
                    show_end_date: endDate,
                    show_end_time: endTime,
                  })
                : setEdited({
                    ...edited,
                    show_start_date: startDate,
                    show_start_time: startTime,
                    show_end_date: endDate,
                    show_end_time: endTime,
                  });
            }}
            showTime={true}
          />
        </td>
        <td>
          <Input
            required
            placeholder="기수"
            type="number"
            value={targetOption.class_round}
            onChange={(value) => {
              !setEdited
                ? setOptions({ ...options, class_round: value })
                : setEdited({ ...edited, class_round: value });
            }}
          />
        </td>
        {offlineOption && (
          <td>
            <Weekdays
              value={targetOption.class_dates}
              onChange={(value) => {
                !setEdited
                  ? setOptions({ ...options, class_dates: value })
                  : setEdited({ ...edited, class_dates: value });
              }}
            />
          </td>
        )}
        <td>
          <Base>
            <Input
              type="time"
              value={targetOption.class_start_time}
              onChange={(value) => {
                !setEdited
                  ? setOptions({ ...options, class_start_time: value })
                  : setEdited({ ...edited, class_start_time: value });
              }}
            />
            ~
            <Input
              type="time"
              value={targetOption.class_end_time}
              onChange={(value) => {
                !setEdited
                  ? setOptions({ ...options, class_end_time: value })
                  : setEdited({ ...edited, class_end_time: value });
              }}
            />
          </Base>
        </td>
        <td>
          <Base type="input">
            <input
              type="number"
              min="0"
              max="999"
              ref={studentLimitRef}
              defaultValue={targetOption.student_limit}
              onKeyUp={(e) =>
                !setEdited
                  ? keyUpStudentLimit(e, studentLimitRef, setOptions, options)
                  : keyUpStudentLimit(e, studentLimitRef, setEdited, edited)
              }
              onClick={(e) => {
                !setEdited
                  ? setOptions({ ...options, student_limit: e.target.value })
                  : setEdited({ ...edited, student_limit: e.target.value });
              }}
              style={{ width: 80, display: 'inline-block' }}
            />
          </Base>
        </td>
        {offlineOption && (
          <td>
            <div className={`offline--options--input--location`}>
              <FormControl required sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-required-label">장소</InputLabel>
                <Select
                  sx={{
                    fontSize: 14,
                  }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={
                    targetOption.FK_classroom_id
                      ? targetOption.FK_classroom_id
                      : targetOption.class_location
                      ? '기타'
                      : ''
                  }
                  label="장소 *"
                  onChange={(event) => {
                    !setEdited
                      ? setOptions({ ...options, FK_classroom_id: event.target.value })
                      : setEdited({ ...edited, FK_classroom_id: event.target.value });
                  }}
                >
                  {classrooms?.map((classroom) => (
                    <MenuItem
                      key={classroom.id}
                      value={classroom.id}
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {classroom.location} | {classroom.name}
                    </MenuItem>
                  ))}
                  {!targetOption.FK_classroom_id && targetOption.class_location && (
                    <MenuItem
                      value="기타"
                      unselectable="true"
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: '#d33',
                        }}
                      >
                        {targetOption.class_location}
                      </span>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {classroomInfo?.type === 'online' && lectureType === 'consulting' && (
                <Button.MUI
                  size="small"
                  variant="outlined"
                  disabled={!targetOption.id || targetOption.student_count <= 0}
                  onClick={() => onClickAddVideoCallLink(targetOption, isEditVideoLink)}
                >
                  링크 {isEditVideoLink ? '수정' : '추가'}
                </Button.MUI>
              )}
            </div>
          </td>
        )}
        <td>
          <Input
            required
            placeholder="옵션가"
            type="number"
            name="option_price"
            value={targetOption.option_price}
            onChange={(value) => {
              !setEdited
                ? setOptions({ ...options, option_price: value })
                : setEdited({ ...edited, option_price: value });
            }}
          />
        </td>
        <td>
          <Checkbox
            checked={targetOption.show_timer}
            onChange={({ target: { checked: value } }) => {
              !setEdited ? setOptions({ ...options, show_timer: value }) : setEdited({ ...edited, show_timer: value });
            }}
          />
        </td>
        <td>
          <Input
            placeholder="타이머 강조문구"
            value={targetOption.timer_text}
            onChange={(value) => {
              !setEdited ? setOptions({ ...options, timer_text: value }) : setEdited({ ...edited, timer_text: value });
            }}
          />
        </td>
        <td>
          <Checkbox
            checked={targetOption.option_price_is_promotion}
            onChange={({ target: { checked: value } }) => {
              !setEdited
                ? setOptions({ ...options, option_price_is_promotion: value })
                : setEdited({ ...edited, option_price_is_promotion: value });
            }}
          />
        </td>
        <td>
          <Button.Negative
            onClick={() => (!setEdited ? handleSubmit() : onSubmitEdit(checkNickname))}
            disabled={getButtonDisabled()}
          >
            {!setEdited ? '+추가' : '수정 완료'}
          </Button.Negative>
        </td>
        <td>{setEdited && <Button.Negative onClick={onCancel}>취소</Button.Negative>}</td>
      </tr>
    </>
  );
}

export function SampleVideos(props) {
  const { value: videos, onChange } = props;

  const onChangeVideo = useCallback(
    (key, value, index) => {
      const _videos = [...videos];
      const video = _videos[index] ? { ..._videos[index] } : {};
      video[key] = value;
      _videos[index] = video;
      onChange(_videos);
    },
    [videos, onChange],
  );

  return (
    <Base {...props} type="sample-videos">
      <table>
        <thead>
          <tr>
            <th>회차</th>
            <th>샘플 강의명</th>
            <th>영상(유튜브URL)</th>
            <th>시간</th>
          </tr>
        </thead>
        <tbody>
          {[videos[0], videos[1]].map((video, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <input
                    value={video?.title || ''}
                    placeholder="샘플 강의명을 입력하세요 (최대20자)"
                    onChange={(e) => onChangeVideo('title', e.target.value, index)}
                  />
                </td>
                <td>
                  <input
                    value={video?.url || ''}
                    placeholder="url을 입력하세요"
                    onChange={(e) => onChangeVideo('url', e.target.value, index)}
                  />
                </td>
                <td>
                  <input
                    value={video?.video_length || ''}
                    placeholder="비디오 길이를 입력하세요"
                    onChange={(e) => {
                      onChangeVideo('video_length', e.target.value, index);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Base>
  );
}

export function Chapters(props) {
  const { type, value: chapters, onChange, shouldUploadImage, editing } = props;

  const SwalWithReactContent = withReactContent(Swal);
  const [videos, setVideos] = useState([]);
  const [compulsoryVideos, setCompulsoryVideos] = useState(null);
  const [anotherVideos, setAnotherVideos] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [isAddPreSurvey, setIsAddPreSurvey] = useState(false);
  const [isAddPostSurvey, setIsAddPostSurvey] = useState(false);
  const [selectedSurveyIds, setSelectedSurveyIds] = useState([]);

  useEffect(() => {
    if (type === 'compulsory') {
      if (compulsoryVideos) {
        setVideos(compulsoryVideos);
      } else {
        request.videosForClass({ isCompulsory: true }).then(({ videos }) => {
          setVideos(videos);
          setCompulsoryVideos(videos);
        });
      }
    } else {
      if (anotherVideos) {
        setVideos(anotherVideos);
      } else {
        request.videosForClass({ isCompulsory: false }).then(({ videos }) => {
          setVideos(videos);
          setAnotherVideos(videos);
        });
      }
    }

    request.getSurveys({ limit: 99999999 }).then(({ surveys }) => {
      setSurveys(surveys.list);
    });
  }, [type]);

  useEffect(() => {
    if (chapters[0]?.timing === 'before') {
      setIsAddPreSurvey(true);
    }
    if (chapters[chapters.length - 1]?.timing === 'after') {
      setIsAddPostSurvey(true);
    }

    setSelectedSurveyIds(chapters.map((chapter) => chapter.ClassSections[0]?.FK_survey_id).filter((id) => id));
  }, [chapters]);

  const [clickedChapter, setClickedChapter] = useState(null);
  const [timeoutIdent, setTimeoutIdent] = useState(null);
  const [clickedSection, setClickedSection] = useState(null);
  const [timeoutIdentForSection, setTimeoutIdentForSection] = useState(null);

  const onClickAddChapter = useCallback(() => {
    const _chapters = [...chapters];
    const _chaptersKeyIds = _chapters.map((_chapter) => {
      if (_chapter.id) return _chapter.id;
      else return _chapter.keyId;
    });
    _chaptersKeyIds.sort((a, b) => a - b);
    const keyId = _chaptersKeyIds[_chaptersKeyIds.length - 1] + 1;

    const chapter = {
      keyId: keyId || 1,
      ClassSections: [],
    };
    if (isAddPostSurvey) {
      const newChapters = [...chapters];
      newChapters.splice(newChapters.length - 1, 0, chapter);
      onChange(newChapters);
    } else {
      onChange([...chapters, chapter]);
    }
  }, [chapters, onChange, isAddPostSurvey]);

  const addSurveyChapter = useCallback(
    ({ title, timing }) => {
      const _chapters = [...chapters];
      const _chaptersKeyIds = _chapters.map((_chapter) => {
        if (_chapter.id) return _chapter.id;
        else return _chapter.keyId;
      });
      _chaptersKeyIds.sort((a, b) => a - b);
      const keyId = _chaptersKeyIds[_chaptersKeyIds.length - 1] + 1;

      const chapter = {
        keyId: keyId || 1,
        type: 'survey',
        timing,
        ClassSections: [],
        title: title || '',
      };
      const section = {
        keyId: 1,
        type: 'survey',
        timing,
        FK_survey: { title: '' },
        image_thumb: 'https://enterjobedu.s3.ap-northeast-2.amazonaws.com/class/introduction/section-default-image.png',
      };
      chapter.ClassSections.push(section);

      if (timing === 'before') {
        onChange([chapter, ...chapters]);
      } else {
        onChange([...chapters, chapter]);
      }
    },
    [chapters, onChange],
  );

  const onClickAddPreSurvey = useCallback(() => {
    SwalWithReactContent.close();
    addSurveyChapter({ title: '수강 전 설문', timing: 'before' });
  }, [addSurveyChapter]);

  const onClickAddPostSurvey = useCallback(() => {
    SwalWithReactContent.close();
    addSurveyChapter({ title: '수강 후 설문', timing: 'after' });
  }, [addSurveyChapter]);

  const onClickAddSurvey = useCallback(() => {
    SwalWithReactContent.fire({
      title: '설문 추가',
      html: (
        <div>
          <div>추가할 설문 위치를 선택하세요.</div>
          <div style={{ marginTop: '10px' }}>
            <button onClick={onClickAddPreSurvey} disabled={isAddPreSurvey}>
              수강 전 설문
            </button>
            <button onClick={onClickAddPostSurvey} disabled={isAddPostSurvey} style={{ marginLeft: '0.5rem' }}>
              수강 후 설문
            </button>
          </div>
        </div>
      ),
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }, [onClickAddPreSurvey, onClickAddPostSurvey, isAddPreSurvey, isAddPostSurvey]);

  const onClickRemoveChapterAt = useCallback(
    (index) => {
      const _chapters = [...chapters];
      const splicedChapter = _chapters.splice(index, 1);

      if (splicedChapter[0].type === 'survey') {
        if (splicedChapter[0].timing === 'before') {
          setIsAddPreSurvey(false);
        } else {
          setIsAddPostSurvey(false);
        }
      }
      onChange(_chapters);
    },
    [chapters, onChange, setIsAddPreSurvey, setIsAddPostSurvey],
  );

  const onClickAddSectionAt = useCallback(
    (index) => {
      const _chapters = [...chapters];
      const _chapter = { ..._chapters[index] };

      const _sectionsKeyIds = _chapter.ClassSections.map((_section) => {
        if (_section.id) return _section.id;
        else return _section.keyId;
      });
      _sectionsKeyIds.sort((a, b) => a - b);
      const keyId = _sectionsKeyIds[_sectionsKeyIds.length - 1] + 1;

      const section = {
        keyId: keyId || 1,
        FK_video: {},
        image_thumb: 'https://enterjobedu.s3.ap-northeast-2.amazonaws.com/class/introduction/section-default-image.png',
      };
      _chapter.ClassSections.push(section);
      _chapters[index] = _chapter;
      onChange(_chapters);
    },
    [chapters, onChange],
  );

  const [selectedSections, setSelectedSections] = useState([]);
  const onSelectSection = useCallback(
    (section) => {
      if (selectedSections.includes(section)) {
        setSelectedSections((sections) => sections.filter((_section) => _section !== section));
      } else {
        setSelectedSections((sections) => [...sections, section]);
      }
    },
    [selectedSections],
  );

  const onClickRemoveSelectedSections = useCallback(() => {
    const _chapters = chapters.map((chapter) => ({
      ...chapter,
      ClassSections: chapter.ClassSections.filter((section) => !selectedSections.includes(section)),
    }));
    onChange(_chapters);
  }, [chapters, onChange, selectedSections]);

  const onChangeChapterAt = useCallback(
    (index, e) => {
      const _chapters = [...chapters];
      const _chapter = { ..._chapters[index] };
      _chapter[e.target.name] = e.target.value;
      _chapters[index] = _chapter;
      onChange(_chapters);
    },
    [chapters, onChange],
  );

  const onChangeSectionAt = useCallback(
    (chapterIndex, index, e) => {
      const _chapters = [...chapters];
      const _chapter = { ..._chapters[chapterIndex] };
      const _sections = [..._chapter.ClassSections];
      const _section = { ..._sections[index] };
      _section[e.target.name] = e.target.value;
      _sections[index] = _section;
      _chapter.ClassSections = _sections;
      _chapters[chapterIndex] = _chapter;
      onChange(_chapters);
    },
    [chapters, onChange],
  );

  const onChangeSectionVideoAt = useCallback(
    (chapterIndex, index, videoId) => {
      const _chapters = [...chapters];
      const _chapter = { ..._chapters[chapterIndex] };
      const _sections = [..._chapter.ClassSections];
      const _section = { ..._sections[index] };
      _section.FK_video_id = videoId;
      _section.FK_video = videos.find((video) => video.id === videoId);
      _sections[index] = _section;
      _chapter.ClassSections = _sections;
      _chapters[chapterIndex] = _chapter;
      onChange(_chapters);
    },
    [chapters, videos, onChange],
  );

  const onChangeSectionSurveyAt = useCallback(
    (chapterIndex, index, surveyId) => {
      const _chapters = [...chapters];
      const _chapter = { ..._chapters[chapterIndex] };
      const _sections = [..._chapter.ClassSections];
      const _section = { ..._sections[index] };
      _section.FK_survey_id = surveyId;
      _section.FK_survey = surveys.find((survey) => survey.id === surveyId);
      _section.title = _section.FK_survey?.title;
      _sections[index] = _section;
      _chapter.ClassSections = _sections;
      _chapters[chapterIndex] = _chapter;
      onChange(_chapters);
    },
    [chapters, surveys, onChange],
  );

  const changeChapterOrder = useCallback(
    (chapterIndex, isUp) => {
      const order = chapterIndex + 1;
      let _chapters;

      if (isUp) {
        _chapters = chapters.map((chapter, index) => {
          if (index === chapterIndex) chapter.order = order - 1;
          if (index === chapterIndex - 1) chapter.order = order;
          return chapter;
        });
      } else {
        _chapters = chapters.map((chapter, index) => {
          if (index === chapterIndex) chapter.order = order + 1;
          if (index === chapterIndex + 1) chapter.order = order;
          return chapter;
        });
      }

      _chapters.sort((a, b) => a.order - b.order);

      onChange(_chapters);
    },
    [chapters, onChange],
  );

  const changeClickedChapterColor = (chapterIdOrKey) => {
    setClickedChapter(chapterIdOrKey);
    clickedChapter && clearTimeout(timeoutIdent);
    const timeoutIdentOrigin = setTimeout(() => {
      setClickedChapter(null);
    }, 1000);
    setTimeoutIdent(timeoutIdentOrigin);
  };

  const changeClickedSectionColor = (sectionIdOrKey) => {
    setClickedSection(sectionIdOrKey);
    clickedSection && clearTimeout(timeoutIdentForSection);
    const timeoutIdentOrigin = setTimeout(() => {
      setClickedSection(null);
    }, 1000);
    setTimeoutIdentForSection(timeoutIdentOrigin);
  };

  return (
    <Base {...props} type="chapters">
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>회차</th>
              <th>강의명/설문명</th>
              <th>썸네일</th>
              {type !== 'offline' && type !== 'consulting' && <th>영상키값/설문값</th>}
              {(type === 'offline' || type === 'consulting') && <th>설문값</th>}
              <th>수정</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {chapters.map((chapter, i) => (
              <Fragment key={chapter.id || chapter.keyId}>
                <tr>
                  <th
                    className={`${
                      chapter.id === clickedChapter || chapter.keyId === clickedChapter
                        ? 'clickedTh field__chapter'
                        : 'field__chapter'
                    } ${chapter.type === 'survey' ? 'field__survey' : ''}`}
                    colSpan="7"
                  >
                    <span>
                      {i + 1}
                      <input
                        name="title"
                        placeholder="목차와 타이틀을 입력하세요."
                        value={chapter.title || ''}
                        onChange={(e) => onChangeChapterAt(i, e)}
                      />
                      <button onClick={() => onClickRemoveChapterAt(i)}>
                        {chapter.type === 'survey' ? '설문 삭제' : '목차 삭제'}
                      </button>
                      {chapter.type !== 'survey' && <button onClick={() => onClickAddSectionAt(i)}>강의 추가</button>}
                      {chapter.type !== 'survey' && (
                        <IconButton
                          size="small"
                          disabled={i === 0 || chapters[i - 1]?.timing === 'before'}
                          onClick={() => {
                            changeChapterOrder(i, true);
                            changeClickedChapterColor(chapter.id || chapter.keyId);
                          }}
                        >
                          <ArrowUpward fontSize="small" />
                        </IconButton>
                      )}
                      {chapter.type !== 'survey' && (
                        <IconButton
                          size="small"
                          disabled={i === chapters.length - 1 || chapters[i + 1]?.timing === 'after'}
                          onClick={() => {
                            changeChapterOrder(i, false);
                            changeClickedChapterColor(chapter.id || chapter.keyId);
                          }}
                        >
                          <ArrowDownward fontSize="small" />
                        </IconButton>
                      )}
                    </span>
                  </th>
                </tr>
                {chapter.ClassSections.map((section, j) => (
                  <ClassSections
                    key={section.id || section.keyId}
                    section={section}
                    chapters={chapters}
                    selectedSections={selectedSections}
                    onChangeSectionAt={onChangeSectionAt}
                    onSelectSection={onSelectSection}
                    Image={Image}
                    i={i}
                    j={j}
                    shouldUploadImage={shouldUploadImage}
                    videos={videos}
                    surveys={surveys}
                    type={type}
                    SelectSearch={SelectSearch}
                    onChangeSectionVideoAt={onChangeSectionVideoAt}
                    onChangeSectionSurveyAt={onChangeSectionSurveyAt}
                    onChange={onChange}
                    changeClickedSectionColor={changeClickedSectionColor}
                    clickedSection={clickedSection}
                    selectedSurveyIds={selectedSurveyIds}
                  />
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
        <button onClick={onClickRemoveSelectedSections}>선택 삭제</button>
        <button onClick={onClickAddChapter}>목차 추가</button>
        <button onClick={onClickAddSurvey} disabled={isAddPreSurvey && isAddPostSurvey}>
          설문 추가
        </button>
      </div>
    </Base>
  );
}

export function Lectures(props) {
  const [searchType, setSearchType] = useState('title');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([]);

  const onClickSearch = useCallback(() => {
    setFilters(searchText ? [{ id: searchType, value: searchText }] : []);
  }, [searchType, searchText]);

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        page: pageIndex + 1,
        limit: pageSize,
        filtered: JSON.stringify(filters),
      };
      if (type === 'transferReview') {
        request.lectures('review_lectures', params).then((response) => {
          setLectures(response.list);
          setTotal(response.total);
        });
      } else {
        request.lectures('in_package', params).then(({ classes }) => {
          setLectures(classes.rows.map((row) => new Model.Lecture(row)));
          setTotal(classes.count);
        });
      }
    },
    [filters],
  );

  const { type, value: selectedLecturesOrigin, transferReviewLogs, onChange } = props;
  const selectedLectures = selectedLecturesOrigin.map((lecture) =>
    lecture.FK_class_single ? lecture.FK_class_single : lecture,
  );
  const transferredLectures = transferReviewLogs?.map((log) => log.FK_class_from.id) || [];
  const onClickLecture = useCallback(
    (lecture) => {
      const index = selectedLectures.findIndex((_lecture) => _lecture.id === lecture.id);
      if (index >= 0) {
        const _selectedLectures = [...selectedLectures];
        _selectedLectures.splice(index, 1);
        onChange(_selectedLectures);
      } else {
        onChange([...selectedLectures, lecture]);
      }
    },
    [selectedLectures, onChange],
  );

  const columns = [
    {
      Header: ' ',
      accessor: (lecture) => (
        <input
          type="checkbox"
          checked={!!selectedLectures.find((_lecture) => _lecture.id === lecture.id)}
          onChange={() => onClickLecture(lecture)}
          disabled={type === 'transferReview' && transferredLectures.includes(lecture.id)}
        />
      ),
    },
    { Header: '강의ID', accessor: 'id' },
    { Header: '강의명', accessor: 'title' },
    {
      Header: '강사명',
      accessor: 'Class_Tutors',
      Cell: ({ value: Class_Tutors }) => Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(', ') || '-',
    },
    {
      Header: '강의구분',
      accessor: 'listen_type',
      Cell: ({ value: listen_type }) => <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />,
    },
  ];

  if (type === 'transferReview') {
    columns.push(
      {
        Header: '공개 리뷰 수',
        accessor: 'reviewCnt',
      },
      {
        Header: '비공개/대기 리뷰 수',
        accessor: 'unseeReviewCnt',
      },
    );
  }

  return (
    <Base {...props} type="lectures">
      {type === 'transferReview' && transferReviewLogs.length > 0 && (
        <div className="field__review-transfer-logs">
          <p className="field__transfer-title">* 후기 이관 내역</p>
          {transferReviewLogs.map((log) => (
            <div key={log.id}>
              <span>
                {moment(log.createdAt).format('YYYY-MM-DD')} | {log.review_count}개 | [{log.FK_class_from.id}]{' '}
                {log.FK_class_from.title}
              </span>
            </div>
          ))}
        </div>
      )}
      <div className="field__search">
        <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <option value="title">강의명</option>
          <option value="id">강의 ID</option>
        </select>
        <input
          placeholder="단일 강의명 혹은 ID를 검색해보세요"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
        {type === 'transferReview' && (
          <span>* 중복 후기는 이관되지 않으며, 베스트 후기는 3개까지 이관되어 등록됩니다.</span>
        )}
      </div>
      <Table
        data={lectures}
        total={total}
        pageSize={type === 'transferReview' ? 5 : 10}
        columns={columns}
        usesLocation={false}
        fetchData={fetchData}
      />
      {type === 'transferReview' && selectedLectures.length > 0 && (
        <span className="field--lectures--selected">
          선택된 강의 :&nbsp;
          {selectedLectures.map((selectedLecture) => (
            <Chip
              key={selectedLecture.id}
              name={`[${selectedLecture.id}] ${selectedLecture.title}`}
              selected={true}
              onClick={() => onClickLecture(selectedLecture)}
            />
          ))}
        </span>
      )}
    </Base>
  );
}

export function PackageLectures(props) {
  const [clickedLecture, setClickedLecture] = useState(null);
  const [timeoutIdent, setTimeoutIdent] = useState(null);

  const { value: selectedLecturesOrigin, onChange } = props;
  const selectedLectures = selectedLecturesOrigin.map((lecture) =>
    lecture.FK_class_single ? lecture.FK_class_single : lecture,
  );
  const onClickLecture = useCallback(
    (lecture) => {
      const index = selectedLectures.findIndex((_lecture) => _lecture.id === lecture.id);
      if (index >= 0) {
        const _selectedLectures = [...selectedLectures];
        _selectedLectures.splice(index, 1);
        onChange(_selectedLectures);
      } else {
        onChange([...selectedLectures, lecture]);
      }
    },
    [selectedLectures, onChange],
  );

  const changeLectureOrder = useCallback(
    (lectureIndex, isUp) => {
      const order = lectureIndex + 1;
      let _selectedLectures;

      if (isUp) {
        _selectedLectures = selectedLecturesOrigin.map((lecture, index) => {
          if (index === lectureIndex) lecture.order = order - 1;
          if (index === lectureIndex - 1) lecture.order = order;
          return lecture;
        });
      } else {
        _selectedLectures = selectedLecturesOrigin.map((lecture, index) => {
          if (index === lectureIndex) lecture.order = order + 1;
          if (index === lectureIndex + 1) lecture.order = order;
          return lecture;
        });
      }

      _selectedLectures.sort((a, b) => a.order - b.order);

      onChange(_selectedLectures);
    },
    [selectedLectures, onChange],
  );

  const changeClickedLectureColor = (lectureId) => {
    setClickedLecture(lectureId);
    clickedLecture && clearTimeout(timeoutIdent);
    const timeoutIdentOrigin = setTimeout(() => {
      setClickedLecture(null);
    }, 1000);
    setTimeoutIdent(timeoutIdentOrigin);
  };

  return (
    <Base {...props} type="lectures">
      <div className="field__lectures">
        <div className="field__lectures--title">
          <span>*패키지 단일 강의 리스트</span>
        </div>
        <table>
          <thead>
            <tr>
              <th>강의 ID</th>
              <th>강의명</th>
              <th>강사명</th>
              <th>강의구분</th>
              <th>순서 변경</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedLectures.map((lecture, index) => (
              <tr key={lecture.id} className={clickedLecture === lecture.id ? 'clickedTr' : ''}>
                <td>{lecture.id}</td>
                <td>{lecture.title}</td>
                <td>{lecture.Class_Tutors?.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(', ') || '-'}</td>
                <td>
                  {lecture.listen_type ? (
                    <FormattedMessage id={'ID_LISTEN_TYPE_' + lecture.listen_type?.toUpperCase()} />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <IconButton
                    size="small"
                    disabled={index === 0}
                    onClick={() => {
                      changeLectureOrder(index, true);
                      changeClickedLectureColor(lecture.id);
                    }}
                  >
                    <ArrowUpward fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={index === selectedLectures.length - 1}
                    onClick={() => {
                      changeLectureOrder(index, false);
                      changeClickedLectureColor(lecture.id);
                    }}
                  >
                    <ArrowDownward fontSize="small" />
                  </IconButton>
                </td>
                <td>
                  <img
                    onClick={() => onClickLecture(lecture)}
                    src={CloseIcon}
                    alt="close_image"
                    className="close-button"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="field__search--title">
        <span>*패키지 단일 강의 추가</span>
      </div>
      <Lectures value={selectedLectures} onChange={onChange} />
    </Base>
  );
}

export function SurveySections(props) {
  const { sections, setSections, registeredQuestions, setRegisteredQuestions } = props;

  const SwalWithReactContent = withReactContent(Swal);

  const onClickAddSection = useCallback(() => {
    const newSections = [...sections, { title: `섹션 ${sections.length + 1}`, questions: [] }];
    setSections(newSections);
  }, [sections]);

  const onClickDeleteSection = useCallback(
    (sectionIndex) => {
      setSections(sections.filter((section, index) => index !== sectionIndex));

      const deletedSection = sections[sectionIndex];
      const deletedQuestions = deletedSection.questions;
      const deletedQuestionIds = deletedQuestions.map((question) => question.id);
      setRegisteredQuestions(registeredQuestions.filter((question) => !deletedQuestionIds.includes(question.id)));
    },
    [sections, registeredQuestions],
  );

  const onClickAddQuestion = useCallback(
    (addQuestions, sectionIndex) => {
      setRegisteredQuestions([...registeredQuestions, ...addQuestions]);
      setSections(
        sections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              questions: [...section.questions, ...addQuestions],
            };
          }
          return section;
        }),
      );
    },
    [registeredQuestions, sections],
  );

  // 질문 추가 팝업 오픈
  const showAddQuestion = (index) => {
    SwalWithReactContent.fire({
      title: `질문 추가`,
      html: (
        <AddQuestion
          registeredQuestions={registeredQuestions}
          onClickAddQuestion={onClickAddQuestion}
          sectionIndex={index}
        />
      ),
      showCloseButton: true,
      allowOutsideClick: false,
      showConfirmButton: false,
      width: '1200px',
    });
  };

  // 설정된 질문 삭제 핸들러
  const onClickDeleteQuestion = useCallback(
    (FK_question_id) => {
      setRegisteredQuestions(registeredQuestions.filter((question) => question.FK_question_id !== FK_question_id));
      sections.forEach((section) => {
        section.questions = section.questions.filter((question) => question.FK_question_id !== FK_question_id);
      });
    },
    [registeredQuestions],
  );

  const onChangeSectionTitle = useCallback(
    (sectionIndex, title) => {
      const newSections = sections.map((section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            title,
          };
        }
        return section;
      });

      setSections(newSections);
    },
    [sections],
  );

  // 테이블 컬럼 설정
  const registeredQuestionsColumns = useMemo(
    () => [
      { Header: '질문 ID', accessor: (row) => row.FK_question_id },
      { Header: '필수값', accessor: (row) => (!!row.is_required ? '필수' : '선택') },
      { Header: '질문 유형', accessor: (row) => row.FK_question_type?.name },
      { Header: '선택 유형', accessor: (row) => row.FK_choice_layout?.name ?? '-' },
      { Header: '질문', accessor: (row) => row.content },
      {
        Header: '제거',
        accessor: (row) => (
          <img
            onClick={() => onClickDeleteQuestion(row.FK_question_id)}
            src={CloseIcon}
            alt="close_image"
            className="close-button"
          />
        ),
      },
    ],
    [onClickDeleteQuestion],
  );

  // 테이블 드래그 앤 드롭 핸들러
  const handleDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      // 아이템이 리스트 밖으로 드래그된 경우
      if (!destination) return;

      const destIndex = Number(destination.droppableId);
      const sourceIndex = Number(source.droppableId);

      const destRows = [...sections[destIndex].questions];
      const sourceRows = [...sections[sourceIndex].questions];
      const copiedDestRows = [...destRows];

      // 다른 리스트로 이동한 경우
      if (sourceIndex !== destIndex) {
        const copiedSourceRows = [...sourceRows];
        const [removed] = copiedSourceRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        setSections(
          sections.map((section, index) => {
            if (index === sourceIndex) {
              return {
                ...section,
                questions: copiedSourceRows,
              };
            } else if (index === destIndex) {
              return {
                ...section,
                questions: copiedDestRows,
              };
            } else {
              return section;
            }
          }),
        );
      } else {
        // 같은 리스트 내에서 이동한 경우
        const [removed] = copiedDestRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        setSections(
          sections.map((section, index) => {
            if (index === destIndex) {
              return {
                ...section,
                questions: copiedDestRows,
              };
            } else {
              return section;
            }
          }),
        );
      }
    },
    [sections],
  );

  return (
    <Base {...props} type="survey-sections">
      <Button.Neutral onClick={onClickAddSection}>섹션 추가</Button.Neutral>
      <DragDropContext onDragEnd={handleDragEnd}>
        {sections.map((section, index) => (
          <Base type="survey-questions" key={index}>
            <div className={`questions--container`}>
              <div className="questions-top--container">
                <div className="questions-top__infos">
                  <input
                    className="questions-section__input"
                    type="text"
                    value={section.title}
                    onChange={(e) => onChangeSectionTitle(index, e.target.value)}
                  />
                  <Button.Neutral onClick={() => showAddQuestion(index)}>질문 추가</Button.Neutral>
                </div>
                <div className="questions-top-close__button">
                  <img
                    onClick={() => onClickDeleteSection(index)}
                    src={CloseIcon}
                    alt="close_image"
                    className="close-button"
                  />
                </div>
              </div>
              <div className="questions-middle--container">
                <div className="questions__total">
                  📌 설정된 질문수 ({section.questions.length})
                  <span style={{ color: 'red' }}>* 정렬된 순서대로 노출됩니다.</span>
                </div>
                <span className="questions__info">* 질문을 드래그하여 옮길 수 있습니다.</span>
              </div>
              <Table
                data={section.questions}
                columns={registeredQuestionsColumns}
                total={section.questions.length}
                pageSize={section.questions.length}
                fetchData={() => {}}
                droppableId={`${index}`}
              />
            </div>
          </Base>
        ))}
      </DragDropContext>
    </Base>
  );
}

export function QuestionChoices(props) {
  const { choices, setChoices, disabled, disabledEtc } = props;

  const updateChoiceContent = (id, newContent) => {
    setChoices(
      choices.map((choice) => {
        if (choice.id === id) {
          return { ...choice, content: newContent };
        }
        return choice;
      }),
    );
  };

  const handleDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      // 아이템이 리스트 밖으로 드래그된 경우
      if (!destination) return;

      const newRows = [...choices];

      const [removed] = newRows.splice(source.index, 1);
      newRows.splice(destination.index, 0, removed);

      setChoices(newRows);
    },
    [choices],
  );

  return (
    <Base {...props}>
      <div>
        <Button.MUI
          onClick={() => {
            setChoices([
              ...choices,
              {
                id: dayjs().valueOf() + Math.random(),
                content: '',
                isEtc: false,
              },
            ]);
          }}
          disabled={disabled}
        >
          선택지 추가
        </Button.MUI>
        <DragDropContext onDragEnd={handleDragEnd}>
          <table className="dragging__table">
            <thead>
              <tr style={{ backgroundColor: 'rgba(166,197,250,0.2)' }}>
                <th
                  style={{
                    width: 30,
                  }}
                >
                  <UnfoldMoreIcon />
                </th>
                <th>선택지</th>
                <th>기타</th>
                <th>삭제</th>
              </tr>
            </thead>
            <Droppable droppableId="selects" key="selects">
              {(provided, snapshot) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {choices.map((choice, index) => (
                    <Choice
                      key={choice.id}
                      choice={choice}
                      index={index}
                      updateChoiceContent={updateChoiceContent}
                      choices={choices}
                      setChoices={setChoices}
                      disabled={disabled}
                      disabledEtc={disabledEtc}
                    />
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </Base>
  );
}

function Choice({ choice, updateChoiceContent, choices, setChoices, index, disabled, disabledEtc }) {
  const [content, setContent] = useState(choice.content);

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleBlur = () => {
    updateChoiceContent(choice.id, content);
  };

  return (
    <Draggable key={choice.id} draggableId={String(choice.id)} index={index}>
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          className={`${snapshot.isDragging ? 'dragged-row-choice' : ''}`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <td>
            <DragHandleIcon
              style={{
                color: '#dc5464',
              }}
            />
          </td>
          <td
            style={{
              backgroundColor: 'inherit',
            }}
          >
            <input
              className="questions-choice-content__input"
              type="text"
              value={content}
              onChange={handleContentChange}
              onBlur={handleBlur}
              style={{
                backgroundColor: 'inherit',
              }}
            />
          </td>
          <td
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
          >
            <Checkbox
              checked={!!choice.isEtc}
              onChange={(e) => {
                setChoices(
                  choices.map((_choice) => {
                    if (_choice.id === choice.id) {
                      return {
                        ..._choice,
                        isEtc: e.target.checked,
                      };
                    }
                    return _choice;
                  }),
                );
              }}
              disabled={disabled || disabledEtc}
            />
          </td>
          <td
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
          >
            <img
              onClick={(event) => {
                event.preventDefault();
                !disabled && setChoices(choices.filter((_choice) => _choice.id !== choice.id));
              }}
              src={CloseIcon}
              alt="close_image"
              className="close-button"
              style={{
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.5 : 1,
              }}
            />
          </td>
        </tr>
      )}
    </Draggable>
  );
}

export const LectureTerms = (props) => {
  const { terms, setTerms } = props;
  const [clickedTerm, setClickedTerm] = useState(null);

  return (
    <div className="field__lecture-terms">
      {terms.map((term, idx) => {
        return (
          <LectureTermItem
            key={term.id || term.tempId}
            term={term}
            setTerms={setTerms}
            setClickedTerm={setClickedTerm}
          />
        );
      })}

      <Button.MUI
        variant="outlined"
        onClick={() => {
          setTerms([
            ...terms,
            {
              tempId: dayjs().valueOf() + Math.random(),
              title: '제목을 입력하세요',
              content: '<p>내용을 입력하세요</p>',
            },
          ]);
        }}
      >
        추가
      </Button.MUI>

      {/*수정 모달*/}
      <Modal
        open={clickedTerm !== null}
        onClose={() => setClickedTerm(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disableEnforceFocus={true}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '4px',
          }}
        >
          <MuiInput
            value={clickedTerm ? clickedTerm.title : ''}
            onChange={(e) => {
              setClickedTerm((prev) => ({
                ...prev,
                title: e.target.value,
              }));
            }}
            sx={{
              width: '100%',
            }}
          />
          <TinyEditor
            content={clickedTerm ? clickedTerm.content : ''}
            setContent={(content) => {
              setClickedTerm((prev) => ({
                ...prev,
                content,
              }));
            }}
          />
          <Button.MUI
            onClick={() => {
              clickedTerm.id
                ? setTerms(terms.map((term) => (term.id === clickedTerm.id ? clickedTerm : term)))
                : setTerms(terms.map((term) => (term.tempId === clickedTerm.tempId ? clickedTerm : term)));

              setClickedTerm(null);
            }}
            variant="outlined"
          >
            저장
          </Button.MUI>
        </div>
      </Modal>
    </div>
  );
};

const LectureTermItem = (props) => {
  const { term, setTerms, setClickedTerm } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        border: '1px solid #e0e0e0',
        padding: '10px',
        borderRadius: '4px',
        minWidth: '25rem',
      }}
    >
      <div>{term.title}</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button.MUI
          variant="outlined"
          onClick={() => {
            setClickedTerm(term);
          }}
        >
          수정
        </Button.MUI>
        <Button.MUI
          variant="outlined"
          color="error"
          onClick={() => {
            term.id
              ? setTerms((terms) => terms.filter((_term) => _term.id !== term.id))
              : setTerms((terms) => terms.filter((_term) => _term.tempId !== term.tempId));
          }}
        >
          삭제
        </Button.MUI>
      </div>
    </div>
  );
};

const TinyEditor = (props) => {
  const { content, setContent } = props;

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const imageUploadHandler = async (blobInfo) => {
    const { image_url } = await uploadIntroductionImage(blobInfo.blob());
    return image_url;
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
      init={{
        menubar: false,
        plugins: [
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'searchreplace',
          'fullscreen',
          'media',
          'table',
          'code',
          'help',
          'emoticons',
          'quickbars',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | lists | ' +
          'table tablecellborderstyle tablecellborderwidth tablecellbackgroundcolor | ' +
          'link charmap searchreplace | ' +
          'image media codesample emoticons fullscreen preview | ' +
          'code | removeformat | help ',
        tinycomments_mode: 'embedded',
        automatic_uploads: true,
        images_upload_handler: imageUploadHandler,
        language: 'ko_KR',
        width: 640,
        min_height: 500,
      }}
      value={content}
      onEditorChange={handleEditorChange}
    />
  );
};

export const SelectThirdPartyQuestionFields = ({ fields, setFields }) => {
  const [open, setOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState(fields);
  const [fieldList, setFieldList] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFieldChange = (field) => {
    if (field.is_required) return;
    const updatedFields = selectedFields.includes(field.field)
      ? selectedFields.filter((f) => f !== field.field)
      : [...selectedFields, field.field];
    setSelectedFields(updatedFields);
  };

  const handleSave = () => {
    setFields(selectedFields);
    handleClose();
  };

  useEffect(() => {
    request.getThirdPartyQuestionFieldList().then((res) => {
      setFieldList(res);
      const requiredFields = Object.values(res).filter((field) => field.is_required);

      setSelectedFields((prevFields) => {
        const newFields = [...prevFields];
        requiredFields.forEach((field) => {
          if (!newFields.includes(field.field)) {
            newFields.push(field.field);
          }
        });

        setFields(newFields);

        return newFields;
      });
    });
  }, []);

  useEffect(() => {
    if (!open) setSelectedFields(fields);
  }, [open]);

  return (
    <div className="field__select-registration-fields">
      <Button.MUI variant="outlined" onClick={handleOpen}>
        질문 선택
      </Button.MUI>

      <Modal
        className="field__select-registration-fields__modal"
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            padding: '20px',
            backgroundColor: 'white',
            margin: '10% auto',
            width: '500px',
            borderRadius: '4px',
          }}
        >
          <h2>질문 항목 선택</h2>

          <FormGroup>
            {Object.values(fieldList)
              .filter((field) => field.is_active)
              .map((field) => (
                <FormControlLabel
                  key={field.field}
                  control={
                    <Checkbox
                      checked={selectedFields.includes(field.field)}
                      onChange={() => handleFieldChange(field)}
                      disabled={field.is_required}
                    />
                  }
                  label={`${field.label} ${field.is_optional ? ' (선택값)' : ''}`}
                />
              ))}
          </FormGroup>

          <div className="modal__buttons">
            <Button.MUI variant="contained" onClick={handleSave}>
              저장
            </Button.MUI>
          </div>
        </div>
      </Modal>
    </div>
  );
};
