import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as request from '../../../../Common/Util/Request';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import Swal from 'sweetalert2';

function TutorList(props) {
  const history = useHistory();

  const [tutors, setTutors] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedTutorIds, setSelectedTutorIds] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: (tutor) => {
          const checked = !!selectedTutorIds.find((id) => id === tutor.id);
          return (
            <input
              type="checkbox"
              checked={checked}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                if (checked) {
                  setSelectedTutorIds(selectedTutorIds.filter((id) => id !== tutor.id));
                } else {
                  setSelectedTutorIds([...selectedTutorIds, tutor.id]);
                }
              }}
            />
          );
        },
      },
      // { Header: '강사ID', accessor: 'id' },
      { Header: '강사명', accessor: 'name' },
      { Header: '이니셜', accessor: 'name_cap' },
      {
        Header: '연락처',
        accessor: ({ phone }) => {
          // phone format: 01012345678 => 010-1234-5678
          if (!phone) return '-';
          return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        },
      },
      { Header: '이메일', accessor: 'email' },
      { Header: '소속(회사)', accessor: ({ company_name }) => company_name || '-' },
      { Header: '소속 노출 여부', accessor: ({ is_company_expose }) => (is_company_expose ? '노출' : '미노출') },
      { Header: '오픈카톡', accessor: ({ kakao_open_chat_code }) => (kakao_open_chat_code ? '✅' : '-') },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/lecture/tutor/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [selectedTutorIds],
  );

  const fetchData = useCallback(({ pageIndex, pageSize }) => {
    request.tutors({ page: pageIndex + 1, limit: pageSize }).then(({ tutors }) => {
      setTutors(tutors.rows);
      setTotal(tutors.count);
    });
  }, []);

  const onClickRemove = useCallback(() => {
    if (selectedTutorIds.length < 1) return;
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      text: '삭제한 데이터는 복구할 수 없습니다.',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request.deleteTutors(selectedTutorIds).then(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">삭제되었습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 4000,
            timerProgressBar: true,
            showConfirmButton: true,
            icon: 'success',
            type: 'success',
          }).then(() => history.go(0));
        }),
    });
  }, [history, selectedTutorIds]);

  return (
    <div className="tutors">
      <div className="tutors__buttons">
        <Button.Negative onClick={onClickRemove}>선택 삭제</Button.Negative>
        <button className="making-btn" onClick={() => window.open(`/lecture/tutor/new`)}>
          <Button.Positive>강사 추가</Button.Positive>
        </button>
      </div>
      <Table data={tutors} total={total} columns={columns} fetchData={fetchData} />
    </div>
  );
}

export default TutorList;
