class Model {
  constructor(json) {
    for (let key in json) {
      this[key] = json[key];
    }
  }
}

export class Video extends Model {
  get categoryIds() {
    return {
      company: this.Video_CategoryCompanies.map((Class_CategoryCompany) => Class_CategoryCompany.FK_category.id),
      field: this.Video_CategoryFields.map((Class_CategoryField) => Class_CategoryField.FK_category.id),
      process: this.Video_CategoryProcesses.map((Class_CategoryProcess) => Class_CategoryProcess.FK_category.id),
    };
  }
}

export class Lecture extends Model {
  get tutorIds() {
    return this.Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.id);
  }

  get tutorRates() {
    return this.Class_Tutors.map((Class_Tutor) => Class_Tutor.rate);
  }

  get categoryIds() {
    return {
      company: this.Class_CategoryCompanies.map((Class_CategoryCompany) => Class_CategoryCompany.FK_category.id),
      field: this.Class_CategoryFields.map((Class_CategoryField) => Class_CategoryField.FK_category.id),
      process: this.Class_CategoryProcesses.map((Class_CategoryProcess) => Class_CategoryProcess.FK_category.id),
    };
  }

  get onlineOption() {
    return this.ClassOnlineSchedule ? new OnlineOption(this.ClassOnlineSchedule) : {};
  }

  get consultingOptions() {
    return this.ClassConsultingSchedules.map(
      (ClassConsultingSchedule) => new ConsultingOption(ClassConsultingSchedule),
    );
  }

  get roundOnlineOptions() {
    return this.ClassOnlineScheduleByRounds.map(
      (ClassRoundOnlineSchedule) => new RoundOnlineOption(ClassRoundOnlineSchedule),
    );
  }

  get offlineOptions() {
    return this.ClassOfflineSchedules.map((ClassOfflineSchedule) => new OfflineOption(ClassOfflineSchedule));
  }

  get childLectures() {
    return this.ClassSingle_ClassPackages
      ? this.ClassSingle_ClassPackages.map((ClassSingle_ClassPackage) => ClassSingle_ClassPackage)
      : [];
  }

  get thirdPartyQuestionFields() {
    return this.ThirdPartyClassQuestions
      ? this.ThirdPartyClassQuestions.map((Question) => Question.question_field)
      : [];
  }
}

export class OnlineOption extends Model {
  constructor(json) {
    super(json);
    this.start_date = this.start_date ? new Date(this.start_date) : null;
  }
}

export class RoundOnlineOption extends Model {
  constructor(json) {
    super(json);
    this.start_date = this.start_date ? new Date(this.start_date) : null;
    this.end_date = this.end_date ? new Date(this.end_date) : null;
  }
}

export class ConsultingOption extends Model {
  constructor(json) {
    super(json);
    this.start_date = this.start_date ? new Date(this.start_date) : null;
    this.end_date = this.end_date ? new Date(this.end_date) : null;
  }

  get weekdays() {
    return this.class_dates
      .split('')
      .map((class_date, index) => (class_date === '1' ? index + 1 : null))
      .filter((weekday) => !!weekday);
  }
}

export class OfflineOption extends Model {
  constructor(json) {
    super(json);
    this.start_date = this.start_date ? new Date(this.start_date) : null;
    this.end_date = this.end_date ? new Date(this.end_date) : null;
  }

  get weekdays() {
    return this.class_dates
      .split('')
      .map((class_date, index) => (class_date === '1' ? index + 1 : null))
      .filter((weekday) => !!weekday);
  }
}
