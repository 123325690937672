import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as request from '../../../Common/Util/Request';
import * as object from '../../../Common/Util/Object';
import * as Field from '../../../Common/Component/Field';
import * as Button from '../../../Common/Component/Button';
import './index.scss';
import SelectSearch from '../../../Common/Component/SelectSearch';

function BannerNew() {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch();
  const { type, id } = match.params;
  const editing = !!id;

  const [bannerOrigin, setBannerOrigin] = useState(null);

  const [order, set_order] = useState(null);
  const [type_name, set_type_name] = useState(null);
  const [title, set_title] = useState(null);
  const [sub_title, set_sub_title] = useState(null);
  const [class_id, set_class_id] = useState(null);
  const [url, set_url] = useState(null);
  const [end_date, set_end_date] = useState(new Date());
  const [image, set_image] = useState(null);
  const [image_pc, set_image_pc] = useState(null);
  const [image_mobile, set_image_mobile] = useState(null);
  const [button_name, set_button_name] = useState(null);

  const banner = useMemo(() => {
    switch (type) {
      case 'top':
        return { order, title, sub_title, url, image, button_name };
      case 'online':
        return { order, title, class_id, url, image };
      case 'offline':
        return { order, title, class_id, url, image };
      case 'video':
        return { order, title, sub_title, url };
      case 'event':
        return { order, title, end_date, url, image };
      case 'bottom':
        return { order, type_name, title, url, image_pc, image_mobile };
      default:
        return {};
    }
  }, [type, order, type_name, title, sub_title, class_id, url, end_date, image, image_pc, image_mobile, button_name]);

  const fields = useMemo(() => {
    switch (type) {
      case 'top':
        return ['order', 'title', 'sub_title', 'url', 'image', 'button_name'];
      case 'online':
        return ['order', 'title', 'class_id', 'url', 'image'];
      case 'offline':
        return ['order', 'title', 'class_id', 'url', 'image'];
      case 'video':
        return ['order', 'title', 'sub_title', 'url'];
      case 'event':
        return ['order', 'title', 'end_date', 'url', 'image'];
      case 'bottom':
        return ['order', 'type_name', 'title', 'url', 'image_pc', 'image_mobile'];
      default:
        return [];
    }
  }, [type]);

  const requiredFields = useMemo(() => {
    switch (type) {
      case 'top':
        return ['order', 'image', 'button_name'];
      case 'online':
        return ['order', 'image'];
      case 'offline':
        return ['order', 'image'];
      case 'video':
        return ['order', 'url'];
      case 'event':
        return ['order', 'image'];
      case 'bottom':
        return ['order', 'image_pc', 'image_mobile'];
      default:
        return [];
    }
  }, [type]);

  const imageSize = useMemo(() => {
    switch (type) {
      case 'top':
        return { width: 585, height: 400 };
      case 'online':
      case 'offline':
        return { width: 276, height: 208 };
      case 'event':
        return { width: 378, height: 208 };
      case 'bottom':
        return {
          pc: { width: 1920, height: 47 },
          mobile: { width: 360, height: 47 },
        };
      default:
        return null;
    }
  }, [type]);

  useEffect(() => {
    if (editing) {
      request.banner(type, id).then(({ banner }) => {
        setBannerOrigin(banner);

        set_order(banner.order);
        set_title(banner.title);
        set_type_name(banner.type_name);
        set_sub_title(banner.sub_title);
        set_class_id(banner.FK_class_id);
        set_url(banner.url);
        set_end_date(new Date(banner.end_date));
        set_image(banner.image);
        set_image_pc(banner.image_pc);
        set_image_mobile(banner.image_mobile);
        set_button_name(banner.button_name);
      });
    }
  }, [type, id, editing]);

  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    if (type === 'online' || type === 'offline') {
      request.bannerLectures(type).then(({ classes }) => setLectures(classes));
    }
  }, [type]);

  const onClickRemove = useCallback(
    (e) => {
      const confirmed = window.confirm('정말 선택된 배너를 삭제하시겠습니까?');
      if (confirmed) {
        request.deleteBanner(type, id).then(() => {
          window.alert('삭제했습니다.');
          history.goBack();
        });
      }
    },
    [history, type, id],
  );

  const onClickSave = useCallback(
    (e) => {
      const emptyFields = requiredFields.filter((field) => !banner[field]);
      if (emptyFields.length > 0) {
        window.alert(
          `${emptyFields
            .map((field) => (type === 'video' && field === 'url' ? 'url_video' : field))
            .map((field) =>
              intl.formatMessage({
                id: 'ID_BANNER_FIELD_' + field.toUpperCase(),
              }),
            )
            .join(', ')} 값을 입력해주세요.`,
        );
        return;
      }

      if (editing) {
        const changed = object.getChanged(bannerOrigin, banner);

        if (changed.image) {
          changed.image_pc = changed.image;
          delete changed.image;
        }

        if (Object.keys(changed).length > 0) {
          request.updateBanner(type, id, changed).then(() => {
            window.alert('저장했습니다.');
            history.goBack();
          });
        }
      } else {
        if (banner.image) {
          banner.image_pc = banner.image;
          delete banner.image;
        }

        request.createBanner(type, object.filterEmpty(banner)).then(() => {
          window.alert('저장했습니다.');
          history.goBack();
        });
      }
    },
    [intl, history, editing, type, id, bannerOrigin, banner, requiredFields],
  );

  return (
    <div className="banner-new">
      <div className="banner-new__fields">
        {fields.includes('order') && (
          <Field.Input title="순서" type="number" value={order} onChange={set_order} required />
        )}
        {fields.includes('type_name') && (
          <Field.Input
            title="배너 유형"
            placeholder="배너 유형을 작성해주세요(ex. EVENT, NOTICE)"
            value={type_name}
            onChange={set_type_name}
          />
        )}
        {fields.includes('title') && (
          <Field.Input
            title="배너 제목"
            placeholder="한글, 영문을 활용해 제목을 작성해주세요"
            value={title}
            onChange={set_title}
          />
        )}
        {fields.includes('sub_title') && (
          <Field.Input
            title="배너 소제목"
            placeholder="한글, 영문을 활용해 제목을 작성해주세요"
            value={sub_title}
            onChange={set_sub_title}
          />
        )}
        {fields.includes('class_id') && (
          <Field.Base title="연결 강의 ID" type="number">
            {lectures.length > 0 && (
              <SelectSearch
                options={[
                  { value: '', name: '선택안함' },
                  ...lectures.map((lecture) => ({
                    value: lecture.id,
                    name: `${lecture.id} - ${lecture.title}`,
                  })),
                ]}
                value={class_id}
                onChange={set_class_id}
              />
            )}
          </Field.Base>
        )}
        {fields.includes('url') &&
          (type === 'video' ? (
            <Field.Input
              title="유튜브 URL"
              placeholder="Youtube 영상 링크 주소를 입력하세요"
              value={url}
              onChange={set_url}
              required
            />
          ) : (
            <Field.Input
              title="랜딩 페이지 URL"
              placeholder="https://www.naver.com 형태로 작성해주세요"
              value={url}
              onChange={set_url}
            />
          ))}
        {fields.includes('end_date') && <Field.Date title="이벤트 종료일" value={end_date} onChange={set_end_date} />}
        {fields.includes('image') && (
          <Field.Image
            title="이미지"
            width={imageSize.width}
            height={imageSize.height}
            value={image}
            onChange={set_image}
            required
          />
        )}
        {fields.includes('image_pc') && (
          <Field.Image
            title="PC 이미지"
            width={imageSize.pc.width}
            height={imageSize.pc.height}
            value={image_pc}
            onChange={set_image_pc}
            required
          />
        )}
        {fields.includes('image_mobile') && (
          <Field.Image
            title="모바일 이미지"
            width={imageSize.mobile.width}
            height={imageSize.mobile.height}
            value={image_mobile}
            onChange={set_image_mobile}
            required
          />
        )}
        {fields.includes('button_name') && (
          <Field.Input
            title="버튼명"
            placeholder="한글, 영문을 활용해 제목을 작성해주세요(최대 8자)"
            value={button_name}
            onChange={set_button_name}
            maxLength={8}
            required
          />
        )}
        <div className="banner-new__fields__message">* 은 필수사항입니다.</div>
      </div>
      <div className="banner-new__buttons">
        <Button.Negative onClick={onClickRemove} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
      </div>
    </div>
  );
}

export default BannerNew;
