import React, { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import * as request from '../../Util/Request';

function QuillCustom(props) {
  const quillRef = useRef(null);

  //image URL로 변환하는 과정
  const ImageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (!file) return;
      let quillObj = quillRef.current?.getEditor();
      const range = quillObj?.getSelection();
      try {
        request.uploadIntroductionImage(file).then(({ image_url }) => {
          quillObj?.insertEmbed(range.index, 'image', `${image_url}`);
        });
      } catch (error) {
        console.log(error);
      }
    };
  };

  function handleLineHeightChange(value) {
    let range = this.quill.getSelection();
    let format = this.quill.getFormat(range);

    Object.keys(format).forEach((key) => {
      if (key.startsWith('line-height')) {
        this.quill.format(key, false);
      }
    });
    this.quill.format('line-height', value);
  }

  // Undo and redo functions for Custom Toolbar
  function undoChange() {
    this.quill.history.undo();
  }
  function redoChange() {
    this.quill.history.redo();
  }

  // Modules object for setting up the Quill editor
  const modules = useMemo(
    () => ({
      toolbar: {
        container: '#toolbar',
        handlers: {
          undo: undoChange,
          redo: redoChange,
          image: ImageHandler,
          lineHeight: handleLineHeightChange,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
      htmlEditButton: {
        debug: true,
        syntax: true,
        closeOnClickOverlay: false,
        buttonHTML: '&lt;HTML/&gt;',
        msg: '"확인" 버튼을 누르면 변경 사항이 적용됩니다.',
        okText: '확인',
        cancelText: '취소',
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    }),
    [],
  );

  // Formats objects for setting up the Quill editor
  const formats = [
    'header',
    'font',
    'size',
    'line-height',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
    'video',
  ];

  const value = props.value?.replace(/enterjobedu.s3.ap-northeast-2.amazonaws.com/gi, 'assets.enterjobedu.co.kr') || '';

  return (
    <ReactQuill
      theme="snow"
      ref={quillRef}
      modules={modules}
      formats={formats}
      value={value}
      onChange={props.onChange}
    />
  );
}
export default QuillCustom;
