import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DatePicker(props) {
  const { dateFormat = 'yyyy/MM/dd', ...otherProps } = props;

  return <ReactDatePicker {...otherProps} placeholderText="날짜 선택" dateFormat={dateFormat} />;
}

export default DatePicker;
