import React from 'react';
import './index.scss';
import { Button } from '@mui/material';

function Base(props) {
  return <button {...props} className={'btn' + (props.className ? ' ' + props.className : '')} />;
}

export function Neutral(props) {
  return <Base {...props} className="btn--neutral" />;
}

export function Positive(props) {
  return <Base {...props} className="btn--positive" />;
}

export function Negative(props) {
  return <Base {...props} className="btn--negative" />;
}

export function Excel(props) {
  return <Base {...props} className="btn--excel" />;
}

export function MUI(props) {
  return <Button {...props} />;
}
