import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import * as Model from '../../../../Common/Model';
import * as request from '../../../../Common/Util/Request';
import * as requestProgress from '../../../../Common/Util/RequestProgress';
import * as Field from '../../../../Common/Component/Field';
import * as Button from '../../../../Common/Component/Button';
import './index.scss';

function Video(props) {
  const intl = useIntl();
  const history = useHistory();
  const { state } = useLocation();
  const [videoOrigin, setVideoOrigin] = useState(null);
  const editing = useMemo(() => !!videoOrigin, [videoOrigin]);
  useEffect(() => {
    setVideoOrigin(state ? new Model.Video(state) : null);
  }, [state]);

  const [title, setTitle] = useState(null);
  const [file, setFile] = useState(null);
  const [length, setLength] = useState(null);
  const [subtitle_en, setSubtitle_en] = useState(null);
  const [subtitle_ja, setSubtitle_ja] = useState(null);
  const [subtitle_vi, setSubtitle_vi] = useState(null);
  const [selectedTutorIds, setSelectedTutorIds] = useState([]);
  const [creationNum, setCreationNum] = useState(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState({
    company: [],
    field: [],
    process: [],
  });
  const [key, setKey] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [whereToUse, setWhereToUse] = useState('our');

  useEffect(() => {
    if (videoOrigin) {
      setTitle(videoOrigin.title);
      setFile(videoOrigin.video_url);
      setLength(videoOrigin.video_length);
      setSubtitle_en(videoOrigin.Subtitles.find((Subtitle) => Subtitle.lang === 'en')?.url);
      setSubtitle_ja(videoOrigin.Subtitles.find((Subtitle) => Subtitle.lang === 'ja')?.url);
      setSubtitle_vi(videoOrigin.Subtitles.find((Subtitle) => Subtitle.lang === 'vi')?.url);
      setSelectedTutorIds(videoOrigin.Video_Tutors.map((Video_Tutor) => Video_Tutor.FK_tutor.id));
      setCreationNum(videoOrigin.creation_num);
      setSelectedCategoryIds(videoOrigin.categoryIds);
      setKey(videoOrigin.video_key);
      setWhereToUse(videoOrigin.where_to_use);
    }
  }, [videoOrigin]);

  const onClickRemove = useCallback(() => {
    if (window.confirm('해당 동영상을 사용하는 강의 목차들이 함께 삭제됩니다. 정말 삭제하시겠습니까?')) {
      request.deleteVideos([videoOrigin.id]).then(() => {
        alert('삭제되었습니다.');
        history.goBack();
      });
    }
  }, [history, videoOrigin]);
  const onClickSave = useCallback(() => {
    if (uploading) return;

    const video = {
      title,
      tutor_ids: selectedTutorIds.join(','),
      creation_num: Number(creationNum),
      video_length: length,
      category_info: JSON.stringify(selectedCategoryIds),
      video: file,
      subtitle_en,
      subtitle_ja,
      subtitle_vi,
      whereToUse,
    };

    const requiredFields = ['title', 'video'];
    const emptyFields = requiredFields.filter((field) => !video[field]);
    if (emptyFields.length > 0) {
      window.alert(
        `${emptyFields
          .map((field) => intl.formatMessage({ id: 'ID_VIDEO_FIELD_' + field.toUpperCase() }))
          .join(', ')} 값을 입력해주세요.`,
      );
      return;
    }

    setUploading(true);
    setProgress(0);
    requestProgress.subscribe((progress) => {
      setProgress(progress);
    });

    if (editing) {
      request
        .updateVideo(videoOrigin.id, video)
        .then(() => {
          alert('저장했습니다.');
          history.goBack();
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      request
        .createVideo(video)
        .then(() => {
          alert('저장했습니다.');
          history.goBack();
        })
        .finally(() => {
          setUploading(false);
        });
    }
  }, [
    uploading,
    title,
    selectedTutorIds,
    creationNum,
    length,
    selectedCategoryIds,
    file,
    subtitle_en,
    subtitle_ja,
    subtitle_vi,
    whereToUse,
    editing,
    intl,
    videoOrigin,
    history,
  ]);

  return (
    <div className="video">
      <div className="video__fields">
        <Field.Input
          title="영상 제목"
          placeholder="한글, 영어 대소문자를 입력해주세요."
          value={title}
          onChange={setTitle}
          required
        />
        <Field.Radio
          title="영상 사용처"
          name="whereToUse"
          value={whereToUse}
          options={[
            { value: 'our', label: '엔터잡에듀' },
            { value: 'compulsory', label: '법정의무교육' },
          ]}
          onChange={setWhereToUse}
          required
        />

        <Field.Video title="영상 파일 업로드" value={file} onChange={setFile} onChangeDuration={setLength} required />
        <Field.File accept=".vtt" title="자막 파일 업로드(영어)" value={subtitle_en} onChange={setSubtitle_en} />
        <Field.File accept=".vtt" title="자막 파일 업로드(일본어)" value={subtitle_ja} onChange={setSubtitle_ja} />
        <Field.File accept=".vtt" title="자막 파일 업로드(베트남어)" value={subtitle_vi} onChange={setSubtitle_vi} />
        <Field.Tutors title="강사명" value={selectedTutorIds} onChange={setSelectedTutorIds} disabled={editing} />
        <Field.Input
          type="number"
          title="강의 생성번호"
          value={creationNum}
          onChange={setCreationNum}
          disabled={editing}
        />
        <Field.Categories title="영상 카테고리 구분" value={selectedCategoryIds} onChange={setSelectedCategoryIds} />
        <Field.Input title="영상 키값" value={key || '영상 등록 후 키값이 노출됩니다'} onChange={setKey} fixed />
      </div>
      <div className="video__btns">
        <Button.Negative onClick={onClickRemove} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>
          {uploading ? (progress < 1 ? `업로드 중 (${Math.round(progress * 100)}%)` : '잠시 기다려주세요...') : '저장'}
        </Button.Positive>
      </div>
    </div>
  );
}

export default Video;
