import Button from './Button';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

const Buttons = ({ attendanceBoardInfo, student, setAttendanceLength }) => {
  const { classDays, startTime, endTime } = attendanceBoardInfo;
  // 현재 시간은 now 변수에 담는다.
  // 현재 시간을 기준으로 출석을 체크해야하므로 현재 시간은 초단위로 갱신된다.
  const [now, setNow] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start', // 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly
        alignItems: 'center',
        columnGap: '4%',
        rowGap: '0.5rem',
      }}
    >
      {classDays?.map((date, idx) => {
        return (
          <Button
            key={idx}
            date={date}
            time={`${startTime} ~ ${endTime}`}
            student={student}
            now={now}
            attendanceBoardId={attendanceBoardInfo.id}
            setAttendanceLength={setAttendanceLength}
          />
        );
      })}
    </div>
  );
};

export default Buttons;
