import React from 'react';
import './index.scss';

function Chip(props) {
  const { name, selected, disabled, hideCloseButton, ...passThroughProps } = props;

  return (
    <div
      {...passThroughProps}
      className={'chip' + (selected ? ' chip--selected' : '') + (disabled ? ' chip--disabled' : '')}
    >
      {name}
      {props.onClick && selected && !disabled && !hideCloseButton && <div className="chip__delete">x</div>}
    </div>
  );
}

export default Chip;
