import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useLocationState } from '../../../../../Common/Util/CustomHooks';
import * as request from '../../../../../Common/Util/Request';
import * as excel from '../../../../../Common/Util/Excel';
import * as Button from '../../../../../Common/Component/Button';
import Table from '../../../../../Common/Component/Table';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import './index.scss';

function Usages(props) {
  const [locationState, setLocationState] = useLocationState({
    searchOption: 'name',
    searchText: '',
    filters: [],
  });
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchText, searchOption]);

  const [coupons, setCoupons] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .couponUsages({
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(locationState.filters),
        })
        .then(({ userCoupon }) => {
          setCoupons(userCoupon.rows);
          setTotal(userCoupon.count);
        });
    },
    [locationState.filters],
  );

  const columns = useMemo(
    () => [
      { Header: '쿠폰명', accessor: 'FK_coupon.name' },
      { Header: '쿠폰코드', accessor: 'FK_coupon.code' },
      { Header: '회원ID', accessor: 'FK_user.id' },
      {
        Header: '사용여부',
        accessor: 'usedAt',
        Cell: ({ value: usedAt }) => (usedAt ? '사용' : '미사용'),
      },
      {
        Header: '쿠폰 사용시간',
        accessor: (row) => (row.usedAt ? moment.utc(row.usedAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      },
    ],
    [],
  );

  const onClickCSV = useCallback(() => {
    request
      .couponUsages({
        page: 1,
        limit: 99999,
        filtered: JSON.stringify(locationState.filters),
      })
      .then(({ userCoupon }) => {
        excel.downloadXLSX(
          userCoupon.rows.map((row) => ({
            쿠폰명: row.FK_coupon?.name,
            쿠폰코드: row.FK_coupon?.code,
            회원ID: row.FK_user?.id,
            사용여부: row.usedAt ? '사용' : '미사용',
            '쿠폰 사용시간': row.usedAt ? moment.utc(row.usedAt).format('YYYY-MM-DD HH:mm:ss') : '-',
            '사용된 강의ID': row.FK_class?.id ?? '-',
            '사용된 강의명': row.FK_class?.title ?? '-',
          })),
          `쿠폰 발급 내역_${dayjs.utc().format('YYYYMMDD')}`,
        );
      });
  }, [locationState.filters]);

  return (
    <div className="coupon__usages">
      <button
        className={'coupon__excel--btn' + (locationState.filters.length < 1 ? ' coupon__excel__disabled--btn' : '')}
        onClick={() => {
          locationState.filters.length < 1
            ? Swal.fire({
                title: '검색 후 다운로드 가능합니다.',
                text: '좌측 상단에서 검색 후 다운로드해 주세요.',
                icon: 'info',
                confirmButtonColor: '#F24462',
              })
            : onClickCSV();
        }}
      >
        엑셀 다운로드
      </button>
      <div className="coupon__search">
        <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
          <option value="name">쿠폰명</option>
          <option value="code">쿠폰코드</option>
          <option value="FK_user_id">회원 ID</option>
        </select>
        <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
      </div>
      <Table data={coupons} total={total} fetchData={fetchData} pageSize={20} columns={columns} />
    </div>
  );
}

export default Usages;
