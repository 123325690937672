import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.scss';
import * as request from '../../../../Common/Util/Request';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import { EJE_THEME } from '../../../../Common/Constant';
import {
  Box,
  Button as MuiButton,
  Input,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EnhancedTableHead from '../../../../Common/Component/EnhancedTableHead';
import * as Button from '../../../../Common/Component/Button';
import { set } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

function InterestedCompanyList(props) {
  const [locationState, setLocationState] = useLocationState({
    searchType: 'name',
    searchText: '',
    sortInfo: { name: 'name', order: 'asc' },
  });

  const {
    sortInfo: { name: sort, order },
  } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [total, setTotal] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [otherCompanies, setOtherCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [otherCompanyMatchingIds, setOtherCompanyMatchingIds] = useState([]);
  const [open, setOpen] = useState(false);

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
    });
  }, [setLocationState, searchType, searchText]);

  /** 테이블 설정 */
  const loadCompanies = useCallback(() => {
    let params = {
      searchType: searchText ? searchType : undefined,
      searchText: searchText || undefined,
      sort,
      order,
    };

    return request.getInterestedCompanies(params).then((data) => data);
  }, [locationState]);
  const fetchData = useCallback(() => {
    loadCompanies().then((data) => {
      setTotal(data.count);
      setTotalUserCount(data.total_users);
      setCompanies(data.list);
      setOtherCompanies(data.other_list);
    });
  }, [loadCompanies]);

  const handleRequestSort = (event, property) => {
    const isAsc = sort === property && order === 'asc';
    setLocationState({
      sortInfo: {
        name: property,
        order: isAsc ? 'desc' : 'asc',
      },
    });
  };

  const handleDeleteClick = (company) => {
    setSelectedCompany(company);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompany(null);
    setOtherCompanyMatchingIds([]);
  };

  const handleDeleteConfirm = () => {
    setOtherCompanies((prev) => prev.filter((c) => c.name !== selectedCompany.name));
    request.matchingOtherInterestedCompany({ name: selectedCompany.name, company_ids: otherCompanyMatchingIds });
    handleClose();
  };

  const headCells = [
    { id: 'id', label: 'ID', align: 'center', minWidth: 10 },
    { id: 'name', label: '이름', align: 'center', minWidth: 100 },
    { id: 'is_show', label: '노출 여부', align: 'center', minWidth: 10 },
    { id: 'user_count', label: '선택 인원', align: 'center', minWidth: 10 },
    { id: 'edit', label: '수정', align: 'center', minWidth: 10 },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ThemeProvider theme={EJE_THEME}>
      <div className="companies__container">
        <section className="companies-filters">
          <div className="search-bar">
            <Select value={searchType} onChange={(e) => setSearchType(e.target.value)} size="small" color="pink">
              <MenuItem value="id" sx={{ fontSize: '0.875rem' }}>
                ID
              </MenuItem>
              <MenuItem value="name" sx={{ fontSize: '0.875rem' }}>
                이름
              </MenuItem>
            </Select>

            <Input
              value={searchText}
              placeholder="검색어를 입력하세요."
              color="pink"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickSearch();
                }
              }}
            />

            <MuiButton
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                onClickSearch();
              }}
              color="pink"
              sx={{
                height: '2rem',
              }}
              endIcon={<SearchIcon fontSize="small" />}
            >
              검색
            </MuiButton>
          </div>

          <div className="making-btn">
            <Button.Positive onClick={() => window.open(`/user/interested-company/new`)}>기업 추가</Button.Positive>
          </div>
        </section>

        <div className="companies-data__container">
          <div className="companies-data__header">
            <div className="companies__total">총 관심 기업수 {total}</div>
            <div className="companies__total-user-count">총 참여 인원수 {totalUserCount}</div>
            <p>기본 정렬 순서는 알파벳 순입니다. 정렬 순서를 변경하려면 테이블 헤더를 클릭하세요.</p>
          </div>

          <Box sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" color="pink">
                <EnhancedTableHead
                  headCells={headCells}
                  rowCount={total}
                  numSelected={0}
                  color="pink"
                  order={order}
                  orderBy={sort}
                  onRequestSort={handleRequestSort}
                  disableSortHeaders={['edit']}
                />

                <TableBody>
                  {companies.map((company) => {
                    return (
                      <TableRow key={company.id} hover role="checkbox" sx={{ backgroundColor: 'white' }}>
                        <TableCell align="center">{company.id}</TableCell>
                        <TableCell align="center">{company.name}</TableCell>
                        <TableCell align="center">{company.is_show ? '노출' : '미노출'}</TableCell>
                        <TableCell align="center">{company.user_count}</TableCell>
                        <TableCell align="center">
                          <button
                            className="btn-detail"
                            onClick={() => window.open(`/user/interested-company/new/${company.id}`)}
                          >
                            수정
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>

        {/* 기타 기업 목록 */}
        <div className="other-companies__container">
          {otherCompanies.length > 0 && (
            <div>
              <div className="other-companies-data__header">
                <div className="other-companies__total">총 기타 기업 답변수: {otherCompanies.length}</div>
                <div>* 기타 답변을 선택하여 기업 매칭 또는 미노출 처리할 수 있습니다.</div>
              </div>
              <div className="other-companies-data__container">
                {otherCompanies.map((company, index) => {
                  const duplicatedCount = otherCompanies.filter((c) => c.name === company.name).length;
                  const isFirst = otherCompanies.findIndex((c) => c.name === company.name) === index;
                  const displayName = duplicatedCount > 0 ? `${company.name}(${duplicatedCount})` : company.name;

                  return (
                    isFirst && (
                      <Chip
                        key={company.id}
                        label={displayName}
                        onClick={() => handleDeleteClick(company)}
                        onDelete={() => handleDeleteClick(company)}
                        deleteIcon={<CloseIcon />}
                        color="pink"
                        size="small"
                        style={{ margin: '0.15rem' }}
                      />
                    )
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>기타 답변 기업 매칭</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {selectedCompany && (
                <div>
                  <div style={{ marginTop: '10px' }}>
                    <div>답변 내용: {selectedCompany.name}</div>
                  </div>

                  <div style={{ marginTop: '10px' }}>
                    <div>선택 인원수: {otherCompanies.filter((c) => c.name === selectedCompany.name).length}</div>
                  </div>

                  <div style={{ marginTop: '10px' }}>
                    매칭 기업 선택 (선택 사항):{' '}
                    <Select
                      multiple
                      value={otherCompanyMatchingIds}
                      onChange={(e) => setOtherCompanyMatchingIds(e.target.value)}
                      color="pink"
                      renderValue={(value) => {
                        return otherCompanyMatchingIds?.length
                          ? companies
                              .filter((companyItem) => otherCompanyMatchingIds.includes(companyItem.id))
                              .map((companyItem) => companyItem.name)
                              .join(', ')
                          : companies.find((company) => company.id === value)?.name || '매칭할 기업을 선택하세요.';
                      }}
                      fullWidth
                      displayEmpty
                    >
                      {companies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose} color="pink" variant="outlined">
              취소
            </MuiButton>
            <MuiButton onClick={handleDeleteConfirm} color="darkerPink" variant="contained">
              미노출
            </MuiButton>
            <MuiButton
              onClick={handleDeleteConfirm}
              color="pink"
              variant="contained"
              disabled={!otherCompanyMatchingIds.length}
              autoFocus
            >
              저장
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}

export default InterestedCompanyList;
