import { useLocation, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../../Common/Util/Request';
import Swal from 'sweetalert2';
import * as Field from '../../../../Common/Component/Field';
import './index.scss';
import * as Button from '../../../../Common/Component/Button';
import { useIntl } from 'react-intl';
import * as requestProgress from '../../../../Common/Util/RequestProgress';
import { minify } from 'html-minifier-terser';
import { html as beautify } from 'js-beautify';

function PostNew(factory, deps) {
  const intl = useIntl();
  const { id } = useParams();
  const { pathname } = useLocation();

  const boardType = useMemo(() => pathname.split('/')[1], [pathname]);
  const boardTitle = useMemo(() => {
    switch (boardType) {
      case 'notice':
        return '공지사항';
      case 'event':
        return '이벤트 게시글';
      case 'blog':
        return '블로그 게시글';
      default:
        return '게시글';
    }
  }, [boardType]);

  const [group, setGroup] = useState(null);
  const [board, setBoard] = useState(null);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [isShow, setIsShow] = useState(true);
  const [showTarget, setShowTarget] = useState('0');
  const [needLoginToView, setNeedLoginToView] = useState(true);
  const [description, setDescription] = useState(null);
  const [files, setFiles] = useState([]);

  const [groupTypeList, setGroupTypeList] = useState([]);
  const [boardTypeList, setBoardTypeList] = useState([]);

  const [postOrigin, setPostOrigin] = useState(null);
  const editing = useMemo(() => !!postOrigin?.id, [postOrigin]);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [renderingType, setRenderingType] = useState(null);

  useEffect(() => {
    let groupTypeListRes = [];
    request.getNoticeGroups().then(({ data }) => {
      groupTypeListRes = data;
      setGroupTypeList(data);
    });

    id &&
      request.getPostDetail(id).then(({ data }) => {
        setPostOrigin(data);

        if (data.FK_group) {
          setBoardTypeList(groupTypeListRes.find((groupType) => +groupType.id === +data.FK_group?.id)?.Boards || []);
        }
      });
  }, []);

  useEffect(() => {
    if (editing) {
      setGroup(postOrigin.FK_group);
      setBoard(postOrigin.FK_board);
      setTitle(postOrigin.title);
      setContent(postOrigin.content);
      setIsShow(postOrigin.isShow);
      setShowTarget(postOrigin.showTarget.toString());
      setFiles(postOrigin.Files);
      setRenderingType(postOrigin.renderingType);
      setDescription(postOrigin.description);
      setNeedLoginToView(postOrigin.needLoginToView);
    } else {
      if (boardType === 'notice') {
        setGroup({ code: 'service' });
        setBoard({ code: 'notice' });
      } else if (boardType === 'event') {
        setGroup({ code: 'service' });
        setBoard({ code: 'event' });
      } else if (boardType === 'blog') {
        setGroup({ code: 'service' });
        setBoard({ code: 'blog' });
      }
      setRenderingType('editor');
    }
  }, [postOrigin, editing, boardType]);

  const onClickSave = useCallback(() => {
    const post = {
      group_code: group?.code,
      board_code: board?.code,
      title,
      content,
      is_show: isShow,
      show_target: showTarget,
      need_login_to_view: needLoginToView,
      rendering_type: renderingType,
      files: files.map((file) => (file.name ? file : file.filePath)),
      description,
    };

    const requiredFields = ['group_code', 'board_code', 'title'];
    const emptyFields = requiredFields.filter((field) => !post[field]);
    if (content === '<p><br></p>') {
      emptyFields.push('content');
    }
    if (emptyFields.length > 0) {
      window.alert(
        `${emptyFields
          .map((field) => intl.formatMessage({ id: 'ID_POST_FIELD_' + field.toUpperCase() }))
          .join(', ')} 값을 입력해주세요.`,
      );
      return;
    }

    if (editing) {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">수정 사항을 반영하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setUploading(true);
          setProgress(0);
          requestProgress.subscribe((progress) => {
            setProgress(progress);
          });

          request
            .updatePost(postOrigin.id, post)
            .then((res) => {
              window.opener?.location.reload();

              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">수정 사항이 반영되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              }).then(() => {
                window.exitFlag = true;
                window.self.close();
              });
            })
            .finally(() => {
              setUploading(false);
            });
        }
      });
    } else {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">게시글을 작성하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setUploading(true);
          setProgress(0);
          requestProgress.subscribe((progress) => {
            setProgress(progress);
          });

          request
            .createPost(post)
            .then(() => {
              window.opener.location.reload();

              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">게시글이 작성되었습니다.</div>`,
                position: 'center',
                icon: 'success',
              }).then(() => {
                window.exitFlag = true;
                window.self.close();
              });
            })
            .finally(() => {
              setUploading(false);
            });
        }
      });
    }
  }, [group, board, title, content, isShow, showTarget, description, files, renderingType, needLoginToView]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">게시글을 저장하지 않고 나가시겠습니까?</div>`,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  });

  const onClickDelete = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deletePost(postOrigin.id)
          .then(({ success }) => {
            if (success) {
              window.opener.location.reload();
              window.exitFlag = true;
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">게시글이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            }
          })
          .catch((err) => {
            console.error(err);
            Swal.fire({
              title: `삭제에 실패했습니다.`,
              text: err.message,
              position: 'center',
              showConfirmButton: true,
              icon: 'error',
            });
          }),
    });
  });

  const changeGroup = (value) => {
    const selectedGroup = groupTypeList.find((groupType) => +groupType.id === +value);
    setGroup(selectedGroup);

    setBoard(null);
    setBoardTypeList(selectedGroup?.Boards || []);
  };

  const changeBoard = (value) => {
    const selectedBoard = boardTypeList.find((boardType) => +boardType.id === +value);
    setBoard(selectedBoard);
  };

  const changeFile = (file, index) => {
    const newFiles = [...files];
    // file order 적용시 아래 코드로 변경
    // newFiles[index] = file;
    // setFiles(newFiles);
    newFiles.push(file);
    setFiles(newFiles);
  };

  const deleteFile = (file, index) => {
    const newFiles = [...files];
    newFiles[index] = null;
    // file order 적용시 filter 제거
    setFiles(newFiles.filter((file) => file));
  };

  const fileList = useMemo(() => {
    // 첨부파일 제한 개수
    const maxUploadFileCount = 3;

    // 노출될 파일 인풋 개수(현재 업로드된 파일 개수 + 1)(미사용)
    // const nowAllowUploadFileCount = maxUploadFileCount > files.length + 1 ? files.length + 1 : maxUploadFileCount;
    const fileList = [];

    for (let i = 0; i < maxUploadFileCount; i++) {
      const currentFile = files[i];
      if (currentFile) {
        if (currentFile.filePath) {
          fileList.push(
            <Field.File
              key={i}
              title={`첨부 파일 ${i + 1} (다운: ${currentFile.downloadCount || 0}회)`}
              file={currentFile.filePath}
              fileName={currentFile.originalFileName + '.' + currentFile.fileExtension}
              value={currentFile.filePath}
              onChange={(file) => changeFile(file, i)}
              onDelete={(file) => deleteFile(file, i)}
            />,
          );
        } else {
          fileList.push(
            <Field.File
              key={i}
              title={`첨부 파일 ${i + 1} (다운: ${currentFile.downloadCount || 0}회)`}
              value={currentFile}
              onChange={(file) => changeFile(file, i)}
              onDelete={(file) => deleteFile(file, i)}
            />,
          );
        }
      } else {
        fileList.push(<Field.File key={i} title={`첨부 파일 ${i + 1}`} onChange={(file) => changeFile(file, i)} />);
      }
    }

    return fileList;
  }, [files]);

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  const setRenderingTypeHandler = (value) => {
    if (renderingType === 'markup') {
      Swal.fire({
        title: '변경 시 작성 중인 내용이 누락/변경 될 수 있습니다.',
        text: '변경하시겠습니까?',
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'error',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setContent('');
          setRenderingType(value);
        } else {
          return;
        }
      });
    } else {
      setRenderingType(value);
    }
  };

  return (
    <div className="post">
      <h2>
        {boardTitle} {editing ? '수정' : '작성'}
      </h2>
      {boardType === 'board' && (
        <Field.SelectById
          title="게시판 그룹"
          value={group?.id || ''}
          emptyOptionText="그룹 선택"
          options={groupTypeList}
          onChange={changeGroup}
          required
        />
      )}
      {boardType === 'board' && (
        <Field.SelectById
          title="노출 게시판"
          value={board?.id || ''}
          emptyOptionText={!group ? '그룹을 선택해주세요' : '게시판 선택'}
          options={boardTypeList}
          onChange={changeBoard}
          disabled={!group}
          required
        />
      )}
      <Field.Input title={boardTitle + ' 제목'} value={title} onChange={setTitle} required />
      <Field.Radio
        title="공개 여부"
        name="isShow"
        value={isShow}
        onChange={setIsShow}
        options={[
          { value: true, label: '공개', order: 1 },
          { value: false, label: '비공개', order: 2 },
        ]}
        required
      />
      <Field.Radio
        title="공개 대상"
        name="showTarget"
        value={showTarget}
        onChange={setShowTarget}
        options={[
          { value: '0', label: '전체', order: 1 },
          { value: '1', label: '회원', order: 2 },
          { value: '2', label: '어드민', order: 3 },
        ]}
        required
      />
      <Field.Radio
        title="비로그인 시 본문 공개 범위"
        name="needLoginToView"
        value={needLoginToView}
        onChange={setNeedLoginToView}
        options={[
          { value: false, label: '전체 내용', order: 1 },
          { value: true, label: '일부 내용', order: 2 },
        ]}
        required
      />

      {fileList.map((file) => file)}

      <Field.Textarea
        title={`SEO 소개글 (권장 길이: ${description ? description.length : 0} / 155자)`}
        name="description"
        value={description}
        onChange={setDescription}
        style={{ resize: 'vertical', minHeight: '100px', height: 'auto' }}
      />

      <Field.Radio
        title="본문 작성 타입"
        name="renderingType"
        value={renderingType}
        onChange={setRenderingTypeHandler}
        options={[
          { value: 'editor', label: '에디터', order: 1 },
          { value: 'markup', label: 'HTML', order: 2 },
        ]}
        required
      />
      {renderingType &&
        (renderingType === 'editor' ? (
          <Field.RichText title={boardTitle + ' 본문'} value={content} onChange={setContent} />
        ) : (
          <>
            <Field.Textarea
              title={boardTitle + ' 본문'}
              value={content}
              onChange={setContent}
              style={{ resize: 'vertical', minHeight: '300px', height: 'auto' }}
            />
            <div className="field">
              <div className="field__title">&nbsp;</div>
              <div className="field__body">
                <button
                  onClick={() => {
                    minify(content, { collapseWhitespace: true, minifyCSS: true, minifyJS: true }).then((result) => {
                      setContent(result);
                    });
                  }}
                >
                  minify
                </button>
                <button
                  onClick={() => {
                    setContent(
                      beautify(content, {
                        indent_size: 2,
                        wrap_line_length: 80,
                        preserve_newlines: true,
                        max_preserve_newlines: 2,
                      }),
                    );
                  }}
                >
                  beautify
                </button>
              </div>
            </div>
          </>
        ))}
      <div className="posts__buttons">
        <Button.Negative onClick={onClickDelete} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>
          {uploading ? (progress < 1 ? `업로드 중 (${Math.round(progress * 100)}%)` : '잠시 기다려주세요...') : '저장'}
        </Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default PostNew;
