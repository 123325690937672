import { useLocationState } from '../../../../Common/Util/CustomHooks';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as request from '../../../../Common/Util/Request';
import moment from 'moment/moment';
import DatePicker from '../../../../Common/Component/DatePicker';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import { DragDropContext } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';

function NoticeList() {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchType: 'title',
    searchText: '',
    filters: [],
  });
  const { startDate, endDate, filters } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [total, setTotal] = useState(0);
  const [topNotices, setTopNotices] = useState([]);
  const [notices, setNotices] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingNormal, setIsDraggingNormal] = useState(false);

  const TOP_NOTICES_MAX = 3;

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText: searchType === 'phone' ? searchText.replace(/^0/, '82') : searchText,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  /** 테이블 설정 */
  const loadPosts = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        filtered: JSON.stringify(filters),
        groupCode: 'service',
        boardCode: 'notice',
      };

      if (startDate) {
        params.start_date = dayjs(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = dayjs(endDate).format('YYYY-MM-DD');
      }

      return request.getPosts(params).then(({ data }) => data);
    },
    [startDate, endDate, filters],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadPosts({ page: pageIndex + 1, limit: pageSize }).then((data) => {
        setTopNotices(data.topPosts);
        setNotices(data.posts);
        setTotal(data.count);
      });
    },
    [loadPosts],
  );

  const columns = useMemo(
    () => [
      { Header: '게시글 번호', accessor: 'id' },
      { Header: '제목', accessor: 'title' },
      { Header: '공개 여부', accessor: 'isShow', Cell: ({ value: isShow }) => (isShow ? '공개' : '비공개') },
      {
        Header: '공개 범위',
        accessor: 'showTarget',
        Cell: ({ value: showTarget }) =>
          showTarget === 0 ? '전체 공개' : showTarget === 1 ? '회원 공개' : '어드민 공개',
      },
      { Header: '작성자 닉네임', accessor: 'FK_user.nickname' },
      {
        Header: '첨부파일 개수',
        accessor: 'fileCount',
      },
      {
        Header: '조회수',
        accessor: 'viewCount',
      },
      {
        Header: '작성일',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => moment(createdAt).format('YYYY-MM-DD'),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/notice/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [],
  );

  const onChangeTopNotices = async (rows, notice) => {
    setTopNotices(rows);
    const method = topNotices.length < rows.length ? 'create' : topNotices.length > rows.length ? 'delete' : 'update';
    let order;

    try {
      switch (method) {
        case 'create':
          order = rows.findIndex((row) => row.id === notice.id) + 1;
          await request.createTopNotice({ notices: [{ board_code: 'notice', FK_post_id: notice.id, order }] });
          break;

        case 'delete':
          order = topNotices.findIndex((topPost) => topPost.id === notice.id) + 1;
          const params = { board_code: 'notice', notice_id: notice.id, nextOrder: order + 1 };
          await request.deleteTopNotice(params);
          break;

        case 'update':
          const body = rows.map((row, index) => ({ board_code: 'notice', FK_post_id: row.id, order: index + 1 }));
          await request.updateTopNotice({ notices: body });
          break;

        default:
          break;
      }
    } catch (err) {
      const errorMessage = {
        create: '상단 고정에 실패했습니다.',
        delete: '상단 고정 해제에 실패했습니다.',
        update: '상단 고정 순서 변경에 실패했습니다.',
      };

      console.error(err);
      Swal.fire(errorMessage[method], '', 'error').then(() => {
        window.location.reload();
      });
    }
  };

  const onChangeNormalNotices = (rows) => {
    setNotices(rows);
  };

  const handleDragStart = useCallback(
    (start) => {
      setIsDragging(true);

      if (start.source.droppableId === 'normalNotices') {
        setIsDraggingNormal(true);
      }
    },
    [notices, topNotices],
  );

  const handleDragEnd = useCallback(
    (result) => {
      setIsDragging(false);
      setIsDraggingNormal(false);

      const { source, destination } = result;

      // 아이템이 리스트 밖으로 드래그된 경우
      if (!destination) return;

      const destId = destination.droppableId;
      const sourceId = source.droppableId;
      const destRows = destId === 'topNotices' ? topNotices : notices;
      const sourceRows = destId === 'topNotices' ? notices : topNotices;

      // 다른 리스트로 이동한 경우
      const copiedDestRows = [...destRows];
      if (sourceId !== destId) {
        const copiedSourceRows = [...sourceRows];
        const [removed] = copiedSourceRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        if (destId === 'topNotices') {
          onChangeTopNotices(copiedDestRows, removed).then(() => {
            onChangeNormalNotices(copiedSourceRows);
          });
        } else {
          onChangeTopNotices(copiedSourceRows, removed).then(() => {
            onChangeNormalNotices(copiedDestRows);
          });
        }
      } else {
        // 같은 리스트 내에서 이동한 경우
        if (destId !== 'topNotices') return;

        const [removed] = copiedDestRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        onChangeTopNotices(copiedDestRows, removed).then(() => {});
      }
    },
    [topNotices, notices],
  );

  return (
    <div className="notices">
      <section>
        <div className="notices__date">
          기간
          <DatePicker
            selected={startDate}
            maxDate={endDate}
            onChange={(date) => setLocationState({ startDate: date })}
          />
          ~
          <DatePicker selected={endDate} minDate={startDate} onChange={(date) => setLocationState({ endDate: date })} />
        </div>
        <div className="notices__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="title">제목</option>
            <option value="id">아이디</option>
            <option value="nickname">작성자 닉네임</option>
          </select>
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
        </div>
        <div className="making-btn">
          <Button.Positive onClick={() => window.open(`/notice/new`)}>게시글 작성</Button.Positive>
        </div>
      </section>
      <div className="notices-top-container">
        <div className="infos__total">총 공지수 {total}</div>
      </div>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div className={`top-notices-container ${isDragging ? 'notices-blue' : 'not-dragging'}`}>
          <div className="top-notices-top-container">
            <div className="notices__total">
              📌 상단 고정 공지수 (
              <span
                style={{
                  color: topNotices.length >= TOP_NOTICES_MAX ? 'red' : 'black',
                }}
              >
                {topNotices.length}
              </span>{' '}
              / {TOP_NOTICES_MAX}) <span style={{ color: 'red' }}>* 정렬된 순서대로 상단에 고정됩니다.</span>
            </div>
            <span className="notices__info">* 게시글을 드래그하여 옮길 수 있습니다.</span>
          </div>
          <Table
            data={topNotices}
            columns={columns}
            total={topNotices.length}
            pageSize={3}
            fetchData={() => {}}
            droppableId="topNotices"
          />
        </div>

        <div
          className={`normal-notices-container ${
            isDragging ? (isDraggingNormal ? 'notices-red' : 'notices-blue') : 'not-dragging'
          }`}
        >
          <div className="notices__total">📬 일반 공지수 {total - topNotices.length}</div>
          <Table
            data={notices}
            columns={columns}
            total={total - topNotices.length}
            pageSize={20}
            fetchData={fetchData}
            pauseDragDrop={topNotices.length >= TOP_NOTICES_MAX}
            droppableId="normalNotices"
          />
        </div>
      </DragDropContext>
    </div>
  );
}

export default NoticeList;
