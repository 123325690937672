import Swal from 'sweetalert2';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import * as excel from '../../../../Common/Util/Excel';
import DatePicker from '../../../../Common/Component/DatePicker';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import dayjs from 'dayjs';

function Reviews(props) {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchOption: 'FK_class.title',
    searchText: '',
    filters: [],
  });
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);
  const [isDownloading, setIsDownloading] = useState(null);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchOption, searchText]);

  const onClickCSV = useCallback(() => {
    setIsDownloading(true);
    request
      .reviews('', '', { page: 1, limit: 99999 }) // temp
      .then(({ reviews }) => {
        const data = reviews.rows.reduce(
          (acc, cur) => [
            ...acc,
            {
              '후기 ID': cur.id,
              '후기 작성 회원 ID': cur.FK_user?.id || '',
              '후기 작성 회원 닉네임': cur.FK_user?.nickname || '',
              '후기 상태': cur.status === 1 ? '대기' : cur.status === 2 ? '공개' : '비공개',
              '최초 등록 강의 ID': cur.FK_class_id,
              '최초 등록 강의명': cur.FK_class.title,
              '현재 노출 강의': cur.selectedReviews,
              총점: cur.rate,
              총평: cur.content,
              '수업 내용 만족도(점수)': cur.toStaffs?.contentRating,
              '수업 내용 만족도(주관식)': cur.toStaffs?.contentComment,
              '강사 만족도(점수)': cur.toStaffs?.tutorRating,
              '강사 만족도(주관식)': cur.toStaffs?.tutorComment,
              '수업 시간 만족도(점수)': cur.toStaffs?.timeRating,
              '수업 시간 만족도(주관식)': cur.toStaffs?.timeComment,
              '엔터잡에듀에 기대하는 것': cur.toStaffs?.suggestionComment,
            },
          ],
          [],
        );
        excel.downloadXLSX(data, `리뷰 목록 ${dayjs().format('YYYY-MM-DD')}`);
        setIsDownloading(false);
      });
  }, []);

  const [reviews, setReviews] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = useMemo(
    () => [
      { Header: '후기ID', accessor: 'id' },
      { Header: '후기 작성 회원', accessor: 'old_nickname' },
      { Header: '노출 강의 ID', accessor: 'FK_class_id' },
      { Header: '노출 강의명', accessor: 'FK_class.title' },
      { Header: '평점', accessor: 'rate' },
      {
        Header: '상태',
        accessor: ({ status }) => {
          switch (status) {
            case 0:
              return '비공개';
            case 1:
              return '대기';
            case 2:
              return '공개';
            default:
              return '알수없음';
          }
        },
      },
      {
        Header: '후기 보기',
        accessor: (row) => (
          <button className="btn-detail-view" onClick={() => window.open(`./lecture/new/${row.id}`)}>
            확인
          </button>
        ),
      },
    ],
    [],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        page: pageIndex + 1,
        limit: pageSize,
        filtered: JSON.stringify(locationState.filters),
      };

      if (locationState.startDate) {
        params.start_date = moment(locationState.startDate).format('YYYY-MM-DD');
      }
      if (locationState.endDate) {
        params.end_date = moment(locationState.endDate).format('YYYY-MM-DD');
      }

      request.reviews('', '', params).then(({ reviews }) => {
        setReviews(reviews.rows);
        setTotal(reviews.count);
      });
    },
    [locationState.filters, locationState.startDate, locationState.endDate],
  );

  const [selectedReviews, setSelectedReviews] = useState([]);
  const [tableKey, setTableKey] = useState(0);
  const deleteSelectedReviews = useCallback(() => {
    request.deleteReviews({ selectedReviews }).then(() => {
      Swal.fire({
        title: '삭제 완료',
        text: '선택한 후기가 삭제되었습니다.',
        icon: 'success',
      }).then(() => {
        setTableKey((prev) => prev + 1);
      });
    });
  }, [selectedReviews]);
  const onChangeSelectedRows = useCallback((rows) => {
    setSelectedReviews(rows.map((row) => row.original.id));
  }, []);
  return (
    <div className="reviews">
      <div className="toolbar">
        <div className="filters">
          <div className="filters__date">
            기간
            <DatePicker
              selected={locationState.startDate}
              maxDate={locationState.endDate}
              onChange={(date) => setLocationState({ startDate: date })}
            />
            ~
            <DatePicker
              selected={locationState.endDate}
              minDate={locationState.startDate}
              onChange={(date) => setLocationState({ endDate: date })}
            />
          </div>
          <div className="filters__search">
            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
              <option value="FK_class.title">강의명</option>
              <option value="FK_class_id">강의ID</option>
              <option value="id">후기ID</option>
              {/*<option value="FK_user.name">회원 이름</option>*/}
              <option value="old_nickname">회원 닉네임</option>
              {/*<option value="FK_user.email">회원 이메일</option>
               <option value="FK_user.phone">회원 핸드폰</option>*/}
              <option value="FK_user_id">회원ID</option>
            </select>
            <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
          </div>
        </div>
        <div className="btns">
          <div className="making-btn" onClick={() => deleteSelectedReviews()}>
            <Button.Negative>삭제</Button.Negative>
          </div>
          <div className="making-btn" onClick={() => window.open(`/review/lecture/new`)}>
            <Button.Positive>신규 등록</Button.Positive>
          </div>
          <Button.Excel
            style={{
              cursor: isDownloading ? 'not-allowed' : 'pointer',
            }}
            onClick={!isDownloading ? onClickCSV : undefined}
          >
            {isDownloading ? 'loading...' : '액셀 다운'}
          </Button.Excel>
        </div>
      </div>
      <Table
        key={tableKey}
        data={reviews}
        total={total}
        columns={columns}
        fetchData={fetchData}
        pageSize={20}
        onChangeSelectedRows={onChangeSelectedRows}
      />
    </div>
  );
}

export default Reviews;
