import React, { useCallback, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

function ClassSections({
  section,
  chapters,
  selectedSections,
  i,
  j,
  onChangeSectionAt,
  onSelectSection,
  Image,
  shouldUploadImage,
  videos,
  surveys,
  type,
  SelectSearch,
  onChangeSectionVideoAt,
  onChangeSectionSurveyAt,
  onChange,
  changeClickedSectionColor,
  clickedSection,
  selectedSurveyIds,
}) {
  const sections = chapters[i].ClassSections;

  const [editable, setEditable] = useState(!section.id);
  const [title, setTitle] = useState(section.title || '');
  const [originTitle] = useState(section.title || '');
  const [imageThumb, setImageThumb] = useState(section.image_thumb || '');
  const [originImageThumb] = useState(section.image_thumb || '');
  const [videoId, setVideoId] = useState(section.FK_video_id || '');
  const [surveyId, setSurveyId] = useState(section.FK_survey_id || '');
  const [survey, setSurvey] = useState(section.FK_survey || { title: '' });
  const [originVideoId] = useState(section.FK_video_id || '');
  const [originSurveyId] = useState(section.FK_survey_id || '');

  const isSurvey = section.type === 'survey';

  const changeTitleHandler = ({ target: { value } }) => {
    onChangeSectionAt(i, j, {
      target: { name: 'title', value },
    });
    setTitle(value);
  };
  const changeImageThumbHandler = (value) => {
    onChangeSectionAt(i, j, {
      target: { name: 'image_thumb', value },
    });
    setImageThumb(value);
  };
  const changeVideoIdHandler = (value) => {
    onChangeSectionVideoAt(i, j, value);
    setVideoId(value);
  };
  const changeSurveyIdHandler = (value) => {
    onChangeSectionSurveyAt(i, j, value);
    setSurveyId(value);
  };
  const saveSectionData = () => {
    setEditable(false);
  };
  const cancelSectionData = () => {
    setTitle(originTitle);
    setImageThumb(originImageThumb);
    setVideoId(originVideoId);
    setSurveyId(originSurveyId);
    onChangeSectionAt(i, j, {
      target: {
        name: 'title',
        value: originTitle,
      },
    });
    onChangeSectionAt(i, j, {
      target: {
        name: 'image_thumb',
        value: originImageThumb,
      },
    });
    onChangeSectionVideoAt(i, j, originVideoId);
    onChangeSectionSurveyAt(i, j, originSurveyId);
    setEditable(false);
  };
  const changeSectionOrder = useCallback(
    (sectionIndex, isUp) => {
      const order = sectionIndex + 1;
      let _sections, _chapters;

      if (isUp) {
        _sections = sections.map((section, index) => {
          if (index === sectionIndex) section.order = order - 1;
          if (index === sectionIndex - 1) section.order = order;
          return section;
        });
      } else {
        _sections = sections.map((section, index) => {
          if (index === sectionIndex) section.order = order + 1;
          if (index === sectionIndex + 1) section.order = order;
          return section;
        });
      }

      _sections.sort((a, b) => a.order - b.order);

      _chapters = [...chapters];
      _chapters[i].ClassSections = _sections;

      onChange(_chapters);
    },
    [chapters, i, sections, onChange],
  );

  useEffect(() => {
    if (surveyId) setSurvey(surveys.find((survey) => survey.id === surveyId) || { title: '' });
  }, [surveyId, surveys]);

  return (
    <tr
      key={section.id || section.keyId}
      className={`${section.id === clickedSection || section.keyId === clickedSection ? 'clickedTr' : ''} ${
        isSurvey ? 'survey' : ''
      }`}
    >
      <td>
        {!isSurvey && (
          <input
            type="checkbox"
            checked={selectedSections.includes(section)}
            onClick={() => onSelectSection(section)}
            onChange={() => {}}
          />
        )}
      </td>
      <td>{j + 1}</td>
      <td>
        <input
          name="title"
          placeholder={isSurvey ? '설문 선택 시 자동입력' : '강의명을 입력해주세요'}
          value={isSurvey ? survey.title ?? title : title}
          onChange={changeTitleHandler}
          disabled={!editable || isSurvey}
        />
      </td>
      <td>
        <Image
          value={imageThumb}
          onChange={changeImageThumbHandler}
          disabled={!editable}
          shouldUpload={shouldUploadImage}
        />
      </td>
      {!isSurvey && (
        <td>
          {type !== 'offline' && type !== 'consulting' && videos.length > 0 && (
            <SelectSearch
              placeholder="영상 키값을 입력해주세요"
              value={videoId}
              options={videos.map((video) => ({
                name: video.video_key,
                value: video.id,
              }))}
              onChange={changeVideoIdHandler}
              disabled={!editable}
            />
          )}
        </td>
      )}
      {isSurvey && (
        <td>
          {surveys.length > 0 && (
            <SelectSearch
              placeholder="설문을 선택해주세요"
              value={surveyId}
              options={surveys.map((survey) =>
                selectedSurveyIds.includes(survey.id)
                  ? {
                      name: survey.title,
                      value: survey.id,
                      disabled: true,
                    }
                  : {
                      name: survey.title,
                      value: survey.id,
                    },
              )}
              onChange={changeSurveyIdHandler}
              disabled={!editable}
            />
          )}
        </td>
      )}
      <td>
        {section.id ? (
          editable ? (
            <>
              <button
                onClick={saveSectionData}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  marginTop: '10px',
                }}
              >
                저장
              </button>
              <button
                onClick={cancelSectionData}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  marginTop: '10px',
                }}
              >
                취소
              </button>
            </>
          ) : (
            <button onClick={() => setEditable(true)}>수정</button>
          )
        ) : null}
      </td>

      <td>
        {!isSurvey && (
          <>
            <IconButton
              size="small"
              disabled={j === 0 || isSurvey}
              onClick={() => {
                changeSectionOrder(j, true);
                changeClickedSectionColor(section.id || section.keyId);
              }}
            >
              <ArrowUpward fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              disabled={j === sections.length - 1 || isSurvey}
              onClick={() => {
                changeSectionOrder(j, false);
                changeClickedSectionColor(section.id || section.keyId);
              }}
            >
              <ArrowDownward fontSize="small" />
            </IconButton>
          </>
        )}
      </td>
    </tr>
  );
}

export default ClassSections;
