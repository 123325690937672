import React, { useCallback, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import * as Button from '../../../../Common/Component/Button';
import './index.scss';
import Table from '../../../../Common/Component/Table';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import * as request from '../../../../Common/Util/Request';

function AddQuestion(props) {
  const [searchOptions, setSearchOptions] = useState({
    searchType: 'search',
    searchText: '',
  });

  const [count, setCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [searchType, setSearchType] = useState('title');
  const [searchText, setSearchText] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        page: pageIndex + 1,
        limit: pageSize,
        search: searchText,
      };

      request.getQuestions(params).then(({ questions }) => {
        setCount(questions.count);
        setQuestions(
          questions.list.map((question) => {
            const isAlreadySelected = props.registeredQuestions.some(
              (registeredQuestion) => registeredQuestion.FK_question_id === question.id,
            );
            const isRequired = props.registeredQuestions.some(
              (registeredQuestion) =>
                registeredQuestion.FK_question_id === question.id && registeredQuestion.is_required,
            );
            return {
              ...question,
              isAlreadySelected,
              isRequired,
            };
          }),
        );
      });
    },
    [searchOptions],
  );

  const onClickSearch = useCallback(() => {
    setSearchOptions({
      searchType,
      searchText,
    });
  }, [searchOptions, setSearchOptions]);

  const onClickSave = useCallback(() => {
    const newSelectedQuestions = selectedQuestions.map((question) => ({
      FK_question_id: question.id,
      content: question.content,
      FK_choice_layout_id: question.FK_choice_layout_id,
      FK_choice_layout: question.FK_choice_layout,
      FK_question_type_id: question.FK_question_type_id,
      FK_question_type: question.FK_question_type,
      is_required: question.is_required,
    }));
    props.onClickAddQuestion(newSelectedQuestions, props.sectionIndex);
    Swal.close();
  }, [props.onClickAddQuestion, questions, selectedQuestions]);

  const onChangeSelectedQuestion = useCallback(
    (selectedQuestion) => {
      const isIncludeInSelectedQuestions = selectedQuestions.some((question) => question.id === selectedQuestion.id);

      if (isIncludeInSelectedQuestions) {
        setSelectedQuestions(selectedQuestions.filter((question) => question.id !== selectedQuestion.id));
      } else {
        setSelectedQuestions([...selectedQuestions, selectedQuestion]);
      }
    },
    [selectedQuestions, setSelectedQuestions],
  );

  const onChangeIsRequired = useCallback(
    (question) => {
      const newSelectedQuestions = selectedQuestions.map((selectedQuestion) => {
        if (selectedQuestion.id === question.id) {
          return {
            ...selectedQuestion,
            is_required: !selectedQuestion.is_required,
          };
        } else {
          return selectedQuestion;
        }
      });
      setSelectedQuestions(newSelectedQuestions);
    },
    [selectedQuestions, setSelectedQuestions],
  );

  // 테이블 컬럼 설정
  const questionsColumns = useMemo(
    () => [
      {
        id: 'selection',
        Header: () => <div>-</div>,
        Cell: ({ row }) =>
          row.original.isAlreadySelected ? (
            <div>
              <Checkbox
                type="checkbox"
                disabled={row.original.isAlreadySelected}
                checked={row.original.isAlreadySelected}
              />
            </div>
          ) : (
            <div>
              <Checkbox
                type="checkbox"
                onChange={() => onChangeSelectedQuestion(row.original)}
                checked={
                  selectedQuestions.filter((selectedQuestion) => {
                    return selectedQuestion.id === row.original.id;
                  }).length > 0
                }
              />
            </div>
          ),
      },
      { Header: '질문 ID', accessor: (row) => row.id },
      { Header: '질문 유형', accessor: (row) => row.FK_question_type?.name },
      { Header: '선택 유형', accessor: (row) => row.FK_choice_layout?.name ?? '-' },
      { Header: '질문 내용', accessor: (row) => row.content },
      {
        Header: '필수 응답',
        Cell: ({ row }) =>
          row.original.isRequired ? (
            <div>
              <Checkbox type="checkbox" disabled={row.original.isAlreadySelected} checked={row.original.isRequired} />
            </div>
          ) : (
            <div>
              <Checkbox
                type="checkbox"
                disabled={
                  row.original.isAlreadySelected ||
                  selectedQuestions.filter((selectedQuestion) => {
                    return selectedQuestion.id === row.original.id;
                  }).length < 1
                }
                onChange={() => onChangeIsRequired(row.original)}
                checked={
                  selectedQuestions.filter((selectedQuestion) => {
                    return selectedQuestion.id === row.original.id;
                  }).length > 0 &&
                  selectedQuestions.filter((selectedQuestion) => {
                    return selectedQuestion.id === row.original.id;
                  })[0].is_required
                }
              />
            </div>
          ),
      },
    ],
    [selectedQuestions, onChangeSelectedQuestion],
  );

  return (
    <div className="add-questions--container">
      <div className="add-questions__table">
        <div className="add-questions__table__header">
          <div className="add-questions__table__header__search">
            <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
              <option value="search">질문 내용</option>
            </select>
            <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
          </div>
        </div>
        <div className="add-questions__table__body">
          <Table
            data={questions}
            columns={questionsColumns}
            total={count}
            pageSize={5}
            usesLocation={false}
            fetchData={fetchData}
          />
        </div>
      </div>
      <div className="buttons">
        <Button.Positive onClick={onClickSave} disabled={selectedQuestions.length < 1}>
          추가
        </Button.Positive>
        <Button.Negative onClick={() => Swal.clickCancel()}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default AddQuestion;
