import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Field from '../../../../Common/Component/Field';
import { Base } from '../../../../Common/Component/Field';
import * as Button from '../../../../Common/Component/Button';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import './index.scss';
import { useParams } from 'react-router-dom';
import * as request from '../../../../Common/Util/Request';
import SelectSearch from '../../../../Common/Component/SelectSearch';
import Table from '../../../../Common/Component/Table';
import Swal from 'sweetalert2';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

function RiviewDetail(props) {
  const params = useParams();
  const { id: reviewId } = params;
  const isNew = !reviewId;

  const [review, setReview] = useState(null);
  const [reviewStatus, setReviewStatus] = useState(1);
  const [reviewRate, setReviewRate] = useState(5);
  const [reviewComment, setReviewComment] = useState('');
  const [reviewContentRate, setReviewContentRate] = useState(5);
  const [reviewContentComment, setReviewContentComment] = useState('');
  const [reviewTutorRate, setReviewTutorRate] = useState(5);
  const [reviewTutorComment, setReviewTutorComment] = useState('');
  const [reviewTimeRate, setReviewTimeRate] = useState(5);
  const [reviewTimeComment, setReviewTimeComment] = useState('');
  const [suggestionComment, setSuggestionComment] = useState('');
  const [selectedClass, setSelectedClass] = useState([]);
  const [reviewerName, setReviewerName] = useState('');
  const [userCouponId, setUserCouponId] = useState(undefined);

  const [allLectures, setAllLectures] = useState([]);
  const [targetLecture, setTargetLecture] = useState('');
  useEffect(() => {
    // reviewId로 review 정보를 가져온다.
    if (!isNew) {
      const fetchReview = async (id) => await request.getReview(id);

      fetchReview(reviewId)
        .then(({ review, reviewToStaffs, targetLectures }) => {
          setReview(review);
          setReviewComment(review.content);
          setReviewRate(review.rate);
          setReviewStatus(review.status);
          setReviewerName(review.old_nickname || review.FK_user?.nickname || '');
          setTargetLecture(review.FK_class.id);
          setUserCouponId(review.FK_user_coupon_id);

          setReviewContentRate(reviewToStaffs?.contentRating);
          setReviewContentComment(reviewToStaffs?.contentComment);
          setReviewTutorRate(reviewToStaffs?.tutorRating);
          setReviewTutorComment(reviewToStaffs?.tutorComment);
          setReviewTimeRate(reviewToStaffs?.timeRating);
          setReviewTimeComment(reviewToStaffs?.timeComment);
          setSuggestionComment(reviewToStaffs?.suggestionComment);

          setSelectedClass((prev) => {
            if (!prev.length) {
              return [
                ...targetLectures.map((lecture) => ({
                  id: lecture.FK_class_id,
                })),
              ];
            } else {
              return prev;
            }
          });
        })
        .catch(({ message }) => {
          Swal.fire('에러', message, 'error');
        });
    } else {
      request.lectures('review', { page: 1, limit: 999999 }).then(({ classes }) => {
        setAllLectures(
          classes.rows.map(({ id, title }) => ({
            value: id,
            name: `${id} - ${title}`,
          })),
        );
      });
    }
  }, [isNew, reviewId]);

  const onClickSave = () => {
    Swal.fire({
      title: '저장하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.opener.location.reload();
        const selectedClassIds = selectedClass.map(({ id }) => id);
        request
          .saveReview({
            ...review,
            targetLecture,
            selectedClassIds,
            reviewRate,
            reviewComment,
            reviewContentRate,
            reviewContentComment,
            reviewTutorRate,
            reviewTutorComment,
            reviewTimeRate,
            reviewTimeComment,
            suggestionComment,
            reviewerName,
            reviewStatus,
          })
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                title: '저장되었습니다.',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: '창닫기',
                cancelButtonText: '확인',
                reverseButtons: true,
              }).then(({ isConfirmed }) => {
                isConfirmed && window.close();
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };
  const onClickCancel = () => {
    Swal.fire({
      title: '취소하시겠습니까?',
      text: '취소하면 작성 중인 내용이 사라집니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) window.close();
    });
  };

  const updateSelectedClass = useCallback((lecture) => {
    setSelectedClass((prev) => {
      if (prev.filter((item) => item.id === lecture.id).length > 0) {
        return prev.filter((item) => item.id !== lecture.id);
      }
      return [...prev, { id: lecture.id }];
    });
  }, []);
  const updateReviewStatus = useCallback((status) => {
    setReviewStatus(Number(status));
  }, []);
  const updateTargetLecture = useCallback((id) => {
    setTargetLecture((prev) => {
      if (!prev) {
        setSelectedClass((prev) => [{ id }, ...prev.filter((item) => item.id)]);
      } else {
        setSelectedClass((prevClasses) => [
          { id },
          ...prevClasses.filter((item) => item.id !== prev && item.id !== id && item.id),
        ]);
      }
      return id;
    });
  }, []);

  function withdrawCoupon(id, FK_user_coupon_id) {
    Swal.fire({
      title: '쿠폰을 회수하시겠습니까?',
      text: '회수 진행 시 저장 여부와 상관없이 발급된 쿠폰이 즉시 회수됩니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        request
          .withdrawCoupon(id, userCouponId)
          .then((response) => {
            if (response?.ok) {
              setUserCouponId('');
              Swal.fire({
                title: '쿠폰이 회수되었습니다.',
                icon: 'success',
                toast: true,
                timer: 2000,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  function giveCoupon(id, FK_user_id) {
    Swal.fire({
      title: '쿠폰을 발급하시겠습니까?',
      text: '발급 진행 시 저장 여부와 상관없이 쿠폰이 즉시 발급됩니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        request
          .giveCoupon(id, FK_user_id)
          .then((response) => {
            if (response?.ok) {
              setUserCouponId(response.FK_user_coupon_id);
              Swal.fire({
                title: '쿠폰이 발급되었습니다.',
                icon: 'success',
                toast: true,
                timer: 2000,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  return (
    <div className="review-detail">
      <div className="review-detail-form-fields">
        <Field.Input title="후기 ID" placeholder="자동생성" value={reviewId} className="readonly" readOnly />
        <Field.Input
          title="후기 작성 회원 ID"
          placeholder="자동입력"
          value={review?.FK_user_id}
          className="readonly"
          readOnly
        />
        <Field.Input
          title="후기 작성 회원 닉네임"
          value={reviewerName}
          className={review?.FK_user_id === 0 ? 'readonly' : ''}
          readOnly={review?.FK_user_id === 0}
          onChange={setReviewerName}
        />
        <Field.Radio
          title="후기 상태"
          name="review-status"
          value={reviewStatus}
          options={[
            { label: '공개', value: 2 },
            { label: '비공개', value: 0 },
            { label: '대기', value: 1 },
          ]}
          onChange={updateReviewStatus}
        />
        {isNew ? (
          <div className="select-target-lecture">
            <div className="field field-number">
              <div className="field__title">최초 등록 강의 *</div>
              <SelectSearch
                placeholder="강의를 선택해주세요"
                options={allLectures}
                value={targetLecture}
                onChange={updateTargetLecture}
              />
            </div>
          </div>
        ) : (
          <Field.Input
            title="최초 등록 강의"
            value={review ? `${review?.FK_class_id}\t\t${review?.FK_class.title}` : ''}
            className="readonly"
            readOnly
          />
        )}
        <Field.Input
          title="최초 등록 일시"
          placeholder="자동생성"
          value={review ? dayjs(review?.createdAt).format('YYYY.MM.DD HH:mm:ss') : ''}
          className="readonly"
          readOnly
        />

        <Base title="쿠폰 발급 이력 ID">
          <input className="readonly" readOnly placeholder="미발급" value={userCouponId} />
          {userCouponId ? (
            <button className="withdraw-coupon-button" onClick={() => withdrawCoupon(review.id, userCouponId)}>
              쿠폰 회수
            </button>
          ) : (
            review?.FK_user_id &&
            review?.FK_user_id !== 2 && (
              <button className="give-coupon-button" onClick={() => giveCoupon(review.id, review.FK_user_id)}>
                쿠폰 발급
              </button>
            )
          )}
        </Base>

        <LectureField
          title="현재 노출 강의 "
          value={selectedClass}
          onChange={updateSelectedClass}
          mainLectureId={targetLecture}
          required
        />

        <div className="field field-number">
          <div className="field__title">총점 *</div>
          <SelectSearch
            options={[
              { value: 1, name: '1' },
              { value: 2, name: '2' },
              { value: 3, name: '3' },
              {
                value: 4,
                name: '4',
              },
              { value: 5, name: '5' },
            ]}
            value={reviewRate}
            onChange={setReviewRate}
          />
        </div>
        <Field.Textarea title="후기 내용" value={reviewComment} onChange={setReviewComment} />
        <div className="field field-number">
          <div className="field__title">수업 내용 만족도 *</div>
          <SelectSearch
            options={[
              { value: 1, name: '1' },
              { value: 2, name: '2' },
              { value: 3, name: '3' },
              {
                value: 4,
                name: '4',
              },
              { value: 5, name: '5' },
            ]}
            value={reviewContentRate}
            onChange={setReviewContentRate}
          />
        </div>
        <Field.Textarea
          title=""
          placeholder="1~2점 체크 시, 불만족 이유를 작성합니다."
          value={reviewContentComment}
          onChange={setReviewContentComment}
        />

        <div className="field field-number">
          <div className="field__title">강사 만족도 *</div>
          <SelectSearch
            options={[
              { value: 1, name: '1' },
              { value: 2, name: '2' },
              { value: 3, name: '3' },
              {
                value: 4,
                name: '4',
              },
              { value: 5, name: '5' },
            ]}
            value={reviewTutorRate}
            onChange={setReviewTutorRate}
          />
        </div>
        <Field.Textarea
          title=""
          placeholder="1~2점 체크 시, 불만족 이유를 작성합니다."
          value={reviewTutorComment}
          onChange={setReviewTutorComment}
        />

        <div className="field field-number">
          <div className="field__title">수업 시간 만족도 *</div>
          <SelectSearch
            options={[
              { value: 1, name: '1' },
              { value: 2, name: '2' },
              { value: 3, name: '3' },
              {
                value: 4,
                name: '4',
              },
              { value: 5, name: '5' },
            ]}
            value={reviewTimeRate}
            onChange={setReviewTimeRate}
          />
        </div>
        <Field.Textarea
          title=""
          placeholder="1~2점 체크 시, 불만족 이유를 작성합니다."
          value={reviewTimeComment}
          onChange={setReviewTimeComment}
        />

        <Field.Textarea
          title="엔터잡에듀에 기대하는 것"
          placeholder="엔터잡에듀에서 개설했으면 하는 콘텐츠 등에 대한 의견이 기재됩니다."
          value={suggestionComment}
          onChange={setSuggestionComment}
        />
      </div>
      <div className="reviewAdd_buttons">
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

function LectureField(props) {
  const { value: selectedLecture, onChange, disabled, mainLectureId, ...otherProps } = props;

  // const [ searchOption, setSearchOption ] = useState( 'title' );
  // const [ searchText, setSearchText ] = useState( '' );
  // const [ filters, setFilters ] = useState( [] );
  // const onClickSearch = useCallback( () => {
  //   setFilters( searchText ? [ { id: searchOption, value: searchText } ] : [] );
  // }, [ searchText, searchOption ] );

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .lectures('review', {
          page: pageIndex + 1,
          limit: pageSize /*, filtered: JSON.stringify( filters )*/,
        })
        .then(({ classes }) => {
          setLectures(classes.rows);
          setTotal(classes.count);
        });
    },
    [
      /*filters*/
    ],
  );

  const getListenTypeName = (type) => {
    switch (type) {
      case 'online':
        return '온라인';
      case 'offline':
        return '오프라인';
      case 'roundOnline':
        return '온라인(기수제)';
      case 'foreigner':
        return '외국인';
      case 'consulting':
        return '컨설팅';
      default:
        return '-';
    }
  };
  const columns = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: (lecture) => {
          const checked = selectedLecture.filter((item) => item.id === lecture.id).length > 0;
          return (
            <input
              type="checkbox"
              checked={checked}
              disabled={disabled}
              onChange={() => {
                onChange(lecture);
              }}
            />
          );
        },
      },
      { Header: '강의ID', accessor: 'id' },
      {
        Header: '수강구분',
        accessor: (lecture) => getListenTypeName(lecture.listen_type),
      },
      { Header: '강의명', accessor: 'title' },
    ];
  }, [selectedLecture, disabled, onChange]);

  return (
    <Field.Base type="lectures" {...otherProps}>
      {/*<div className="field--lectures__search">
     <select
     value={searchOption}
     onChange={(e) => setSearchOption(e.target.value)}
     >
     <option value="title">강의명</option>
     <option value="id">강의 ID</option>
     </select>
     <input
     value={searchText}
     onChange={(e) => setSearchText(e.target.value)}
     />
     <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
     </div>*/}
      <div className="table-wrapper">
        <Table pageSize={99999} total={total} data={lectures} columns={columns} fetchData={fetchData} />
      </div>
      {(mainLectureId || selectedLecture.length > 0) && (
        <div className="field--lectures__selected">
          후기가 소속된 강의 ID: {selectedLecture.map(({ id }) => id).join(', ')}
        </div>
      )}
    </Field.Base>
  );
}

export default RiviewDetail;
