import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useLocationState } from '../../../../../Common/Util/CustomHooks';
import * as request from '../../../../../Common/Util/Request';
import * as Button from '../../../../../Common/Component/Button';
import Table from '../../../../../Common/Component/Table';
import './index.scss';

function Issues(props) {
  const [locationState, setLocationState] = useLocationState({
    searchOption: 'name',
    searchText: '',
    filters: [],
  });
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchText, searchOption]);

  const [coupons, setCoupons] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .coupons({
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(locationState.filters),
        })
        .then(({ coupons }) => {
          setCoupons(coupons.rows);
          setTotal(coupons.count);
        });
    },
    [locationState.filters],
  );

  return (
    <div className="coupon__issues">
      <div className="making-btn" onClick={() => window.open(`/promotion/coupon/new`)}>
        <Button.Positive>+ 쿠폰 생성</Button.Positive>
      </div>
      <div className="coupon__search">
        <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
          <option value="name">쿠폰명</option>
          <option value="code">쿠폰코드</option>
        </select>
        <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
      </div>
      <div className="coupon__total">총 쿠폰수 {total}</div>
      <Table
        data={coupons}
        total={total}
        fetchData={fetchData}
        pageSize={20}
        columns={[
          { Header: '쿠폰명', accessor: 'name' },
          { Header: '쿠폰코드', accessor: 'code' },
          {
            Header: '쿠폰 생성일',
            accessor: 'createdAt',
            Cell: ({ value: createdAt }) => (createdAt ? moment(createdAt).format('YYYY-MM-DD') : '-'),
          },
          {
            Header: '쿠폰 사용기간',
            accessor: ({ start_date, end_date, valid_date_type, valid_days }) =>
              valid_date_type === 'days'
                ? `발급일로부터 ${valid_days}일`
                : `${moment(start_date).format('YYYY-MM-DD')} ~ ${moment(end_date).format('YYYY-MM-DD')}`,
          },
          {
            Header: '사용/발급수',
            accessor: (row) =>
              row.User_Coupons.filter((user_coupon) => user_coupon.usedAt).length + ' / ' + row.User_Coupons.length,
          },
          {
            Header: '쿠폰 발급 가능 개수',
            accessor: (row) => row.count_issue || '무제한',
          },
          {
            Header: '정보수정',
            accessor: (row) =>
              row.deletedAt ? (
                '-'
              ) : (
                <button onClick={() => window.open(`/promotion/coupon/new/${row.id}`)}>수정</button>
              ),
          },
        ]}
      />
    </div>
  );
}

export default Issues;
