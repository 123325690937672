import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../Common/Util/Request';
import * as Button from '../../../Common/Component/Button';
import Table from '../../../Common/Component/Table';
import './index.scss';
import Swal from 'sweetalert2';

function Admin() {
  const [admins, setAdmins] = useState([]);
  const [total, setTotal] = useState(0);

  const [selectedAdminIndex, setSelectedAdminIndex] = useState(null);
  const selectedAdmin = useMemo(() => admins[selectedAdminIndex], [admins, selectedAdminIndex]);

  const [hasMasterAuth, setMasterAuth] = useState(false);
  const [hasMainAuth, setMainAuth] = useState(false);
  const [hasUserAuth, setUserAuth] = useState(false);
  const [hasLectureAuth, setLectureAuth] = useState(false);
  const [hasSalesAuth, setSalesAuth] = useState(false);
  const [hasPromotionAuth, setPromotionAuth] = useState(false);
  const [hasRecordAuth, setRecordAuth] = useState(false);
  const [hasReviewBestAuth, setReviewBestAuth] = useState(false);
  const [hasNoticeAuth, setNoticeAuth] = useState(false);
  const [hasEventAuth, setEventAuth] = useState(false);
  const [hasBoardAuth, setBoardAuth] = useState(false);
  const [hasSearchAuth, setSearchAuth] = useState(false);
  const [hasOrganizationAuth, setOrganizationAuth] = useState(false);
  const [hasSurveyAuth, setSurveyAuth] = useState(false);

  const [searchOption, setSearchOption] = useState('nickname');
  const [searchText, setSearchText] = useState('');
  const [filtered, setFiltered] = useState([]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      if (filtered.length < 1) {
        return;
      }

      request
        .users({
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(filtered),
          korean: true,
        })
        .then(({ users }) => {
          setAdmins(users.rows);
          setTotal(users.count);
          setSelectedAdminIndex(null);
        });
    },
    [filtered],
  );

  const onChangeSearchOption = useCallback((e) => {
    setSearchOption(e.target.value);
  }, []);
  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);
  const onClickSearch = useCallback(() => {
    if (searchText) {
      setFiltered([{ id: searchOption, value: searchText }]);
    } else {
      setFiltered([]);
    }
  }, [searchOption, searchText]);

  const onClickRow = useCallback(
    (row) => {
      setSelectedAdminIndex(selectedAdminIndex === row.index ? null : row.index);
    },
    [selectedAdminIndex],
  );

  const onClickSave = useCallback(() => {
    const authIds = [];

    if (hasMasterAuth) authIds.push(1);
    if (hasMainAuth) authIds.push(2);
    if (hasUserAuth) authIds.push(3);
    if (hasLectureAuth) authIds.push(4);
    if (hasSalesAuth) authIds.push(5);
    if (hasPromotionAuth) authIds.push(6);
    if (hasRecordAuth) authIds.push(13);
    if (hasReviewBestAuth) authIds.push(15);
    if (hasNoticeAuth) authIds.push(18);
    if (hasEventAuth) authIds.push(20);
    if (hasBoardAuth) authIds.push(19);
    if (hasSearchAuth) authIds.push(16);
    if (hasOrganizationAuth) authIds.push(17);
    if (hasSurveyAuth) authIds.push(21);

    request.updateAdminAuthes(selectedAdmin.id, authIds).then(() => {
      Swal.fire({
        title: '저장했습니다.',
        type: 'success',
        confirmButtonText: '확인',
      });
    });
  }, [
    hasMasterAuth,
    hasMainAuth,
    hasUserAuth,
    hasLectureAuth,
    hasSalesAuth,
    hasPromotionAuth,
    hasRecordAuth,
    hasReviewBestAuth,
    hasNoticeAuth,
    hasEventAuth,
    hasBoardAuth,
    hasSearchAuth,
    hasOrganizationAuth,
    hasSurveyAuth,
  ]);

  useEffect(() => {
    if (!selectedAdmin) return;

    const authIds = selectedAdmin.User_Auths.map((userAuth) => userAuth.FK_auth.id);

    setMasterAuth(authIds.includes(1));
    setMainAuth(authIds.includes(2));
    setUserAuth(authIds.includes(3));
    setLectureAuth(authIds.includes(4));
    setSalesAuth(authIds.includes(5));
    setPromotionAuth(authIds.includes(6));
    setRecordAuth(authIds.includes(13));
    setReviewBestAuth(authIds.includes(15));
    setNoticeAuth(authIds.includes(18));
    setEventAuth(authIds.includes(20));
    setBoardAuth(authIds.includes(19));
    setSearchAuth(authIds.includes(16));
    setOrganizationAuth(authIds.includes(17));
    setSurveyAuth(authIds.includes(21));
  }, [selectedAdmin]);

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: '닉네임', accessor: 'nickname' },
      { Header: '핸드폰 번호', accessor: 'phone' },
      { Header: '이메일', accessor: 'email' },
    ],
    [],
  );

  return (
    <div className="admin">
      <div className="admin__search">
        <select value={searchOption} onChange={onChangeSearchOption}>
          <option value="nickname">닉네임</option>
          <option value="phone">핸드폰 번호</option>
        </select>
        <input value={searchText} onChange={onChangeSearchText} />
        <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
      </div>
      <div className="admin__list">
        <Table data={admins} columns={columns} total={total} fetchData={fetchData} onClickRow={onClickRow} />
      </div>
      {selectedAdmin && (
        <div className="admin__auth">
          <div className="admin__auth__id">{selectedAdmin.email_id} 권한 설정</div>
          <table>
            <thead>
              <tr>
                <th>메뉴</th>
                <th>권한 허용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>최고관리자</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasMasterAuth}
                    onChange={(e) => {
                      setMasterAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>메인관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasMainAuth}
                    onChange={(e) => {
                      setMainAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>회원관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasUserAuth}
                    onChange={(e) => {
                      setUserAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>수강관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasLectureAuth}
                    onChange={(e) => {
                      setLectureAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>설문관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasSurveyAuth}
                    onChange={(e) => {
                      setSurveyAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>매출관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasSalesAuth}
                    onChange={(e) => {
                      setSalesAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>혜택관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasPromotionAuth}
                    onChange={(e) => {
                      setPromotionAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>콘텐츠관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasRecordAuth}
                    onChange={(e) => {
                      setRecordAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>후기관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasReviewBestAuth}
                    onChange={(e) => {
                      setReviewBestAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>공지사항관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasNoticeAuth}
                    onChange={(e) => {
                      setNoticeAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>이벤트관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasEventAuth}
                    onChange={(e) => {
                      setEventAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              {/*<tr>*/}
              {/*  <td*/}
              {/*    style={{*/}
              {/*      textDecoration: 'line-through',*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    게시판관리*/}
              {/*  </td>*/}
              {/*  <td>*/}
              {/*    <input*/}
              {/*      type="checkbox"*/}
              {/*      checked={hasBoardAuth}*/}
              {/*      onChange={(e) => {*/}
              {/*        setBoardAuth(e.target.checked);*/}
              {/*      }}*/}
              {/*      disabled={true}*/}
              {/*    />*/}
              {/*  </td>*/}
              {/*</tr>*/}
              <tr>
                <td>검색관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasSearchAuth}
                    onChange={(e) => {
                      setSearchAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>조직관리</td>
                <td>
                  <input
                    type="checkbox"
                    checked={hasOrganizationAuth}
                    onChange={(e) => {
                      setOrganizationAuth(e.target.checked);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        </div>
      )}
    </div>
  );
}

export default Admin;
