import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import Chip from '../../../../Common/Component/Chip';
import './index.scss';

function VideoList() {
  const history = useHistory();

  const [videos, setVideos] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);

  const columns = useMemo(
    () => [
      { Header: '영상제목', accessor: 'title' },
      { Header: '영상키값', accessor: 'video_key' },
      {
        Header: '등록일',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => (createdAt ? moment(createdAt).format('YYYY.MM.DD') : '-'),
      },
      {
        Header: '강의길이',
        accessor: 'video_length',
        Cell: ({ value: video_length }) =>
          moment.utc(moment.duration(video_length, 's').asMilliseconds()).format('H:mm:ss'),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <Link
            to={{
              pathname: `/lecture/video/new/${row.id}`,
              state: row,
            }}
          >
            수정
          </Link>
        ),
      },
    ],
    [],
  );

  const [locationState, setLocationState] = useLocationState({
    searchText: '',
    selectedCategoryType: 'company',
    selectedCategories: [],
    filters: [],
  });
  const { selectedCategoryType, selectedCategories, filters } = locationState;
  const [searchText, setSearchText] = useState(locationState.searchText);

  const categoryTypes = useMemo(() => ['company', 'field', 'process'], []);
  const categories = useMemo(() => {
    switch (selectedCategoryType) {
      case 'company':
        return ['SM', 'JYP', 'YG', 'CJ ENM', 'HYBE', 'Kakao', '기타'];
      case 'field':
        return ['음악', '영화', '방송', '공연', '기타'];
      case 'process':
        return ['입문', '직무', '서류', '인적성', '면접', '기타'];
      default:
        return [];
    }
  }, [selectedCategoryType]);
  const categoryInfo = useMemo(() => {
    const info = { company: [], field: [], process: [] };
    info[selectedCategoryType] = selectedCategories.map((category) => categories.indexOf(category) + 1);
    return info;
  }, [categories, selectedCategoryType, selectedCategories]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .videos({
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(filters),
          category_info: JSON.stringify(categoryInfo),
        })
        .then(({ videos }) => {
          setVideos(videos.rows);
          setTotal(videos.count);
        });
    },
    [filters, categoryInfo],
  );

  const onClickCategoryType = useCallback(
    (type) => {
      setLocationState({
        selectedCategoryType: type,
        selectedCategories: [],
      });
    },
    [setLocationState],
  );
  const onClickCategory = useCallback(
    (category) => {
      const index = selectedCategories.indexOf(category);
      const _selectedCategories = [...selectedCategories];
      if (index >= 0) {
        _selectedCategories.splice(index, 1);
      } else {
        _selectedCategories.push(category);
      }
      setLocationState({ selectedCategories: _selectedCategories });
    },
    [selectedCategories, setLocationState],
  );
  const onClickClear = useCallback(() => {
    setLocationState({ selectedCategories: [] });
  }, [setLocationState]);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchText,
      filters: searchText ? [{ id: 'title', value: searchText }] : [],
    });
  }, [setLocationState, searchText]);

  const onClickRemove = useCallback(() => {
    if (selectedVideoIds.length <= 0) return;

    if (!window.confirm('해당 동영상을 사용하는 강의 목차들이 함께 삭제됩니다. 정말로 삭제하시겠습니까?')) return;

    request.deleteVideos(selectedVideoIds).then(() => {
      window.alert('삭제했습니다.');
      history.go(0);
    });
  }, [history, selectedVideoIds]);

  const onChangeSelectedRows = useCallback((rows) => {
    setSelectedVideoIds(rows.map((row) => row.original.id));
  }, []);

  return (
    <div className="video-list">
      <section>
        <div className="video-list__search">
          <input
            placeholder="영상 제목을 검색해보세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
        </div>
        <div className="video-list__buttons">
          <Button.Negative onClick={onClickRemove}>선택 삭제</Button.Negative>
          <Link to="/lecture/video/new">
            <Button.Positive>동영상 강의 등록</Button.Positive>
          </Link>
        </div>
      </section>
      <div className="video-list__category">
        <div className="video-list__category__types">
          {categoryTypes.map((type) => {
            const selected = type === selectedCategoryType;
            return (
              <div
                key={type}
                className={'video-list__category__type' + (selected ? ' video-list__category__type--selected' : '')}
                onClick={() => onClickCategoryType(type)}
              >
                <FormattedMessage id={'ID_VIDEO_CATEGORY_TYPE_' + type.toUpperCase()} />
              </div>
            );
          })}
        </div>
        <div className="video-list__category__categories">
          {categories.map((category) => (
            <Chip key={category} name={category} onClick={() => onClickCategory(category)} />
          ))}
        </div>
        <div className="video-list__category__selected-categories">
          {selectedCategories.map((category) => (
            <Chip key={category} name={category} onClick={() => onClickCategory(category)} selected />
          ))}
          {selectedCategories.length > 0 && (
            <div className="video-list__category__clear" onClick={onClickClear}>
              초기화
            </div>
          )}
        </div>
      </div>
      <div className="video-list__table">
        <div className="video-list__table__total">총 영상수 {total}</div>
        <Table
          data={videos}
          columns={columns}
          fetchData={fetchData}
          total={total}
          pageSize={20}
          onChangeSelectedRows={onChangeSelectedRows}
        />
      </div>
    </div>
  );
}

export default VideoList;
