import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as Field from '../../../../Common/Component/Field';
import { Base } from '../../../../Common/Component/Field';
import './index.scss';
import ReactDatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import * as request from '../../../../Common/Util/Request';

function LectureUserDetail(props) {
  const intl = useIntl();
  const location = useLocation();
  const lectureUser = location.state.user;
  const lectures = useMemo(
    () =>
      lectureUser.FK_class.class_type === 'single'
        ? [lectureUser.FK_class]
        : lectureUser.FK_class.ClassSingle_ClassPackages.map(
            (ClassSingle_ClassPackage) => ClassSingle_ClassPackage.FK_class_single,
          ),
    [lectureUser],
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    setEndDate(new Date(lectureUser.end_date));
    setStartDate(new Date(lectureUser.start_date));
    setIsOnline(lectureUser.FK_class.listen_type === 'online' || lectureUser.FK_class.listen_type === 'foreigner');
  }, [lectureUser]);

  const updateEndDate = useCallback(() => {
    const hasModified = moment(endDate).format('YYYY-MM-DD') !== moment(lectureUser.end_date).format('YYYY-MM-DD');
    if (hasModified) {
      Swal.fire({
        title: `수강종료일을 ${moment(endDate).format('YYYY-MM-DD')}로 수정하시겠습니까?`,
        text: '확인 시 즉시 반영됩니다.',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async (data) => {
          const result = await request.updateEndDate(lectureUser.id, endDate);
          return new Promise((resolve) => {
            setTimeout(() => {
              if (result.ok) {
                Swal.fire({
                  text: '수강종료일이 수정되었습니다.',
                  type: 'success',
                  icon: 'success',
                  confirmButtonText: '확인',
                  toast: true,
                  timer: 2000,
                });
                lectureUser.end_date = endDate;
              }
              resolve();
            }, 1000);
          });
        },
      });
    } else {
      Swal.fire({
        text: '수강종료일이 수정되지 않았습니다.',
        type: 'error',
        icon: 'error',
        confirmButtonText: '확인',
        toast: true,
      });
    }
  }, [endDate, lectureUser]);

  const offlineConsultingSchedule = lectureUser.FK_class_offline_schedule || lectureUser.FK_class_consulting_schedule;

  return (
    <div className="lecture-user">
      <Field.Input title="회원ID" value={lectureUser.FK_user.id} fixed />
      <Field.Input title="닉네임" value={lectureUser.FK_user.nickname} fixed />
      <Field.Input title="이름" value={lectureUser.FK_user.name} fixed />
      <Field.Input title="국가 정보" value={lectureUser.FK_user.FK_country?.name_kor || '-'} fixed />
      <Field.Input title="강의ID" value={lectureUser.FK_class.id} fixed />
      <Field.Input title="강의명" value={lectureUser.FK_class.title} fixed />
      <Field.Input
        title="수강옵션"
        value={
          offlineConsultingSchedule
            ? `${offlineConsultingSchedule.class_round && `${offlineConsultingSchedule.class_round}기`} | ${
                !!offlineConsultingSchedule.title ? `${offlineConsultingSchedule.title} |` : '옵션명 없음 | '
              } ${offlineConsultingSchedule.class_dates
                .split('')
                .map((classDate, index) => {
                  return +classDate !== 0
                    ? intl.formatMessage({
                        id: 'ID_WEEKDAY_' + (index + 1),
                      })
                    : '';
                })
                .join('')} | ${offlineConsultingSchedule.class_start_time} | ${offlineConsultingSchedule.start_date}~${
                offlineConsultingSchedule.end_date
              }
        `
            : lectureUser.FK_class_online_schedule_id
            ? `${lectureUser.FK_class_online_schedule.class_round}기 | ${
                !!lectureUser.FK_class_online_schedule.title
                  ? `${lectureUser.FK_class_online_schedule.title} |`
                  : '옵션명 없음 | '
              } ${lectureUser.FK_class_online_schedule.start_date}~${lectureUser.FK_class_online_schedule.end_date}${
                lectureUser.FK_class_online_schedule.class_start_time
                  ? ` | ${lectureUser.FK_class_online_schedule.class_start_time}~${lectureUser.FK_class_online_schedule.class_end_time}`
                  : ` | 강의 시간 없음`
              }`
            : ``
        }
        fixed
      />
      <Field.Input
        title="강사명"
        value={lectureUser.FK_class.Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(',')}
        fixed
      />
      <Field.Input
        title="구분"
        value={<FormattedMessage id={'ID_LISTEN_TYPE_' + lectureUser.FK_class.listen_type.toUpperCase()} />}
        fixed
      />
      <Field.Input
        title="결제일"
        value={lectureUser.FK_tx.deletedAt ? '-' : moment.utc(lectureUser.FK_tx.date_paid_at).format('YYYY-MM-DD')}
        fixed
      />
      <Field.Input
        title="콘텐츠 지급일"
        value={lectureUser.FK_tx.deletedAt ? moment.utc(lectureUser.FK_tx.createdAt).format('YYYY-MM-DD') : '-'}
        fixed
      />
      <Field.Input
        title="수강시작일"
        value={lectureUser.start_date ? moment.utc(lectureUser.start_date).format('YYYY-MM-DD') : '-'}
        fixed
      />
      {isOnline ? (
        <Base title="수강종료일">
          {/*{endDate}*/}
          {endDate && endDate !== '-' && (
            <div>
              <ReactDatePicker selected={endDate} minDate={startDate} dateFormat="yyyy-MM-dd" onChange={setEndDate} />
            </div>
          )}
          <button style={{ marginLeft: '12px' }} onClick={() => updateEndDate()}>
            저장
          </button>
        </Base>
      ) : (
        <Field.Input
          title="수강종료일"
          value={lectureUser.end_date ? moment.utc(lectureUser.end_date).format('YYYY-MM-DD') : '-'}
          fixed
        />
      )}

      <Field.Input
        title="남은일수"
        value={lectureUser.count_left_day ? lectureUser.count_left_day + '일' : '-'}
        fixed
      />
      <Field.Input title="금액(정가)" value={lectureUser.FK_class.price_original + '₩'} fixed />
      <Field.Input title="결제금액" value={lectureUser.FK_tx.paid_amount + '₩'} fixed />
      <Field.Input
        title="강의자료 다운로드"
        value={lectureUser.is_download_attached_file ? '다운로드 후' : '다운로드 전'}
        fixed
      />
      <Field.Input title="진도율" value={`${lectureUser.completion_rate ? lectureUser.completion_rate : 0}%`} fixed />
      <Field.Base title="차시별 진도율">
        <ul>
          {lectures.map((lecture) =>
            lecture.ClassChapters.map((ClassChapter, chapterIndex) =>
              ClassChapter.ClassSections.map((ClassSection, sectionIndex) => {
                const key = `${lecture.id}-${chapterIndex + 1}-${sectionIndex + 1}`;
                return (
                  <li key={key}>
                    {key}
                    {` `}
                    {ClassSection.section_completion_rate ? `${ClassSection.section_completion_rate}` : '0'}%
                  </li>
                );
              }),
            ),
          )}
        </ul>
      </Field.Base>
    </div>
  );
}

export default LectureUserDetail;
