import React from 'react';
import Menu from './Menu';
import SubMenu from './SubMenu';
import './index.scss';

function Menus(props) {
  return (
    <div className="menus">
      <Menu path="/dashboard" />
      <Menu path="/main/banner/top">
        <SubMenu path="/main/banner/top" />
        <SubMenu path="/main/banner/online" />
        <SubMenu path="/main/banner/offline" />
        <SubMenu path="/main/banner/video" />
        <SubMenu path="/main/banner/event" />
        <SubMenu path="/main/banner/bottom" />
      </Menu>
      <Menu path="/user/list">
        <SubMenu path="/user/list" />
        <SubMenu path="/user/foreign-list" />
        <SubMenu path="/user/admin" />
        <SubMenu path="/user/interested-company" />
      </Menu>
      <Menu path="/lecture/video">
        <SubMenu path="/lecture/video" />
        <SubMenu path="/lecture/single" />
        <SubMenu path="/lecture/package" />
        <SubMenu path="/lecture/3rd" />
        <SubMenu path="/lecture/waiting" />
        <SubMenu path="/lecture/like" />
        <SubMenu path="/lecture/user" />
        <SubMenu path="/lecture/foreign-user" />
        <SubMenu path="/lecture/tutor" />
        <SubMenu path="/lecture/given" />
        <SubMenu path="/lecture/offline" />
      </Menu>
      <Menu path="/survey/list">
        <SubMenu path="/survey/list" />
        <SubMenu path="/survey/question-list" />
      </Menu>
      <Menu path="/sales/old">
        <SubMenu path="/sales/old" />
        <SubMenu path="/sales/settlement" />
        <SubMenu path="/sales/logs" />
      </Menu>
      <Menu path="/promotion/coupon-issues">
        <SubMenu path={['/promotion/coupon-issues', '/promotion/coupon-usages']} />
      </Menu>
      <Menu path="/contents/records">
        <SubMenu path="/contents/records" />
        <SubMenu path="/contents/artists" />
        <SubMenu path="/contents/agencies" />
      </Menu>
      <Menu path="/review/lecture">
        <SubMenu path="/review/lecture" />
        <SubMenu path="/review/best" />
        <SubMenu path="/review/request" />
      </Menu>
      <Menu path="/notice/list">
        <SubMenu path="/notice/list" />
      </Menu>
      <Menu path="/event/list">
        <SubMenu path="/event/list" />
      </Menu>
      <Menu path="/blog/list">
        <SubMenu path="/blog/list" />
      </Menu>
      {/*<Menu path="/board/posts">*/}
      {/*  <SubMenu path="/board/posts" />*/}
      {/*</Menu>*/}
      <Menu path="/search/logs">
        <SubMenu path="/search/logs" />
        <SubMenu path="/search/recommends" />
      </Menu>
      <Menu path="/organization/list">
        <SubMenu path="/organization/list" />
      </Menu>
    </div>
  );
}

export default Menus;
