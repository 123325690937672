import ACTION from '../Action';

export const set = (admin) => ({
  type: ACTION.SET_ADMIN,
  admin,
});

const initialState = {
  authes: [],
};

function admin(state = initialState, action) {
  switch (action.type) {
    case ACTION.SET_ADMIN:
      return action.admin;
    default:
      return state;
  }
}

export default admin;
