import './index.scss';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as request from '../../../../Common/Util/Request';
import Table from '../../../../Common/Component/Table';
import DatePicker from '../../../../Common/Component/DatePicker';
import * as Button from '../../../../Common/Component/Button';
import moment from 'moment';

function PostsList(props) {
  const [locationState, setLocationState] = useLocationState({
    selectedGroupCode: undefined,
    selectedBoardCode: undefined,
    startDate: null,
    endDate: null,
    searchType: 'title',
    searchText: '',
    filters: [],
  });
  const { selectedGroupCode, selectedBoardCode, startDate, endDate, filters } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [groupTypes, setGroupTypes] = useState([]);
  const [boardTypes, setBoardTypes] = useState([]);

  const onClickClear = useCallback(() => {
    setLocationState({ selectedGroupCode: undefined, selectedBoardCode: undefined });
  }, [setLocationState]);

  const onClickGroup = useCallback(
    (groupCode) => {
      if (groupCode === '') {
        setLocationState({ selectedGroupCode: undefined, selectedBoardCode: undefined });
      } else if (groupCode !== selectedGroupCode) {
        setLocationState({ selectedGroupCode: groupCode, selectedBoardCode: undefined });
      } else {
        setLocationState({ selectedGroupCode: groupCode });
      }

      setBoardTypes(groupTypes.find((group) => group.code === groupCode)?.Boards || []);
    },
    [selectedGroupCode, setLocationState],
  );

  const onClickBoard = useCallback(
    (boardCode) => {
      if (boardCode === '') {
        setLocationState({ selectedBoardCode: undefined });
      } else {
        setLocationState({ selectedBoardCode: boardCode });
      }
    },
    [selectedBoardCode, setLocationState],
  );

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText: searchType === 'phone' ? searchText.replace(/^0/, '82') : searchText,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  /** 테이블 설정 */
  const loadPosts = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        filtered: JSON.stringify(filters),
        groupCode: selectedGroupCode,
        boardCode: selectedBoardCode,
      };

      if (startDate) {
        params.start_date = dayjs(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = dayjs(endDate).format('YYYY-MM-DD');
      }

      return request.getPosts(params).then(({ data }) => data);
    },
    [startDate, endDate, filters, selectedGroupCode, selectedBoardCode],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadPosts({ page: pageIndex + 1, limit: pageSize }).then((data) => {
        setPosts(data.posts);
        setTotal(data.count);
        setGroupTypes(data.groups); // 목록 페이지는 조건 변경 시마다 api 호출하므로 목록 API에서 게시판 그룹 데이터를 같이 받아옴
      });
    },
    [loadPosts],
  );

  /** 그룹 코드가 'service'인 게시판 목록 데이터 설정 */
  useEffect(() => {
    setBoardTypes(groupTypes.find((group) => group.code === selectedGroupCode)?.Boards || []);
  }, [groupTypes, setBoardTypes]);

  const columns = useMemo(
    () => [
      { Header: '게시글 번호', accessor: 'id' },
      { Header: '게시판 그룹', accessor: 'FK_group.name' },
      { Header: '게시판', accessor: 'FK_board.name' },
      { Header: '제목', accessor: 'title' },
      { Header: '공개 여부', accessor: 'isShow', Cell: ({ value: isShow }) => (isShow ? '공개' : '비공개') },
      {
        Header: '공개 범위',
        accessor: 'showTarget',
        Cell: ({ value: showTarget }) =>
          showTarget === 0 ? '전체 공개' : showTarget === 1 ? '회원 공개' : '어드민 공개',
      },
      { Header: '작성자 이름', accessor: 'FK_user.name' },
      { Header: '작성자 닉네임', accessor: 'FK_user.nickname' },
      {
        Header: '작성자 권한',
        accessor: 'FK_user.role',
        Cell: ({ value: role }) => (role === 'administrator' ? '관리자' : '일반'),
      },
      {
        Header: '첨부파일 개수',
        accessor: 'fileCount',
      },
      {
        Header: '조회수',
        accessor: 'viewCount',
      },
      {
        Header: '작성일',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => moment(createdAt).format('YYYY-MM-DD'),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/board/posts/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [],
  );

  return (
    <div className="posts-list">
      <section>
        <div className="posts-list__date">
          기간
          <DatePicker
            selected={startDate}
            maxDate={endDate}
            onChange={(date) => setLocationState({ startDate: date })}
          />
          ~
          <DatePicker selected={endDate} minDate={startDate} onChange={(date) => setLocationState({ endDate: date })} />
        </div>
        <div className="posts-list__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="id">게시글 아이디</option>
            <option value="title">게시글 제목</option>
            <option value="FK_user_id">작성자 아이디</option>
            <option value="username">작성자 이름</option>
            <option value="nickname">작성자 닉네임</option>
            <option value="email">작성자 이메일</option>
            <option value="phone">작성자 핸드폰</option>
          </select>
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
        </div>
        <div className="making-btn">
          <Button.Positive onClick={() => window.open(`/board/posts/new`)}>게시글 작성</Button.Positive>
        </div>
      </section>
      <section className="posts-grouping-section">
        <div className="posts-grouping-title-container">
          <b>게시판 선택</b>
          <Button.Positive
            onClick={(selectedGroupCode || selectedBoardCode) && onClickClear}
            style={!selectedGroupCode && !selectedBoardCode ? { backgroundColor: '#999', cursor: 'not-allowed' } : {}}
          >
            초기화
          </Button.Positive>
        </div>
        <div className="posts-grouping-select-container">
          <label htmlFor="group">게시판 그룹</label>
          <select id="group" value={selectedGroupCode} onChange={(e) => onClickGroup(e.target.value)}>
            <option value="">그룹 선택</option>
            {groupTypes.map((groupType) => (
              <option key={groupType.code} value={groupType.code}>
                {groupType.name}
              </option>
            ))}
          </select>
          <label htmlFor="board">게시판</label>
          <select
            id="board"
            disabled={!selectedGroupCode}
            value={selectedBoardCode}
            onChange={(e) => onClickBoard(e.target.value)}
          >
            <option value="">{selectedGroupCode ? '게시판 선택' : '그룹 선택 필요'}</option>
            {boardTypes.map((boardType) => (
              <option key={boardType.code} value={boardType.code}>
                {boardType.name}
              </option>
            ))}
          </select>
        </div>
      </section>
      <div className="posts-list-top-container">
        <div className="posts-list__total">총 게시글수 {total}</div>
      </div>
      <Table data={posts} columns={columns} total={total} pageSize={20} fetchData={fetchData} />
    </div>
  );
}

export default PostsList;
