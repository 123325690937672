import React, { useCallback, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import * as request from '../../../../Common/Util/Request';
import * as Button from '../../../../Common/Component/Button';
import * as Field from '../../../../Common/Component/Field';
import './index.scss';

function Upsert(props) {
  const id = useMemo(() => props.data?.id || '', [props.data]);
  const [name, setName] = useState(props.data?.name || '');
  const [image, setImage] = useState(props.data?.thumbnail || null);

  const onChangeName = (value) => {
    setName(value);
  };
  const onChangeImage = (value) => {
    setImage(value);
  };
  const onClickSave = useCallback(() => {
    const artist = {
      thumbnail: image,
      name,
      id,
    };
    const requiredFields = ['name'];
    const valid = requiredFields.every((field) => artist[field]);
    if (!valid) {
      Swal.resetValidationMessage();
      return Swal.showValidationMessage('입력되지 않은 항목이 있습니다.');
    }
    const actionType = Boolean(id) ? 'update' : 'create';

    const params = {
      ...artist,
      name: artist.name.trim(),
      id,
    };
    request
      .upsertArtist(actionType, params)
      .then((res) => {
        props.modalClose(true);
      })
      .catch((error) => {
        setName(params.name);
        Swal.resetValidationMessage();
        Swal.showValidationMessage(error.response.data.detail);
      });
    //
  }, [id, image, name, props]);
  return (
    <>
      <div className={`mb-3 ${Boolean(id) ? '' : 'hidden'}`}>
        <Field.Input title="아티스트 ID" value={id} disabled />
      </div>
      <Field.Input title="아티스트명" value={name} onChange={onChangeName} required />
      <Field.Image title="대표이미지" value={image} onChange={onChangeImage} />
      <div className="buttons">
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={() => Swal.clickCancel()}>취소</Button.Negative>
      </div>
    </>
  );
}

export default Upsert;
