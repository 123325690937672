import React, { useCallback, useMemo, useState } from 'react';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import DatePicker from '../../../../Common/Component/DatePicker';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import Chip from '../../../../Common/Component/Chip';
import * as excel from '../../../../Common/Util/Excel';
import dayjs from 'dayjs';

function LectureWaitingList(props) {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchOption: 'title',
    searchText: '',
    filters: [],
    selectedStatuses: [],
    selectedStatusType: 'waiting',
  });
  const { selectedStatuses, selectedStatusType, startDate, endDate, filters } = locationState;
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);
  const [waitings, swtWaitings] = useState([]);
  const [total, setTotal] = useState(0);

  /** status 설정 */
  const statusTypes = useMemo(() => ['waiting'], []);

  const statuses = useMemo(() => {
    switch (selectedStatusType) {
      case 'waiting':
        return ['안내 예정', '안내 가능', '안내 종료'];
      default:
        return [];
    }
  }, [selectedStatusType]);

  const onClickClear = useCallback(() => {
    setLocationState({ selectedStatuses: [] });
  }, [setLocationState]);

  // status 유형 많아질 경우 사용
  const onClickStatusType = useCallback(
    (type) => {
      setLocationState({
        selectedStatusType: type,
        selectedStatuses: [],
      });
    },
    [setLocationState],
  );

  const onClickStatus = useCallback(
    (status) => {
      const index = selectedStatuses.indexOf(status);
      const _selectedStatuses = [...selectedStatuses];
      if (index >= 0) {
        _selectedStatuses.splice(index, 1);
      } else {
        _selectedStatuses.push(status);
      }
      setLocationState({ selectedStatuses: _selectedStatuses });
    },
    [selectedStatuses, setLocationState],
  );

  const statusInfo = useMemo(() => {
    const info = { waiting: [] };
    info[selectedStatusType] = selectedStatuses.map((status) => statuses.indexOf(status) + 1);
    return info;
  }, [statuses, selectedStatusType, selectedStatuses]);

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText: searchOption === 'phone' ? searchText.replace(/^0/, '82') : searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchOption, searchText]);

  /** 테이블 설정 */
  const loadWaitings = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        filtered: JSON.stringify(filters),
        status_info: JSON.stringify(statusInfo),
      };

      if (startDate) {
        params.start_date = dayjs(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = dayjs(endDate).format('YYYY-MM-DD');
      }

      return request.lectureWaitings(params).then(({ data }) => data);
    },
    [startDate, endDate, filters, statusInfo],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadWaitings({ page: pageIndex + 1, limit: pageSize }).then((classWaitings) => {
        swtWaitings(classWaitings.rows);
        setTotal(classWaitings.count);
      });
    },
    [loadWaitings],
  );

  const columns = useMemo(
    () => [
      { Header: '대기번호', accessor: 'id' },
      {
        Header: '대기 상태',
        accessor: 'status',
        Cell: ({ value: status }) => (status === 0 ? '안내 종료' : status === 1 ? '안내 예정(오픈 대기)' : '안내 가능'),
      },
      { Header: '강의ID', accessor: 'FK_class.id' },
      { Header: '강의명', accessor: 'FK_class.title' },
      {
        Header: '구분',
        accessor: 'FK_class.listen_type',
        Cell: ({ value: listen_type }) => <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />,
      },
      { Header: '회원ID', accessor: 'FK_user.id' },
      { Header: '이름', accessor: 'FK_user.name' },
      { Header: '닉네임', accessor: 'FK_user.nickname' },
      {
        Header: '핸드폰',
        accessor: 'FK_user.phone',
        Cell: ({ value: phone }) => phone?.replace(/^82/, '0') ?? '',
      },
      { Header: '이메일', accessor: 'FK_user.email' },
      {
        Header: '대기 신청일',
        accessor: 'createdAt', // timestamp data -> utc 전송받음(timezone 적용하여 KST)
        Cell: ({ value: createdAt }) => (createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '결제일',
        accessor: 'paidAt', // datetime data -> utc + 9 전송받음(timezone 적용하지 않고 한국 시간과 같아 보임)
        Cell: ({ value: paidAt }) => (paidAt ? dayjs.utc(paidAt).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '안내 횟수',
        accessor: 'messageLogs', // datetime data -> utc + 9 전송받음(timezone 적용하지 않고 한국 시간과 같아 보임)
        Cell: ({ value: messageLogs }) => (messageLogs?.length ? messageLogs.length : 0),
      },
    ],
    [],
  );

  const onClickCSV = useCallback(() => {
    loadWaitings({ page: 1, limit: 9999999 }).then((waitings) => {
      const csvData = waitings.rows.map((waiting) => ({
        대기번호: waiting.id,
        대기상태: waiting.status === 0 ? '안내 종료' : waiting.status === 1 ? '안내 예정(오픈 대기)' : '안내 가능',
        강의ID: waiting.FK_class.id,
        강의명: waiting.FK_class.title,
        구분: <FormattedMessage id={'ID_LISTEN_TYPE_' + waiting.FK_class.listen_type.toUpperCase()} />,
        회원ID: waiting.FK_user.id,
        이름: waiting.FK_user.name,
        닉네임: waiting.FK_user.nickname,
        핸드폰: waiting.FK_user.phone?.replace(/^82/, '0') ?? '',
        이메일: waiting.FK_user.email,
        '대기 신청일': waiting.createdAt ? dayjs(waiting.createdAt).format('YYYY-MM-DD') : '-',
        결제일: waiting.paidAt ? dayjs.utc(waiting.paidAt).format('YYYY-MM-DD') : '-',
        '안내 횟수': waiting.messageLogs?.length ? waiting.messageLogs.length : 0,
      }));
      excel.downloadXLSX(csvData, `강의대기목록_${dayjs.utc().add(9, 'h').format('YYYYMMDD')}`);
    });
  }, [loadWaitings]);

  return (
    <div className="lecture-waiting-list">
      <div className="lecture-waiting-list__date">
        기간
        <DatePicker selected={startDate} maxDate={endDate} onChange={(date) => setLocationState({ startDate: date })} />
        ~
        <DatePicker selected={endDate} minDate={startDate} onChange={(date) => setLocationState({ endDate: date })} />
      </div>
      <div className="lecture-waiting-list__search">
        <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
          <option value="username">회원 이름</option>
          <option value="nickname">닉네임</option>
          <option value="email">이메일</option>
          <option value="phone">핸드폰번호</option>
          <option value="FK_user_id">회원ID</option>
          <option value="FK_class_id">강의ID</option>
          <option value="title">강의명</option>
          <option value="name">강사명</option>
        </select>
        <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
      </div>
      <div className="waiting-list__status">
        <div className="waiting-list__status__types">
          {statusTypes.map((type) => {
            // const selected = type === selectedStatusType;
            return (
              <div key={type} className={'waiting-list__status__type' + ' waiting-list__status__type--selected'}>
                <FormattedMessage id={'ID_WAITING_TYPE_' + type.toUpperCase()} />
              </div>
            );
          })}
        </div>
        <div className="waiting-list__status__statuses">
          {statuses.map((status) => (
            <Chip key={status} name={status} onClick={() => onClickStatus(status)} />
          ))}
        </div>
        <div className="waiting-list__status__selected-statuses">
          {selectedStatuses.map((status) => (
            <Chip key={status} name={status} onClick={() => onClickStatus(status)} selected />
          ))}
          {selectedStatuses.length > 0 && (
            <div className="waiting-list__status__clear" onClick={onClickClear}>
              초기화
            </div>
          )}
        </div>
      </div>
      <div className="lecture-waiting-list__total">
        총 신청 회원수 {total}
        <Button.Excel onClick={onClickCSV}>엑셀 다운로드</Button.Excel>
      </div>
      <Table data={waitings} columns={columns} total={total} pageSize={20} fetchData={fetchData} />
    </div>
  );
}

export default LectureWaitingList;
