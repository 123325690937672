import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as request from '../../../../Common/Util/Request';
import * as Field from '../../../../Common/Component/Field';
import * as Button from '../../../../Common/Component/Button';
import Chip from '../../../../Common/Component/Chip';
import Table from '../../../../Common/Component/Table';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import './index.scss';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

function Given(props) {
  const intl = useIntl();
  const { id } = useParams();
  const [lectureOrigin, setLectureOrigin] = useState(null);
  const editing = useMemo(() => !!lectureOrigin, [lectureOrigin]);

  const [selectedLecture, setSelectedLecture] = useState(null);
  const [days, setDays] = useState(null);
  const [userIds, setUserIds] = useState([]);
  const [userIdsToDelete, setUserIdsToDelete] = useState([]);
  const [userIdsStringToAdd, setUserIdsStringToAdd] = useState('');

  useEffect(() => {
    id &&
      request.getGivenLectureDetail(id).then((data) => {
        setLectureOrigin(data);
      });
  }, [id]);

  useEffect(() => {
    if (lectureOrigin) {
      setSelectedLecture(lectureOrigin.FK_class);
      setDays(lectureOrigin.valid_days);
      setUserIds(lectureOrigin.User_AdminAddClasses.map((User_AdminAddClass) => User_AdminAddClass.FK_user_id));
    }
  }, [lectureOrigin]);

  const lecture = useMemo(() => {
    const lecture = {
      class_id: selectedLecture?.id,
      valid_days: Number(days),
    };
    if (userIdsToDelete.length > 0) {
      lecture.delete_user_ids = userIdsToDelete.join(',');
    }
    if (userIdsStringToAdd) {
      lecture.user_ids = userIdsStringToAdd;
    }
    return lecture;
  }, [selectedLecture, days, userIdsToDelete, userIdsStringToAdd]);

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  const onClickUserId = useCallback((userId) => {
    setUserIds((userIds) => userIds.filter((_userId) => _userId !== userId));
    setUserIdsToDelete((userIds) => [...userIds, userId]);
  }, []);

  const onClickDeleteAllUserIds = useCallback(() => {
    setUserIds([]);
    setUserIdsToDelete((_userIds) => [..._userIds, ...userIds]);
  }, [userIds]);

  const onClickSave = useCallback(() => {
    const requiredFields = ['class_id', 'valid_days'];
    const emptyRequiredFields = requiredFields.filter((field) => !lecture[field]);
    if (emptyRequiredFields.length > 0) {
      Swal.fire({
        html: `${emptyRequiredFields
          .map((field) =>
            intl.formatMessage({
              id: 'ID_LECTURE_FIELD_' + field.toUpperCase(),
            }),
          )
          .join(', ')} 값을 입력해주세요.`,
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }

    const classOnlineSchedule = selectedLecture.ClassOnlineSchedule;
    if (classOnlineSchedule) {
      const classValidDays = selectedLecture.ClassOnlineSchedule.valid_days;
      if (lecture.valid_days > classValidDays) {
        Swal.fire({
          html: `강의의 수강일수는 최대 ${classValidDays}일 입니다.`,
          toast: true,
          position: 'center',
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: true,
          icon: 'error',
        });
        return;
      } else {
        let startDay = classOnlineSchedule.start_date;

        if (startDay) {
          const nowKo = dayjs.utc(dayjs().utc().format('YYYY-MM-DD'));
          let unitValue = nowKo.add(lecture.valid_days, 'd').subtract(classValidDays, 'd');
          startDay = dayjs.utc(startDay);

          // 강의 시작일이 현재보다 이 후 날짜일 경우
          const addDays = startDay.isAfter(unitValue) ? startDay.diff(unitValue, 'd') : 0;
          unitValue = unitValue.add(addDays, 'd');

          if (!startDay.isSameOrBefore(unitValue)) {
            let minimumValidDays = startDay.diff(nowKo, 'd') + classValidDays;
            if (minimumValidDays > classValidDays) {
              minimumValidDays = classValidDays;
            }
            Swal.fire({
              html: `해당 강의의 최소 강의 수강일수는 ${minimumValidDays}일 입니다.`,
              toast: true,
              position: 'center',
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: true,
              icon: 'error',
            });
            return;
          }
        }
      }
    }

    if (editing) {
      request
        .updateGivenLecture(lectureOrigin.id, lecture)
        .then(() => {
          window.opener.location.reload();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 수정되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        })
        .catch(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">수정에 실패했습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            type: 'error',
          });
        });
    } else {
      request
        .createGivenLecture(lecture)
        .then(() => {
          window.opener.location.reload();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 수정되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        })
        .catch(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">수정에 실패했습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            type: 'error',
          });
        });
    }
  }, [intl, lecture, editing, lectureOrigin, selectedLecture]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">저장하지 않고 나가시겠습니까?</div>`,
      toast: true,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  }, []);

  return (
    <div className="given">
      <div className="given__fields">
        <LectureField
          title="지급할 콘텐츠"
          value={selectedLecture}
          onChange={setSelectedLecture}
          disabled={editing}
          required
        />
        <Field.Input type="number" title="수강일수" textAfter="일" value={days} onChange={setDays} required />
        <Field.Textarea title="콘텐츠 지급 대상 회원 ID" value={userIdsStringToAdd} onChange={setUserIdsStringToAdd} />
        {userIds.length > 0 && (
          <Field.Base type="user-ids">
            <div className="user-ids">
              {userIds.map((userId) => (
                <Chip key={userId} name={userId} onClick={() => onClickUserId(userId)} selected />
              ))}
              <Button.Negative onClick={onClickDeleteAllUserIds}>회원ID 일괄삭제</Button.Negative>
            </div>
          </Field.Base>
        )}
      </div>
      <div className="classAdd__buttons">
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

function LectureField(props) {
  const { value: selectedLecture, onChange, disabled, ...otherProps } = props;

  const [searchOption, setSearchOption] = useState('title');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([]);
  const onClickSearch = useCallback(() => {
    setFilters(searchText ? [{ id: searchOption, value: searchText }] : []);
  }, [searchText, searchOption]);

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .lectures('given', {
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(filters),
        })
        .then(({ classes }) => {
          setLectures(classes.rows);
          setTotal(classes.count);
        });
    },
    [filters],
  );

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: (lecture) => {
          const checked = selectedLecture?.id === lecture.id;
          return (
            <input
              type="checkbox"
              checked={checked}
              disabled={disabled}
              onChange={() => {
                onChange(lecture);
              }}
            />
          );
        },
      },
      { Header: '강의ID', accessor: 'id' },
      {
        Header: '강의구분',
        accessor: (lecture) => (lecture.class_type === 'package' ? '패키지' : '단일'),
      },
      {
        Header: '수강구분',
        accessor: (lecture) => (lecture.listen_type === 'online' ? '온라인' : '외국인'),
      },
      { Header: '강의명', accessor: 'title' },
      {
        Header: '수강시작일',
        accessor: 'ClassOnlineSchedule.start_date',
        Cell: ({ value: start_date }) => (start_date ? start_date : '-'),
      },
      {
        Header: '수강일수',
        accessor: 'ClassOnlineSchedule.valid_days',
        Cell: ({ value: valid_days }) => (valid_days ? valid_days + '일' : '-'),
      },
    ],
    [selectedLecture, disabled, onChange],
  );

  return (
    <Field.Base type="lectures" {...otherProps}>
      <div className="field--lectures__search">
        <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
          <option value="title">강의명</option>
          <option value="id">강의 ID</option>
        </select>
        <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
      </div>
      <Table pageSize={10} total={total} data={lectures} columns={columns} fetchData={fetchData} />
      {selectedLecture && (
        <div className="field--lectures__selected">콘텐츠 임의지급 강의 ID: {selectedLecture.id}</div>
      )}
    </Field.Base>
  );
}

export default Given;
