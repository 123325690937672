import { useParams } from 'react-router-dom';
import * as request from '../../../Common/Util/Request';
import React, { useCallback, useEffect, useState } from 'react';
import { Star } from '@mui/icons-material';
import {
  Container,
  Description,
  Download,
  Layout,
  Logo,
  QuestionBody,
  QuestionContainer,
  QuestionDescription,
  QuestionHeader,
  QuestionNumber,
  QuestionTitle,
  QuestionTitleContainer,
  SectionBody,
  SectionContainer,
  SectionHeader,
  SectionItem,
  SectionNumber,
  Sections,
  SectionTitle,
  Title,
} from './styles';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as excel from '../../../Common/Util/Excel';
import dayjs from 'dayjs';

const OptionList = styled.ul.attrs({ className: 'option-list' })`
  padding-left: 3.5rem;
`;
const OptionWrapper = styled.li.attrs({ className: 'option-wrapper' })`
  margin-bottom: 1rem;
`;

const OptionGraph = styled.div.attrs({ className: 'option-graph' })`
  width: 100%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 0;
  padding: 0.5rem 0.5rem 0.5rem ${(props) => props.percentage}%;
  border-radius: 0.5rem;
  height: 1rem;
`;

const OptionGraphBar = styled.div.attrs({ className: 'option-graph-bar' })`
  width: ${(props) => props.percentage}%;
  height: 100%;
  background-color: #f46684;
  border-radius: 0.5rem;
  z-index: 1;
  position: absolute;
`;

const SurveyStatistics = () => {
  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState([]);
  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const { success, data } = await request.getSurveyStatistics(id);
        if (success) {
          return data;
        } else {
          throw new Error('설문조사 통계를 불러오는데 실패했습니다.');
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchSurvey().then(({ survey, responses, totalResponseCount }) => {
      setSurvey(survey);
      setResponses(responses);
    });
  }, [id]);

  const QuestionNumberElement = ({ number, isRequired }) => {
    return (
      <QuestionNumber>
        <Star sx={{ color: '#F24462', fontSize: '0.625rem', opacity: isRequired ? '1' : '0' }} />
        <span>{number}</span>
      </QuestionNumber>
    );
  };

  const getXLSX = useCallback(async () => {
    // console.log('getXLSX');
    try {
      // console.log(responses);
      // 각 행은 응답자의 정보를 담고 있음
      // 각 열은 질문에 대한 응답을 담고 있음
      // 각 셀은 응답자가 해당 질문에 대해 선택한 답변을 담고 있음
      // 각 셀의 값은 질문의 타입에 따라 달라짐
      // 질문의 타입이 shortText, longText인 경우 셀의 값은 응답자가 입력한 텍스트를 담고 있음

      // survey에서 questionId를 중복값 없이 담는다.
      const questionIds = [];
      const questions = [];
      survey.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (!questionIds.includes(question.id)) {
            questionIds.push(question.id);
            questions.push({
              id: question.id,
              content: question.content.replace(/\./g, ''), // 엑셀에서 셀의 이름으로 사용되는 문자열에 .이 포함되면 오류가 발생하므로 .을 제거한다.
              questionType: question.questionType,
              description: question.description,
            });
          }
        });
      });
      // responses 데이터를 responseId를 기준으로 병합한다.
      const mergedResponses = [];
      responses.forEach((response) => {
        const index = mergedResponses.findIndex((mergedResponse) => mergedResponse.responseId === response.responseId);
        if (index === -1) {
          const row = {
            userId: response.userId,
            responseId: response.responseId,
            submitionDate: dayjs(response.submitionDate).subtract(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
          };
          questions.forEach((question) => {
            row[question.id] = '';
          });
          row[response.questionId] = response.answerText || '';
          mergedResponses.push(row);
        } else {
          mergedResponses[index][response.questionId] = response.answerText || '';
        }
      });

      // mergedResponses에서 키값으로 사용되는 questionId를 question.content로 변환한다.
      function transformJsonArray(standard, data) {
        const transformedArray = [];

        for (let i = 0; i < data.length; i++) {
          const dataObject = data[i];
          const transformedObject = {};

          for (let j = 0; j < standard.length; j++) {
            const standardObject = standard[j];
            const id = standardObject.id;

            transformedObject[standardObject.content.replace(/\./g, '')] = dataObject[id];
            delete dataObject[id];
          }

          // data의 나머지 키-값 쌍을 그대로 유지하기 위해 추가
          for (const key in dataObject) {
            if (!transformedObject.hasOwnProperty(key)) {
              transformedObject[key] = dataObject[key];
            }
          }

          transformedArray.push(transformedObject);
        }

        return transformedArray;
      }

      const transformedResult = transformJsonArray(questions, mergedResponses);
      // mergedResponses를 xlsx로 변환한다.
      excel.downloadXLSX(
        transformedResult.map(({ responseId, submitionDate, userId, ...rest }) => ({
          응답시간: submitionDate,
          '유저 고유아이디': userId,
          ...rest,
        })),
        survey.title,
      );
    } catch (error) {
      // console.log(error);
    }
  }, [responses, survey]);
  return (
    survey && (
      <>
        <Layout>
          <Container>
            <Download onClick={getXLSX}>다운로드</Download>
            <Logo src={survey.logo} alt={survey.title} />
            <Title>{survey.title}</Title>
            <Description>{survey.description}</Description>
            <SectionContainer>
              <Sections>
                {survey.sections.map((section, index) => {
                  return (
                    <SectionItem key={`section${index}-${section.title}`} id={`section${index}`} sectionIndex={index}>
                      <SectionHeader>
                        <SectionNumber>{section.sort_order || index + 1}</SectionNumber>
                        <SectionTitle>{section.title}</SectionTitle>
                      </SectionHeader>
                      <SectionBody>
                        {section.questions.map((question, qIndex) => {
                          return (
                            <QuestionContainer key={`question${question.id}-${question.content}`}>
                              <QuestionHeader>
                                <QuestionTitleContainer>
                                  <QuestionNumberElement number={question.sort_order || qIndex + 1} />
                                  <QuestionTitle>{question.content}</QuestionTitle>
                                </QuestionTitleContainer>
                                {question.description && (
                                  <QuestionDescription>{question.description}</QuestionDescription>
                                )}
                              </QuestionHeader>
                              <QuestionBody>
                                <OptionList>
                                  {question.questionType === 'shortText' || question.questionType === 'longText'
                                    ? question.options.map((option, oIndex) => {
                                        return option.answerText?.map((text, index) => {
                                          return (
                                            <li
                                              key={`${question.questionType}-${index}`}
                                              style={{
                                                whiteSpace: 'pre-line',
                                                marginBottom: '0.25rem',
                                              }}
                                            >
                                              {text}
                                            </li>
                                          );
                                        });
                                      })
                                    : question.options.map((option, oIndex) => {
                                        return (
                                          <OptionWrapper key={`option${option.id}-${option.content}`}>
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                                marginBottom: '0.25rem',
                                              }}
                                            >
                                              <span>{option.content}</span>
                                              <div>
                                                {option.count}&nbsp;&nbsp;&nbsp;({option.percentage}%)
                                              </div>
                                            </div>
                                            <OptionGraph>
                                              <OptionGraphBar percentage={option.percentage} />
                                            </OptionGraph>
                                            {typeof option.answerText === 'object' && option.answerText.length > 0 && (
                                              <ul
                                                style={{
                                                  marginTop: '0.5rem',
                                                  paddingLeft: '1.5rem',
                                                }}
                                              >
                                                {option.answerText?.map((text, index) => {
                                                  return (
                                                    <li
                                                      key={`${question.questionType}-${question.id}-${index}`}
                                                      style={{
                                                        whiteSpace: 'pre-line',
                                                        marginBottom: '0.25rem',
                                                      }}
                                                    >
                                                      {text}
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            )}
                                          </OptionWrapper>
                                        );
                                      })}
                                </OptionList>
                              </QuestionBody>
                              <hr
                                style={{
                                  borderTop: 'none',
                                  borderBottom: '1px solid #E9ECEF',
                                  marginTop: '2.125rem',
                                  width: '100%',
                                }}
                              />
                            </QuestionContainer>
                          );
                        })}
                      </SectionBody>
                    </SectionItem>
                  );
                })}
              </Sections>
            </SectionContainer>
          </Container>
        </Layout>
      </>
    )
  );
};

const Survey = ({ surveyId: passedSurveyId, classId, setIsCompleted }) => {
  const [survey, setSurvey] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
};

export default SurveyStatistics;
