import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import * as Button from '../../../../Common/Component/Button';
import * as Model from '../../../../Common/Model';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import moment from 'moment';
import * as excel from '../../../../Common/Util/Excel';
import ManageWaitings from '../ManageWaitings';
import withReactContent from 'sweetalert2-react-content';
import { LECTURE } from '../../../../Common/Constant';
import { Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

function SingleList(props) {
  const intl = useIntl();
  const SwalWithReactContent = withReactContent(Swal);

  const [locationState, setLocationState] = useLocationState({
    searchType: 'title',
    searchText: '',
    isShowSelling: null,
    filters: [],
  });
  const { isShowSelling } = locationState;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [downloadingId, setDownloadingId] = useState(null);
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
      isShowSelling,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText, isShowSelling]);

  const [lectures, setLectures] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        page: pageIndex + 1,
        limit: pageSize,
        filtered: JSON.stringify(
          isShowSelling
            ? [...locationState.filters, { id: 'is_selling', value: isShowSelling }]
            : locationState.filters,
        ),
      };
      request.lectures('single', params).then(({ classes }) => {
        setLectures(classes.rows.map((row) => new Model.Lecture(row)));
        setTotal(classes.count);
      });
    },
    [locationState.filters, isShowSelling],
  );

  const showStudentInfo = (lecture) => {
    const {
      title,
      userClassCount,
      userClassReadyCount,
      listen_type,
      ClassOfflineSchedules,
      ClassConsultingSchedules,
      ClassOnlineSchedule,
      ClassOnlineScheduleByRounds,
    } = lecture;
    if (LECTURE.ROUND_LISTEN_TYPES.includes(listen_type)) {
      let schedules = '';
      if (listen_type === 'offline') {
        ClassOfflineSchedules.map((lecture) => {
          const {
            class_round,
            title,
            start_date,
            end_date,
            class_start_time,
            student_limit,
            student_count,
            student_ready_count,
            class_dates,
          } = lecture;
          schedules += `<li>
        ${class_round}기, 
        ${title ? `${title}` : `옵션명 없음`}, 
        ${class_dates
          .split('')
          .map((weekday, index) => {
            return +weekday !== 0 ? intl.formatMessage({ id: 'ID_WEEKDAY_' + (index + 1) }) : '';
          })
          .join('')}, 
        ${class_start_time}, 
        ${start_date}~${end_date} : 
        ${`${(student_count || 0) + (student_ready_count || 0)}명`} / ${
            student_limit === 0 ? '제한없음' : `${student_limit}명`
          }
        </li>`;
          return lecture;
        });
      } else if (listen_type === 'consulting') {
        ClassConsultingSchedules.forEach((lecture) => {
          const {
            class_round,
            title,
            start_date,
            end_date,
            class_start_time,
            student_limit,
            student_count,
            student_ready_count,
            class_dates,
          } = lecture;
          schedules += `<li>
        ${class_round}기, 
        ${title ? `${title}` : `옵션명 없음`}, 
        ${class_dates
          .split('')
          .map((weekday, index) => {
            return +weekday !== 0 ? intl.formatMessage({ id: 'ID_WEEKDAY_' + (index + 1) }) : '';
          })
          .join('')}, 
        ${class_start_time}, 
        ${start_date}~${end_date} : 
        ${`${(student_count || 0) + (student_ready_count || 0)}명`} / ${
            student_limit === 0 ? '제한없음' : `${student_limit}명`
          }
        </li>`;
        });
      } else {
        ClassOnlineScheduleByRounds.forEach((lecture) => {
          const {
            start_date,
            end_date,
            class_start_time,
            student_limit,
            student_count,
            student_ready_count,
            class_round,
            title,
          } = lecture;
          schedules += `<li>
        ${class_round ? `${class_round}기` : '기수 없음'},
        ${title ? `${title}` : `옵션명 없음`}, 
        ${class_start_time ? class_start_time : '시작 시간 없음'}, 
        ${start_date ? start_date : '시작일 없음'}~${end_date ? end_date : '종료일 없음'} : 
        ${(student_count || 0) + (student_ready_count || 0)}명 / ${
            student_limit === 0 ? '제한없음' : `${student_limit}명`
          }
        </li>`;
        });
      }
      Swal.fire({
        title,
        html: `
        <ol>
          <li>총 수강자
            <ul>
              <li>
                ${(userClassCount || 0) + (userClassReadyCount || 0)}명
                ${userClassReadyCount ? `(결제대기 : ${userClassReadyCount}명)` : ''}
              </li>
            </ul>
          </li>
          <li>수강 옵션별 수강자
            <ul>
              ${schedules}
            </ul>
          </li>
        </ol>
        `,
        showCloseButton: true,
        showConfirmButton: false,
      });
    } else {
      const { student_limit } = ClassOnlineSchedule || { student_limit: 0 };
      return Swal.fire({
        title,
        html: `
        <ol>
          <li>총 수강자
            <ul>
              <li>
                ${(userClassCount || 0) + (userClassReadyCount || 0)}명
                ${+student_limit !== 0 ? ` / ${student_limit}명` : ``}
                ${userClassReadyCount ? `(결제대기 : ${userClassReadyCount}명)` : ''}
              </li>
            </ul>
          </li>
        </ol>
        `,
        showCloseButton: true,
        showConfirmButton: false,
      });
    }
  };

  const showManageWaitings = (lecture) => {
    SwalWithReactContent.fire({
      title: `[기수 오픈 안내] - ${lecture.title}`,
      html: <ManageWaitings data={lecture} />,
      showCloseButton: true,
      allowOutsideClick: false,
      showConfirmButton: false,
      width: '1200px',
    });
  };

  const loadUsers = useCallback(
    ({ page, limit, lectureId }) => {
      const params = {
        page,
        limit,
        all_people: true,
        filtered: JSON.stringify([{ id: 'lectureId', value: lectureId }]),
      };
      if (startDate) {
        params.start_date = moment(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = moment(endDate).format('YYYY-MM-DD');
      }

      return request.lectureUsers(params).then(({ userClasses }) => userClasses);
    },
    [startDate, endDate],
  );

  const onClickCSV = useCallback(
    (lecture) => {
      if (!!lecture.userClassCount || !!lecture.userClassReadyCount) {
        setDownloadingId(lecture.id);
        loadUsers({ page: 1, limit: 9999999, lectureId: lecture.id }) // temp
          .then((users) => {
            if (!!users.count) {
              const data = users.rows.map((user) => {
                const classModel = user.FK_class;
                const sectionProgresses = {};
                let number = 1;
                switch (classModel.class_type) {
                  case 'single':
                    for (let chapter of classModel.ClassChapters) {
                      for (let section of chapter.ClassSections) {
                        sectionProgresses[number + '차시'] = section.section_completion_rate
                          ? section.section_completion_rate + '%'
                          : '-';
                        number++;
                      }
                    }
                    break;
                  case 'package':
                    const classSinglePackages = classModel.ClassSingle_ClassPackages;
                    for (const classSinglePackage of classSinglePackages) {
                      const classModelInPackage = classSinglePackage.FK_class_single;

                      for (const chapter of classModelInPackage.ClassChapters) {
                        const sections = chapter.ClassSections;
                        for (const section of sections) {
                          sectionProgresses[`${number}차시`] = section.section_completion_rate
                            ? section.section_completion_rate + '%'
                            : '-';
                          number++;
                        }
                      }
                    }
                    break;

                  default:
                    break;
                }
                const txStatus = {
                  ready: '결제대기',
                  paid: '결제',
                  partial_cancelled: '부분환불',
                  cancelled: '환불',
                };

                const getScheduleInfo = (user) => {
                  return user.FK_class_offline_schedule_id
                    ? `${
                        user.FK_class_offline_schedule.class_round && `${user.FK_class_offline_schedule.class_round}기`
                      } | ${
                        !!user.FK_class_offline_schedule.title
                          ? `${user.FK_class_offline_schedule.title} |`
                          : '옵션명 없음 | '
                      } ${user.FK_class_offline_schedule.class_dates
                        .split('')
                        .map((classDate, index) => {
                          return +classDate !== 0
                            ? intl.formatMessage({
                                id: 'ID_WEEKDAY_' + (index + 1),
                              })
                            : '';
                        })
                        .join('')} | ${user.FK_class_offline_schedule.class_start_time} | ${
                        user.FK_class_offline_schedule.start_date
                      }~${user.FK_class_offline_schedule.end_date}
              `
                    : user.FK_class_online_schedule_id
                    ? `${user.FK_class_online_schedule.class_round}기 | ${
                        !!user.FK_class_online_schedule.title
                          ? `${user.FK_class_online_schedule.title} |`
                          : '옵션명 없음 | '
                      } ${user.FK_class_online_schedule.start_date}~${user.FK_class_online_schedule.end_date}${
                        user.FK_class_online_schedule.class_start_time
                          ? ` | ${user.FK_class_online_schedule.class_start_time}~${user.FK_class_online_schedule.class_end_time}`
                          : ` | 강의 시간 없음`
                      }`
                    : user.FK_class_consulting_schedule_id
                    ? `${
                        user.FK_class_consulting_schedule.class_round &&
                        `${user.FK_class_consulting_schedule.class_round}기`
                      } | ${
                        !!user.FK_class_consulting_schedule.title
                          ? `${user.FK_class_consulting_schedule.title} |`
                          : '옵션명 없음 | '
                      } ${user.FK_class_consulting_schedule.class_dates
                        .split('')
                        .map((classDate, index) => {
                          return +classDate !== 0
                            ? intl.formatMessage({
                                id: 'ID_WEEKDAY_' + (index + 1),
                              })
                            : '';
                        })
                        .join('')} | ${user.FK_class_consulting_schedule.class_start_time} | ${
                        user.FK_class_consulting_schedule.start_date
                      }~${user.FK_class_consulting_schedule.end_date}
              `
                    : ``;
                };

                return {
                  회원ID: user.FK_user.id,
                  닉네임: user.FK_user.nickname,
                  이름: user.FK_user.name,
                  국가정보: user.FK_user.FK_country?.name_kor || '-',
                  이메일: user.FK_user.email,
                  핸드폰번호: user.FK_user.phone ? user.FK_user.phone.toPhone() : '-',
                  '혜택 수신 동의': user.FK_user.is_marketing_on ? '동의' : '비동의',
                  강의ID: user.FK_class.id,
                  강의명: user.FK_class.title,
                  수강옵션: getScheduleInfo(user),
                  강사명: user.FK_class.Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(','),
                  구분: intl.formatMessage({
                    id: 'ID_LISTEN_TYPE_' + user.FK_class.listen_type.toUpperCase(),
                  }),
                  결제상태: txStatus[user.FK_tx.status],
                  결제일:
                    user.FK_tx.deletedAt || !user.FK_tx.date_paid_at
                      ? '-'
                      : moment.utc(user.FK_tx.date_paid_at).format('YYYY-MM-DD'),
                  콘텐츠지급일: user.FK_tx.deletedAt ? moment.utc(user.FK_tx.createdAt).format('YYYY-MM-DD') : '-',
                  수강시작일: user.start_date ? moment.utc(user.start_date).format('YYYY-MM-DD') : '-',
                  수강종료일: user.end_date ? moment.utc(user.end_date).format('YYYY-MM-DD') : '-',
                  남은일수: user.count_left_day ? user.count_left_day + '일' : '-',
                  정가: user.FK_class.price_original,
                  결제금액: user.FK_tx.paid_amount - user.FK_tx.canceled_amount,
                  '강의자료 다운로드': user.is_download_attached_file ? '다운로드 후' : '다운로드 전',
                  진도율: user.completion_rate ? user.completion_rate + '%' : '-',
                  ...sectionProgresses,
                };
              });
              excel.downloadXLSX(data, `수강회원관리(${lecture.title})_${moment.utc().format('YYYYMMDD')}`);
              setDownloadingId(false);
            } else {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">설정한 기간 내에 결제한 수강자가 없습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'error',
                type: 'error',
              });
              setDownloadingId(false);
            }
          });
      } else {
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">해당 강의의 수강자가 없습니다.</div>`,
          toast: true,
          position: 'center',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: true,
          icon: 'error',
          type: 'error',
        });
      }
    },
    [intl, loadUsers],
  );

  return (
    <div className="single-list">
      <section>
        <div className="single-list__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="title">강의명</option>
            <option value="name">강사명</option>
          </select>
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
        </div>
        <div className="making-btn">
          <Button.Positive onClick={() => window.open(`/lecture/single/new`)}>신규 강의 생성</Button.Positive>
        </div>
      </section>
      <div className="single-list-top-container">
        <div className="single-list__total">총 강의수 {total}</div>
        <div className="single-list__options">
          <div className="single-user-list__selling">
            *판매 가능 여부 : &nbsp;{' '}
            <Checkbox
              checked={isShowSelling}
              onChange={(e) => {
                if (!!e.target.checked) {
                  setLocationState({ ...locationState, isShowSelling: true });
                } else {
                  setLocationState({ ...locationState, isShowSelling: null });
                }
              }}
            />
          </div>
          <div className="single-user-list__date">
            *수강자 다운 기간 : &nbsp;
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                selected={startDate}
                maxDate={dayjs(endDate)}
                onChange={(date) => setStartDate(dayjs(date).format('YYYY-MM-DD'))}
              />
              ~
              <DatePicker
                selected={endDate}
                minDate={dayjs(startDate)}
                onChange={(date) => setEndDate(dayjs(date).format('YYYY-MM-DD'))}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <Table
        data={lectures}
        total={total}
        pageSize={20}
        columns={[
          { Header: '강의ID', accessor: 'id' },
          { Header: '강의명', accessor: 'title' },
          {
            Header: '강사명',
            accessor: 'Class_Tutors',
            Cell: ({ value: Class_Tutors }) =>
              Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(', ') || '-',
          },
          {
            Header: '판매가능',
            accessor: 'is_selling',
            Cell: ({ value: is_selling }) => (is_selling ? '판매' : '불가'),
          },
          {
            Header: '커리큘럼',
            accessor: 'ClassChapters',
            Cell: ({ value: ClassChapters }) => {
              const allChaptersLength =
                ClassChapters.map((ClassChapter) => ClassChapter.ClassSections.length).reduce(
                  (acc, cur) => acc + cur,
                  0,
                ) || '-';

              const needUpdateChaptersLength =
                ClassChapters.map(
                  (ClassChapter) =>
                    ClassChapter.ClassSections.map((ClassSection) => (!ClassSection.FK_video_id ? 1 : 0)).reduce(
                      (acc, cur) => acc + cur,
                      0,
                    ) || 0,
                ).reduce((acc, cur) => acc + cur, 0) || 0;
              return (
                <>
                  {allChaptersLength}
                  {needUpdateChaptersLength ? (
                    <span
                      style={{
                        color: 'gray',
                        fontSize: '0.9rem',
                        marginLeft: 5,
                      }}
                    >
                      ({needUpdateChaptersLength})
                    </span>
                  ) : (
                    ''
                  )}
                </>
              );
            },
          },
          {
            Header: '구분',
            accessor: 'listen_type',
            Cell: ({ value: listen_type }) => <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />,
          },
          {
            Header: '수강자',
            accessor: (row) => (
              <span className="user-class-count" onClick={() => showStudentInfo(row)}>
                {(row.userClassCount || 0) + (row.userClassReadyCount || 0)}명
              </span>
            ),
          },
          {
            Header: '대기 명단',
            accessor: (row) => {
              if (!LECTURE.ROUND_LISTEN_TYPES.includes(row.listen_type)) {
                return '-';
              }
              return (
                <span className="waiting-count" onClick={() => showManageWaitings(row)}>
                  {row.waitingUsers?.length || 0}명
                </span>
              );
            },
          },
          {
            Header: '수강자 다운',
            accessor: (row) => (
              <button className="btn-detail btn-download" onClick={() => !downloadingId && onClickCSV(row)}>
                {row.id === downloadingId ? 'loading...' : '액셀 다운'}
              </button>
            ),
          },
          {
            Header: '평점',
            accessor: (row) => <span className="user-class-rete">{row.rate || 0}</span>,
          },
          {
            Header: '정보수정',
            accessor: (row) => (
              <button className="btn-detail" onClick={() => window.open(`/lecture/single/new/${row.id}`)}>
                수정
              </button>
            ),
          },
        ]}
        fetchData={fetchData}
      />
    </div>
  );
}

export default SingleList;
