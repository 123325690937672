import { useLocation, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as request from '../../../../Common/Util/Request';
import Swal from 'sweetalert2';
import * as Field from '../../../../Common/Component/Field';
import './index.scss';
import * as Button from '../../../../Common/Component/Button';
import { useIntl } from 'react-intl';

function InterestedCompanyNew(factory, deps) {
  const intl = useIntl();
  const { id } = useParams();

  const [name, setName] = useState(null);
  const [content, setContent] = useState(null);
  const [isShow, setIsShow] = useState(true);

  const [companyOrigin, setCompanyOrigin] = useState(null);
  const editing = useMemo(() => !!companyOrigin?.id, [companyOrigin]);

  useEffect(() => {
    id &&
      request.getInterestedCompanyDetail(id).then(({ interestedCompany }) => {
        setCompanyOrigin(interestedCompany);
      });
  }, []);

  useEffect(() => {
    if (editing) {
      setName(companyOrigin.name);
      setIsShow(companyOrigin.is_show);
    }
  }, [companyOrigin, editing]);

  const onClickSave = useCallback(() => {
    const company = {
      name,
      is_show: isShow,
    };

    const requiredFields = ['name'];
    const emptyFields = requiredFields.filter((field) => !company[field]);
    if (content === '<p><br></p>') {
      emptyFields.push('content');
    }
    if (emptyFields.length > 0) {
      window.alert(
        `${emptyFields
          .map((field) => intl.formatMessage({ id: 'ID_INTERESTED_COMPANY_FIELD_' + field.toUpperCase() }))
          .join(', ')} 값을 입력해주세요.`,
      );
      return;
    }

    if (editing) {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">수정 사항을 반영하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          request.updateInterestedCompany(companyOrigin.id, company).then((res) => {
            window.opener?.location.reload();

            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">수정 사항이 반영되었습니다.</div>`,
              position: 'center',
              icon: 'success',
            }).then(() => {
              window.exitFlag = true;
              window.self.close();
            });
          });
        }
      });
    } else {
      Swal.fire({
        html: `<div style="margin-top:20px; font-weight: bold;">관심 기업을 생성하시겠습니까?</div>`,
        position: 'center',
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'info',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          request.createInterestedCompany(company).then(() => {
            window.opener.location.reload();

            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">관심 기업이 생성되었습니다.</div>`,
              position: 'center',
              icon: 'success',
            }).then(() => {
              window.exitFlag = true;
              window.self.close();
            });
          });
        }
      });
    }
  }, [name, isShow]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">관심 기업을 저장하지 않고 나가시겠습니까?</div>`,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  });

  const onClickDelete = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteInterestedCompany(companyOrigin.id)
          .then(({ success }) => {
            if (success) {
              window.opener.location.reload();
              window.exitFlag = true;
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">관심 기업이 삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            }
          })
          .catch((err) => {
            console.error(err);
            Swal.fire({
              title: `관심 기업 삭제에 실패했습니다.`,
              text: err.message,
              position: 'center',
              showConfirmButton: true,
              icon: 'error',
            });
          }),
    });
  });

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  return (
    <div className="post">
      <h2>관심 기업 {editing ? '수정' : '생성'}</h2>
      <Field.Input title="이름" value={name} onChange={setName} required />
      <Field.Radio
        title="노출 여부"
        name="isShow"
        value={isShow}
        onChange={setIsShow}
        options={[
          { value: true, label: '노출', order: 1 },
          { value: false, label: '미노출', order: 2 },
        ]}
        required
      />

      <div className="posts__buttons">
        <Button.Negative onClick={onClickDelete} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave}>저장</Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default InterestedCompanyNew;
