import styled from 'styled-components';

const lightenColor = (color, percent) => {
  const hex = color.replace('#', ''); // strip the leading # if it's there
  const num = parseInt(hex, 16); // convert the hex string to a number
  const amt = Math.round(2.55 * percent); // make the percent less than 1 and change to amount
  const R = (num >> 16) + amt > 255 ? 255 : (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt > 255 ? 255 : ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt > 255 ? 255 : (num & 0x0000ff) + amt;

  return `#${(0x1000000 + (R << 16) + (B << 8) + G).toString(16).slice(1)}`;
};

const fetchButtonBackgroundColor = '#f24462';
const defaultButtonBackgroundColor = '#EFEFEF';
export const ReviewRequest = styled.div.attrs({ className: 'ReviewRequest' })`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
    min-height: 10rem;
    max-height: 30rem;
    font-size: 0.875rem;
    color: #999999;

  }

  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #555555;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: ${defaultButtonBackgroundColor};

    &:hover {
      &:not(:disabled) {
        background-color: ${lightenColor(defaultButtonBackgroundColor, -10)};
        font-weight: 700;
      }
    }

    &:disabled {
      color: #999999;
      cursor: not-allowed;
    }
  }

  .MuiList-root {
    font-size: 0.875rem;
  }

  .description {
    border: 1px solid #e9ecef;
    border-radius: 0.375rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: rgb(241, 241, 239);
    font-size: 0.875rem;
    width: 100%;
    box-sizing: border-box;

    .description-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      button {
        font-size: 0.75rem;
      }
    }

    .description-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow: hidden;
      transition: all 0.2s ease-in-out;

      p {
        margin: 0;
        line-height: 1.5;
      }

      ul {
        margin: 0;
        padding-left: 0.5rem;
        list-style: none;
        font-size: 0.75rem;
        color: #333333;

        li {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;
        }
      }
    }
  }

  .need-request {
    &-header {
      display: flex;
      justify-content: space-between;

      .MuiSelect-select {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.75rem;
        cursor: pointer;
      }

      .date-picker {
        height: 2rem;
        width: 120px;

        label, input {
          font-size: 0.75rem;
          line-height: 0.875rem;
        }

        input {
          padding: 0.25rem 0.5rem;
          height: 1.5rem;

        }

        label {
          matrix: matrix(0.75, 0, 0, 0.75, 14, -8)
        }

        button {
          padding: 0;
          cursor: pointer;
          background-color: transparent;
        }


      }

      .filter-message-count,
      .filter-wrote-review,
      .filter-allowed-marketing {
        input {
          padding: 0.25rem 0.5rem;
          border: 1px solid #e9ecef;
          border-radius: 0.375rem;
          min-height: 1.25rem;

          &:focus {
            outline: none;
            border-color: #c6cacd;
          }
        }
      }

      .filters,
      .actions {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
      }

      .filters {
        font-size: 0.875rem;
        color: #777777;
        display: flex;
        align-items: center;
        column-gap: 1rem;

        .filter {
          &-message-count, &-wrote-review, &-allowed-marketing, &-date-wrap {
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
          }
        }
      }

      .search-wrap {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
      }
    }

    &-content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
      width: 100%;

      .need-request-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        box-sizing: border-box;
        width: 100%;

        .table-wrap {
          width: 100%;
          max-height: 500px;
          overflow: auto;
          box-sizing: border-box;
          border-radius: 0.5rem;
          border: 1px solid #e9ecef;

          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 0.875rem;
            text-align: center;
            margin: 0;

            th,
            td {
              border: 1px solid #e9ecef;
              padding: 0.5rem;
              text-align: center;

              &:first-child {
                border-left: none;
              }

              &:last-child {
                border-right: none;
              }
            }

            thead {
              background-color: #f1f1ef;
              position: sticky;
              top: -1px;
              white-space: nowrap;
              z-index: 1;
            }

            th {
              div {
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                width: 100%;
                column-gap: 0.375rem;
              }
            }

            tr {
              &.wrote-review {
                cursor: default;
              }
            }
          }
        }

        .need-request-actions {
          width: 100%;
          display: flex;
          justify-content: center;
          column-gap: 0.5rem;

          button {
            &:not(:disabled) {
              color: #fff;
              background-color: ${fetchButtonBackgroundColor};

              &:hover {
                background-color: ${lightenColor(fetchButtonBackgroundColor, 10)};
              }
            }
          }
        }
      }
    }

  }
}
`;

export const Loading = styled.div.attrs({ className: 'Loading' })`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  justify-content: center;
  align-items: center;
`;

export const StyledSortIcon = styled.span`
  width: 1rem;
  height: 1rem;
  display: inline-block;
  opacity: ${({ active }) => (active ? 1 : 0.5)};

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const TrueIconWrap = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const FalseIconWrap = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 auto;

  svg {
    width: 100%;
    height: 100%;
  }
`;
