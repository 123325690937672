import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as request from '../../../../Common/Util/Request';
import * as Field from '../../../../Common/Component/Field';
import * as Button from '../../../../Common/Component/Button';
import './index.scss';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function Tutor(props) {
  const intl = useIntl();
  const { id } = useParams();
  const [tutorOrigin, setTutorOrigin] = useState(null);
  const editing = useMemo(() => !!tutorOrigin, [tutorOrigin]);
  useEffect(() => {
    id &&
      request.tutorDetail(id).then((data) => {
        // console.log(data);
        setTutorOrigin(data);
      });
  }, [id]);

  const [name, setName] = useState('');
  const [initial, setInitial] = useState('');
  const [phone, setPhone] = useState('');
  const [checkPhone, setCheckPhone] = useState('');
  const [email, setEmail] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [image, setImage] = useState('');
  const [settlementRate, setSettlementRate] = useState('');
  const [contractFile, setContractFile] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isCompanyExpose, setIsCompanyExpose] = useState(false);
  const [openChatCode, setOpenChatCode] = useState(null);

  useEffect(() => {
    if (tutorOrigin) {
      setName(tutorOrigin.name || '');
      setInitial(tutorOrigin.name_cap || '');
      setPhone(tutorOrigin.phone || '');
      setEmail(tutorOrigin.email || '');
      setImage(tutorOrigin.image_tutor || 'https://assets.enterjobedu.co.kr/default-profile.jpg');
      setSettlementRate(tutorOrigin.settlement_rate || '');
      setContractFile(tutorOrigin.url_contract_file || '');
      setCompanyName(tutorOrigin.company_name || '');
      setIsCompanyExpose(tutorOrigin.is_company_expose);
      setOpenChatCode(tutorOrigin.kakao_open_chat_code);
    }
  }, [tutorOrigin]);

  const exitHandler = (event) => {
    if (!event.currentTarget.exitFlag) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  window.addEventListener('beforeunload', exitHandler);

  const onClickRemove = useCallback(() => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      text: '삭제한 데이터는 복구할 수 없습니다.',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteTutors([tutorOrigin.id])
          .then(({ success }) => {
            if (success) {
              window.exitFlag = true;
              window.opener.location.reload();
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: true,
                icon: 'success',
                type: 'success',
              }).then(() => window.self.close());
            } else {
              throw new Error('삭제에 실패하였습니다.');
            }
          })
          .catch(() => {
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
              toast: true,
              position: 'center',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'error',
              type: 'error',
            });
          }),
    });
  }, [tutorOrigin]);

  const onClickSave = useCallback(() => {
    const tutor = {
      name: name,
      name_cap: initial,
    };
    if (phone) tutor.phone = phone;
    if (email) tutor.email = email;
    if (image) tutor.file = image;
    if (settlementRate) tutor.settlement_rate = settlementRate;
    if (contractFile) tutor.contract_file = contractFile;
    if (companyName) tutor.company_name = companyName;
    if (isCompanyExpose) tutor.is_company_expose = isCompanyExpose;
    if (openChatCode) tutor.kakao_open_chat_code = openChatCode;

    const requiredFields = ['name', 'name_cap'];
    const emptyFields = requiredFields.filter((field) => !tutor[field]);
    if (emptyFields.length > 0) {
      Swal.fire({
        html: `${emptyFields
          .map((field) => intl.formatMessage({ id: 'ID_TUTOR_FIELD_' + field.toUpperCase() }))
          .join(', ')} 값을 입력해주세요.`,
        toast: true,
        position: 'center',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: true,
        icon: 'error',
      });
      return;
    }

    if (editing) {
      request
        .updateTutor(tutorOrigin.id, tutor)
        .then(() => {
          window.opener.location.reload();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 수정되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        })
        .catch(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">수정에 실패했습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            type: 'error',
          });
        });
    } else {
      request
        .createTutor(tutor)
        .then(() => {
          window.opener.location.reload();
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 생성되었습니다.</div>`,
            position: 'center',
            icon: 'success',
          }).then(() => {
            window.exitFlag = true;
            window.self.close();
          });
        })
        .catch(() => {
          Swal.fire({
            html: `<div style="margin-top:20px; font-weight: bold;">생성에 실패했습니다.</div>`,
            toast: true,
            position: 'center',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            type: 'error',
          });
        });
    }
  }, [
    intl,
    tutorOrigin,
    editing,
    name,
    companyName,
    isCompanyExpose,
    initial,
    email,
    phone,
    image,
    settlementRate,
    contractFile,
    openChatCode,
  ]);

  const onClickCancel = useCallback(() => {
    Swal.fire({
      html: `<div style="margin-top:20px; font-weight: bold;">저장하지 않고 나가시겠습니까?</div>`,
      toast: true,
      position: 'center',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'warning',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.exitFlag = true;
        window.close();
      }
    });
  }, []);

  const isInvalidPhone = phone && !checkPhone;
  const isInvalidEmail = email && !checkEmail;

  const isSubmitDisabled = isInvalidPhone || isInvalidEmail;

  useEffect(() => {
    // 핸드폰 번호 입력 값 검증
    const phonePattern = /^01(?:0|1|[6-9])(?:\d{4})\d{4}$/;
    phonePattern.test(phone) ? setCheckPhone(true) : setCheckPhone(false);

    // 이메일 입력 값 검증
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    emailPattern.test(email) ? setCheckEmail(true) : setCheckEmail(false);
  }, [phone, email]);

  return (
    <div className="tutor">
      <div className="tutor__fields">
        <Field.Input
          title="강사명"
          placeholder="강의 등록 시 선택할 강사명을 입력하세요. (최대 10자, 한글/영어)"
          value={name}
          onChange={setName}
          required
        />
        <Field.Input
          title="이니셜"
          placeholder="동영상 등록 시 선택할 강사의 이니셜을 입력하세요. (최대 10자, 한글/영어)"
          value={initial}
          onChange={setInitial}
          required
        />
        <Field.Input
          title="핸드폰"
          placeholder="컨설팅 신청 및 정산 시 안내될 핸드폰 번호를 입력하세요."
          className={isInvalidPhone ? 'uncheck' : 'check'}
          value={phone}
          onChange={setPhone}
          required
        />
        <Field.Input
          title="이메일"
          placeholder="컨설팅 신청 및 정산 시 안내될 이메일 주소를 입력하세요."
          className={isInvalidEmail ? 'uncheck' : 'check'}
          value={email}
          onChange={setEmail}
          required
        />
        <Field.Input
          title="소속 회사"
          placeholder="강의 상세페이지에 노출될 강사의 회사를 입력하세요."
          value={companyName || ''}
          onChange={(value) => {
            setCompanyName(value);
            if (!value.length) {
              setIsCompanyExpose(false);
            }
          }}
        />
        <Field.Radio
          title="소속 회사 노출 여부"
          name="is_company_expose"
          value={isCompanyExpose}
          onChange={setIsCompanyExpose}
          disabled={!companyName}
          options={[
            { label: '노출', value: true },
            { label: '미노출', value: false },
          ]}
        />
        <Field.Input
          title="오픈카톡아이디"
          name="kakao_open_chat_code"
          value={openChatCode}
          onChange={setOpenChatCode}
        />
        <Field.Image title="강사이미지" value={image} onChange={setImage} />
      </div>
      <div className="tutor__buttons">
        <Button.Negative onClick={onClickRemove} disabled={!editing}>
          삭제
        </Button.Negative>
        <Button.Positive onClick={onClickSave} disabled={isSubmitDisabled}>
          저장
        </Button.Positive>
        <Button.Negative onClick={onClickCancel}>취소</Button.Negative>
      </div>
    </div>
  );
}

export default Tutor;
