import axios from 'axios';
import { URL } from '../Constant';
import * as progress from './RequestProgress';

const apiServer = axios.create({
  baseURL: URL.API_SERVER,
  onUploadProgress: ({ loaded, total }) => progress.event(loaded / total),
});

apiServer.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (config.data instanceof FormData) {
      config.data.append('authorization', token);
    } else {
      config.headers.EJE_API_KEY = token;
    }
  }
  return config;
});

apiServer.interceptors.response.use(
  (response) => {
    progress.disposeAll();
    return response.data;
  },
  (error) => {
    if (error.response && !error.response.data.detail?.includes('중복되었습니다.')) {
      console.error(error);
      alert(error.response.data.detail);
    }
    return Promise.reject(error);
  },
);

function toFormData(object) {
  const data = new FormData();
  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (Array.isArray(value)) {
      value.forEach((_value) => data.append(key, _value));
    } else {
      data.append(key, object[key]);
    }
  });
  return data;
}

export function login() {
  return apiServer.get('/user/login');
}

export function users(params) {
  return apiServer.get('/users/admin', { params });
}

export function allUsers(params) {
  return apiServer.get('/users/admin/all', { params });
}

export function getOfflineSchedules(params) {
  return apiServer.get(`/class/admin/lecture/${params.classId}/schedules`, { params });
}

export function addStudentCreatePayment(params) {
  console.log(params);
  return apiServer.post(`/class/admin/lecture/${params.classId}/schedule/${params.scheduleId}/addstudent`, params);
}

export function sendPaymentUrlToStudent(param) {
  console.log(param);
  return apiServer.post(`/class/admin/lecture/${param.classId}/schedule/${param.scheduleId}/addstudent/payment`, param);
}

export function updateUser(id, user) {
  return apiServer.put(`/user/admin/${id}`, user);
}

export function deleteUser(id) {
  return apiServer.delete(`/user/admin/${id}`);
}

export function updateAdminAuthes(id, authIds) {
  return apiServer.post(`/user/admin/auth/${id}`, undefined, {
    params: authIds.length > 0 ? { auth_ids: authIds.join(',') } : {},
  });
}

export function banners(type, params) {
  return apiServer.get(`/banners/admin/${type}`, { params });
}

export function banner(type, id) {
  return apiServer.get(`/banner/admin/${type}/${id}`);
}

export function createBanner(type, banner) {
  return apiServer.post(`/banner/admin/${type}`, toFormData(banner));
}

export function updateBanner(type, id, banner) {
  return apiServer.put(`/banner/admin/${type}/${id}`, toFormData(banner));
}

export function deleteBanner(type, id) {
  return apiServer.delete(`/banners/admin/${type}`, { params: { ids: id } });
}

export function deleteBanners(type, ids) {
  return apiServer.delete(`/banners/admin/${type}`, {
    params: { ids: ids.join(',') },
  });
}

export function videos(params) {
  return apiServer.get('/class/admin/videos', { params });
}

export function videosForClass(params) {
  return apiServer.get('/class/admin/class/videos', { params });
}

export function deleteVideos(ids) {
  return apiServer.delete('class/admin/videos', {
    params: { ids: ids.join(',') },
  });
}

export function createVideo(params) {
  return apiServer.post('/class/admin/video', toFormData(params));
}

export function updateVideo(id, params) {
  return apiServer.put(`/class/admin/video/${id}`, toFormData(params));
}

export function lectures(type, params) {
  switch (type) {
    case 'coupon':
      return apiServer.get('/benefit/admin/classes/search', { params });
    case 'given':
      return apiServer.get('/class/admin/addclass/classes', { params });
    case 'review':
      return apiServer.get('/class/admin/review/classes', { params });
    case 'in_package':
      return apiServer.get('/class/admin/package/singles', { params });
    case 'best_review':
      return apiServer.get('/admin/reviews/best/classes', { params });
    case 'review_lectures':
      return apiServer.get('/class/admin/classes/review-exist', { params });
    case 'compulsory':
      // organizations/classes/single?page=1&limit=20&filtered=[]
      return apiServer.get('/organizations/classes/single', { params });
    default:
      return apiServer.get(`/class/admin/classes/${type}`, { params });
  }
}

export function lectureDetail(type, id) {
  return apiServer.get(`/class/admin/classes/${type}/${id}`);
}

export function createLecture(params) {
  const { class_type, ...otherParams } = params;
  // console.log(params);
  return apiServer.post(`/class/admin/class/${class_type}`, toFormData(otherParams));
}

export function updateLecture(id, params, lectureType) {
  // console.log(params);
  return apiServer.put(`/class/admin/class/${lectureType}/${id}`, toFormData(params));
}

export function deleteLecture(id) {
  return apiServer.delete(`/class/admin/class/${id}`);
}

export function lectureWaitings(params) {
  return apiServer.get('/class/admin/classes/waitings', { params });
}

export function lectureUsers(params) {
  return apiServer.get('/class/admin/userclasses', { params });
}

export function bannerLectures(listen_type) {
  return apiServer.get(`/bannerclass/admin/${listen_type}`);
}

export function givenLectures(params) {
  return apiServer.get('/class/admin/addclasses', { params });
}

export function createCertificate(param, id) {
  return apiServer.post(`/class/admin/userclass/certificate/${id}`, toFormData(param));
}

export function deleteCertificate(id) {
  return apiServer.delete(`/class/admin/userclass/certificate`, {
    params: { id: id },
  });
}

export function updateSurveyDone(params, id) {
  return apiServer.put(`/class/admin/userclass/surveydone/${id}`, params);
}

export function reviews(type, id, params) {
  switch (type) {
    case 'best_reviews':
      return apiServer.get(`/admin/reviews/${id}/best`);
    default:
      return apiServer.get('/admin/reviews', { params });
  }
}

export function getReview(id, params) {
  return apiServer.get(`/admin/reviews/${id}`, { params });
}

export function saveReview(param) {
  return apiServer.post(`/admin/reviews${param.id ? '/' + param.id : ''}`, {
    param,
  });
}

export function deleteReviews(params) {
  return apiServer.delete('/admin/reviews', { params });
}

export function lectureReviews(id, params) {
  return apiServer.get(`/admin/reviews/lecture/${id}`, { params });
}

export function saveBestReview(id, selectedReviews) {
  const data = { selectedReviews };
  return apiServer.post(`/admin/reviews/${id}/best`, data);
}

export function withdrawCoupon(id, FK_user_coupon_id) {
  const data = { FK_user_coupon_id };
  return apiServer.delete(`/admin/reviews/${id}/coupon`, { data });
}

export function giveCoupon(id, FK_user_id) {
  const data = { FK_user_id };
  return apiServer.post(`/admin/reviews/${id}/coupon`, data);
}

export function getGivenLectureDetail(id) {
  return apiServer.get(`/class/admin/addclass/classes/${id}`);
}

export function createGivenLecture(params) {
  return apiServer.post('/class/admin/addclass', params);
}

export function updateGivenLecture(id, params) {
  return apiServer.put(`/class/admin/addclass/${id}`, params);
}

export function payments(params) {
  return apiServer.get('/payment/admin/sales', { params });
}

export function paymentsLogs(params) {
  return apiServer.get('/payment/admin/sales/logs', { params });
}

export function paymentsExactCalculationLogs(params) {
  return apiServer.get('/payment/admin/sales/exact-calc-logs', {
    params: {
      start_date: params.targetStartDate,
      end_date: params.targetEndDate,
    },
  });
}

export function settlement(params) {
  return apiServer.get('/payment/admin/sales/settlement-logs', {
    params,
  });
}

export function settlementVersions(params) {
  return apiServer.get('/payment/admin/sales/settlement-versions', {
    params,
  });
}

export function postSettlement(data) {
  return apiServer.post('/payment/admin/sales/settlement', data);
}

export function paymentStat(params) {
  return apiServer.get('/payment/admin/sale/stat', { params });
}

export function tutors(params) {
  return apiServer.get('/class/admin/tutors', { params });
}

export function tutorDetail(id) {
  return apiServer.get(`/class/admin/tutor/${id}`);
}

export function deleteTutors(ids) {
  return apiServer.delete('/class/admin/tutors', {
    params: { ids: ids.join(',') },
  });
}

export function createTutor(params) {
  return apiServer.post('/class/admin/tutor', toFormData(params));
}

export function updateTutor(id, params) {
  return apiServer.put(`/class/admin/tutor/${id}`, toFormData(params));
}

export function coupons(params) {
  return apiServer.get('/benefit/admin/coupons', { params });
}

export function couponUsages(params) {
  return apiServer.get('/benefit/admin/usercoupons', { params });
}

export function couponDetail(id) {
  return apiServer.get(`/benefit/admin/coupons/${id}`);
}

export function deleteCoupon(id) {
  return apiServer.delete(`/benefit/admin/coupon/${id}`);
}

export function createCoupon(params) {
  return apiServer.post('/benefit/admin/coupon', params);
}

export function updateCoupon(id, params) {
  return apiServer.put(`/benefit/admin/coupon/${id}`, params);
}

export function uploadIntroductionImage(file) {
  return apiServer.post('/class/admin/introduction', toFormData({ file }));
}

export function uploadSectionThumbnail(file) {
  return apiServer.post('/class/admin/section/image', toFormData({ file }));
}

export function agencies(params) {
  return apiServer.get('/agencies', { params });
}

export function upsertAgency(actionType, params) {
  if (actionType === 'create') {
    return apiServer.post('/agencies', toFormData(params));
  }
  return apiServer.put(`/agencies/${params.id}`, toFormData(params));
}

export async function deleteAgencies(ids) {
  return apiServer.delete('/agencies', {
    params: { ids: ids.join(',') },
  });
}

export function artists(params) {
  return apiServer.get('/artists', { params });
}

export function upsertArtist(actionType, params) {
  if (actionType === 'create') {
    return apiServer.post('/artists', toFormData(params));
  }
  return apiServer.put(`/artists/${params.id}`, toFormData(params));
}

export async function deleteArtists(ids) {
  return apiServer.delete('/artists', {
    params: { ids: ids.join(',') },
  });
}

export function records(params) {
  return apiServer.get('/records', { params });
}

export function getRecord(id) {
  return apiServer.get(`/records/${id}`);
}

export async function upsertRecord(actionType, params) {
  if (actionType === 'create') {
    return apiServer.post('/records', toFormData(params));
  }
  return apiServer.put(`/records/${params.id}`, toFormData(params));
}

export async function deleteRecords(ids) {
  return apiServer.delete('/records', {
    params: { ids: ids.join(',') },
  });
}

export function updateEndDate(id, endDate) {
  return apiServer.put(`/class/admin/userclass/${id}/enddate`, { endDate });
}

export function getRecommends() {
  return apiServer.get('/auth/search/recommend', {
    params: {
      cate: 'class,record',
    },
  });
}

export function postRecommendWords(data) {
  return apiServer.post(`/search/recommend`, data);
}

export function putRecommendWords(data) {
  return apiServer.put(`/search/recommend/${data.id}`, data);
}

export function deleteRecommendWords(id) {
  return apiServer.delete(`/search/recommend/${id}`);
}

export function getAllSearchLogs() {
  return apiServer.get('/admin/search/logs/all');
}

export function getSearchLogs(data) {
  return apiServer.post('/admin/search/logs', data);
}

export function getOrganizations(params) {
  return apiServer.get('/organizations', { params });
}

export function getOrganization(id) {
  return apiServer.get(`/organizations/${id}`);
}

export function updateOrganization(id, body) {
  return apiServer.put(`/organizations/${id}`, body);
}

export function createOrganization(body) {
  return apiServer.post('/organizations', body);
}

export function deactivateOrganization(id, body) {
  return apiServer.patch(`/organizations/${id}/activate`, body);
}

export function deleteOrganization(id) {
  return apiServer.delete(`/organizations/${id}`);
}

export function sendPersonalMessages(body) {
  return apiServer.post('/personal-messages', body);
}

export function getNoticeGroups() {
  return apiServer.get('/auth/groups');
}

export function getPosts(params) {
  return apiServer.get('/admin/notice/posts', { params });
}

export function getPostDetail(id) {
  return apiServer.get(`/admin/notice/posts/${id}`);
}

export function createPost(body) {
  return apiServer.post('/admin/notice/posts', toFormData(body));
}

export function updatePost(id, body) {
  return apiServer.put(`/admin/notice/posts/${id}`, toFormData(body));
}

export function deletePost(id) {
  return apiServer.delete(`/admin/notice/posts/${id}`);
}

export function createTopNotice(body) {
  return apiServer.post(`/admin/notice/notices/top`, body);
}

export function updateTopNotice(body) {
  return apiServer.patch(`/admin/notice/notices/top`, body);
}

export function deleteTopNotice(params) {
  return apiServer.delete(`/admin/notice/notices/top`, { params });
}

export function updateGnbMenu(id, body) {
  return apiServer.patch(`/admin/system/gnbs/${id}`, body);
}

export function getOfflineClassesBySchedule(params) {
  return apiServer.get('/class/admin/offline', { params });
}

export function getOfflineClassBySchedule({ classId, attendanceBoardId }) {
  // console.log(classId, scheduleIds);
  return apiServer.get(`/class/admin/offline/${classId}/attendance/${attendanceBoardId}`);
}

export function postTakeAttendance(body) {
  return apiServer.post('/class/admin/attendance', body);
}

export function deleteCancelAttendance({ id, data = {} }) {
  return apiServer.delete(`/class/admin/attendance/${id}`, { data });
}

export function updateAttendanceBoard() {
  return apiServer.post('/class/admin/offline');
}

export function updateNoteToOnCall({ link, attendanceBoardId }) {
  return apiServer.patch(`/class/admin/attendanceboard/${attendanceBoardId}/notelink`, { link });
}

export function classrooms(params) {
  return apiServer.get('/admin/classrooms', { params });
}

export function getSurveys(params) {
  return apiServer.get('/surveys', { params });
}

export function getSurveyDetail(id) {
  return apiServer.get(`/surveys/${id}`);
}

export function createSurvey(body) {
  return apiServer.post(`/surveys`, toFormData(body));
}

export function updateSurvey(id, body) {
  return apiServer.put(`/surveys/${id}`, toFormData(body));
}

export function deleteSurvey(id) {
  return apiServer.delete(`/surveys/${id}`);
}

export function getQuestions(params) {
  return apiServer.get('/questions', { params });
}

export function getQuestionDetail(id) {
  return apiServer.get(`/questions/${id}`);
}

export function createQuestion(body) {
  return apiServer.post(`/questions`, body);
}

export function updateQuestion(body) {
  return apiServer.put(`/questions/${body.id}`, body);
}

export function deleteQuestion(id) {
  return apiServer.delete(`/questions/${id}`);
}

export function getCodes() {
  return apiServer.get(`/auth/codes`);
}

export const getSurveyStatistics = (id) => apiServer.get(`/surveys/${id}/result`);

export const getTargetUsers = (params) => apiServer.get(`/review/request/targetusers`, { params });

export const sendReviewRequest = (body) => apiServer.post(`/review/request`, body);

export const getLectureLikes = (params) => {
  return apiServer.get(`/class/admin/classes/likes`, { params });
};

export const issueCouponToLikes = (body) => {
  return apiServer.post(`/class/admin/classes/likes/issue-coupon`, body);
};

export const updateScheduleVideoCallLink = (id, body) => {
  return apiServer.patch(`/class/admin/class/schedule/${id}/call-link`, body);
};

export const notifySettlement = ({ targetTutors = [], targetMonth }) =>
  apiServer.post(`/notify/settlements`, { targetTutors, targetMonth });

export const getThirdPartyQuestionFieldList = (params) =>
  apiServer.get(`/class/third-party/question-fields`, { params });

export function getInterestedCompanies(params) {
  return apiServer.get('/users/admin/interested-companies', { params });
}

export function getInterestedCompanyDetail(id) {
  return apiServer.get(`/users/admin/interested-companies/${id}`);
}

export function updateInterestedCompany(id, body) {
  return apiServer.put(`/users/admin/interested-companies/${id}`, body);
}

export function createInterestedCompany(body) {
  return apiServer.post('/users/admin/interested-companies', body);
}

export function deleteInterestedCompany(id) {
  return apiServer.delete(`/users/admin/interested-companies/${id}`);
}

export function matchingOtherInterestedCompany(body) {
  return apiServer.put(`/users/admin/match-other-interested-companies`, body);
}

export function simpleLectures(type, params) {
  return apiServer.get(`/class/admin/classes-simple/${type}`, { params });
}
