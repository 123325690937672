import { useCallback, useState } from 'react';
import { getOrganizations } from '../../../Common/Util/Request';
import Table from '../../../Common/Component/Table';
import { Button } from '@mui/material';

import styles from './index.module.scss';
import { Add } from '@mui/icons-material';

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    const params = {};
    const response = await getOrganizations(params);
    const { total, list } = response;
    setOrganizations(list.sort((a, b) => +b.id - +a.id).splice(pageIndex * pageSize, pageSize));
    setTotal(total);
  }, []);

  const createOrganization = useCallback(() => {
    window.open('/organization/new');
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.organizationListHeader}>
        <p>
          전체 조직 수: <strong>{total}</strong>
        </p>
        <div className="organization-list-header-actions">
          <Button variant="contained" color="primary" onClick={createOrganization}>
            <Add
              style={{
                width: '16px',
                height: '16px',
              }}
            />
            생성
          </Button>
        </div>
      </div>
      <Table
        columns={[
          {
            Header: '조직ID',
            accessor: 'id',
            width: 80,
            maxWidth: 80,
          },
          {
            Header: '조직명',
            accessor: 'name',
            width: 120,
            maxWidth: 120,
          },
          {
            Header: '설명',
            accessor: 'description',
            width: 200,
            maxWidth: 200,
          },
          {
            Header: '관리자',
            accessor: 'supervisor',
            width: 200,
            maxWidth: 200,
          },
          {
            Header: '강의수',
            accessor: 'lectureCount',
            width: 80,
            maxWidth: 80,
          },
          {
            Header: '구성원수',
            accessor: 'memberCount',
            width: 80,
            maxWidth: 80,
          },
          {
            Header: 'Action',
            accessor: ({ id }) => (
              <Button
                onClick={() => {
                  window.open(`/organization/${id}`);
                }}
              >
                관리
              </Button>
            ),
            width: 80,
            maxWidth: 80,
          },
        ]}
        pageSize={15}
        total={total}
        data={organizations}
        fetchData={fetchData}
      />
    </div>
  );
};

export default OrganizationList;
