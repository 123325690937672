import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useLocationState(defaultState) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname, defaultState);
    }
    // eslint-disable-next-line
  }, [history, location]);

  const setLocationState = useCallback(
    (_state) => {
      history.replace(location.pathname, { ...location.state, ..._state });
    },
    [history, location],
  );

  return [location.state || defaultState, setLocationState];
}
