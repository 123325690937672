import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Base, Input } from '../../../../Common/Component/Field';
import { IconButton } from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, Delete, SaveSharp } from '@mui/icons-material';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import * as request from '../../../../Common/Util/Request';

export default function RecommendWords(props) {
  const { control, register, handleSubmit, setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      words: props.word,
    },
  });

  useEffect(() => {
    setValue('words', props.words);
  }, [props.words, setValue]);

  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'words',
  });

  async function onValid(data) {
    const deleteWords = [];
    const updateWords = [];
    const createWords = [];
    data.words = data.words.map((word, index) => {
      let action = '';
      const order = index + 1;

      if (word.id) {
        for (let w of props.words) {
          if (w.id === word.id) {
            if (w.word !== word.word || +w.order !== order) {
              action = 'update';
              updateWords.push({
                ...word,
                order,
              });
            }
            break;
          }
        }
      } else {
        action = 'create';
        createWords.push({
          ...word,
          order,
        });
      }

      return {
        ...word,
        order,
        action,
      };
    });

    props.words.forEach((word) => {
      data.words.find((w) => word.id === w.id) || deleteWords.push(word.id);
    });
    if (createWords.length > 0 || updateWords.length > 0 || deleteWords.length > 0) {
      const promises = [];
      deleteWords.forEach((id) => {
        promises.push(request.deleteRecommendWords(id));
      });
      updateWords.forEach((word) => {
        promises.push(
          request.putRecommendWords({
            ...word,
            category: props.category,
          }),
        );
      });
      Promise.all(promises)
        .then(() => {
          return Promise.all(
            createWords.map((word) =>
              request.postRecommendWords({
                ...word,
                category: props.category,
              }),
            ),
          );
        })
        .then(() => {
          Swal.fire({
            title: '저장되었습니다.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .finally(() => {
          // 데이터 패칭
          props.refresh();
        });
    } else {
      Swal.fire({
        title: '수정되지 않았습니다.',
        text: '수정된 내용이 없습니다.',
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  function onInvalid(error) {
    Swal.fire({
      title: '빈 값이 있으면 안됩니다.',
      icon: 'error',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  return (
    <form className="recommend-keywords-class" onSubmit={handleSubmit(onValid, onInvalid)}>
      <input {...register('category')} type="hidden" value={props.category} />
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <Controller
              rules={{ required: true }}
              render={({ field }) => (
                <Base style={{ margin: '4px 0' }}>
                  <Input {...field} required ref={null} />
                  <IconButton size="small" onClick={() => move(index, index - 1)} disabled={index === 0} disableRipple>
                    <ArrowUpward fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => move(index, index + 1)}
                    disabled={index === fields.length - 1}
                    disableRipple
                  >
                    <ArrowDownward fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (fields.length > 1) {
                        remove(index);
                      } else {
                        Swal.fire({
                          title: '최소 1개의 추천 키워드가 있어야 합니다.',
                          icon: 'warning',
                        });
                      }
                    }}
                    disableRipple
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Base>
              )}
              name={`words.${index}.word`}
              control={control}
            />
          </div>
        );
      })}
      <button
        className="btn-add"
        type="input"
        onClick={(event) => {
          event.preventDefault();
          if (fields.length < 5) {
            append({ word: '' });
          } else {
            Swal.fire({
              title: '최대 5개까지만 추가할 수 있습니다.',
              icon: 'warning',
              confirmButtonText: '확인',
            });
          }
        }}
      >
        <Add fontSize="small" />
      </button>
      <button type="submit">
        <SaveSharp fontSize="small" />
      </button>
      <style jsx="true">{`
        .recommend-keywords-class {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          column-gap: 20px;
        }

        .recommend-keywords-class > button {
          margin-bottom: 20px;
          padding: 12px 0;
          cursor: pointer;
          border: none;
          border-radius: 6px;
          background-color: rgba(0, 0, 0, 0.1);
        }

        .recommend-keywords-class > button:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }

        .recommend-keywords-class .btn-add {
          padding: 4px 0;
        }
      `}</style>
    </form>
  );
}
