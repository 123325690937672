import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import * as request from '../../../Common/Util/Request';
import DatePicker from '../../../Common/Component/DatePicker';
import Table from '../../../Common/Component/Table/index';
import { PAYMENT } from '../../../Common/Constant';
import * as CustomButton from '../../../Common/Component/Button';
import { Button, Chip, createTheme, Input, MenuItem, Select, ThemeProvider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocationState } from '../../../Common/Util/CustomHooks';
import dayjs from 'dayjs';
import * as excel from '../../../Common/Util/Excel';

function Sales(props) {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    selectedStatusList: [],
    searchType: 'lectureTitle',
    searchText: '',
  });
  const { startDate, endDate, selectedStatusList = [] } = locationState;

  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [logList, setLogList] = useState([]);
  const [total, setTotal] = useState(0);

  const onClickSearch = useCallback(() => {
    setLocationState({
      ...locationState,
      searchType,
      searchText,
    });
  }, [setLocationState, searchType, searchText]);

  const loadLogs = useCallback(
    ({ page, limit }) => {
      const params = { page, limit };

      if (selectedStatusList.length > 0) {
        params.status = selectedStatusList.join(',');
      }

      if (startDate) {
        params.start_date = moment(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = moment(endDate).format('YYYY-MM-DD');
      }

      if (searchText) {
        params.searchType = searchType;
        params.searchText = searchText;
      }

      return request.paymentsLogs(params).then((res) => {
        return res;
      });
    },
    [startDate, endDate, selectedStatusList, locationState],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadLogs({ page: pageIndex + 1, limit: pageSize }).then((logs) => {
        setLogList(logs.list);
        setTotal(logs.total);
      });
    },
    [loadLogs],
  );

  const columns = useMemo(
    () => [
      { Header: '결제명(강의명)', accessor: ({ name }) => name },
      {
        Header: '강의구분',
        accessor: ({ FK_class: { listen_type } }) =>
          listen_type === 'offline'
            ? '오프라인'
            : listen_type === 'online'
            ? '온라인'
            : listen_type === 'roundOnline'
            ? '온라인(기수제)'
            : '외국인',
      },
      { Header: '결제자(ID)', accessor: (row) => row.FK_tx?.FK_user?.id || 0 },
      {
        Header: '결제자(이름,닉네임)',
        accessor: (row) => {
          if (row.FK_tx?.FK_user?.id) {
            return `${row.FK_tx.FK_user.name}(${row.FK_tx.FK_user.nickname})`;
          } else {
            return `${row.json_data.buyer_name}(-)`;
          }
        },
      },
      {
        Header: '결제자(이메일)',
        accessor: (row) => row.FK_tx?.FK_user?.email || row.json_data.buyer_email,
      },
      {
        Header: '결제자(전화번호)',
        accessor: (row) => row?.FK_tx?.FK_user?.phone?.toPhone() || row?.json_data?.buyer_tel?.toPhone() || '-',
      },
      {
        Header: '결제상태',
        accessor: ({ status }) => PAYMENT.STATUS_TEXT[status],
      },
      { Header: '화폐단위', accessor: ({ currency }) => currency },
      {
        Header: '거래금액',
        accessor: ({ amount, current_cancel_amount, status }) => {
          if (status === PAYMENT.STATUS.PAID) {
            return <span style={{ color: 'green', fontWeight: 'bold' }}>{amount.toPrice()}</span>;
          } else {
            return <span style={{ color: 'red' }}>-{current_cancel_amount.toPrice()}</span>;
          }
        },
      },
      {
        Header: '결제시간',
        accessor: (row) => {
          const json_data = row.json_data;
          return json_data.status === 'paid'
            ? moment.unix(json_data.paid_at).format('YYYY-MM-DD HH:mm:ss')
            : json_data.status === 'cancelled'
            ? moment.unix(json_data.cancelled_at).format('YYYY-MM-DD HH:mm:ss')
            : json_data.status === 'pending'
            ? moment.unix(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : '-';
        },
      },
      {
        Header: '환불사유',
        accessor: ({ json_data }) => json_data.cancel_history[0]?.reason || '-',
      },
    ],
    [],
  );

  useEffect(() => {
    const params = {};
    if (startDate) {
      params.start_date = moment(startDate).format('YYYY-MM-DD');
    }
    if (endDate) {
      params.end_date = moment(endDate).format('YYYY-MM-DD');
    }
  }, [startDate, endDate]);

  const handleStatusClick = (status) => () => {
    if (selectedStatusList.includes(status)) {
      setLocationState({
        startDate,
        endDate,
        selectedStatusList: selectedStatusList.filter((item) => item !== status),
      });
    } else {
      setLocationState({ startDate, endDate, selectedStatusList: [...selectedStatusList, status] });
    }
  };

  const onClickCSV = useCallback(() => {
    loadLogs({ page: 1, limit: 9999999 }).then((log) => {
      const csvData = log.list.map((log) => ({
        '결제명(강의명)': log.name,
        강의구분:
          log.FK_class.listen_type === 'offline'
            ? '오프라인'
            : log.FK_class.listen_type === 'online'
            ? '온라인'
            : log.FK_class.listen_type === 'roundOnline'
            ? '온라인(기수제)'
            : '외국인',
        '결제자(ID)': log.FK_tx?.FK_user?.id || '-',
        '결제자(이름, 닉네임)': log.FK_tx?.FK_user?.name + `(${log.FK_tx?.FK_user?.nickname})`,
        '결제자(이메일)': log.FK_tx?.FK_user?.email,
        '결제자(전화번호)': log.FK_tx?.FK_user?.phone?.toPhone() || '-',
        결제상태: PAYMENT.STATUS_TEXT[log.status],
        화폐단위: log.currency,
        거래금액: log.amount.toPrice(),
        결제시간: moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        환불사유: log.json_data.cancel_history[0]?.reason || '-',
      }));
      excel.downloadXLSX(csvData, `매출관리Logs${dayjs.utc().add(9, 'h').format('YYYYMMDD')}`);
    });
  }, [loadLogs]);

  const ejeTheme = createTheme({
    palette: {
      pink: {
        light: '#f46981',
        main: '#F24462',
        dark: '#a92f44',
        contrastText: '#fff',
      },
      pinkHover: {
        light: '#ff93ab',
        main: '#FF7896',
        dark: '#b25469',
        contrastText: '#000',
      },
    },
  });

  return (
    <ThemeProvider theme={ejeTheme}>
      <div className="logs_wrapper">
        <section className="logs_header_container">
          <section className="logs_header_top_container">
            <div className="date">
              <div>기간</div>
              <DatePicker
                selected={startDate}
                maxDate={endDate}
                onChange={() => {
                  setLocationState({
                    ...locationState,
                    startDate: startDate,
                  });
                }}
              />
              <div className="divider">~</div>
              <DatePicker
                selected={endDate}
                minDate={startDate}
                onChange={() => {
                  setLocationState({
                    ...locationState,
                    endDate: endDate,
                  });
                }}
              />
            </div>

            <div className="filters_search_wrap">
              <Select value={searchType} onChange={(e) => setSearchType(e.target.value)} size="small" color="pink">
                <MenuItem value="lectureTitle" sx={{ fontSize: '0.875rem' }}>
                  강의 제목
                </MenuItem>
                <MenuItem value="userId" sx={{ fontSize: '0.875rem' }}>
                  회원 ID
                </MenuItem>
                <MenuItem value="userName" sx={{ fontSize: '0.875rem' }}>
                  회원 이름
                </MenuItem>
              </Select>

              <Input
                value={searchText}
                placeholder="검색어를 입력하세요."
                color="pink"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onClickSearch();
                }}
              />

              <Button
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  onClickSearch();
                }}
                color="pink"
                sx={{
                  height: '2rem',
                }}
                endIcon={<SearchIcon fontSize="small" />}
              >
                검색
              </Button>
            </div>
          </section>

          <section className="logs_header_bottom_container">
            <div className="logs_tag_container">
              <Chip
                label="결제"
                color={selectedStatusList.includes(PAYMENT.STATUS.PAID) ? 'pink' : 'default'}
                onClick={handleStatusClick(PAYMENT.STATUS.PAID)}
              />
              <Chip
                label="환불"
                color={selectedStatusList.includes(PAYMENT.STATUS.CANCELLED) ? 'pink' : 'default'}
                onClick={handleStatusClick(PAYMENT.STATUS.CANCELLED)}
              />
            </div>

            <CustomButton.Excel onClick={onClickCSV}>엑셀 다운로드</CustomButton.Excel>
          </section>
        </section>
        <Table data={logList} columns={columns} total={total} pageSize={40} fetchData={fetchData} />

        <style jsx="true">{`
          .logs_wrapper {
            padding: 12px 40px 0 0;
            font-size: 14px;
          }

          .logs_wrapper .date {
            display: flex;
            width: 400px;
            margin-bottom: 12px;
            column-gap: 4px;
          }

          .MuiSelect-select {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            font-size: 0.875rem;
            cursor: pointer;
          }

          .logs_wrapper {
            .logs_header_top_container {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .filters_search_wrap {
                display: flex;
                column-gap: 0.5rem;
                align-items: center;

                input {
                  width: 12rem;
                  padding: 0.25rem 0.5rem;
                  font-size: 0.875rem;
                }
              }
            }

            .logs_header_bottom_container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0.5rem 0;
            }
          }

          .logs_wrapper .logs_tag_container {
            display: flex;
            column-gap: 4px;
            margin-bottom: 12px;
          }

          .logs_wrapper .date > div:nth-child(1) {
            width: 30px;
          }

          .logs_wrapper .date .react-datepicker-wrapper {
            width: fit-content;
          }

          .logs_wrapper .date .divider {
            margin: 0 4px;
          }

          .logs_wrapper .date .react-datepicker__tab-loop + .divider {
            margin-left: 0;
          }

          .react-datepicker__month-container {
            box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.3);
          }
        `}</style>
      </div>
    </ThemeProvider>
  );
}

export default Sales;
