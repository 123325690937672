import { useEffect, useRef, useState } from 'react';
import { getRecommends } from '../../../Common/Util/Request';
import RecommendWords from './components/RecommendWords';

export default function SearchRecommends() {
  const classWordsRef = useRef(1);
  const recordWordsRef = useRef(1);
  const [recommendKeywords, setRecommendKeywords] = useState({
    class: [],
    record: [],
  });

  const fetchData = () => {
    getRecommends().then((data) => {
      const {
        classRecommends: { classRecommendWord },
        recordRecommends: { recordRecommendWord },
      } = data;
      setRecommendKeywords({
        class: classRecommendWord,
        record: recordRecommendWord,
      });
    });
  };
  const refresh = () => {
    fetchData();
    classWordsRef.current++;
    recordWordsRef.current++;
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="recommend-keywords">
        <div className="recommend-keywords__class">
          <h3 className="recommend-keywords__title">강의 검색 추천 키워드</h3>
          <RecommendWords
            forwadRef={classWordsRef}
            refresh={refresh}
            words={recommendKeywords.class}
            category="class"
          />
        </div>
        <div className="recommend-keywords__record">
          <h3 className="recommend-keywords__title">앨범 검색 추천 키워드</h3>
          <RecommendWords
            forwardRef={recordWordsRef}
            refresh={refresh}
            words={recommendKeywords.record}
            category="record"
          />
        </div>
      </div>
      <style jsx="true">{`
        .recommend-keywords {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          column-gap: 20px;
        }
      `}</style>
    </div>
  );
}
