import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Request from '../../../Common/Util/Request';
import { deactivateOrganization, getOrganization, updateOrganization } from '../../../Common/Util/Request';
import * as Field from '../../../Common/Component/Field';
import { Base } from '../../../Common/Component/Field';
import { Button } from '@mui/material';

import styles from './index.module.scss';
import dayjs from 'dayjs';
import SelectSearch from '../../../Common/Component/SelectSearch';
import swal from 'sweetalert2';

const OrganizationEdit = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState({});

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [members, setMembers] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [addMembers, setAddMembers] = useState([]);
  const [addLectures, setAddLectures] = useState([]);
  const [deleteMembers, setDeleteMembers] = useState([]);
  const [deleteLectures, setDeleteLectures] = useState([]);

  const fetch = useCallback(async () => {
    const response = await getOrganization(id);
    setOrganization(response);
    setName(response.name);
    setDescription(response.description);
    setMembers(response.members);
    setLectures(response.lectures);
    setAddMembers([]);
    setAddLectures([]);
    setDeleteMembers([]);
    setDeleteLectures([]);
  }, [id]);

  useEffect(() => {
    if (Object.keys(organization).length > 0) {
    }
  }, [organization]);

  useEffect(() => {
    fetch().then(() => setIsLoading(false));
  }, [fetch]);

  const submit = useCallback(() => {
    const organizedMembers = [
      ...members.map((member) => ({
        id: member.FK_user_id,
        role: member.role,
      })),
    ]
      .filter((member) => {
        return !deleteMembers.includes(member.id);
      })
      .concat(addMembers);

    const supervisor = organizedMembers.filter(({ role }) => role === 'supervisor');
    const managers = organizedMembers.filter(({ role }) => role === 'manager');
    const commonMembers = organizedMembers.filter(({ role }) => role === 'member');

    if (supervisor.length > 1) {
      swal.fire({
        title: '슈퍼바이저는 한명만 지정할 수 있습니다.',
        icon: 'error',
      });
      return;
    }

    if (supervisor.length === 0) {
      swal.fire({
        title: '슈퍼바이저를 지정해주세요.',
        icon: 'error',
      });
      return;
    }

    const organizedLectures = [...lectures.map((lecture) => lecture.FK_class_id)]
      .filter((id) => {
        return !deleteLectures.includes(id);
      })
      .concat(addLectures);

    const body = {
      name,
      description,
      supervisor_id: supervisor[0].id,
      manager_ids: managers.map(({ id }) => id).join(','),
      member_ids: commonMembers.map(({ id }) => id).join(','),
      class_ids: organizedLectures.join(','),
    };

    swal
      .fire({
        title: '정말로 수정하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '수정',
        cancelButtonText: '취소',
        html:
          `조직명: ${name}<br />` +
          `조직설명: ${description}<br />` +
          `슈퍼바이저: ${supervisor[0].id}<br />` +
          `매니저: ${managers && managers.map(({ id }) => id)}<br />` +
          `구성원: ${commonMembers && commonMembers.map(({ id }) => id)}<br />` +
          `강의: ${organizedLectures}`,
      })
      .then(async (confirm) => {
        try {
          if (confirm.isConfirmed) {
            const result = await updateOrganization(id, body);
            if (result.success === true || result.success === 'true') {
              swal
                .fire({
                  title: '수정되었습니다.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then(() => {
                  window.opener.location.reload();
                })
                .then(() => {
                  fetch();
                });
            }
          }
        } catch (e) {
          console.error(e);
          swal.fire({
            title: '오류가 발생했습니다.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }, [addLectures, addMembers, deleteLectures, deleteMembers, description, fetch, id, lectures, members, name]);

  const allLectures = useMemo(async () => {
    return await Request.lectures('compulsory', {
      page: 1,
      limit: 1000,
    }).then((response) => {
      return response;
    });
  }, []);

  const allMembers = useMemo(async () => {
    return await Request.users({
      page: 1,
      limit: 99999999,
      korean: true,
    });
  }, []);

  const disableOrganization = useCallback(() => {
    swal
      .fire({
        title: '정말로 비활성화하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '비활성화',
        confirmButtonColor: '#d33',
        cancelButtonText: '취소',
      })
      .then(async (confirm) => {
        try {
          if (confirm.isConfirmed) {
            const result = await deactivateOrganization(id, {
              is_activation: false,
            });
            if (result.success === true || result.success === 'true') {
              window.opener.location.reload();
              swal
                .fire({
                  title: '비활성화되었습니다.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then(() => {
                  window.close();
                });
            }
          }
        } catch (e) {
          console.error(e);
          swal.fire({
            title: '오류가 발생했습니다.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }, [id]);

  return (
    !isLoading && (
      <div style={{ maxWidth: '1280px' }}>
        <Field.Input title="조직 ID" name="id" value={id} disabled />
        <Field.Input title="조직명" name="name" value={name} onChange={setName} required />
        <Field.Input title="조직설명" name="description" value={description} onChange={setDescription} required />
        <OrganizationMembers
          title="구성원"
          allList={allMembers}
          list={members}
          addList={addMembers}
          setAddList={setAddMembers}
          deleteList={deleteMembers}
          setDeleteList={setDeleteMembers}
        />
        <OrganizationLectures
          title="강의"
          allList={allLectures}
          list={lectures}
          addList={addLectures}
          setAddList={setAddLectures}
          deleteList={deleteLectures}
          setDeleteList={setDeleteLectures}
        />
        <div
          className="organization-edit-actions"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100px' }} />
          <div
            style={{
              display: 'flex',
              columnGap: '20px',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <Button variant="contained" color="primary" onClick={submit} disableRipple disableElevation>
              저장
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#adadad' }}
              onClick={() => {
                window.close();
              }}
              disableRipple
              disableElevation
            >
              닫기(취소)
            </Button>
          </div>
          <div style={{ width: '100px' }}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#ff5555' }}
              onClick={disableOrganization}
              disableRipple
              disableElevation
            >
              비활성화
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default OrganizationEdit;

const OrganizationMembers = (props) => {
  const { allList, list, addList, setAddList, deleteList, setDeleteList } = useMemo(() => props, [props]);
  const [options, setOptions] = useState(list);
  const [addSelect, setAddSelect] = useState('');
  const [addSelectOptions, setAddSelectOptions] = useState([]);

  const willAddId = useMemo(() => addSelect, [addSelect]);
  // const willAddPhone = useMemo(() => addSelect, [addSelect]);
  // const willAddEMail = useMemo(() => addSelect, [addSelect]);

  useEffect(() => {
    setOptions(list);
  }, [list]);

  useEffect(() => {
    setOptions((prev) =>
      prev.map((option) => {
        if (deleteList.includes(option.FK_user_id)) {
          return { ...option, deleted: true };
        }
        return { ...option, deleted: false };
      }),
    );
  }, [deleteList]);

  useEffect(() => {
    allList
      .then(({ users: response }) => {
        return response.rows;
      })
      .then((rows) => {
        return rows.map((row) => {
          const arrName = [];
          arrName.push(row.id);
          arrName.push(row.name);
          if (row.phone) arrName.push(row.phone.toPhone());
          if (row.email) arrName.push(row.email);
          return {
            ...row,
            value: row.id,
            name: arrName.join(' ----- '),
          };
        });
      })
      .then((rows) => {
        const hadList = list.map((item) => item.FK_user.id);
        setAddSelectOptions(rows.filter((row) => !hadList.includes(row.id) && row.deletedAt === null));
      })
      .catch(() => []);
  }, [allList, list, options]);

  const [addRole, setAddRole] = useState('member');

  return (
    <Base {...props} type="org-members-wrapper">
      <div className="org-members-div">
        <table className={styles.orgMemberTable}>
          <thead>
            <tr>
              <th className={styles.id}>ID</th>
              <th className={styles.role}>역할</th>
              <th className={styles.name}>이름</th>
              <th className={styles.phone}>전화번호</th>
              <th className={styles.email}>이메일</th>
              <th className={styles.deleteItem}>삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.addActionRow}>
              <td className={styles.id}>{willAddId}</td>
              <td className={styles.role}>
                {addSelect && (
                  <SelectSearch
                    value={addRole}
                    onChange={setAddRole}
                    options={[
                      {
                        value: 'supervisor',
                        name: 'supervisor',
                      },
                      {
                        value: 'manager',
                        name: 'manager',
                      },
                      {
                        value: 'member',
                        name: 'member',
                      },
                    ]}
                    placeholder="역할을 선택하세요"
                    style={{ width: '100%' }}
                  />
                )}
              </td>
              <td className={styles.name} colSpan={3} style={{ width: '60%' }}>
                <SelectSearch
                  value={addSelect}
                  onChange={setAddSelect}
                  options={addSelectOptions.filter(
                    (row) =>
                      !addList.find(({ id }) => id === row.id) &&
                      !options.find((option) => option.FK_user_id === row.id),
                  )}
                  placeholder="구성원을 선택하세요"
                  style={{ width: '100%' }}
                />
              </td>
              <td className={styles.deleteItem}>
                <Button
                  className={styles.addButton}
                  onClick={() => {
                    if (!willAddId) return false;
                    if (!addRole) {
                      swal.fire('역할을 선택해주세요', {
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                      return false;
                    }
                    if (!addList.includes(willAddId)) {
                      setAddList([
                        {
                          id: willAddId,
                          role: addRole,
                        },
                        ...addList,
                      ]);
                      setAddRole('member');
                      setAddSelect('');
                    } else {
                      setAddRole('member');
                      setAddSelect('');
                    }
                  }}
                  disableRipple
                  disableFocusRipple
                >
                  추가
                </Button>
              </td>
            </tr>
            {addList.map(({ id }) => {
              const item = addSelectOptions.find((row) => row.id === id);
              return (
                <tr key={id} className={styles.willAdd}>
                  <td className={styles.id}>{item.id}</td>
                  <td className={styles.role}>{addList.find(({ id }) => id === item.id).role}</td>
                  <td className={styles.name}>{item.name.split(' ----- ')[1]}</td>
                  <td className={styles.phone}>{item.phone?.toPhone() || ''}</td>
                  <td className={styles.email}>{item.email || ''}</td>
                  <td className={styles.deleteItem}>
                    <Button
                      className={styles.deleteButton}
                      onClick={() => {
                        setAddList(addList.filter((row) => row.id !== id));
                      }}
                      disableRipple
                      disableFocusRipple
                    >
                      삭제
                    </Button>
                  </td>
                </tr>
              );
            })}
            {options.map((option) => (
              <tr key={option.id} className={option.deleted ? styles.willDelete : ''}>
                <td className={styles.id}>{option.FK_user_id || '-'}</td>
                <td className={styles.role}>{option.role || '-'}</td>
                <td className={styles.name}>{option.FK_user?.name || '-'}</td>
                <td className={styles.phone}>{option.FK_user?.phone?.toPhone() || '-'}</td>
                <td className={styles.email}>{option.FK_user?.email || '-'}</td>
                <td className={styles.deleteItem}>
                  <Button
                    className={styles.deleteButton}
                    onClick={() => {
                      if (deleteList.find((id) => +id === +option.FK_user_id)) {
                        setDeleteList(deleteList.filter((id) => id !== option.FK_user_id));
                      } else {
                        setDeleteList([...deleteList, option.FK_user_id]);
                      }
                    }}
                    disableRipple
                    disableFocusRipple
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Base>
  );
};

const OrganizationLectures = (props) => {
  const { allList, list, addList, setAddList, deleteList, setDeleteList } = useMemo(() => props, [props]);
  const [options, setOptions] = useState(list);
  const [addSelect, setAddSelect] = useState('');
  const [addSelectOptions, setAddSelectOptions] = useState([]);

  const willAddId = useMemo(() => addSelect, [addSelect]);
  const willAddItemSchedule = useMemo(() => {
    const item = addSelectOptions.find((row) => row.id === willAddId);
    return addSelect
      ? `${item?.start_date} ~ ${item?.end_date} (${dayjs(item.end_date).diff(dayjs(item.start_date), 'days')}일)`
      : '';
  }, [addSelect, addSelectOptions, willAddId]);

  useEffect(() => {
    setOptions(list);
  }, [list]);

  useEffect(() => {
    setOptions((prev) =>
      prev.map((option) => {
        if (deleteList.includes(option.FK_class_id)) {
          return { ...option, deleted: true };
        }
        return { ...option, deleted: false };
      }),
    );
  }, [deleteList]);

  useEffect(() => {
    allList
      .then((response) => {
        return response.list;
      })
      .then((rows) => {
        return rows.map((row) => {
          return { ...row, value: row.id, name: `${row.id} - ${row.title}` };
        });
      })
      .then((rows) => {
        const hadList = list.map((item) => item.FK_class.id);
        setAddSelectOptions(rows.filter((row) => !hadList.includes(row.id)));
      })
      .catch(() => []);
  }, [allList, list, options]);

  return (
    <Base {...props} type="org-lectures-wrapper">
      <div className="org-lectures-div">
        <table className={styles.orgLectureTable}>
          <thead>
            <tr>
              <th className={styles.lectureId}>ID</th>
              <th className={styles.title}>제목</th>
              <th className={styles.schedule}>일정</th>
              <th className={styles.deleteItem}>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.addActionRow}>
              <td className={styles.lectureId}>{willAddId}</td>
              <td className={styles.title}>
                <SelectSearch
                  value={addSelect}
                  onChange={setAddSelect}
                  options={addSelectOptions.filter((row) => !addList.includes(row.id))}
                  placeholder="강의를 선택하세요"
                  style={{ width: '100%' }}
                />
              </td>
              <td className={styles.schedule}>{willAddItemSchedule}</td>
              <td className={styles.deleteItem}>
                <Button
                  className={styles.addButton}
                  onClick={() => {
                    if (!willAddId) return false;
                    if (!addList.includes(willAddId)) {
                      setAddList([willAddId, ...addList]);
                      setAddSelect('');
                    } else {
                      setAddSelect('');
                    }
                  }}
                  disableRipple
                  disableFocusRipple
                >
                  추가
                </Button>
              </td>
            </tr>
            {addList.map((id) => {
              const item = addSelectOptions.find((row) => row.id === id);
              return (
                <tr key={id} className={styles.willAdd}>
                  <td className={styles.lectureId}>{item.id}</td>
                  <td className={styles.title}>{item.title}</td>
                  <td className={styles.schedule}>
                    {item.start_date} ~ {item.end_date} ({dayjs(item.end_date).diff(dayjs(item.start_date), 'days')}
                    일)
                  </td>
                  <td className={styles.deleteItem}>
                    <Button
                      className={styles.deleteButton}
                      onClick={() => {
                        setAddList(addList.filter((row) => row !== id));
                      }}
                      disableRipple
                      disableFocusRipple
                    >
                      삭제
                    </Button>
                  </td>
                </tr>
              );
            })}
            {options.map((option) => (
              <tr key={option.id} className={option.deleted ? styles.willDelete : ''}>
                <td className={styles.lectureId}>{option.FK_class_id || '-'}</td>
                <td className={styles.title}>{option.FK_class?.title || '-'}</td>
                <td className={styles.schedule}>
                  {option.start_date || ''} ~ {option.end_date || ''} (
                  {dayjs(option.end_date).diff(dayjs(option.start_date), 'day') + 1}
                  일)
                </td>
                <td className={styles.deleteItem}>
                  <Button
                    className={styles.deleteButton}
                    onClick={() => {
                      if (deleteList.includes(option.FK_class_id)) {
                        setDeleteList(deleteList.filter((id) => id !== option.FK_class_id));
                      } else {
                        setDeleteList([...deleteList, option.FK_class_id]);
                      }
                    }}
                    disableRipple
                    disableFocusRipple
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Base>
  );
};
