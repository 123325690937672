import ReactDOM from 'react-dom';
import { CSVDownload } from 'react-csv';
import XLSX from 'json-as-xlsx';

export function downloadCSV(data) {
  const element = document.getElementById('csv');
  ReactDOM.render(<CSVDownload data={data} target="_blank" />, element);
  ReactDOM.render(null, element);
}

export function downloadXLSX(data, fileName) {
  XLSX(
    [
      {
        columns: Object.keys(
          data[data.map((e) => Object.keys(e).length).reduce((m, _, i, arr) => (arr[i] > arr[m] ? i : m), 0)],
        ).map((key) => ({
          label: key,
          value: key,
          style: {
            font: {
              bold: true,
            },
          },
        })),
        content: data,
      },
    ],
    {
      fileName,
      writeOptions: {},
    },
  );
}
