import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import Login from './Common/Util/Login';
import AdminRoute from './Common/Util/AdminRoute';
import Navigator from './Common/Component/Navigator';
import Dashboard from './Page/Dashboard';
import BannerList from './Page/Banner/List';
import BannerNew from './Page/Banner/New';
import UserList from './Page/User/List';
import UserDetail from './Page/User/Detail';
import UserAdmin from './Page/User/Admin';
import UserBlacklist from './Page/User/Blacklist';
import VideoList from './Page/Lecture/Video/List';
import VideoNew from './Page/Lecture/Video/New';
import SingleList from './Page/Lecture/Single/List';
import PackageList from './Page/Lecture/Package/List';
import ThirdPartyList from './Page/Lecture/ThirdParty/List';
import ThirdPartyUserList from './Page/Lecture/ThirdParty/Users';
import LectureNew from './Page/Lecture/New';
import LectureUserList from './Page/Lecture/User/List';
import LectureUserDetail from './Page/Lecture/User/Detail';
import TutorList from './Page/Lecture/Tutor/List';
import TutorNew from './Page/Lecture/Tutor/New';
import GivenLectures from './Page/Lecture/Given/List';
import GivenLecture from './Page/Lecture/Given/New';
import Sales from './Page/Sales';
import SalesLogs from './Page/Sales/Logs';
import Settlement from './Page/Sales/Settlement';
import CouponList from './Page/Promotion/Coupon/List';
import CouponNew from './Page/Promotion/Coupon/New';
import Records from './Page/Contents/Records';
import RecordsNew from './Page/Contents/Records/New';
import Agencies from './Page/Contents/Agencies';
import Artists from './Page/Contents/Artists';
import Reviews from './Page/Lecture/Review/List';
import ReviewNew from './Page/Lecture/Review/New';
import SearchRecommends from './Page/Search/Recommends';
import ReviewBestList from './Page/ReviewBest/List';
import ReviewRequest from './Page/Review/Request';
import ReviewBestEdit from './Page/ReviewBest/Edit';
import SearchLogs from './Page/Search/Logs';
import OrganizationList from './Page/Organization/List';
import OrganizationEdit from './Page/Organization/Edit';
import OrganizationNew from './Page/Organization/New';
import LectureWaitingList from './Page/Lecture/Waiting/List';
import PostsList from './Page/Boards/Post/List';
import PostNew from './Page/Boards/Post/New';
import NoticeList from './Page/Boards/Notice/List';
import EventList from './Page/Boards/Event/List';
import OfflineClassesBySchedule from './Page/Lecture/OfflineClassesBySchedule/List';
import OfflineClassBySchedule from './Page/Lecture/OfflineClassesBySchedule/Detail';
import SurveyList from './Page/Survey/List';
import SurveyNew from './Page/Survey/New';
import QuestionList from './Page/Survey/Question/List';
import QuestionNew from './Page/Survey/Question/New';
import SurveyStatistics from './Page/Survey/Statistics';
import LectureLikeList from './Page/Lecture/Like/List';
import BlogList from './Page/Boards/Blog/List';
import InterestedCompanyList from './Page/User/InterestedCompany/List';
import InterestedCompanyNew from './Page/User/InterestedCompany/Detail';

function App() {
  return (
    <BrowserRouter>
      <Login>
        <Navigator>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <AdminRoute exact path="/dashboard" component={Dashboard} />
            <AdminRoute
              exact
              type="main"
              path={['/main', '/main/banner', '/main/banner/:type']}
              component={BannerList}
            />
            <AdminRoute
              exact
              type="main"
              path={['/main/banner/:type/new', '/main/banner/:type/new/:id']}
              component={BannerNew}
            />
            <AdminRoute exact type="user" path={['/user', '/user/list', '/user/foreign-list']} component={UserList} />
            <AdminRoute exact type="user" path={['/user/list/:id', '/user/foreign-list/:id']} component={UserDetail} />
            <AdminRoute exact type="user" path="/user/admin" component={UserAdmin} />
            <AdminRoute exact type="user" path="/user/interested-company" component={InterestedCompanyList} />
            <AdminRoute
              exact
              type="user"
              path={['/user/interested-company/new', '/user/interested-company/new/:id']}
              component={InterestedCompanyNew}
            />
            <AdminRoute exact type="user" path="/user/blacklist" component={UserBlacklist} />
            <AdminRoute exact type="class" path={['/lecture', '/lecture/video']} component={VideoList} />
            <AdminRoute
              exact
              type="class"
              path={['/lecture/video/new', '/lecture/video/new/:id']}
              component={VideoNew}
            />
            <AdminRoute exact type="class" path="/lecture/single" component={SingleList} />
            <AdminRoute exact type="class" path="/lecture/package" component={PackageList} />
            <AdminRoute exact type="class" path="/lecture/3rd" component={ThirdPartyList} />
            <AdminRoute exact type="class" path="/lecture/3rd/users/:id" component={ThirdPartyUserList} />
            <AdminRoute
              exact
              type="class"
              path={[
                '/lecture/single/new',
                '/lecture/single/new/:id',
                '/lecture/package/new',
                '/lecture/package/new/:id',
              ]}
              component={LectureNew}
            />
            <AdminRoute exact type="class" path="/lecture/waiting" component={LectureWaitingList} />
            <AdminRoute exact type="class" path="/lecture/like" component={LectureLikeList} />
            <AdminRoute
              exact
              type="class"
              path={['/lecture/user', '/lecture/foreign-user']}
              component={LectureUserList}
            />
            <AdminRoute
              exact
              type="class"
              path={['/lecture/user/:id', '/lecture/foreign-user/:id']}
              component={LectureUserDetail}
            />
            <AdminRoute exact type="class" path="/lecture/tutor" component={TutorList} />
            <AdminRoute
              exact
              type="class"
              path={['/lecture/tutor/new', '/lecture/tutor/new/:id']}
              component={TutorNew}
            />
            <AdminRoute exact type="class" path="/lecture/given" component={GivenLectures} />
            <AdminRoute exact type="class" path="/lecture/offline" component={OfflineClassesBySchedule} />
            <AdminRoute
              exact
              type="class"
              path="/lecture/:classId/attendance/:attendanceBoardId"
              component={OfflineClassBySchedule}
            />
            <AdminRoute exact type="class" path="/lecture/:classId/schedule" component={OfflineClassBySchedule} />
            <AdminRoute
              exact
              type="class"
              path={['/lecture/given/new', '/lecture/given/new/:id']}
              component={GivenLecture}
            />

            <AdminRoute exact type="survey" path="/survey/list" component={SurveyList} />
            <AdminRoute exact type="survey" path={['/survey/list/new', '/survey/list/new/:id']} component={SurveyNew} />
            <AdminRoute exact type="survey" path="/survey/question-list" component={QuestionList} />
            <AdminRoute
              exact
              type="survey"
              path={['/survey/question-list/new', '/survey/question-list/new/:id']}
              component={QuestionNew}
            />
            <AdminRoute exact type="survey" path="/survey/:id/statistics" component={SurveyStatistics} />

            <AdminRoute exact type="benefit" path="/sales/old" component={Sales} />
            <AdminRoute exact type="benefit" path="/sales/logs" component={SalesLogs} />
            <AdminRoute exact type="benefit" path="/sales/settlement" component={Settlement} />
            <AdminRoute
              exact
              type="benefit"
              path={['/promotion', '/promotion/coupon-issues', '/promotion/coupon-usages']}
              component={CouponList}
            />
            <AdminRoute
              exact
              type="benefit"
              path={['/promotion/coupon/new', '/promotion/coupon/new/:id']}
              component={CouponNew}
            />
            <AdminRoute exact type="record" path="/contents/records" component={Records} />
            <AdminRoute exact type="record" path={['/contents/records/new']} component={RecordsNew} />
            <AdminRoute exact type="record" path={['/contents/records/edit/:id']} component={RecordsNew} />
            <AdminRoute exact type="record" path="/contents/artists" component={Artists} />
            <AdminRoute exact type="record" path="/contents/agencies" component={Agencies} />
            <AdminRoute exact type="review" path="/review/lecture" component={Reviews} />
            <AdminRoute
              exact
              type="review"
              path={['/review/lecture/new', '/review/lecture/new/:id']}
              component={ReviewNew}
            />
            <AdminRoute exact type="review" path="/review/best" component={ReviewBestList} />
            <AdminRoute exact type="review" path="/review/request" component={ReviewRequest} />
            <AdminRoute exact type="review" path="/review/best/edit/:id" component={ReviewBestEdit} />
            <AdminRoute exact type="notice" path="/notice/list" component={NoticeList} />
            <AdminRoute exact type="event" path="/event/list" component={EventList} />
            <AdminRoute exact type="blog" path="/blog/list" component={BlogList} />
            <AdminRoute exact type="board" path="/board/posts" component={PostsList} />
            <AdminRoute exact type="notice" path={['/notice/new', '/notice/new/:id']} component={PostNew} />
            <AdminRoute exact type="event" path={['/event/new', '/event/new/:id']} component={PostNew} />
            <AdminRoute exact type="event" path={['/blog/new', '/blog/new/:id']} component={PostNew} />
            <AdminRoute exact type="board" path={['/board/posts/new', '/board/posts/new/:id']} component={PostNew} />
            <AdminRoute exact type="search" path="/search/recommends" component={SearchRecommends} />
            <AdminRoute exact type="search" path="/search/logs" component={SearchLogs} />
            <AdminRoute exact type="organization" path="/organization/list" component={OrganizationList} />
            <AdminRoute exact type="organization" path="/organization/new" component={OrganizationNew} />
            <AdminRoute exact type="organization" path="/organization/:id" component={OrganizationEdit} />
          </Switch>
        </Navigator>
      </Login>
    </BrowserRouter>
  );
}

export default App;
