import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useLocationState } from '../../../../Common/Util/CustomHooks';
import * as request from '../../../../Common/Util/Request';
import * as excel from '../../../../Common/Util/Excel';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import Swal from 'sweetalert2';
import { createTheme, Input, MenuItem, Select, ThemeProvider } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';

function LectureUserList(props) {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const isForeign = useMemo(() => location.pathname === '/lecture/foreign-user', [location.pathname]);

  const [locationState, setLocationState] = useLocationState({
    startDate: undefined,
    endDate: undefined,
    searchOption: 'username',
    searchText: '',
    filters: [],
    selectTxStatus: '',
    userOption: '',
  });
  const { startDate, endDate, filters, selectTxStatus, userOption } = locationState;
  const [searchOption, setSearchOption] = useState(locationState.searchOption);
  const [searchText, setSearchText] = useState(locationState.searchText);
  const [isDownloading, setIsDownloading] = useState(null);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchOption,
      searchText,
      filters: searchText ? [{ id: searchOption, value: searchText }] : [],
    });
  }, [setLocationState, searchOption, searchText]);

  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);

  const loadUsers = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        selectTxStatus: selectTxStatus || undefined,
        userOption: userOption || undefined,
        korean: !isForeign,
        filtered: JSON.stringify(filters),
      };

      if (startDate) {
        params.start_date = moment(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = moment(endDate).format('YYYY-MM-DD');
      }

      return request.lectureUsers(params).then(({ userClasses }) => userClasses);
    },
    [isForeign, startDate, endDate, filters],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadUsers({ page: pageIndex + 1, limit: pageSize }).then((users) => {
        setUsers(users.rows);
        setTotal(users.count);
      });
    },
    [loadUsers],
  );

  const txStatus = useMemo(
    () => ({
      ready: '결제대기', // 무통장입금대기
      paid: '결제',
      cancelled: '환불',
      partial_cancelled: '부분환불',
    }),
    [],
  );

  const columns = useMemo(
    () => [
      { Header: '회원ID', accessor: 'FK_user.id' },
      { Header: '닉네임', accessor: 'FK_user.nickname' },
      { Header: '이름', accessor: 'FK_user.name' },
      {
        Header: '탈퇴',
        accessor: 'FK_user.deletedAt',
        Cell: ({ value: deletedAt }) => (deletedAt ? '탈퇴' : '가입'),
      },
      { Header: '강의ID', accessor: 'FK_class.id' },
      { Header: '강의명', accessor: 'FK_class.title' },
      {
        Header: '구분',
        accessor: 'FK_class.listen_type',
        Cell: ({ value: listen_type }) => <FormattedMessage id={'ID_LISTEN_TYPE_' + listen_type.toUpperCase()} />,
      },
      {
        Header: '결제상태',
        accessor: 'FK_tx.status',
        Cell: ({ value }) => txStatus[value],
      },
      {
        Header: '결제일',
        accessor: 'FK_tx.date_paid_at',
        Cell: (data) => {
          const userClass = data.row.original;
          const transaction = userClass.FK_tx;
          return transaction.deletedAt || !transaction.date_paid_at
            ? '-'
            : moment.utc(transaction.date_paid_at).format('YYYY-MM-DD');
        },
      },
      {
        Header: '유료/무료 구매 횟수',
        accessor: 'FK_user.Transactions',
        Cell: ({ value }) => {
          const paid = value.filter(
            (tx) => (tx.status === 'paid' || tx.status === 'partial_cancelled') && tx.paid_amount > 0,
          );
          const free = value.filter((tx) => tx.status === 'paid' && tx.paid_amount === 0);
          return paid.length + ' / ' + free.length;
        },
      },
      {
        Header: '콘텐츠 지급일',
        Cell: (data) => {
          const userClass = data.row.original;
          const transaction = userClass.FK_tx;
          return transaction.deletedAt ? moment.utc(transaction.createdAt).format('YYYY-MM-DD') : '-';
        },
      },
      {
        Header: '수강시작일',
        accessor: 'start_date',
        Cell: ({ value: start_date }) => (start_date ? moment.utc(start_date).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '수강종료일',
        accessor: 'end_date',
        Cell: ({ value: end_date }) => (end_date ? moment.utc(end_date).format('YYYY-MM-DD') : '-'),
      },
      {
        Header: '남은일수',
        accessor: 'count_left_day',
        Cell: ({ value: count_left_day }) => (count_left_day ? count_left_day + '일' : '-'),
      },
      {
        Header: '정가',
        accessor: 'FK_class.price_original',
        Cell: ({ value: price_original }) => price_original.toPrice(),
      },
      {
        Header: '결제금액',
        accessor: 'FK_tx.paid_amount',
        Cell: (data) => {
          const userClass = data.row.original;
          const transaction = userClass.FK_tx;
          return (transaction.paid_amount - transaction.canceled_amount).toPrice();
        },
      },
      {
        Header: '진도율',
        accessor: 'completion_rate',
        Cell: ({ value: completion_rate }) => (completion_rate ? completion_rate + '%' : '-'),
      },
    ],
    [txStatus],
  );

  const onClickCSV = useCallback(() => {
    const startDateMoment = startDate ? moment(startDate) : moment('2020-01-01');
    const endDateMoment = endDate ? moment(endDate) : moment();
    if (endDateMoment.diff(startDateMoment, 'days') > 365) {
      Swal.fire({
        title: '기간 설정 오류',
        text: '다운로드 가능한 기간은 최대 1년입니다. 기간을 다시 설정해주세요.',
        icon: 'error',
      });
      return;
    }

    setIsDownloading(true);
    loadUsers({ page: 1, limit: 9999999 }) // temp
      .then((users) => {
        const data = users.rows.map((user) => {
          const classModel = user.FK_class;
          const sectionProgresses = {};
          let number = 1;
          switch (classModel.class_type) {
            case 'single':
              for (let chapter of classModel.ClassChapters) {
                for (let section of chapter.ClassSections) {
                  sectionProgresses[number + '차시'] = section.section_completion_rate
                    ? section.section_completion_rate + '%'
                    : '-';
                  number++;
                }
              }
              break;
            case 'package':
              const classSinglePackages = classModel.ClassSingle_ClassPackages;
              for (const classSinglePackage of classSinglePackages) {
                const classModelInPackage = classSinglePackage.FK_class_single;

                for (const chapter of classModelInPackage.ClassChapters) {
                  const sections = chapter.ClassSections;
                  for (const section of sections) {
                    sectionProgresses[`${number}차시`] = section.section_completion_rate
                      ? section.section_completion_rate + '%'
                      : '-';
                    number++;
                  }
                }
              }
              break;

            default:
              break;
          }
          const txStatus = {
            ready: '결제대기',
            paid: '결제',
            partial_cancelled: '부분환불',
            cancelled: '환불',
          };
          const classLargeCategory = {
            online: ['online', 'roundOnline', 'foreigner', 'compulsory'],
            offline: ['offline'],
          };
          const classSubCategory = {
            round: ['offline', 'roundOnline'],
            normal: ['online', 'foreigner'],
            compulsory: ['compulsory'],
          };
          return {
            회원ID: user.FK_user.id,
            닉네임: user.FK_user.nickname,
            이름: user.FK_user.name,
            탈퇴: user.FK_user.deletedAt ? '탈퇴' : '가입',
            '혜택 수신 동의': user.FK_user.is_marketing_on ? '동의' : '비동의',
            국가정보: user.FK_user.FK_country?.name_kor || '-',
            이메일: user.FK_user.email,
            핸드폰번호: user.FK_user.phone ? user.FK_user.phone.toPhone() : '-',
            강의ID: user.FK_class.id,
            강의명: user.FK_class.title,
            수강옵션: user.FK_class_offline_schedule_id
              ? `${user.FK_class_offline_schedule.class_round && `${user.FK_class_offline_schedule.class_round}기`} | ${
                  !!user.FK_class_offline_schedule.title
                    ? `${user.FK_class_offline_schedule.title} |`
                    : '옵션명 없음 | '
                } ${user.FK_class_offline_schedule.class_dates
                  .split('')
                  .map((classDate, index) => {
                    return +classDate !== 0
                      ? intl.formatMessage({
                          id: 'ID_WEEKDAY_' + (index + 1),
                        })
                      : '';
                  })
                  .join('')} | ${user.FK_class_offline_schedule.class_start_time} | ${
                  user.FK_class_offline_schedule.start_date
                }~${user.FK_class_offline_schedule.end_date}
              `
              : user.FK_class_online_schedule_id
              ? `${user.FK_class_online_schedule.class_round}기 | ${
                  !!user.FK_class_online_schedule.title ? `${user.FK_class_online_schedule.title} |` : '옵션명 없음 | '
                } ${user.FK_class_online_schedule.start_date}~${user.FK_class_online_schedule.end_date}${
                  user.FK_class_online_schedule.class_start_time
                    ? ` | ${user.FK_class_online_schedule.class_start_time}~${user.FK_class_online_schedule.class_end_time}`
                    : ` | 강의 시간 없음`
                }`
              : ``,
            강사명: user.FK_class.Class_Tutors.map((Class_Tutor) => Class_Tutor.FK_tutor.name).join(','),
            // 구분: intl.formatMessage({
            //   id: 'ID_LISTEN_TYPE_' + user.FK_class.listen_type.toUpperCase(),
            // }),
            '구분(대분류)': classLargeCategory.online.includes(user.FK_class.listen_type)
              ? intl.formatMessage({
                  id: 'ID_LISTEN_TYPE_LARGE_ONLINE',
                })
              : intl.formatMessage({
                  id: 'ID_LISTEN_TYPE_LARGE_OFFLINE',
                }),
            '구분(소분류)': classSubCategory.round.includes(user.FK_class.listen_type)
              ? intl.formatMessage({
                  id: 'ID_LISTEN_TYPE_SUB_ROUND',
                })
              : classSubCategory.normal.includes(user.FK_class.listen_type)
              ? intl.formatMessage({
                  id: 'ID_LISTEN_TYPE_SUB_NORMAL',
                })
              : intl.formatMessage({
                  id: 'ID_LISTEN_TYPE_SUB_COMPULSORY',
                }),
            결제상태: txStatus[user.FK_tx.status],
            결제일:
              user.FK_tx.deletedAt || !user.FK_tx.date_paid_at
                ? '-'
                : moment.utc(user.FK_tx.date_paid_at).format('YYYY-MM-DD'),
            '유료 구매 횟수': user.FK_user.Transactions.filter(
              (tx) => (tx.status === 'paid' || tx.status === 'partial_cancelled') && tx.paid_amount > 0,
            ).length,
            '무료 구매 횟수': user.FK_user.Transactions.filter((tx) => tx.status === 'paid' && tx.paid_amount === 0)
              .length,
            콘텐츠지급일: user.FK_tx.deletedAt ? moment.utc(user.FK_tx.createdAt).format('YYYY-MM-DD') : '-',
            수강시작일: user.start_date ? moment.utc(user.start_date).format('YYYY-MM-DD') : '-',
            수강종료일: user.end_date ? moment.utc(user.end_date).format('YYYY-MM-DD') : '-',
            남은일수: user.count_left_day ? user.count_left_day + '일' : '-',
            정가: user.FK_class.price_original,
            결제금액: user.FK_tx.paid_amount - user.FK_tx.canceled_amount,
            '강의자료 다운로드': user.is_download_attached_file ? '다운로드 후' : '다운로드 전',
            진도율: user.completion_rate ? user.completion_rate + '%' : '-',
            ...sectionProgresses,
          };
        });
        excel.downloadXLSX(data, `${isForeign ? '해외' : '국내'}수강회원관리_${moment.utc().format('YYYYMMDD')}`);
        setIsDownloading(false);
      })
      .catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          title: '다운로드 실패',
          text: '다운로드에 실패하였습니다. 다시 시도해주세요.',
        });
        setIsDownloading(false);
      });
  }, [intl, isForeign, loadUsers]);

  const onClickRow = useCallback(
    (row) => {
      const user = row.original;
      history.push(`${location.pathname}/${user.id}`, { user });
    },
    [history, location],
  );

  const ejeTheme = createTheme({
    palette: {
      pink: {
        light: '#f46981',
        main: '#F24462',
        dark: '#a92f44',
        contrastText: '#fff',
      },
      pinkHover: {
        light: '#ff93ab',
        main: '#FF7896',
        dark: '#b25469',
        contrastText: '#000',
      },
    },
  });

  return (
    <div className="lecture-user-list">
      <ThemeProvider theme={ejeTheme}>
        <div className={`lecture-user-list__top`}>
          <div className="lecture-user-list__date">
            기간
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate && dayjs(startDate)}
                maxDate={endDate && dayjs(endDate)}
                onChange={(value) => {
                  setLocationState({
                    startDate: dayjs(value).format('YYYY-MM-DD'),
                  });
                }}
                label={startDate && 'From'}
                disableFuture
                views={['year', 'month', 'day']}
              />
              -
              <DatePicker
                value={endDate && dayjs(endDate)}
                minDate={startDate && dayjs(startDate)}
                onChange={(value) => {
                  setLocationState({
                    endDate: dayjs(value).format('YYYY-MM-DD'),
                  });
                }}
                label={endDate && 'From'}
                disableFuture
                views={['year', 'month', 'day']}
              />
            </LocalizationProvider>
          </div>
          <div className="lecture-user-list__search">
            <Select
              value={searchOption}
              onChange={(e) => setSearchOption(e.target.value)}
              displayEmpty
              color="pink"
              size="small"
            >
              <MenuItem value={'username'} sx={{ fontSize: '0.875rem' }}>
                회원 이름
              </MenuItem>
              <MenuItem value={'nickname'} sx={{ fontSize: '0.875rem' }}>
                닉네임
              </MenuItem>
              <MenuItem value={'email'} sx={{ fontSize: '0.875rem' }}>
                이메일
              </MenuItem>
              <MenuItem value={'phone'} sx={{ fontSize: '0.875rem' }}>
                핸드폰번호
              </MenuItem>
              <MenuItem value={'id'} sx={{ fontSize: '0.875rem' }}>
                회원ID
              </MenuItem>
              <MenuItem value={'title'} sx={{ fontSize: '0.875rem' }}>
                강의명
              </MenuItem>
              <MenuItem value={'name'} sx={{ fontSize: '0.875rem' }}>
                강사명
              </MenuItem>
            </Select>
            <Input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onClickSearch();
              }}
              color="pink"
              placeholder="검색어를 입력하세요."
            />
            <Button.MUI
              onClick={onClickSearch}
              variant="outlined"
              endIcon={<SearchIcon fontSize="small" />}
              color="pink"
              sx={{ height: '2rem' }}
            >
              검색
            </Button.MUI>
          </div>
        </div>
        <div className={`lecture-user-list__filter`}>
          <div>
            <span>결제상태: </span>
            <Select
              value={selectTxStatus}
              onChange={(e) => setLocationState({ selectTxStatus: e.target.value })}
              size="small"
              color="pink"
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              <MenuItem value={''} sx={{ fontSize: '0.875rem' }}>
                전체
              </MenuItem>
              <MenuItem value={'paid'} sx={{ fontSize: '0.875rem' }}>
                결제
              </MenuItem>
              <MenuItem value={'ready'} sx={{ fontSize: '0.875rem' }}>
                결제대기
              </MenuItem>
              <MenuItem value={'cancelled'} sx={{ fontSize: '0.875rem' }}>
                환불
              </MenuItem>
              <MenuItem value={'partial_cancelled'} sx={{ fontSize: '0.875rem' }}>
                부분환불
              </MenuItem>
            </Select>
          </div>
          <div>
            <span>회원 종류: </span>
            <Select
              value={userOption}
              onChange={(e) => setLocationState({ userOption: e.target.value })}
              size="small"
              color="pink"
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              <MenuItem value={''} sx={{ fontSize: '0.875rem' }}>
                전체
              </MenuItem>
              <MenuItem value={'user'} sx={{ fontSize: '0.875rem' }}>
                일반 회원
              </MenuItem>
              <MenuItem value={'administrator'} sx={{ fontSize: '0.875rem' }}>
                관리자 회원
              </MenuItem>
            </Select>
          </div>
        </div>
        <div className="lecture-user-list__total">
          총 수강회원수 {total}
          <button
            className={
              'lecture-user-list__excel--btn' +
              (filters.length < 1 && !startDate && !endDate ? ' lecture-user-list__excel__disabled--btn' : '')
            }
            style={{
              cursor: isDownloading ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              filters.length < 1 && !startDate && !endDate
                ? Swal.fire({
                    title: '기간 설정 또는 검색 후 다운로드 가능합니다.',
                    text: '좌측 상단에서 기간 설정 또는 검색 후 다운로드해 주세요.',
                    icon: 'info',
                    confirmButtonColor: '#F24462',
                  })
                : !isDownloading
                ? onClickCSV()
                : undefined;
            }}
          >
            {isDownloading ? 'Loading...' : '액셀 다운로드'}
          </button>
        </div>
        <Table
          data={users}
          columns={columns}
          total={total}
          pageSize={20}
          fetchData={fetchData}
          onClickRow={onClickRow}
        />
      </ThemeProvider>
    </div>
  );
}

export default LectureUserList;
