import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { usePagination, useRowSelect, useTable } from 'react-table';
import useCheckbox from './useCheckbox';
import './index.scss';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DragHandleIcon from '@mui/icons-material/DragHandle';

function Table(props) {
  const {
    columns,
    data,
    total,
    pageSize: controlledPageSize = 10,
    usesLocation = true,
    fetchData,
    onClickRow,
    onChangeSelectedRows,
    droppableId,
    pauseDragDrop,
  } = props;

  const history = useHistory();
  const location = usesLocation ? useLocation() : {};
  const { pageIndex: locationPageIndex } = location.state || {};

  const controlledPageCount = useMemo(() => Math.ceil(total / controlledPageSize), [total, controlledPageSize]);
  const selectable = !!onChangeSelectedRows;
  const isDragDropTable = !!droppableId;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: usesLocation ? locationPageIndex || 0 : 0,
        pageSize: controlledPageSize,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    selectable ? useRowSelect : () => {},
    selectable ? useCheckbox : () => {},
    (hooks) => {
      hooks.getRowProps.push((props, ref) => ({
        ...props,
        className: ref.row.original.deletedAt || ref.row.original.FK_user?.deletedAt ? 'deleted' : '',
      }));
    },
  );

  useEffect(() => {
    if (!usesLocation || locationPageIndex === pageIndex) {
      fetchData && fetchData({ pageIndex, pageSize });
    } else {
      history.replace({ ...location, state: { ...location.state, pageIndex } });
    }
  }, [usesLocation, fetchData, locationPageIndex, pageIndex, pageSize]);

  useEffect(() => {
    if (onChangeSelectedRows) {
      onChangeSelectedRows(selectedFlatRows);
    }
  }, [onChangeSelectedRows, selectedFlatRows]);

  return (
    <>
      <div className="table--wrap">
        <table
          {...getTableProps()}
          className={`${isDragDropTable ? 'dragging__table' : ''} ${pauseDragDrop ? 'paused-dragging__table' : ''}`}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {isDragDropTable && (
                  <th
                    style={{
                      width: 30,
                      backgroundColor: '#f2f2f2',
                    }}
                  >
                    <UnfoldMoreIcon />
                  </th>
                )}
                {headerGroup.headers.map((column) => {
                  const style =
                    column.width && column.width !== 150
                      ? { width: column.width, backgroundColor: '#f2f2f2' }
                      : { backgroundColor: '#f2f2f2' };

                  return (
                    <th
                      {...column.getHeaderProps({
                        style,
                        ...column.headerProps,
                      })}
                    >
                      <div
                        className={`table-header-top__container ${
                          column?.onClickHeader ? 'click-available-header' : ''
                        }`}
                        onClick={column?.onClickHeader}
                      >
                        {column.render('Header')}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {isDragDropTable ? (
            <Droppable droppableId={droppableId || 'rows'} key={droppableId || 'rows'}>
              {(provided, snapshot) => (
                <tbody {...getTableBodyProps()} ref={provided.innerRef} {...provided.droppableProps}>
                  {props.fixedRow}
                  {page.map((row, idx) => {
                    prepareRow(row);
                    return (
                      <Draggable
                        isDragDisabled={props.pauseDragDrop}
                        key={JSON.stringify(row.original)}
                        draggableId={JSON.stringify(row.original)}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <tr
                            {...row.getRowProps()}
                            onClick={(e) => {
                              onClickRow && onClickRow(row, e);
                            }}
                            ref={provided.innerRef}
                            className={snapshot.isDragging ? 'dragged-row' : ''}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>
                              <DragHandleIcon
                                style={{
                                  color: pauseDragDrop ? 'rgba(115,115,115,0.25)' : '#dc5464',
                                }}
                              />
                            </td>
                            {row.cells.map((cell) => {
                              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {isDragDropTable ? provided.placeholder : null}
                </tbody>
              )}
            </Droppable>
          ) : (
            <tbody {...getTableBodyProps()}>
              {props.fixedRow}
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={(e) => {
                      if (onClickRow) {
                        onClickRow(row, e);
                      }
                    }}
                  >
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {(canPreviousPage || canNextPage) && (
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>
          {[...new Array(5)]
            .map((_, index) => {
              if (pageIndex <= 1) {
                return index;
              } else if (1 < pageIndex && pageIndex < pageCount - 2) {
                return index + pageIndex - 2;
              } else {
                return index + pageCount - 5;
              }
            })
            .filter((index) => 0 <= index && index < pageCount)
            .map((index) => (
              <span key={index} className={index === pageIndex ? 'selected' : null} onClick={() => gotoPage(index)}>
                {index + 1}
              </span>
            ))}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>
        </div>
      )}
    </>
  );
}

export default Table;
