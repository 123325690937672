import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

function Menu(props) {
  const location = useLocation();

  const menuId = props.path.split('/')[1];
  const selectedMenuId = location.pathname.split('/')[1];
  const selected = menuId === selectedMenuId;

  return (
    <div className={'menu' + (selected ? ' menu--selected' : '')}>
      <Link className="menu__name" to={props.path}>
        <FormattedMessage id={'ID_MENU_' + menuId.toUpperCase()} />
      </Link>
      {selected && props.children && <div className="menu__sub-menus">{props.children}</div>}
    </div>
  );
}

export default Menu;
