import React, { useCallback, useEffect, useState } from 'react';
import * as request from '../../../../../Common/Util/Request';
import {
  createTheme,
  Input,
  MenuItem,
  Select,
  Button,
  ThemeProvider,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableRow,
  Checkbox,
  TableCell,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import EnhancedTableHead from '../../../../../Common/Component/EnhancedTableHead';
import PropTypes from 'prop-types';
import './index.scss';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const Index = (props) => {
  const { targetInfos } = props;

  const [coupons, setCoupons] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedCouponIds, setSelectedCouponIds] = useState([]);
  const [searchOption, setSearchOption] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const ALL_ROWS_PER_PAGE = 9_999_999_999;

  const onClickSearch = useCallback(() => {
    setFilters(searchText ? [{ id: searchOption, value: searchText }] : []);
    setPage(0);
  }, [searchText, searchOption]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      request
        .coupons({
          page: pageIndex + 1,
          limit: pageSize,
          filtered: JSON.stringify(filters),
          allData: false,
        })
        .then(({ coupons }) => {
          setCoupons(coupons.rows);
          setTotal(coupons.count);
        });
    },
    [filters],
  );

  useEffect(() => {
    fetchData({ pageIndex: page, pageSize: rowsPerPage });
  }, [page, rowsPerPage, filters]);

  const validateCouponWithAlert = useCallback(
    (selectedCoupon) => {
      const couponUserIssued = selectedCoupon.User_Coupons.length;
      const couponIssued = selectedCoupon.count_issue;
      const isInfiniteIssued = selectedCoupon.count_issue === null;
      const isDaysType = selectedCoupon.valid_date_type === 'days';
      const koNowDate = dayjs(dayjs().format('YYYY-MM-DD'));

      if (!isInfiniteIssued && couponUserIssued >= couponIssued) {
        alert('쿠폰 발급 가능 개수가 가득찬 쿠폰입니다. 쿠폰 발급 가능 개수를 확인해주세요.');
        return false;
      }

      if (!isInfiniteIssued && targetInfos.length > couponIssued - couponUserIssued) {
        alert('선택된 회원 수 대비 쿠폰 발급 가능 개수가 부족합니다. 쿠폰 발급 가능 개수를 확인해주세요.');
        return false;
      }

      if (!isDaysType && dayjs(selectedCoupon.end_date).isBefore(koNowDate)) {
        alert('쿠폰 사용 기간이 지난 쿠폰입니다. 쿠폰 사용 기간을 확인해주세요.');
        return false;
      }

      if (
        !isDaysType &&
        dayjs(selectedCoupon.end_date).isSameOrBefore(koNowDate.add(2, 'd')) &&
        dayjs(selectedCoupon.end_date).isSameOrAfter(koNowDate)
      ) {
        alert('쿠폰 사용 기한이 짧은 쿠폰입니다(기한 3일 이내). 기한 확인 후 전송해주세요.');
        return true;
      }

      return true;
    },
    [coupons, selectedCouponIds, targetInfos],
  );

  const handleClick = useCallback(
    (event, coupon) => {
      const selectedIndex = selectedCouponIds.indexOf(coupon.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        const validateResult = validateCouponWithAlert(coupon);
        if (!validateResult) return;

        newSelected = newSelected.concat(selectedCouponIds, coupon.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedCouponIds.slice(1));
      } else if (selectedIndex === selectedCouponIds.length - 1) {
        newSelected = newSelected.concat(selectedCouponIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedCouponIds.slice(0, selectedIndex),
          selectedCouponIds.slice(selectedIndex + 1),
        );
      }
      setSelectedCouponIds(newSelected);
    },
    [selectedCouponIds, coupons],
  );

  const isSelected = (name) => selectedCouponIds.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const alertSwal = useCallback(({ type }) => {
    switch (type) {
      case 'alreadyAll':
        Swal.fire({
          title: '이미 모든 회원이 해당 쿠폰을 보유하고 있습니다.',
          icon: 'warning',
          confirmButtonText: '확인',
          confirmButtonColor: '#F24462',
        });
        break;
      case 'success':
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">성공적으로 발급되었습니다.</div>`,
          position: 'center',
          icon: 'success',
        }).then(() => {
          window.location.reload();
        });
        break;
      case 'fail':
        Swal.fire({
          html: `<div style="margin-top:20px; font-weight: bold;">쿠폰 발급에 실패했습니다.</div>`,
          toast: true,
          position: 'center',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          icon: 'error',
          type: 'error',
        });
      default:
        break;
    }
  }, []);

  const onClickSend = useCallback(() => {
    const selectedCoupon = coupons.find((coupon) => selectedCouponIds[0] === coupon.id);

    const couponUsers = selectedCoupon.User_Coupons.map((userCoupon) => ({
      id: userCoupon.FK_user.id,
      name: userCoupon.FK_user.name,
    }));
    const alreadyHaveUserInfos = couponUsers.filter((couponUser) =>
      targetInfos.some((targetInfo) => targetInfo.userId === couponUser.id),
    );
    const isExistAlreadyHaveUser = alreadyHaveUserInfos.length > 0;

    if (targetInfos.length === alreadyHaveUserInfos.length) {
      alertSwal({ type: 'alreadyAll' });
      return;
    }

    Swal.fire({
      title: '쿠폰 발급 안내를 발송하시겠습니까?',
      html: `<div>선택된 ${
        isExistAlreadyHaveUser ? `<span style='font-weight: bold;'>${targetInfos.length}명</span>의` : ''
      } 회원 ${isExistAlreadyHaveUser ? '중 ' : ''}<span style='color: red; font-weight: bold;'>${
        targetInfos.length - alreadyHaveUserInfos.length
      }명</span>에게 쿠폰 발급 및 안내 메시지를 발송합니다.
      <br />
      <br />
      ${
        isExistAlreadyHaveUser
          ? `
      (<span style='font-weight: bold;'>${
        alreadyHaveUserInfos.length
      }명</span>의 회원은 이미 해당 쿠폰을 보유하고 있어 발급되지 않습니다.)<br />
      (보유 회원 목록: ${alreadyHaveUserInfos.map((user) => user.id + '-' + user.name).join(', ')})
      <br />
      <br />
          `
          : ''
      }
      * 쿠폰명: <span style='font-weight: bold;'>${selectedCoupon.name}</span>
      </div> 
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '안내 발송',
      confirmButtonColor: '#F24462',
      cancelButtonText: '취소',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        // 쿠폰 발급 및 안내 발송
        const couponData = {
          couponId: selectedCoupon.id,
          targetInfos: targetInfos.map((targetInfo) => ({
            userId: targetInfo.userId,
            lectureId: targetInfo.lectureId,
            lectureTitle: targetInfo.lectureTitle,
          })),
        };

        try {
          const result = await request.issueCouponToLikes(couponData);

          if (result.success) {
            alertSwal({ type: 'success' });
          }
        } catch (err) {
          alertSwal({ type: 'fail' });
        }
      }
    });
  }, [targetInfos, selectedCouponIds]);

  const headCells = [
    { id: 'name', label: '쿠폰명', align: 'center', minWidth: 40 },
    { id: 'code', label: '쿠폰코드', align: 'center', minWidth: 40 },
    { id: 'createdAt', label: '쿠폰 생성일', align: 'center', minWidth: 80 },
    { id: 'validDays', label: '쿠폰 사용기간', align: 'center', minWidth: 200 },
    { id: 'couponUsed', label: '사용/발급수', align: 'center', minWidth: 70 },
    { id: 'couponIssue', label: '쿠폰 발급 가능 개수', align: 'center', minWidth: 70 },
  ];

  const ejeTheme = createTheme({
    palette: {
      pink: {
        light: '#f46981',
        main: '#F24462',
        dark: '#a92f44',
        contrastText: '#fff',
      },
      pinkHover: {
        light: '#ff93ab',
        main: '#FF7896',
        dark: '#b25469',
        contrastText: '#000',
      },
    },
  });

  return (
    <ThemeProvider theme={ejeTheme}>
      <div className={`coupon--send--wrap`}>
        <div className={`coupon--send--header`}>
          <div className={`coupon--send--info`}>
            <span>
              ✅ 선택된 회원 수: <span style={{ fontWeight: 'bold', color: '#F24462' }}>{targetInfos.length}</span>명
            </span>
          </div>
          <div className={`coupon--send--search--wrap`}>
            <Select
              value={searchOption}
              onChange={(e) => setSearchOption(e.target.value)}
              size="small"
              color="pink"
              sx={{
                marginLeft: '0.5rem',
              }}
            >
              <MenuItem value="name" sx={{ fontSize: '0.875rem' }}>
                쿠폰명
              </MenuItem>
              <MenuItem value="code" sx={{ fontSize: '0.875rem' }}>
                쿠폰코드
              </MenuItem>
            </Select>

            <Input
              value={searchText}
              placeholder="검색어를 입력하세요."
              color="pink"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickSearch();
                }
              }}
            />

            <Button
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                onClickSearch();
              }}
              color="pink"
              sx={{
                height: '2rem',
              }}
            >
              검색 <SearchIcon fontSize="small" sx={{ marginLeft: '0.25rem' }} />
            </Button>
          </div>
        </div>

        <div className={`coupon--send--main`}>
          <div className={`coupon--send--total`}>전체: {total}</div>

          <div className={`coupon--table--wrap`}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table" color="pink">
                <EnhancedTableHead headCells={headCells} rowCount={total} numSelected={selectedCouponIds.length} />

                <TableBody>
                  {coupons.map((coupon) => {
                    const isItemSelected = isSelected(coupon.id);
                    const labelId = `enhanced-table-checkbox-${coupon.id}`;

                    const couponUserIssued = coupon.User_Coupons.length;
                    const couponIssued = coupon.count_issue;
                    const koNowDate = dayjs(dayjs().format('YYYY-MM-DD'));

                    const isOverdue =
                      coupon.valid_date_type === 'manual' ? dayjs(coupon.end_date).isBefore(koNowDate) : false;
                    const isMaxCoupon =
                      couponIssued === null
                        ? false
                        : couponUserIssued >= couponIssued || targetInfos.length > couponIssued - couponUserIssued;
                    const isImminent =
                      dayjs(coupon.end_date).isSameOrBefore(koNowDate.add(2, 'd')) &&
                      dayjs(coupon.end_date).isSameOrAfter(koNowDate);

                    const isDisabled = isOverdue || isMaxCoupon || (selectedCouponIds.length > 0 && !isItemSelected);

                    return (
                      <TableRow
                        key={coupon.id}
                        hover
                        onClick={(event) =>
                          !isItemSelected && selectedCouponIds.length > 0 ? null : handleClick(event, coupon)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={ALL_ROWS_PER_PAGE}
                        selected={isItemSelected}
                        className={`${!isDisabled && isImminent ? 'imminent' : ''} ${isDisabled ? 'disabled' : ''}`}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="pink"
                            checked={isItemSelected}
                            disabled={isDisabled}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">{coupon.name}</TableCell>
                        <TableCell align="center">{coupon.code}</TableCell>
                        <TableCell align="center">
                          {coupon.createdAt ? dayjs(coupon.createdAt).format('YYYY년 MM월 DD일') : '-'}
                        </TableCell>
                        <TableCell align="center">
                          {coupon.valid_date_type === 'days'
                            ? `발급일로부터 ${coupon.valid_days}일`
                            : `${dayjs(coupon.start_date).format('YYYY년 MM월 DD일')} ~ ${dayjs(coupon.end_date).format(
                                'YYYY년 MM월 DD일',
                              )}`}
                        </TableCell>
                        <TableCell align="center">
                          {coupon.User_Coupons.filter((user_coupon) => user_coupon.usedAt).length +
                            ' / ' +
                            coupon.User_Coupons.length}
                        </TableCell>
                        <TableCell align="center">{coupon.count_issue || '무제한'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, { value: ALL_ROWS_PER_PAGE, label: '전체' }]}
            rowsPerPage={rowsPerPage}
            count={total}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            labelRowsPerPage={'페이지당 표시 수 :'}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} / ${count !== -1 ? count : `more than ${to}`}`;
            }}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>

        <div className={`coupon--send--footer`}>
          <div className={`footer--button--wrap`}>
            <Button
              variant="contained"
              color="pink"
              endIcon={<SendIcon />}
              disabled={selectedCouponIds.length === 0 || targetInfos.length === 0}
              onClick={onClickSend}
              sx={{
                width: '12rem',
                height: '2.5rem',
                fontWeight: 'bold',
              }}
            >
              쿠폰 발급 및 안내 발송
            </Button>
            <Button
              variant="outlined"
              onClick={() => Swal.clickCancel()}
              color="pink"
              sx={{
                width: '12rem',
                height: '2.5rem',
                fontWeight: 'bold',
              }}
            >
              취소
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

Index.propTypes = {
  targetInfos: PropTypes.array.isRequired,
};

export default Index;
