import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Unauth from '../../Page/Unauth';

function AdminRoute(props) {
  const { type, component: Component, ...passThroughProps } = props;

  const admin = useSelector((state) => state.admin);

  return (
    <Route
      {...passThroughProps}
      component={(props) => {
        const notLoggedIn = !admin.id;
        if (notLoggedIn) {
          alert('로그인을 해주세요.');
          return null;
        }

        const authes = admin.User_Auths.map((User_Auth) => User_Auth.FK_auth.type);
        const isMaster = authes.includes('admin');
        const inaccessible = type && !authes.includes(type) && !isMaster;
        if (inaccessible) {
          return <Unauth />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default AdminRoute;
