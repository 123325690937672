import { Grid } from '@mui/material';
import StudentCard from './StudentCard';

const ListContainer = ({ students, classInfo, attendanceBoardInfo, expandAll = 'false', setExpandAll }) => {
  // console.log(students.length);
  return (
    <Grid container spacing={2}>
      {students.map((student, index) => {
        return (
          <StudentCard
            student={student}
            classInfo={classInfo}
            attendanceBoardInfo={attendanceBoardInfo}
            key={index}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
          />
        );
      })}
    </Grid>
  );
};

export default ListContainer;
