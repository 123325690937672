import { createTheme } from '@mui/material';

export const URL = {
  BASE: process.env.REACT_APP_BASE,
  API_SERVER: process.env.REACT_APP_API_SERVER,
  HOST: process.env.REACT_APP_HOST,
};

export const LECTURE = {
  ROUND_LISTEN_TYPES: ['offline', 'roundOnline', 'consulting'],
};

export const PAYMENT = {
  STATUS: {
    READY: 'ready',
    PAID: 'paid',
    PARTIAL_CANCELLED: 'partial_cancelled',
    CANCELLED: 'cancelled',
  },
  STATUS_TEXT: {
    ready: '결제대기',
    paid: '결제',
    partial_cancelled: '부분환불',
    cancelled: '환불',
  },
};

export const EJE_THEME = createTheme({
  palette: {
    pink: {
      light: '#f46981',
      main: '#F24462',
      dark: '#a92f44',
      contrastText: '#fff',
    },
    pinkHover: {
      light: '#ff93ab',
      main: '#FF7896',
      dark: '#b25469',
      contrastText: '#000',
    },
    darkerPink: {
      light: '#d13b55',
      main: '#b22e46',
      dark: '#8a2336',
      contrastText: '#fff',
    },
  },
});
