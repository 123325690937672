import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SortHeader from './SortIcons';

function EnhancedTableHead(props) {
  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    disableSortHeaders,
    color,
    useCheckbox = false,
  } = props;

  const sortable = !!onRequestSort;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {useCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color={color || 'primary'}
              indeterminate={numSelected > 0 && numSelected !== rowCount}
              checked={numSelected > 0 && numSelected === rowCount}
              disabled={!onSelectAllClick}
              onClick={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          const disableSort =
            !sortable || (disableSortHeaders?.length > 0 ? disableSortHeaders.includes(headCell.id) : true);

          return (
            <TableCell
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              style={{ minWidth: headCell.minWidth, fontWeight: 'bold' }}
            >
              <TableSortLabel
                disabled={disableSort}
                active={active}
                direction={active ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() =>
                  disableSort ? null : <SortHeader isActive={active} order={active ? order : 'asc'} />
                }
                sx={{
                  lineHeight: '1rem',
                  '& > span': {
                    opacity: orderBy === headCell.id ? 1 : 0.5,
                  },
                  '&:hover': {
                    color: '#000000 !important',
                    '& > span': {
                      opacity: 1,
                    },
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  useCheckbox: PropTypes.bool,
  onSelectAllClick: (props, propName, componentName) => {
    if (props.useCheckbox && typeof props[propName] !== 'function') {
      return new Error(`${propName} is required in ${componentName} when useCheckbox is true.`);
    }
    return null;
  },
  numSelected: (props, propName, componentName) => {
    if (props.useCheckbox && typeof props[propName] !== 'number') {
      return new Error(`${propName} is required in ${componentName} when useCheckbox is true.`);
    }
    return null;
  },
};

export default EnhancedTableHead;
