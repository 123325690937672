import { useLocationState } from '../../../../Common/Util/CustomHooks';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as request from '../../../../Common/Util/Request';
import moment from 'moment/moment';
import * as Button from '../../../../Common/Component/Button';
import Table from '../../../../Common/Component/Table';
import './index.scss';
import { DragDropContext } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import { Input, MenuItem, Select, ThemeProvider } from '@mui/material';
import { EJE_THEME } from '../../../../Common/Constant';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';

function BlogList() {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    endDate: null,
    searchType: 'title',
    searchText: '',
    filters: [],
  });
  const { startDate, endDate, filters } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const [total, setTotal] = useState(0);
  const [topPosts, setTopPosts] = useState([]);
  const [posts, setPosts] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingNormal, setIsDraggingNormal] = useState(false);

  const TOP_NOTICES_MAX = 3;

  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText: searchType === 'phone' ? searchText.replace(/^0/, '82') : searchText,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  /** 테이블 설정 */
  const loadPosts = useCallback(
    ({ page, limit }) => {
      const params = {
        page,
        limit,
        filtered: JSON.stringify(filters),
        groupCode: 'service',
        boardCode: 'blog',
      };

      if (startDate) {
        params.start_date = dayjs(startDate).format('YYYY-MM-DD');
      }
      if (endDate) {
        params.end_date = dayjs(endDate).format('YYYY-MM-DD');
      }

      return request.getPosts(params).then(({ data }) => data);
    },
    [locationState],
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadPosts({ page: pageIndex + 1, limit: pageSize }).then((data) => {
        setTopPosts(data.topPosts);
        setPosts(data.posts);
        setTotal(data.count);
      });
    },
    [loadPosts],
  );

  const columns = useMemo(
    () => [
      { Header: '게시글 번호', accessor: 'id' },
      { Header: '제목', accessor: 'title' },
      { Header: '공개 여부', accessor: 'isShow', Cell: ({ value: isShow }) => (isShow ? '공개' : '비공개') },
      {
        Header: '공개 범위',
        accessor: 'showTarget',
        Cell: ({ value: showTarget }) =>
          showTarget === 0 ? '전체 공개' : showTarget === 1 ? '회원 공개' : '어드민 공개',
      },
      { Header: '작성자 닉네임', accessor: 'FK_user.nickname' },
      {
        Header: '첨부파일 개수',
        accessor: 'fileCount',
      },
      {
        Header: '조회수',
        accessor: 'viewCount',
      },
      {
        Header: '작성일',
        accessor: 'createdAt',
        Cell: ({ value: createdAt }) => moment(createdAt).format('YYYY-MM-DD'),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/blog/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [],
  );

  const onChangeTopPosts = async (rows, post) => {
    setTopPosts(rows);
    const method = topPosts.length < rows.length ? 'create' : topPosts.length > rows.length ? 'delete' : 'update';
    let order;

    try {
      switch (method) {
        case 'create':
          order = rows.findIndex((row) => row.id === post.id) + 1;
          await request.createTopNotice({ notices: [{ board_code: 'blog', FK_post_id: post.id, order }] });
          break;

        case 'delete':
          order = topPosts.findIndex((topPost) => topPost.id === post.id) + 1;
          const params = { board_code: 'blog', notice_id: post.id, nextOrder: order + 1 };
          await request.deleteTopNotice(params);
          break;

        case 'update':
          const body = rows.map((row, index) => ({ board_code: 'blog', FK_post_id: row.id, order: index + 1 }));
          await request.updateTopNotice({ notices: body });
          break;

        default:
          break;
      }
    } catch (err) {
      const errorMessage = {
        create: '상단 고정에 실패했습니다.',
        delete: '상단 고정 해제에 실패했습니다.',
        update: '상단 고정 순서 변경에 실패했습니다.',
      };

      console.error(err);
      Swal.fire(errorMessage[method], '', 'error').then(() => {
        window.location.reload();
      });
    }
  };

  const onChangeNormalPosts = (rows) => {
    setPosts(rows);
  };

  const handleDragStart = useCallback(
    (start) => {
      setIsDragging(true);

      if (start.source.droppableId === 'normalPosts') {
        setIsDraggingNormal(true);
      }
    },
    [posts, topPosts],
  );

  const handleDragEnd = useCallback(
    (result) => {
      setIsDragging(false);
      setIsDraggingNormal(false);

      const { source, destination } = result;

      // 아이템이 리스트 밖으로 드래그된 경우
      if (!destination) return;

      const destId = destination.droppableId;
      const sourceId = source.droppableId;
      const destRows = destId === 'topPosts' ? topPosts : posts;
      const sourceRows = destId === 'topPosts' ? posts : topPosts;

      // 다른 리스트로 이동한 경우
      const copiedDestRows = [...destRows];
      if (sourceId !== destId) {
        const copiedSourceRows = [...sourceRows];
        const [removed] = copiedSourceRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        if (destId === 'topPosts') {
          onChangeTopPosts(copiedDestRows, removed).then(() => {
            onChangeNormalPosts(copiedSourceRows);
          });
        } else {
          onChangeTopPosts(copiedSourceRows, removed).then(() => {
            onChangeNormalPosts(copiedDestRows);
          });
        }
      } else {
        // 같은 리스트 내에서 이동한 경우
        if (destId !== 'topPosts') return;

        const [removed] = copiedDestRows.splice(source.index, 1);
        copiedDestRows.splice(destination.index, 0, removed);

        onChangeTopPosts(copiedDestRows, removed).then(() => {});
      }
    },
    [topPosts, posts],
  );

  return (
    <ThemeProvider theme={EJE_THEME}>
      <div className="posts">
        <section className="posts__header">
          <div className="posts__date">
            기간
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate && dayjs(startDate)}
                maxDate={endDate && dayjs(endDate)}
                onChange={(value) => {
                  setLocationState({
                    startDate: dayjs(value).format('YYYY-MM-DD'),
                  });
                }}
                label={startDate && 'From'}
                disableFuture
                views={['year', 'month', 'day']}
              />
              -
              <DatePicker
                value={endDate && dayjs(endDate)}
                minDate={startDate && dayjs(startDate)}
                onChange={(value) => {
                  setLocationState({
                    endDate: dayjs(value).format('YYYY-MM-DD'),
                  });
                }}
                label={endDate && 'From'}
                disableFuture
                views={['year', 'month', 'day']}
              />
            </LocalizationProvider>
          </div>

          <div className="posts__search">
            <Select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              displayEmpty
              color="pink"
              size="small"
            >
              <MenuItem value={'title'} sx={{ fontSize: '0.875rem' }}>
                제목
              </MenuItem>
              <MenuItem value={'id'} sx={{ fontSize: '0.875rem' }}>
                게시글 번호
              </MenuItem>
              <MenuItem value={'nickname'} sx={{ fontSize: '0.875rem' }}>
                작성자 닉네임
              </MenuItem>
            </Select>
            <Input
              value={searchText}
              placeholder="검색어를 입력하세요."
              color="pink"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onClickSearch();
              }}
            />
            <Button.MUI
              onClick={onClickSearch}
              variant="outlined"
              endIcon={<SearchIcon fontSize="small" />}
              color="pink"
              sx={{ height: '2rem' }}
            >
              검색
            </Button.MUI>
          </div>
          <div className="making-btn">
            <Button.Positive onClick={() => window.open(`/blog/new`)}>게시글 작성</Button.Positive>
          </div>
        </section>

        <div className="posts-top-container">
          <div className="infos__total">총 공지수 {total}</div>
        </div>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div className={`top-posts-container ${isDragging ? 'posts-blue' : 'not-dragging'}`}>
            <div className="top-posts-top-container">
              <div className="posts__total">
                📌 상단 고정 공지수 (
                <span
                  style={{
                    color: topPosts.length >= TOP_NOTICES_MAX ? 'red' : 'black',
                  }}
                >
                  {topPosts.length}
                </span>{' '}
                / {TOP_NOTICES_MAX}) <span style={{ color: 'red' }}>* 정렬된 순서대로 상단에 고정됩니다.</span>
              </div>
              <span className="posts__info">* 게시글을 드래그하여 옮길 수 있습니다.</span>
            </div>
            <Table
              data={topPosts}
              columns={columns}
              total={topPosts.length}
              pageSize={3}
              fetchData={() => {}}
              droppableId="topPosts"
            />
          </div>

          <div
            className={`normal-posts-container ${
              isDragging ? (isDraggingNormal ? 'posts-red' : 'posts-blue') : 'not-dragging'
            }`}
          >
            <div className="posts__total">📬 일반 공지수 {total - topPosts.length}</div>
            <Table
              data={posts}
              columns={columns}
              total={total - topPosts.length}
              pageSize={20}
              fetchData={fetchData}
              pauseDragDrop={topPosts.length >= TOP_NOTICES_MAX}
              droppableId="normalPosts"
            />
          </div>
        </DragDropContext>
      </div>
    </ThemeProvider>
  );
}

export default BlogList;
