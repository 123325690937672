import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import swal from 'sweetalert2';
import { deleteCancelAttendance, postTakeAttendance } from '../functions';

const ButtonItem = ({ date, time, student, now, attendanceBoardId, setAttendanceLength }) => {
  const [isToday, setIsToday] = useState(false);
  const [isPast, setIsPast] = useState(false);
  const [isAttended, setIsAttended] = useState(false);
  const [attendanceId, setAttendanceId] = useState(null);

  useEffect(() => {
    const nowDate = dayjs(now);
    const startTime = time.split('~')[0].trim() + ':00';
    const classDate = dayjs(`${date} ${startTime}`);
    setIsPast(nowDate.isAfter(classDate));

    const startOfDay = dayjs(`${date}`).startOf('day');
    const endOfDay = dayjs(`${date}`).endOf('day');
    setIsToday(nowDate.isBetween(startOfDay, endOfDay, null, '[]'));

    // 출석 여부 확인
    if (student?.AttendanceLogs) {
      const attended = student?.AttendanceLogs?.find((a) => {
        return a?.date === date;
      });

      if (attended) {
        setIsAttended(true);
        setAttendanceId(attended?.id);
      } else {
        setIsAttended(false);
      }
    }
  }, [now]);

  useEffect(() => {
    // console.log('isAttended', isAttended);
  }, [isAttended]);

  const takeAttendance = async ({ attendanceBoardId, userId, date, time, html, classId, scheduleId }) => {
    const { isConfirmed } = await swal.fire({
      icon: 'warning',
      title: '출석 체크를 하시겠습니까?',
      html,
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    });

    if (!isConfirmed)
      return {
        success: false,
        type: 'cancel',
      };

    try {
      const responseOfTakeAttendance = await postTakeAttendance({
        attendanceBoardId,
        userId,
        classId,
        scheduleId,
        date,
        time,
      });

      const {
        success,
        data: { attendance },
      } = responseOfTakeAttendance;
      // console.log(attendance);

      return {
        success,
        attendance,
      };
    } catch (error) {
      return {
        success: false,
      };
    }
  };
  const cancelAttendance = async ({ attendanceId }) => {
    const { isConfirmed } = await swal.fire({
      icon: 'warning',
      title: '출석 체크를 취소하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    });

    if (!isConfirmed) return false;

    // 출석 체크 취소
    const responseOfCancelAttendance = await deleteCancelAttendance({ id: attendanceId });

    const { success } = responseOfCancelAttendance;

    return {
      success,
      attendanceId,
    };
  };

  const handleAttendance = useCallback(async () => {
    if (!isToday) {
      swal.fire({
        icon: 'error',
        title: '오늘의 수업이 아닙니다.',
        text: '출석 체크는 당일에만 가능합니다.',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    } else if ((isPast && !isAttended) || (isToday && !isAttended)) {
      takeAttendance({
        attendanceBoardId,
        userId: student?.id,
        scheduleId: student?.scheduleId,
        classId: student?.classId,
        date,
        time,
        html: `<strong>${isPast ? '수업 시간이 지났습니다.' : '수업 시간입니다.'}</strong>(${date} ${time})`,
      })
        .then(({ success, attendance, type }) => {
          if (!success && type === 'cancel') return;
          if (!success) throw new Error('에러');

          student.AttendanceLogs.push(attendance);
          setIsAttended(true);
          setAttendanceLength((prev) => prev + 1);
          setAttendanceId(attendance?.id);
        })
        .catch((err) => {
          swal.fire({
            icon: 'error',
            title: '출석 체크 실패',
            text: '관리자에게 문의해주세요.',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        });
    } else if ((isPast && isAttended) || (isToday && isAttended)) {
      cancelAttendance({ attendanceId })
        .then(({ success, attendanceId }) => {
          if (!success) throw new Error('에러');

          student.AttendanceLogs = student.AttendanceLogs.filter((a) => a.id !== attendanceId);
          setIsAttended(false);
          setAttendanceLength((prev) => prev - 1);
          setAttendanceId(null);
        })
        .catch((err) => {
          swal.fire({
            icon: 'error',
            title: '출석 체크 취소 실패',
            text: '관리자에게 문의해주세요.',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        });
    } else {
      swal.fire({
        icon: 'error',
        title: '에러',
        text: '에러',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }
  }, [isPast, isToday, isAttended, attendanceId, attendanceBoardId, student, date, time, setAttendanceLength]);

  return (
    <Button
      variant="contained"
      size="small"
      data-date={date}
      data-time={time}
      data-student-id={student?.id}
      style={{
        color: isAttended || isPast ? '#333333' : !isToday ? '#888888' : '#FFFFFF',
        backgroundColor: isAttended ? '#FFA07A' : isToday ? '#FF7F50' : isPast ? '#BFC1C2' : '#AEC6CF',
        fontWeight: isToday || isAttended ? 'bold' : 'normal',
        width: '48%',
        minWidth: 'fit-content',
        padding: '0.5rem',
        fontSize: '0.75rem',
        cursor: !isToday ? 'not-allowed' : 'pointer',
        height: '2.875rem',
      }}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onClick={handleAttendance}
    >
      <span style={{ lineHeight: '1.3' }}>
        {date}
        {(isPast || (isToday && isAttended)) && (
          <>
            <br />
            {isAttended ? '출석' : '결석'}
          </>
        )}
      </span>
    </Button>
  );
};
export default ButtonItem;
