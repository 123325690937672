import { FalseIconWrap, TrueIconWrap } from './styled';

const TrueIcon = () => (
  <TrueIconWrap>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-check-lg"
      viewBox="0 0 16 16"
    >
      <path
        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"
        fill="#00b900"
      ></path>
    </svg>
  </TrueIconWrap>
);

const FalseIcon = () => (
  <FalseIconWrap>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-x-lg"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
        fill="#9e9e9e"
      ></path>
      <path
        fillRule="evenodd"
        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
        fill="#9e9e9e"
      ></path>
    </svg>
  </FalseIconWrap>
);
const BooleanIcon = ({ value }) => (value ? <TrueIcon /> : <FalseIcon />);

export default BooleanIcon;
