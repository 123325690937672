import { useEffect, useMemo, useState } from 'react';
import * as request from '../../../Common/Util/Request';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export default function SearchLogs() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => await request.getAllSearchLogs();
    fetchData().then((data) => {
      setRows(data);
      setSelected(data[0].word);
    });
  }, []);

  const columns = useMemo(() => {
    return [
      { id: 'keyword', label: '검색어', align: 'center', width: '20%' },
      {
        id: 'count',
        label: '검색횟수',
        align: 'center',
        width: '20%',
      },
    ];
  }, []);

  const selectedColumns = useMemo(() => {
    return [
      { id: 'createdAt', label: '검색 시간', align: 'center', width: '20%' },
      {
        id: 'keyword',
        label: '검색어',
        align: 'center',
        width: '20%',
      },
      { id: 'fk_user_id', label: '검색자', align: 'center', width: '20%' },
      {
        id: 'action_type',
        label: '유입 형태',
        align: 'center',
        width: '20%',
      },
    ];
  }, []);

  function showDetails(word) {
    setSelected(word);
  }

  useEffect(() => {
    const fetchLogs = async (selected) => await request.getSearchLogs({ word: selected });
    if (selected) {
      fetchLogs(selected).then((data) => {
        setSelectedRows(data.rows);
      });
    }
  }, [selected]);

  return (
    <div>
      <h3>누적 검색량</h3>
      <div style={{ display: 'flex', columnGap: '16px', alignItems: 'end' }}>
        <div style={{ minWidth: '300px' }}>
          <p style={{ fontSize: '14px', marginBottom: '0' }}>검색어 선택 시 상세 로그 확인 가능</p>
          <Paper sx={{ width: '100%', maxWidth: '600px', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '360px' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => {
                      return (
                        <TableCell key={column.id || index} align={column.align} style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((log, index) => {
                    return (
                      <TableRow key={index} onClick={() => showDetails(log.word)}>
                        <TableCell
                          align="center"
                          style={{
                            maxWidth: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {log.word}
                        </TableCell>
                        <TableCell align="center">{log.count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div>
          <div style={{ marginTop: '20px', width: '700px', height: '300px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width={500} height={300} data={rows.slice(0, 10)} barSize={20}>
                <XAxis dataKey="word" scale="point" padding={{ left: 10, right: 10 }} fontSize={13} />
                <YAxis fontSize={13} />
                <Tooltip contentStyle={{ fontSize: '14px' }} />
                {/*<Legend/>*/}
                <CartesianGrid strokeDasharray="3 3" fontSize={13} />
                <Bar dataKey="count" fill="#8884d8" background={{ fill: '#eee' }} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <p
            style={{
              fontSize: '13px',
              marginTop: '-8px',
              marginLeft: '60px',
              marginBottom: 0,
              color: 'lightpink',
              textAlign: 'right',
            }}
          >
            [검색량이 가장 많은 검색어 10개만 표시]
          </p>
        </div>
      </div>
      <hr />

      {selected && (
        <>
          <h3>"{selected}" 검색 이력</h3>
          <Paper sx={{ width: '100%', maxWidth: '800px', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '500px' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {selectedColumns.map((column, index) => (
                      <TableCell key={column.id || index} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRows?.map((log, index) => {
                    return (
                      <TableRow key={index} onClick={() => showDetails(log.word)}>
                        <TableCell align="center">{dayjs(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                        <TableCell align="center">{log.word}</TableCell>
                        <TableCell align="center">{log.fk_user_id === 1 ? '비회원' : log.fk_user_id}</TableCell>
                        <TableCell align="center">{log.action_type}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </div>
  );
}
