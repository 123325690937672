import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as request from '../../../Common/Util/Request';
import { useLocationState } from '../../../Common/Util/CustomHooks';
import Table from '../../../Common/Component/Table';
import * as Button from '../../../Common/Component/Button';
import { FormControl, MenuItem, Select } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './index.scss';
import SortHeader from '../../../Common/Component/Table/SortIcons';

function SurveyList() {
  const [locationState, setLocationState] = useLocationState({
    startDate: null,
    startTime: null,
    startCondition: 'gte',
    endDate: null,
    endTime: null,
    dueCondition: 'lte',
    searchType: 'title',
    searchText: '',
    sortInfo: { name: 'id', order: 'desc' },
  });
  const {
    startDate,
    startTime,
    startCondition,
    endDate,
    endTime,
    dueCondition,
    sortInfo: { name: sort, order },
  } = locationState;
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);
  const [total, setTotal] = useState(0);
  const [surveys, setSurveys] = useState([]);
  /** 검색 설정 */
  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
    });
  }, [setLocationState, searchType, searchText]);
  const onChangeStartDate = useCallback(
    (value) => {
      setLocationState({
        startDate: value,
        [!startTime && 'startTime']: dayjs().set('hour', 0).set('minute', 0).set('second', 0),
      });
    },
    [setLocationState, startTime],
  );
  const onChangeEndDate = useCallback(
    (value) => {
      setLocationState({
        endDate: value,
        [!endTime && 'endTime']: dayjs().set('hour', 23).set('minute', 59).set('second', 59),
      });
    },
    [setLocationState, endTime],
  );
  const resetStartConditions = useCallback(() => {
    setLocationState({
      startDate: null,
      startTime: null,
      startCondition: 'gte',
    });
  }, [setLocationState]);
  const resetEndConditions = useCallback(() => {
    setLocationState({
      endDate: null,
      endTime: null,
      dueCondition: 'lte',
    });
  }, [setLocationState]);
  /** 테이블 설정 */
  const loadSurveys = useCallback(
    ({ page, limit }) => {
      let params = {
        page,
        limit,
        sort,
        order,
      };
      if (searchText) {
        params = {
          ...params,
          [searchType]: searchText,
        };
      }
      if (startDate) {
        params.start = dayjs(startDate).format(`YYYY-MM-DDT${dayjs(startTime).format('HH:mm:ss')}`);
        params.startCondition = startCondition;
      }
      if (endDate) {
        params.due = dayjs(endDate).format(`YYYY-MM-DDT${dayjs(endTime).format('HH:mm:ss')}`);
        params.dueCondition = dueCondition;
      }
      return request.getSurveys(params).then(({ surveys }) => surveys);
    },
    [startDate, endDate, startTime, endTime, startCondition, dueCondition],
  );
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      loadSurveys({ page: pageIndex + 1, limit: pageSize }).then((data) => {
        setTotal(data.count);
        setSurveys(data.list);
      });
    },
    [loadSurveys],
  );
  const clickHeader = useCallback((columnId) => {
    const { name: prevSort, order: prevOrder } = locationState.sortInfo;
    const order = prevSort === columnId && prevOrder === 'desc' ? 'asc' : 'desc';

    setLocationState({
      sortInfo: {
        name: columnId,
        order,
      },
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="id">
            설문 ID
          </SortHeader>
        ),
        accessor: 'id',
        onClickHeader: () => clickHeader('id'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="title">
            제목
          </SortHeader>
        ),
        accessor: 'title',
        onClickHeader: () => clickHeader('title'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="startDatetime">
            시작시점
          </SortHeader>
        ),
        accessor: 'startDatetime',
        Cell: ({ value: startDatetime }) =>
          startDatetime ? dayjs.utc(startDatetime).format('YYYY년 MM월 DD일 HH시 mm분') : '제한 없음',
        onClickHeader: () => clickHeader('startDatetime'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="endDatetime">
            종료시점
          </SortHeader>
        ),
        accessor: 'endDatetime',
        Cell: ({ value: endDatetime }) =>
          endDatetime ? dayjs.utc(endDatetime).format('YYYY년 MM월 DD일 HH시 mm분') : '제한 없음',
        onClickHeader: () => clickHeader('endDatetime'),
      },
      {
        Header: (
          <SortHeader sort={locationState.sortInfo} name="response_count">
            응답 수
          </SortHeader>
        ),
        accessor: 'response_count',
        Cell: ({ value: response_count }) => response_count || 0,
        onClickHeader: () => clickHeader('response_count'),
      },
      {
        Header: '통계',
        accessor: (row) => (
          <button className="btn-statistics" onClick={() => window.open(`/survey/${row.id}/statistics`)}>
            통계
          </button>
        ),
      },
      {
        Header: '수정',
        accessor: (row) => (
          <button className="btn-detail" onClick={() => window.open(`/survey/list/new/${row.id}`)}>
            수정
          </button>
        ),
      },
    ],
    [],
  );
  const timeSteps = { hours: 1, minutes: 1, seconds: 1 };
  return (
    <div className="surveys">
      <section>
        <div className="surveys__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="title">설문 제목</option>
          </select>
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <Button.Positive onClick={onClickSearch}>검색</Button.Positive>
        </div>
        <div className="making-btn">
          <Button.Positive onClick={() => window.open(`/survey/list/new`)}>설문 생성</Button.Positive>
        </div>
      </section>
      <div className="surveys__date">
        <div className="surveys-detail__date">
          시작시점 설정 :
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate && dayjs(startDate)}
              maxDate={dayjs(endDate)}
              onChange={(value) => onChangeStartDate(dayjs(value).format('YYYY-MM-DD'))}
              sx={{ margin: '0 0.5rem' }}
            />
            <TimePicker
              ampm={false}
              timeSteps={timeSteps}
              value={startTime}
              disabled={!startDate}
              onChange={(value) =>
                setLocationState({
                  startTime: value,
                })
              }
              sx={{ margin: '0 0.5rem 0 0', width: '11rem' }}
            />
          </LocalizationProvider>
          <FormControl sx={{ minWidth: 100 }}>
            <Select
              onChange={(e) => {
                setLocationState({
                  startCondition: e.target.value,
                });
              }}
              value={startCondition}
              displayEmpty={true}
              defaultValue="gte"
              disabled={!startDate}
              sx={{ fontSize: '0.875rem', height: '1.7rem' }}
            >
              <MenuItem value="gte">부터</MenuItem>
              <MenuItem value="eq">일치</MenuItem>
              <MenuItem value="lte">까지</MenuItem>
            </Select>
          </FormControl>
          <Button.Positive onClick={resetStartConditions}>초기화</Button.Positive>
        </div>
        <div className="surveys-detail__date">
          종료시점 설정 :
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate && dayjs(endDate)}
              minDate={dayjs(startDate)}
              onChange={(value) => onChangeEndDate(dayjs(value).format('YYYY-MM-DD'))}
              sx={{ margin: '0 0.5rem' }}
            />
            <TimePicker
              ampm={false}
              timeSteps={timeSteps}
              value={endTime}
              disabled={!endDate}
              onChange={(value) =>
                setLocationState({
                  endTime: value,
                })
              }
              sx={{ margin: '0 0.5rem 0 0', width: '11rem' }}
            />
          </LocalizationProvider>
          <FormControl sx={{ minWidth: 100 }}>
            <Select
              onChange={(e) => {
                setLocationState({
                  dueCondition: e.target.value,
                });
              }}
              value={dueCondition}
              displayEmpty={true}
              defaultValue="lte"
              disabled={!endDate}
              sx={{ fontSize: '0.875rem', height: '1.7rem' }}
            >
              <MenuItem value="lte">까지</MenuItem>
              <MenuItem value="eq">일치</MenuItem>
              <MenuItem value="gte">부터</MenuItem>
            </Select>
          </FormControl>
          <Button.Positive onClick={resetEndConditions}>초기화</Button.Positive>
        </div>
      </div>
      <div className="surveys-container">
        <div className="surveys__total">총 설문수 {total}</div>
        <Table
          data={surveys}
          columns={columns}
          total={total}
          pageSize={10}
          sortedId={sort}
          isSortedDesc={order === 'desc'}
          fetchData={fetchData}
        />
      </div>
    </div>
  );
}

export default SurveyList;
