import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocationState } from '../../../Common/Util/CustomHooks';
import * as request from '../../../Common/Util/Request';
import * as Button from '../../../Common/Component/Button';
import Table from '../../../Common/Component/Table';
import './index.scss';
import Upsert from './Upsert';

function Artists(props) {
  const SwalWithReactContent = withReactContent(Swal);
  const [locationState, setLocationState] = useLocationState({
    searchType: 'name',
    searchText: '',
    filters: [],
  });
  const [searchType, setSearchType] = useState(locationState.searchType);
  const [searchText, setSearchText] = useState(locationState.searchText);

  const onClickSearch = useCallback(() => {
    setLocationState({
      searchType,
      searchText,
      pageIndex: 0,
      filters: searchText ? [{ id: searchType, value: searchText }] : [],
    });
  }, [setLocationState, searchType, searchText]);

  const [artists, setArtists] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const params = {
        ...locationState,
        page: pageIndex + 1,
        limit: pageSize,
      };
      request.artists(params).then(({ rows, count }) => {
        setArtists(rows);
        setTotal(count);
      });
    },
    [locationState],
  );
  const modalClose = (flag) => {
    if (flag) {
      fetchData({ pageIndex: 0, pageSize: 20 });
    }
    Swal.close();
  };

  const upsertAgency = (actionType, data) => {
    SwalWithReactContent.fire({
      title: data.name || '아티스트 등록',
      html: <Upsert modalClose={modalClose} data={data} />,
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  const [deleteTargets, setDeleteTargets] = useState([]);
  const checkboxHandler = ({ target: { checked, value } }) => {
    const id = +value;
    if (checked) setDeleteTargets([...deleteTargets, id]);
    else setDeleteTargets(deleteTargets.filter((target) => target !== id));
  };

  const deleteHandler = () => {
    Swal.fire({
      title: '정말 삭제하시겠습니까?',
      text: '삭제한 데이터는 복구할 수 없습니다.',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: '삭제',
      preConfirm: () =>
        request
          .deleteArtists(deleteTargets)
          .then(({ success, deletedArtistCount }) => {
            if (success) {
              Swal.fire({
                html: `<div style="margin-top:20px; font-weight: bold;">아티스트 정보 ${deletedArtistCount}개가<br>삭제되었습니다.</div>`,
                toast: true,
                position: 'center',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
                icon: 'success',
              });
            } else {
              throw new Error('삭제에 실패하였습니다.');
            }
          })
          .then(() => fetchData({ pageIndex: 0, pageSize: 20 }))
          .then(() => document.getElementsByName('deleteTargets').forEach((item) => (item.checked = false)))
          .then(() => setDeleteTargets([]))
          .catch(() => {
            Swal.fire({
              html: `<div style="margin-top:20px; font-weight: bold;">삭제에 실패했습니다.</div>`,
              toast: true,
              position: 'center',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false,
              icon: 'error',
              type: 'error',
            });
          }),
    });
  };

  return (
    <div className="artists">
      <section>
        <div className="artists__search">
          <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
            <option value="name">아티스트명</option>
            <option value="id">아티스트ID</option>
          </select>
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyUp={(e) => (e.keyCode === 13 || e.code === 'Enter') && onClickSearch()}
          />
          <Button.Negative onClick={onClickSearch}>검색</Button.Negative>
        </div>
        <div className="artists-action-buttons-wrapper">
          <Button.Negative onClick={deleteHandler} disabled={!deleteTargets.length}>
            삭제
          </Button.Negative>
          <Button.Positive onClick={() => upsertAgency('create', {})}>아티스트등록</Button.Positive>
        </div>
      </section>
      <div className="artists__total">총 아티스트수 {total}</div>
      <Table
        data={artists}
        total={total}
        pageSize={20}
        columns={[
          {
            Header: '선택',
            accessor: ({ id }) => (
              <input type="checkbox" name="deleteTargets" id={id} value={id} onChange={checkboxHandler} />
            ),
          },
          { Header: '아티스트ID', accessor: 'id' },
          { Header: '아티스트명', accessor: 'name' },
          {
            Header: '대표이미지',
            accessor: ({ thumbnail }) =>
              thumbnail ? (
                <img
                  src={thumbnail?.replace('enterjobedu.s3.ap-northeast-2.amazonaws.com', 'assets.enterjobedu.co.kr')}
                  alt="thumbnail"
                  className="list-thumbnail"
                />
              ) : (
                '-'
              ),
          },
          {
            Header: '정보수정',
            accessor: (row) => <button onClick={() => upsertAgency('update', row)}>수정</button>,
          },
        ]}
        fetchData={fetchData}
      />
    </div>
  );
}

export default Artists;
