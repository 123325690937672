import { useCallback } from 'react';
import ReactSelectSearch from 'react-select-search';
import './index.scss';

function SelectSearch(props) {
  const filterOptions = useCallback((options) => {
    return (value) => options.filter((option) => option.name.includes(value));
  }, []);

  return <ReactSelectSearch {...props} search filterOptions={filterOptions} />;
}

export default SelectSearch;
