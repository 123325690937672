import { StyledSortIcon } from './styled';

const ASCIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-sort-down-alt"
    viewBox="0 0 16 16"
  >
    <path
      d="M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z"
      stroke={fill}
      fill={fill}
    ></path>
  </svg>
);

const DESCIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-sort-down"
    viewBox="0 0 16 16"
  >
    <path
      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
      stroke={fill}
      fill={fill}
    ></path>
  </svg>
);

// fill : default lightgrey hex code
const SortIcon = ({ name, sort, setSort }) => {
  const isActive = sort.name === name;
  const isAsc = isActive && sort.order === 'asc';
  const fill = isActive ? '#000000' : '#777777';

  const handleClick = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev.name === name) {
        return { name, order: prev.order === 'asc' ? 'desc' : 'asc' };
      } else {
        return { name, order: 'asc' };
      }
    });
  };
  return (
    <StyledSortIcon active={isActive} onClick={handleClick}>
      {(!isActive || isAsc) && <ASCIcon fill={fill} />}
      {isActive && !isAsc && <DESCIcon fill={fill} />}
    </StyledSortIcon>
  );
};

export default SortIcon;
